import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useParams } from "react-router-dom";
import { Col, Form, Row } from "react-bootstrap";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
// import FormControl from "@mui/material/FormControl";
import { Box, Button as MuiButton } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ContractService from "../../../service/contract/ContractService";
import TopModal from "../../../components/common/TopModal";
import { toast } from "react-toastify";
import "./ContactsView.css";
import CircularProgress from "@mui/material/CircularProgress";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import CustomTabPanel from "../../../components/common/CustomTabPanel";
import ContractsViewTable from "../../../components/backend/contracts/contractsViewTable/ContractsViewTable";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ContractTextToEditModal from "../../../components/backend/contracts/modals/ContractTextToEditModal";
import ContractAddressEditModal from "../../../components/backend/contracts/modals/ContractAddressEditModal";
import ContractNameEditModal from "../../../components/backend/contracts/modals/ContractNameEditModal";
import ContractImagesModal from "../../../components/backend/contracts/modals/ContractImagesModal";
import PaymentService from "../../../service/payment/PaymentService";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import PrintIcon from "@mui/icons-material/Print";
import ActivitiesTable from "../../../components/backend/activities/ActivitiesTable";
import EventService from "../../../service/event/EventService";
import ContractCancelModal from "../../../components/backend/contracts/modals/ContractCancelContractModal";
import ContractBillingStatusModal from "../../../components/backend/contracts/modals/ContractBillingStatusModal";
import ContractsAbpTable from "../../../components/backend/contracts/ContractsAbpTable";
import ContractMakePaymentModal from "../../../components/backend/contracts/modals/ContractMakePaymentModal";
// import RescheduleModal from "../../../components/backend/contracts/modals/RescheduleModal";
// import RescheduleModal from "../../../components/backend/contracts/modals/RescheduleModal";
import RescheduleModal from "../../../components/backend/contracts/modals/RescheduleModal";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import SessionService from "../../../service/SessionService";
import ContractEarlyPayoffModal from "../../../components/backend/contracts/modals/ContractEarlyPayoffModal";
import {
	formatAmountWithComma,
	formatPhoneNumber,
	formatDate,
} from "../../../helpers/Utils";

function ContractsView3({ loading, setLoading }) {
	const { id } = useParams();
	const pageTitle = `Contract: ${id} | RTO Mobile`;
	const [contractDetail, setContractDetails] = useState(null);
	const [statusId, setStatusId] = useState(1);
	const [serialOrIMEI, setSerialOrIMEI] = useState(null);
	const [code, setCode] = useState(null);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [currentStep, setCurrentStep] = useState(0);
	const [disabledButton, setDisabledButton] = useState(false);
	const [modalLoading, setModalLoading] = useState(false);
	const [isRemoveDeviceVisible, setIsRemoveDeviceVisible] = useState(false);
	const [transactionHistory, setTransactionHistory] = useState([]);
	const [voidModalShow, setVoidModalShow] = useState(false);
	const [voidResponse, setVoidResponse] = useState(null);
	const [selectedPaymentVoid, setSelectedPaymentVoid] = useState(null);
	const [openAddModal, setOpenAddModal] = useState(null);
	const [retref, setRetref] = useState(null);
	const [invoices, setInVoices] = useState(null);
	const [eventLogData, setEventLogData] = useState([]);
	//const [cardToShow, setCardToShow] = useState(null);
	const [isCancelVisible, setIsCancelVisible] = useState(false);
	const [isEarlyPayoffVisible, setIsEarlyPayoffVisible] = useState(false);
	const [showContractTextToEditModal, setShowContractTextToEditModal] =
		useState(false);
	const [showContractAddressEditModal, setShowContractAddressEditModal] =
		useState(false);
	const [showContractNameEditModal, setShowContractNameEditModal] =
		useState(false);
	const [showContractImagesModal, setShowContractImagesModal] =
		useState(false);
	const [showBillingModal, setShowBillingModal] = useState(false);
	const [addTransactionCheck, setAddTransactionCheck] = useState(null);
	const [textToEditData, setTextToEditData] = useState({
		label: null,
		name: null,
		value: null,
		type: null,
		selectOptions: null,
	});

	const [namePayload, setNamePayload] = useState({
		first_name: "",
		last_name: "",
	});

	const [addresPayload, setAddressPayload] = useState({
		zip: "",
		city: "",
		state: "",
		address_1: "",
		address_2: "",
	});

	const [value, setValue] = useState(0);

	const [showDefaultModal, setShowDefaultModal] = useState(false);
	const [showContractMakePaymentModal, setShowContractMakePaymentModal] =
		useState(false);

	const [showAddDevice, setShowAddDevice] = useState(false);
	const [rescheduleIsOpen, setRescheduleIsOpen] = useState(false);

	const [changeDeviceResponse, setChangeDeviceResponse] = useState({
		manufacture: "BLU PRODUCTS INC",
		model: "BLU G91S",
		serial: "1120305021004654",
		imei: "358749540097136",
	});

	const handleOpenReschedule = () => {
		setRescheduleIsOpen(true);
	};

	const handleCloseReschedule = (refresh) => {
		setRescheduleIsOpen(false);
		if (refresh) {
			getContractById();
		}
	};

	const handleTabChange = (event, newValue) => {
		if (newValue === 2 || newValue === 4) {
			getTransactionHistory();
		} else if (newValue === 6) {
			getEventLogs();
		} else if (newValue === 3) {
			//setCardToShow("Token");
			openContractPrintWindow(paymentSheduleToken);
		} else if (newValue === 0) {
			setShowContractMakePaymentModal(true);
		}
		setValue(newValue);
	};

	const closeContractTextToEditModal = (refresh) => {
		setTextToEditData({
			label: null,
			name: null,
			value: null,
			type: null,
			selectOptions: null,
		});
		setShowContractTextToEditModal(false);
		if (refresh) {
			getContractById();
		}
	};

	const closeContractAddressEditModal = (refresh) => {
		setShowContractAddressEditModal(false);
		if (refresh) {
			getContractById();
		}
	};
	const closeContractNameEditModal = (refresh) => {
		setShowContractNameEditModal(false);
		if (refresh) {
			getContractById();
		}
	};
	const closeContractImagesModal = () => {
		setShowContractImagesModal(false);
	};

	function calculateTotal(fieldName, dataArray) {
		// Initialize sum variable
		let sum = 0;

		// Iterate through each object in the array
		dataArray.forEach((item) => {
			// Check if the field exists and is a number
			if (item[fieldName] && !isNaN(item[fieldName])) {
				// Add the value to the sum
				sum += parseFloat(item[fieldName]);
			}
		});

		// Return the final sum
		return sum.toFixed(2).toLocaleString();
	}

	/*function calculateBalances(dataArray) {
		// Initialize balance variable
		let balance = 0;

		// Iterate through each object in the array
		dataArray.forEach((item) => {
			// Check the type of the object and update the balance accordingly
			if (item.type === "Invoice") {
				balance += item.amount; // Add the amount to the balance for invoices
			} else if (item.type === "Payment") {
				balance -= item.amount; // Subtract the amount from the balance for payments
			}
			// Update the balance field for the current object
			item.running_balance = balance;
		});

		// Return the modified array with balance for each object
		return dataArray;
	}*/

	const openNewTab = () => {
		let base =
			window.location.protocol +
			"//" +
			window.location.hostname +
			(window.location.port ? ":" + window.location.port : "");
		const url = `${base}/api/payments/get-history-pdf?contract_id=${contractDetail?.id}&contract_number=${id}`;
		window.open(url, "_blank");
	};

	function isValidIMEI(imei) {
		if (/^\d{15}$/.test(imei)) {
			const imeiDigits = imei.split("").map(Number);
			let sum = 0;
			for (let i = 0; i < imeiDigits.length; i++) {
				let digit = imeiDigits[i];
				if (i % 2 === 1) {
					digit *= 2;
					if (digit > 9) {
						digit -= 9;
					}
				}
				sum += digit;
			}
			return sum % 10 === 0;
		}
		return false;
	}

	const OpenVoidModal = () => {
		setVoidModalShow(true);
	};

	const CloseVoidModal = () => {
		setVoidModalShow(false);
		setVoidResponse(null);
		setSelectedPaymentVoid(null);
	};

	const handleOpenAddModal = () => {
		setOpenAddModal(true);
	};

	const closeAddModal = () => {
		setOpenAddModal(false);
		setRetref(null);
		setAddTransactionCheck(null);
	};

	const openCancelModal = () => {
		setIsCancelVisible(true);
	};
	const closeCancelModal = () => {
		setIsCancelVisible(false);
	};

	const openEarlyPayoffModal = () => {
		setIsEarlyPayoffVisible(true);
	};
	const closeEarlyPayoffModal = () => {
		setIsEarlyPayoffVisible(false);
	};

	const [defaultCheck, setDefaultCheck] = useState(null);

	const setContractDefaulted = async () => {
		try {
			setLoading(true);
			setModalLoading(true);
			const response = await ContractService.getCancelReasons({
				contract_id: contractDetail?.id,
				cancel: {
					reason: "defaulted",
				},
			});
			setDefaultCheck(response?.data?.data?.cancel?.check);
			setShowDefaultModal(true);
			setModalLoading(false);
			setLoading(false);
		} catch (error) {
			setLoading(false);
			setModalLoading(false);
			console.error("Error fetching Contract:", error);
		}
	};

	const confirmContractDefault = async () => {
		try {
			setLoading(true);
			setModalLoading(true);
			//@todo : Do we need to store this response in a state?
			const response = await ContractService.getCancelReasons({
				contract_id: contractDetail?.id,
				cancel: {
					reason: "defaulted",
					check: defaultCheck,
				},
			});
			console.log(response);
			setShowDefaultModal(false);
			setModalLoading(false);
			setLoading(false);
		} catch (error) {
			setLoading(false);
			setModalLoading(false);
			console.error("Error fetching Contract:", error);
		}
	};

	// Custom Toolbar
	const CustomToolbar = () => {
		return (
			<>
				<MuiButton
					variant="contained"
					className=""
					color="success"
					style={{ background: "#d9534f" }}
					// disabled={false}
					onClick={handleOpenAddModal}
				>
					<div className="outer-div">
						<div className="bin-button">
							<AddCircleIcon></AddCircleIcon>
						</div>
						<span>Add CCN Payment</span>
					</div>
				</MuiButton>
				<MuiButton
					variant="contained"
					className=""
					color="primary"
					style={{ background: "#5bc0de", marginLeft: "10px" }}
					// disabled={false}
					onClick={openNewTab}
				>
					<div className="outer-div">
						<div className="bin-button">
							<PrintIcon></PrintIcon>
						</div>
						<span>Print History</span>
					</div>
				</MuiButton>
			</>
		);
	};

	const handleDeleteClick = (dataIndex) => {
		// Access the data of the clicked row using dataIndex
		setSelectedPaymentVoid(transactionHistory[dataIndex]?.inv_pmt_number);
		voidHistory(transactionHistory[dataIndex]?.inv_pmt_number);
	};

	const columns_test = [
		{
			name: "",
			label: "",
			options: {
				customBodyRenderLite: (dataIndex) => {
					return (
						<>
							{transactionHistory[dataIndex]?.can_void === 1 ? (
								<button
									onClick={() => handleDeleteClick(dataIndex)}
								>
									<HighlightOffIcon
										// color="red"
										style={{
											color: "red",
											cursor: "pointer",
										}}
									></HighlightOffIcon>
								</button>
							) : (
								<></>
							)}
						</>
					);
				},
			},
		},
		{
			name: "type",
			label: "Type",
		},
		// {
		// 	name: "name",
		// 	label: "Name",
		// },
		{
			name: "tender",
			label: "Tender",
		},
		{
			name: "cc_last_4",
			label: "CC Last 4",
		},
		{
			name: "reason_desc",
			label: "Reason Desc",
			options: {
				customBodyRenderLite: (dataIndex, rowIndex) => {
					return (
						<div style={{ whiteSpace: "nowrap" }}>
							{/* Replace 'data[dataIndex].type' with the appropriate way to access your data */}
							{transactionHistory[dataIndex]?.reason_desc}
						</div>
					);
				},
			},
		},
		{
			name: "Inv Pmt Number",
			label: "Inv / Pmt #",
		},
		{
			name: "auth_number",
			label: "Auth Number",
		},
		{
			name: "date",
			label: "Date",
			options: {
				customBodyRender: (value, dataIndex) => {
					// console.log();

					const date = new Date(value + " 00:00:00");

					const formattedDateOptions = {
						year: "numeric",
						month: "numeric",
						day: "numeric",
						hour12: true,
					};

					// if (dataIndex.rowData[1] === "Payment") {
					// 	formattedDateOptions.hour = "numeric";
					// 	formattedDateOptions.minute = "numeric";
					// 	formattedDateOptions.second = "numeric";
					// }

					const formattedDate = date.toLocaleDateString(
						"en-US",
						formattedDateOptions
					);

					return <div>{value ? formattedDate : ""}</div>;
				},
			},
		},

		{
			name: "amount",
			label: "Amount",
			options: {
				customBodyRenderLite: (dataIndex) => {
					return (
						<div
							style={{
								whiteSpace: "nowrap",
								textAlign: "right",
							}}
						>
							{/* Replace 'data[dataIndex].type' with the appropriate way to access your data */}
							{transactionHistory[dataIndex]?.amount && (
								<>${transactionHistory[dataIndex]?.amount}</>
							)}
						</div>
					);
				},
			},
		},
		{
			name: "sales_tax",
			label: "Sales Tax",
			options: {
				customBodyRenderLite: (dataIndex) => {
					return (
						<div
							style={{
								whiteSpace: "nowrap",
								textAlign: "right",
							}}
						>
							{transactionHistory[dataIndex]?.sales_tax && (
								<>${transactionHistory[dataIndex]?.sales_tax}</>
							)}
						</div>
					);
				},
			},
		},
		{
			name: "fee",
			label: "Fee",
			options: {
				customBodyRenderLite: (dataIndex) => {
					return (
						<div
							style={{
								whiteSpace: "nowrap",
								textAlign: "right",
							}}
						>
							{/* Replace 'data[dataIndex].type' with the appropriate way to access your data */}

							{transactionHistory[dataIndex]?.fee && (
								<>${transactionHistory[dataIndex]?.fee}</>
							)}
						</div>
					);
				},
			},
		},
		{
			name: "total_payment_auth",
			label: "Total Payment Auth",
			options: {
				customBodyRenderLite: (dataIndex) => {
					return (
						<div
							style={{
								whiteSpace: "nowrap",
								textAlign: "right",
							}}
						>
							{/* Replace 'data[dataIndex].type' with the appropriate way to access your data */}

							{transactionHistory[dataIndex]
								?.total_payment_auth && (
								<>
									$
									{
										transactionHistory[dataIndex]
											?.total_payment_auth
									}
								</>
							)}
						</div>
					);
				},
			},
		},
		{
			name: "total_payment_received",
			label: "Total Payment Received",
			options: {
				customBodyRenderLite: (dataIndex) => {
					return (
						<div
							style={{
								whiteSpace: "nowrap",
								textAlign: "right",
							}}
						>
							{/* Replace 'data[dataIndex].type' with the appropriate way to access your data */}

							{transactionHistory[dataIndex]
								?.total_payment_received && (
								<>
									$
									{
										transactionHistory[dataIndex]
											?.total_payment_received
									}
								</>
							)}
						</div>
					);
				},
			},
		},
		{
			name: "running_balance",
			label: "Contract Running Balance",
			options: {
				customBodyRenderLite: (dataIndex) => {
					return (
						<div
							style={{
								whiteSpace: "nowrap",
								textAlign: "right",
							}}
						>
							{/* Replace 'data[dataIndex].type' with the appropriate way to access your data */}

							{transactionHistory[dataIndex]?.running_balance && (
								<>
									$
									{
										transactionHistory[dataIndex]
											?.running_balance
									}
								</>
							)}
						</div>
					);
				},
			},
		},
	];

	const columns_fee_schedule = [
		{
			name: "invoice_id",
			label: "Invoice ID",
			options: {
				customBodyRenderLite: (dataIndex) => {
					return (
						<div style={{ whiteSpace: "nowrap" }}>
							{/* Replace 'data[dataIndex].type' with the appropriate way to access your data */}

							{invoices[dataIndex]?.fee && (
								<>{invoices[dataIndex]?.fee}</>
							)}
						</div>
					);
				},
			},
		},
		{
			name: "invoice_date",
			label: "Date",
			options: {
				customBodyRender: (value, dataIndex) => {
					// console.log();

					const date = new Date(value + " 00:00:00");

					const formattedDateOptions = {
						year: "numeric",
						month: "numeric",
						day: "numeric",
						hour12: true,
					};
					const formattedDate = date.toLocaleDateString(
						"en-US",
						formattedDateOptions
					);

					return <div>{value ? formattedDate : ""}</div>;
				},
			},
		},

		{
			name: "scheduled_amount",
			label: "Scheduled Amount",
			options: {
				customBodyRenderLite: (dataIndex) => {
					return (
						<div style={{ whiteSpace: "nowrap" }}>
							{/* Replace 'data[dataIndex].type' with the appropriate way to access your data */}
							{invoices[dataIndex]?.amount && (
								<>${invoices[dataIndex]?.amount}</>
							)}
						</div>
					);
				},
			},
		},
		{
			name: "debit_amount",
			label: "Debit Amount",
			options: {
				customBodyRenderLite: (dataIndex) => {
					return (
						<div style={{ whiteSpace: "nowrap" }}>
							{invoices[dataIndex]?.sales_tax && (
								<>${invoices[dataIndex]?.sales_tax}</>
							)}
						</div>
					);
				},
			},
		},
	];

	const columns_events_log = [
		{
			name: "created",
			label: "Timestamp",
			options: {
				customBodyRender: (value, dataIndex) => {
					// console.log(value);
					let parts = value.split(" ");
					const date = new Date(parts[0] + " 00:00:00");

					// console.log(date);

					const formattedDateOptions = {
						year: "numeric",
						month: "numeric",
						day: "numeric",
						hour12: true,
					};
					const formattedDate = date.toLocaleDateString(
						"en-US",
						formattedDateOptions
					);

					return (
						<div>{value ? formattedDate + " " + parts[1] : ""}</div>
					);
				},
			},
		},
		{
			name: "username",
			label: "User",
			options: {
				customBodyRenderLite: (dataIndex) => {
					return (
						<div style={{ whiteSpace: "nowrap" }}>
							{/* Replace 'data[dataIndex].type' with the appropriate way to access your data */}
							{eventLogData[dataIndex]?.username}
						</div>
					);
				},
			},
		},
		{
			name: "action",
			label: "Event",
			options: {
				customBodyRenderLite: (dataIndex) => {
					return (
						<div style={{ whiteSpace: "nowrap" }}>
							{eventLogData[dataIndex]?.action}
						</div>
					);
				},
			},
		},
		{
			name: "text",
			label: "Text",
			options: {
				customBodyRenderLite: (dataIndex) => {
					return (
						<div style={{ whiteSpace: "nowrap" }}>
							{/* Replace 'data[dataIndex].type' with the appropriate way to access your data */}
							{eventLogData[dataIndex]?.text}
						</div>
					);
				},
			},
		},
	];

	const getEventLogs = async () => {
		try {
			setLoading(true);
			const response = await EventService.get(contractDetail?.id);

			setEventLogData(response?.data?.data);
			// setTransactionHistory(tableData);
			// setInVoices(response?.data?.data?.invoices);
			// setShowTable(true);
			//setCardToShow("event");
			setLoading(false);
		} catch (error) {
			setLoading(false);
			console.error("Error fetching Event Logs:", error);
		}
	};

	const nextStep = () => {
		setCurrentStep(currentStep + 1);
	};

	const resetSteps = () => {
		setCurrentStep(0);
	};

	const handleChangeContinue = () => {
		if (
			currentStep !== 1 &&
			currentStep !== 2 &&
			currentStep !== 3 &&
			currentStep !== 4
		) {
			nextStep();
		} else if (currentStep === 1) {
			changeDevice();
		} else if (currentStep === 2) {
			lockDevice();
		} else if (currentStep === 3) {
			nextStep();
		} else if (currentStep === 4) {
			sendCode();
		}
	};

	const handleAddDeviceChangeContinue = () => {
		if (
			currentStep !== 0 &&
			currentStep !== 1 &&
			currentStep !== 2 &&
			currentStep !== 3
		) {
			nextStep();
		} else if (currentStep === 0) {
			addDevice();
		} else if (currentStep === 1) {
			addDeviceLock();
		} else if (currentStep === 2) {
			addDeviceSendCode();
		} else if (currentStep === 3) {
			addDeviceConfirm();
		}
	};
	const openModal = () => setIsModalVisible(true);
	const closeModal = () => {
		setIsModalVisible(false);
		resetSteps();
		setSerialOrIMEI("");
		setCode("");
	};

	const handleOpenAddDeviceModal = () => {
		setShowAddDevice(true);
	};

	const handleCloseAddDeviceModal = () => {
		setSerialOrIMEI("");
		setCode("");
		setShowAddDevice(false);
		resetSteps();
	};

	const openRemoveDeviceModal = () => {
		setIsRemoveDeviceVisible(true);
	};
	const closeRemoveDeviceModal = () => {
		setIsRemoveDeviceVisible(false);
	};

	const getTransactionHistory = async () => {
		try {
			setLoading(true);

			const response = await PaymentService.contractHistoryGet(
				contractDetail?.id
			);

			let reponse_data = response?.data?.data?.history
				? response?.data?.data?.history
				: [];

			// reponse_data = calculateBalances(reponse_data);

			const totalsRow = {
				type: null,
				tender: null,
				cc_last_4: null,
				reason_desc: null,
				inv_pmt_number: null,
				auth_number: null,
				date: null,
				amount: null,
				sales_tax: calculateTotal("sales_tax", reponse_data),
				fee: calculateTotal("fee", reponse_data),
				total_payment_auth: calculateTotal(
					"total_payment_auth",
					reponse_data
				),
				total_payment_received: calculateTotal(
					"total_payment_received",
					reponse_data
				),
				change: null,
				can_void: 0,
				running_balance: null,
			};

			const tableData = reponse_data
				? [...reponse_data, totalsRow]
				: [reponse_data, totalsRow];
			setTransactionHistory(tableData);
			setInVoices(
				response?.data?.data?.invoices
					? response?.data?.data?.invoices
					: []
			);
			// setShowTable(true);
			//setCardToShow("transaction");
			setLoading(false);
		} catch (error) {
			setLoading(false);
			console.error("Error fetching Contract:", error);
		}
	};

	function findIdByName(data, name) {
		for (let item of data) {
			if (item.name === name) {
				return item.id;
			}
		}
		return null; // Return null if the name is not found
	}

	const getContractById = async () => {
		try {
			setLoading(true);
			const response = await ContractService.fetchById(id);
			setContractDetails(response?.data?.data);
			// console.log(contractDetail);
			setStatusId(
				findIdByName(
					response?.data?.data?.dropdowns?.billing_status_name,
					response?.data?.data?.billing?.billing_status
				)
			);
			setAddressPayload({
				zip: response?.data?.data?.customer?.zip,
				city: response?.data?.data?.customer?.city,
				state: response?.data?.data?.customer?.state,
				address_1: response?.data?.data?.customer?.address_1,
				address_2: response?.data?.data?.customer?.address_2,
			});
			setNamePayload({
				first_name: response?.data?.data?.customer?.first_name,
				last_name: response?.data?.data?.customer?.last_name,
			});
			console.log("Response", response?.data?.data);
			setLoading(false);
		} catch (error) {
			setLoading(false);
			console.error("Error fetching Contract:", error);
		}
	};

	const changeDevice = async () => {
		try {
			setLoading(true);
			setModalLoading(true);
			const response = await ContractService.changeDevice(
				isValidIMEI(serialOrIMEI)
					? {
							contract_id: Number(contractDetail?.id),
							imei: Number(serialOrIMEI),
					  }
					: {
							contract_id: Number(contractDetail?.id),
							serial_number: serialOrIMEI,
					  }
			);

			if (response?.data?.isOk) {
				setCurrentStep(currentStep + 1);
				// console.log(response?.data);
				setChangeDeviceResponse(response?.data?.data?.device);
			} else {
				toast.error(`Error changing device: ${response?.data?.msg}`, {
					position: "top-center",
					autoClose: 1000,
				});
			}

			setLoading(false);
			setModalLoading(false);
		} catch (error) {
			setLoading(false);
			setModalLoading(false);
			console.error("Error changing device:", error);
		}
	};

	const lockDevice = async () => {
		try {
			setLoading(true);
			setModalLoading(true);
			const response = await ContractService.deviceChangeLock(
				isValidIMEI(serialOrIMEI)
					? {
							contract_id: Number(contractDetail?.id),
							imei: Number(serialOrIMEI),
					  }
					: {
							contract_id: Number(contractDetail?.id),
							serial_number: serialOrIMEI,
					  }
			);
			if (response?.data?.isOk) {
				setCode(response?.data?.data?.lock_code);
				setCurrentStep(currentStep + 1);
			} else {
				toast.error(`Error changing device: ${response?.data?.msg}`, {
					position: "top-center",
					autoClose: 1000,
				});
			}
			setLoading(false);
			setModalLoading(false);
		} catch (error) {
			setLoading(false);
			setModalLoading(false);
			console.error("Error locking device:", error);
		}
	};

	const sendCode = async () => {
		try {
			setLoading(true);
			setModalLoading(true);
			const response = await ContractService.deviceChangeCode(
				isValidIMEI(serialOrIMEI)
					? {
							contract_id: Number(contractDetail?.id),
							imei: Number(serialOrIMEI),
							code: code,
					  }
					: {
							contract_id: Number(contractDetail?.id),
							serial_number: serialOrIMEI,
							code: code,
					  }
			);
			if (response?.data?.isOk) {
				// closeModal();
				setCurrentStep(4);
				setLoading(false);
				setModalLoading(false);
			} else {
				toast.error(`Error changing device: ${response?.data?.msg}`, {
					position: "top-center",
					autoClose: 1000,
				});
				setLoading(false);
				setModalLoading(false);
			}
			setLoading(false);
			setModalLoading(false);
		} catch (error) {
			setLoading(false);
			setModalLoading(false);
			console.error("Error changing device:", error);
		}
	};

	/*const confirmChange = async () => {
		try {
			setLoading(true);
			setModalLoading(true);
			const response = await ContractService.deviceChangeCode(
				isValidIMEI(serialOrIMEI)
					? {
							contract_id: Number(contractDetail?.id),
							imei: Number(serialOrIMEI),
							code: code,
							confirmed: true,
					  }
					: {
							contract_id: Number(contractDetail?.id),
							serial_number: serialOrIMEI,
							code: code,
							confirmed: true,
					  }
			);
			if (response?.data?.isOk) {
				closeModal();
				setLoading(false);
				setModalLoading(false);
			} else {
				toast.error(`Error changing device: ${response?.data?.msg}`, {
					position: "top-center",
					autoClose: 1000,
				});
			}
		} catch (error) {
			setLoading(false);
			setModalLoading(false);
			console.error("Error changing device:", error);
		}
	}; */

	const [addResponse, setAddResponse] = useState({});

	const addDevice = async () => {
		try {
			setLoading(true);
			setModalLoading(true);
			const response = await ContractService.addDevice(
				isValidIMEI(serialOrIMEI)
					? {
							contract_id: Number(contractDetail?.id),
							imei: Number(serialOrIMEI),
					  }
					: {
							contract_id: Number(contractDetail?.id),
							serial_number: serialOrIMEI,
					  }
			);

			if (response?.data?.isOk) {
				setCurrentStep(currentStep + 1);
				setAddResponse(response?.data?.data?.device);
			} else {
				toast.error(`Error adding device: ${response?.data?.msg}`, {
					position: "top-center",
					autoClose: 1000,
				});
			}

			setLoading(false);
			setModalLoading(false);
		} catch (error) {
			setLoading(false);
			setModalLoading(false);
			console.error("Error adding device:", error);
		}
	};

	const addDeviceLock = async () => {
		try {
			setLoading(true);
			setModalLoading(true);
			const response = await ContractService.deviceAddLock(
				isValidIMEI(serialOrIMEI)
					? {
							contract_id: Number(contractDetail?.id),
							imei: isValidIMEI(serialOrIMEI)
								? Number(serialOrIMEI)
								: null,
					  }
					: {
							contract_id: Number(contractDetail?.id),
							serial_number: isValidIMEI(serialOrIMEI)
								? null
								: serialOrIMEI,
					  }
			);
			if (response?.data?.isOk) {
				setCode(response?.data?.data?.lock_code);
				setCurrentStep(currentStep + 1);
			} else {
				setLoading(false);
				setModalLoading(false);
				toast.error(`${response?.data?.msg}`, {
					position: "top-center",
					autoClose: 1000,
				});
			}
			setLoading(false);
			setModalLoading(false);
		} catch (error) {
			setLoading(false);
			setModalLoading(false);
			console.error("Error locking device:", error);
		}
	};

	const addDeviceSendCode = async () => {
		try {
			setLoading(true);
			setModalLoading(true);
			const response = await ContractService.deviceAddLock(
				isValidIMEI(serialOrIMEI)
					? {
							contract_id: Number(contractDetail?.id),
							imei: isValidIMEI(serialOrIMEI)
								? Number(serialOrIMEI)
								: null,
							code: code,
					  }
					: {
							contract_id: Number(contractDetail?.id),
							serial_number: isValidIMEI(serialOrIMEI)
								? null
								: serialOrIMEI,
							code: code,
					  }
			);
			if (response?.data?.isOk) {
				// handleCloseAddDeviceModal();
				setLoading(false);
				setModalLoading(false);
				setCurrentStep(3);
			} else {
				toast.error(`${response?.data?.msg}`, {
					position: "top-center",
					autoClose: 1000,
				});
				setLoading(false);
				setModalLoading(false);
			}
			setLoading(false);
			setModalLoading(false);
		} catch (error) {
			setLoading(false);
			setModalLoading(false);
			console.error("Error adding device:", error);
		}
	};

	const addDeviceConfirm = async () => {
		try {
			setLoading(true);
			setModalLoading(true);
			const response = await ContractService.deviceAddLock(
				isValidIMEI(serialOrIMEI)
					? {
							contract_id: Number(contractDetail?.id),
							imei: isValidIMEI(serialOrIMEI)
								? Number(serialOrIMEI)
								: null,
							code: code,
							confirmed: true,
					  }
					: {
							contract_id: Number(contractDetail?.id),
							serial_number: isValidIMEI(serialOrIMEI)
								? null
								: serialOrIMEI,
							code: code,
							confirmed: true,
					  }
			);
			if (response?.data?.isOk) {
				toast.success(`Device Added Successfully!`, {
					position: "top-center",
					autoClose: 1000,
				});
				handleCloseAddDeviceModal();
				setLoading(false);
				setModalLoading(false);
				getContractById();
			} else {
				toast.error(`${response?.data?.msg}`, {
					position: "top-center",
					autoClose: 1000,
				});
			}
		} catch (error) {
			setLoading(false);
			setModalLoading(false);
			console.error("Error adding device:", error);
		}
	};

	useEffect(() => {
		getContractById();
	}, [id]);

	const removeDevice = async () => {
		try {
			setLoading(true);
			setModalLoading(true);
			const response = await ContractService.removeDevice({
				contract_id: Number(contractDetail?.id),
				serial_number: contractDetail?.device?.serial,
			});
			setLoading(false);

			if (response?.data?.isOk) {
				closeRemoveDeviceModal();
				setModalLoading(false);
				toast.success(`${response?.data?.msg}`, {
					position: "top-center",
					autoClose: 1000,
				});
				getContractById();
			}
			closeRemoveDeviceModal();
			setModalLoading(false);
		} catch (error) {
			setModalLoading(false);
			setLoading(false);
			console.error("Error removing device:", error);
		}
	};

	const ChangeBillingStatus = async (selectedId) => {
		try {
			setLoading(true);
			setModalLoading(true);
			//@todo : Do we need to store this response in a state?
			const response = await ContractService.changeBillingStatus({
				contractId: Number(contractDetail?.id),
				status: selectedId,
			});
			console.log(response);
			setModalLoading(false);
			setLoading(false);
			setShowBillingModal(false);
			getContractById();
		} catch (error) {
			setLoading(false);
			console.error("Error changing billing statuses:", error);
		}
	};

	const testClick = (selectedID) => {
		// console.log(selectedID);
		ChangeBillingStatus(selectedID);
	};

	const voidHistory = async (paymentId) => {
		try {
			setLoading(true);
			setModalLoading(true);
			const response = await PaymentService.contractHistoryVoid({
				contract_id: Number(contractDetail?.id),
				payment_id: Number(paymentId),
			});

			if (response?.data?.isOk) {
				// console.log(response);
				setVoidResponse(response?.data?.data?.payment);
				OpenVoidModal();
			}
			setModalLoading(false);
			setLoading(false);
		} catch (error) {
			setModalLoading(false);

			setLoading(false);
			console.error("Error changing device:", error);
		}
	};

	const voidHistoryConfirm = async (
		paymentId,
		paymentNet,
		paymentTax,
		paymentFee,
		PaymentTotal
	) => {
		try {
			setLoading(true);
			setModalLoading(true);
			const response = await PaymentService.contractHistoryVoidConfirm({
				contract_id: Number(contractDetail?.id),
				payment_id: Number(paymentId),
				payment: {
					net: Number(paymentNet),
					tax: Number(paymentTax),
					fee: Number(paymentFee),
					total: Number(PaymentTotal),
				},
			});

			if (response?.data?.isOk) {
				// console.log(response);
				CloseVoidModal();
				setModalLoading(false);
			}
			setModalLoading(false);
			setLoading(false);
		} catch (error) {
			setLoading(false);
			setModalLoading(false);
			console.error("Error changing device:", error);
		}
	};

	const addHistory = async () => {
		try {
			setLoading(true);
			setModalLoading(true);
			const response = await PaymentService.contractHistoryAdd({
				contract_id: Number(contractDetail?.id),
				retref: Number(retref),
			});

			if (response?.data?.isOk) {
				setAddTransactionCheck(response?.data?.data?.check);
				// setVoidResponse(response?.data?.data?.payment);
				// OpenVoidModal();
			} else {
				toast.error(
					`Error adding Payment CCN: ${response?.data?.msg}`,
					{
						position: "top-center",
						autoClose: 1000,
					}
				);
			}
			setModalLoading(false);
			setLoading(false);
		} catch (error) {
			// setModalLoading(false);
			setLoading(false);
			console.error("Error changing device:", error);
		}
	};

	const addHistoryConfirm = async () => {
		try {
			setLoading(true);
			setModalLoading(true);
			const response = await PaymentService.contractHistoryVoidConfirm({
				contract_id: Number(contractDetail?.id),
				retref: Number(retref),
				authcode: addTransactionCheck,
			});

			if (response?.data?.isOk) {
				console.log(response);
				closeAddModal();
			}
			setModalLoading(false);
			setLoading(false);
		} catch (error) {
			setLoading(false);
			setModalLoading(false);
			console.error("Error changing device:", error);
		}
	};

	const steps = [
		<>
			<p>
				Are you sure you want to <strong>CHANGE THE DEVICE</strong> on
				this contract? This will <strong>unlock</strong> the current
				device and return it into your inventory.
			</p>
			<p>
				To successfully add the replacement device you need to test the
				locking functionality before the change can be completed.
			</p>
			<p>Please follow the steps through this tool.</p>
		</>,
		<>
			<p>
				Please provide the IMEI or SERIAL NUMBER of the replacement
				device.
			</p>

			<Box sx={{ flexGrow: 1 }}>
				<Grid container spacing={2} alignItems="center">
					<Grid item xs={12}>
						<TextField
							fullWidth
							variant="standard"
							label="IMEI/SERIAL"
							name="IMEI"
							placeholder="IMEI/SERIAL"
							value={serialOrIMEI}
							onChange={(event) => {
								setSerialOrIMEI(event.target.value);
								if (event.target.value) {
									setDisabledButton(false);
								} else {
									setDisabledButton(true);
								}
							}}
							sx={{ width: "80%" }} // Adjust width as needed
						/>
					</Grid>
				</Grid>
			</Box>
		</>,
		<div style={{ padding: "10px" }}>
			<p>
				<strong>Replacement Device Info</strong>
			</p>
			<Row style={{ textAlign: "start" }}>
				<Col xs={3}>
					<Row>
						<Col xs={12}>Device Mfr</Col>
						<Col xs={12}>Device Model</Col>
						<Col xs={12}>IMEI</Col>
						<Col xs={12}>Serial #</Col>
					</Row>
				</Col>
				<Col xs={9}>
					<Row>
						<Col xs={12}>{changeDeviceResponse?.manufacture}</Col>
						<Col xs={12}>{changeDeviceResponse?.model}</Col>
						<Col xs={12}>{changeDeviceResponse?.imei}</Col>
						<Col xs={12}>{changeDeviceResponse?.serial}</Col>
					</Row>
				</Col>
			</Row>
			<br />
			<Row style={{ textAlign: "start" }}>
				If this is the correct device please click 'Continue' to engage
				the lock on this device and test the locking functionality is
				properly installed.
			</Row>
		</div>,
		<>
			<Box sx={{ flexGrow: 1 }}>
				<Grid container spacing={2} alignItems="center">
					<Grid item xs={12}>
						<TextField
							fullWidth
							variant="standard"
							label="Unlock Code"
							name="unlock_code"
							placeholder="Unlock Code"
							value={code}
							onChange={(event) => {
								setCode(event.target.value);
								if (event.target.value) {
									setDisabledButton(false);
								} else {
									setDisabledButton(true);
								}
							}}
							sx={{ width: "80%" }} // Adjust width as needed
						/>
					</Grid>
				</Grid>
			</Box>
		</>,
		<>
			Please finally <strong>CONFIRM</strong> that you want to exchange
			these devices on this contract by clicking{" "}
			<strong>CONFIRM & PROCESS.</strong>
		</>,
	];

	const add_device_steps = [
		<>
			<p>Please provide the IMEI or SERIAL NUMBER of the new device.</p>

			<Box sx={{ flexGrow: 1 }}>
				<Grid container spacing={2} alignItems="center">
					<Grid item xs={3}>
						<Typography
							sx={{
								display: "flex",
								justifyContent: "flex-end",
								alignItems: "center",
								height: "100%",
							}}
						>
							IMEI/SERIAL
						</Typography>
					</Grid>
					<Grid item xs={9}>
						<TextField
							fullWidth
							variant="outlined"
							label="IMEI/SERIAL"
							name="IMEI"
							placeholder="IMEI/SERIAL"
							value={serialOrIMEI}
							onChange={(event) => {
								setSerialOrIMEI(event.target.value);
								if (event.target.value) {
									setDisabledButton(false);
								} else {
									setDisabledButton(true);
								}
							}}
							sx={{ width: "80%" }} // Adjust width as needed
						/>
					</Grid>
				</Grid>
			</Box>
		</>,
		<div style={{ padding: "10px" }}>
			<p>
				<strong>New Device Info</strong>
			</p>

			<Row style={{ textAlign: "start" }}>
				<Col xs={3}>
					<Row>
						<Col xs={12}>Device Mfr</Col>
						<Col xs={12}>Device Model</Col>
						<Col xs={12}>IMEI</Col>
						<Col xs={12}>Serial #</Col>
					</Row>
				</Col>
				<Col xs={9}>
					<Row>
						<Col xs={12}>{addResponse?.manufacturer}</Col>
						<Col xs={12}>{addResponse?.model}</Col>
						<Col xs={12}>{addResponse?.imei}</Col>
						<Col xs={12}>{addResponse?.serial}</Col>
					</Row>
				</Col>
			</Row>
			<br />
			<Row style={{ textAlign: "start" }}>
				If this is the correct device please click 'Continue' to engage
				the lock on this device and test the locking functionality is
				properly installed.
			</Row>
		</div>,
		<>
			<Box sx={{ flexGrow: 1 }}>
				<Grid container spacing={2} alignItems="center">
					<Grid item xs={12}>
						<TextField
							fullWidth
							variant="standard"
							label="Unlock Code"
							name="unlock_code"
							placeholder="Unlock Code"
							value={code}
							onChange={(event) => {
								setCode(event.target.value);
								if (event.target.value) {
									setDisabledButton(false);
								} else {
									setDisabledButton(true);
								}
							}}
							sx={{ width: "80%" }} // Adjust width as needed
						/>
					</Grid>
				</Grid>
			</Box>
		</>,
		<>
			Please finally <strong>CONFIRM</strong> that you want to add this
			device on this contract by clicking{" "}
			<strong>CONFIRM & PROCESS.</strong>
		</>,
	];

	const convertDate = (value) => {
		const date = new Date(value);
		const formattedDate = date.toLocaleDateString("en-US", {
			year: "numeric",
			month: "2-digit",
			day: "2-digit",
			// hour: "numeric",
			// minute: "numeric",
			// hour12: true,
		});

		return formattedDate;
	};

	useEffect(() => {
		checkIfDisabled();
		if (showAddDevice) {
			checkIfDisabledAddDeviceButtons();
		}
	}, [currentStep, showAddDevice]);

	const checkIfDisabled = () => {
		if (currentStep === 1) {
			if (!serialOrIMEI) {
				setDisabledButton(true);
			}
		} else if (currentStep === 3) {
			if (!code) {
				setDisabledButton(true);
			}
		} else {
			setDisabledButton(false);
		}
	};

	const checkIfDisabledAddDeviceButtons = () => {
		if (currentStep === 0) {
			if (!serialOrIMEI) {
				setDisabledButton(true);
			}
		} else if (currentStep === 2) {
			if (!code) {
				setDisabledButton(true);
			}
		} else {
			setDisabledButton(false);
		}
	};

	const modal_body = steps[currentStep];
	const modal_footer = (
		<div
			style={{
				display: "flex",
				justifyContent: "space-between",
				width: "100%",
			}}
		>
			<MuiButton
				// variant="danger"
				variant="contained"
				color="error"
				onClick={closeModal}
				disabled={modalLoading}
			>
				No
			</MuiButton>
			{currentStep === steps.length - 1 ? (
				<MuiButton
					variant="contained"
					color="success"
					onClick={handleChangeContinue}
					disabled={modalLoading}
				>
					CONFIRM AND PROCCESS
				</MuiButton>
			) : (
				<MuiButton
					variant="contained"
					color="success"
					onClick={handleChangeContinue}
					disabled={disabledButton || modalLoading}
				>
					Continue {">>>"}
				</MuiButton>
			)}
		</div>
	);

	const add_device_modal_body = add_device_steps[currentStep];
	const add_device_modal_footer = (
		<div
			style={{
				display: "flex",
				justifyContent: "space-between",
				width: "100%",
			}}
		>
			<MuiButton
				// variant="danger"
				variant="contained"
				color="error"
				onClick={handleCloseAddDeviceModal}
				disabled={modalLoading}
			>
				No
			</MuiButton>
			{currentStep === steps.length - 1 ? (
				<MuiButton
					variant="contained"
					color="success"
					onClick={handleAddDeviceChangeContinue}
					disabled={modalLoading}
				>
					CONFIRM AND PROCCESS
				</MuiButton>
			) : (
				<MuiButton
					variant="contained"
					color="success"
					onClick={handleAddDeviceChangeContinue}
					disabled={disabledButton || modalLoading}
				>
					Continue {">>>"}
				</MuiButton>
			)}
		</div>
	);

	const modal_title = <>Change Device</>;
	const modal_remove_device_body = (
		<>
			Are you sure you want to <strong>REMOVE THIS DEVICE</strong> from
			this contract? This will <strong>unlock</strong> the device and
			return it into your inventory.
		</>
	);
	const modal_remove_device_footer = (
		<div
			style={{
				display: "flex",
				justifyContent: "space-between",
				width: "100%",
			}}
		>
			<MuiButton
				variant="contained"
				color="error"
				onClick={closeRemoveDeviceModal}
				disabled={modalLoading}
			>
				No
			</MuiButton>

			<MuiButton
				variant="contained"
				color="success"
				// variant="success"
				onClick={removeDevice}
				disabled={modalLoading}
			>
				Yes
			</MuiButton>
		</div>
	);

	const void_modal_body = (
		<Row style={{ textAlign: "start" }}>
			<Col xs={3}>
				<Row>
					<Col xs={12}>Payment Tax</Col>
					<Col xs={12}>Payment Net</Col>
					<Col xs={12}>Payment Fee</Col>
					<Col xs={12}>Payment Total</Col>
				</Row>
			</Col>
			<Col xs={9}>
				<Row>
					<Col xs={12}>{voidResponse?.tax}</Col>
					<Col xs={12}>{voidResponse?.net}</Col>
					<Col xs={12}>{voidResponse?.fee}</Col>
					<Col xs={12}>{voidResponse?.net}</Col>
				</Row>
			</Col>
		</Row>
	);
	const void_modal_footer = (
		<div
			style={{
				display: "flex",
				justifyContent: "space-between",
				width: "100%",
			}}
		>
			<MuiButton
				variant="contained"
				color="error"
				// variant="danger"
				onClick={CloseVoidModal}
				disabled={modalLoading}
			>
				No
			</MuiButton>

			<MuiButton
				variant="success"
				onClick={() => {
					voidHistoryConfirm(
						selectedPaymentVoid,
						voidResponse?.net,
						voidResponse?.tax,
						voidResponse?.fee,
						voidResponse?.total
					);
				}}
				disabled={modalLoading}
			>
				CONFIRM AND PROCCESS
			</MuiButton>
		</div>
	);

	const add_modal_body =
		addTransactionCheck === null ? (
			<Form.Group as={Row}>
				<Form.Label
					column
					xs={3}
					className="input-label"
					style={{
						display: "flex",
						justifyContent: "end",
					}}
				>
					Transaction #{" "}
				</Form.Label>
				<Col xs={9}>
					<Form.Control
						style={{ width: "80%" }}
						name="TransactionNumber"
						placeholder="Transaction #"
						value={retref}
						onChange={(event) => {
							setRetref(event?.target?.value);
						}}
					/>
				</Col>
			</Form.Group>
		) : (
			<div>Confirm Add Transaction</div>
		);

	const add_modal_footer = (
		<div
			style={{
				display: "flex",
				justifyContent: "space-between",
				width: "100%",
			}}
		>
			<MuiButton
				color="error"
				variant="contained"
				onClick={closeAddModal}
				disabled={modalLoading}
			>
				Cancel
			</MuiButton>
			{addTransactionCheck === null ? (
				<MuiButton
					color="success"
					variant="contained"
					onClick={() => {
						addHistory();
					}}
					disabled={modalLoading || !retref}
				>
					Submit
				</MuiButton>
			) : (
				<MuiButton
					color="success"
					variant="contained"
					onClick={() => {
						addHistoryConfirm();
					}}
					disabled={modalLoading}
				>
					Confirm
				</MuiButton>
			)}
		</div>
	);

	/**
	 * Token for payment schedule page .
	 * base64encode({contract_id:123,contract_number:12345678})
	 * @todo: move to a new logic
	 */
	const paymentSheduleToken = btoa(
		JSON.stringify({
			contract_id: Number(contractDetail?.id),
			contract_number: id,
		})
	);

	let canViewBackMarketCost =
		SessionService.canSystemAdmin() ||
		SessionService.canRtoAdmin() ||
		SessionService.canIsoAdmin() ||
		SessionService.canMasterAgentAdmin() ||
		SessionService.canOrganizationAdmin() ||
		SessionService.canCompanyAdmin() ||
		SessionService.canStoreAdmin();
	let canEditBackMarketCost =
		SessionService.canSystemAdmin() || SessionService.canRtoAdmin();

	function openContractPrintWindow(paymentScheduleToken) {
		// Base URL - adjust this to your application's base URL
		const baseUrl = window.location.origin;

		// Constructing the URL with the path and query parameter
		const url = `${baseUrl}/contracts/print?token=${encodeURIComponent(
			paymentScheduleToken
		)}`;

		// Opening a new window with the constructed URL
		window.open(url, "_blank");
	}

	return (
		<>
			<Helmet>
				<title>{pageTitle}</title>
				<meta name="robots" content="noindex,nofollow"></meta>
				<meta property="og:title" content={pageTitle} />
				<meta name="twitter:title" content={pageTitle} />
			</Helmet>
			<div className="rto_form-sections">
				<TopModal
					isVisible={openAddModal}
					body={
						<>
							{add_modal_body}{" "}
							{modalLoading && (
								<div className="loading-backdrop">
									<CircularProgress
									// animation="border"
									// variant="primary"
									/>
								</div>
							)}
						</>
					}
					footer={add_modal_footer}
					title={"Add Card Connect Transaction"}
					onClose={closeAddModal}
					loading={modalLoading}
				></TopModal>
				<TopModal
					isVisible={voidModalShow}
					body={
						<>
							{void_modal_body}{" "}
							{modalLoading && (
								<div className="loading-backdrop">
									<CircularProgress
									// animation="border"
									// variant="primary"
									/>
								</div>
							)}
						</>
					}
					footer={void_modal_footer}
					title={"Confirm Void Payment"}
					onClose={CloseVoidModal}
					loading={modalLoading}
				></TopModal>
				<TopModal
					isVisible={isModalVisible}
					body={
						<>
							{modal_body}{" "}
							{modalLoading && (
								<div className="loading-backdrop">
									<CircularProgress
									// animation="border"
									// variant="primary"
									/>
								</div>
							)}
						</>
					}
					footer={modal_footer}
					title={modal_title}
					onClose={closeModal}
					loading={modalLoading}
				></TopModal>

				{/* ADD DEVICE MODAL START*/}
				<TopModal
					isVisible={showAddDevice}
					body={
						<>
							{add_device_modal_body}{" "}
							{modalLoading && (
								<div className="loading-backdrop">
									<CircularProgress
									// animation="border"
									// variant="primary"
									/>
								</div>
							)}
						</>
					}
					footer={add_device_modal_footer}
					title={"Add Secured Device To Contract"}
					onClose={handleCloseAddDeviceModal}
					loading={modalLoading}
				></TopModal>
				{/* ADD DEVICE MODAL END */}

				<TopModal
					isVisible={isRemoveDeviceVisible}
					body={
						<>
							{modal_remove_device_body}{" "}
							{modalLoading && (
								<div className="loading-backdrop">
									<CircularProgress />
								</div>
							)}
						</>
					}
					title="Remove Device"
					footer={modal_remove_device_footer}
					onClose={closeRemoveDeviceModal}
					loading={modalLoading}
				></TopModal>

				<TopModal
					isVisible={showDefaultModal}
					body={
						<>
							{
								"Are you sure you want to mark this contract as defaulted? This action cannot be reversed."
							}
							{modalLoading && (
								<div className="loading-backdrop">
									<CircularProgress />
								</div>
							)}
						</>
					}
					title="Mark As default"
					footer={
						<div
							style={{
								display: "flex",
								justifyContent: "space-between",
								width: "100%",
							}}
						>
							<MuiButton
								variant="contained"
								color="error"
								onClick={() => {
									setShowDefaultModal(false);
								}}
								disabled={modalLoading}
							>
								Cancel
							</MuiButton>

							<MuiButton
								variant="contained"
								color="success"
								// variant="success"
								onClick={() => {
									// setShowDefaultModal(false);
									confirmContractDefault();
								}}
								disabled={modalLoading}
							>
								Ok
							</MuiButton>
						</div>
					}
					onClose={() => {
						setShowDefaultModal(false);
					}}
					loading={modalLoading}
				></TopModal>

				<RescheduleModal
					show={rescheduleIsOpen}
					setShow={setRescheduleIsOpen}
					onHide={handleCloseReschedule}
					contractId={id}
					contractIdShort={contractDetail?.id}
				></RescheduleModal>

				{/* ************** CANCEL MODAL START ************** */}
				<ContractCancelModal
					isCancelVisible={isCancelVisible}
					closeCancelModal={closeCancelModal}
					contractId={contractDetail?.id}
					setLoading={setLoading}
				></ContractCancelModal>
				{/* ************** CANCEL MODAL END ************** */}

				{/* ************** Early Payoff MODAL START ************** */}
				<ContractEarlyPayoffModal
					isEarlyPayoffVisible={isEarlyPayoffVisible}
					closeEarlyPayoffModal={closeEarlyPayoffModal}
					contractId={contractDetail?.id}
					contractDetail={contractDetail}
					setLoading={setLoading}
				></ContractEarlyPayoffModal>
				{/* ************** Early Payoff MODAL END ************** */}

				{/* TextToEdit modal Begin */}
				<ContractTextToEditModal
					show={showContractTextToEditModal}
					onHide={closeContractTextToEditModal}
					setTextToEditData={setTextToEditData}
					textToEditData={textToEditData}
					contractId={contractDetail?.id}
				></ContractTextToEditModal>
				{/* TextToEdit modal End */}

				{/* Billing status modal Begin */}
				<ContractBillingStatusModal
					show={showBillingModal}
					// onHide={handleCloseBillingStatusModal}
					contractId={contractDetail?.id}
					contractDetail={contractDetail}
					setBillingStatus={testClick}
					setShowBillingModal={setShowBillingModal}
					statusId={statusId}
					setStatusId={setStatusId}
				></ContractBillingStatusModal>
				{/* Billing status modal End */}

				{/* AddressEdit modal Begin */}
				<ContractAddressEditModal
					show={showContractAddressEditModal}
					onHide={closeContractAddressEditModal}
					contractDetail={contractDetail}
					address={addresPayload}
					setAddress={setAddressPayload}
					modalLoading={modalLoading}
					setModalLoading={setModalLoading}
				></ContractAddressEditModal>
				{/* AddressEdit modal Ends */}

				{/* NameEdit modal Begin */}
				<ContractNameEditModal
					show={showContractNameEditModal}
					onHide={closeContractNameEditModal}
					contractDetail={contractDetail}
					namePayload={namePayload}
					setNamePayload={setNamePayload}
					modalLoading={modalLoading}
					setModalLoading={setModalLoading}
				></ContractNameEditModal>
				{/* NameEdit modal Ends */}

				{/* Images modal Begin */}
				<ContractImagesModal
					show={showContractImagesModal}
					onHide={closeContractImagesModal}
					contractId={contractDetail?.id}
				></ContractImagesModal>

				<div className="rto_content-div" style={{ maxWidth: "1600px" }}>
					<Box
						display="flex"
						justifyContent="flex-end"
						backgroundColor="#f5f5f5"
						p={(0.5, 1)}
						borderRadius={1}
					>
						<Breadcrumbs aria-label="breadcrumb">
							<Link to="/dashboard" className="rto_link-2">
								Dashboard
							</Link>
							<Link to="/contracts" className="rto_link-2">
								Contracts
							</Link>
							<Typography>{id}</Typography>
						</Breadcrumbs>
					</Box>
					<div className="rto_title-div">
						<h1 className="rto_page-title">Contract: {id}</h1>
						<p className="contracts-sub-text">
							<strong>Store:</strong>{" "}
							{contractDetail?.store?.name}
						</p>
						<p className="contracts-sub-text">
							MID: {contractDetail?.store?.mid} | StoreID:{" "}
							{contractDetail?.store?.id} | ContractID:{" "}
							{contractDetail?.id}
						</p>
						{/* <p>
							<button className="btn btn-xs btn-info">
								Switch To Store / ContractID{" "}
								{contractDetail?.id}
								<br></br>
								{"[prod1]"}
							</button>
						</p> */}
					</div>
					<div>
						<Row>
							<Col xs={12} lg={6} className="view-left">
								<Row>
									<Col xs={12} lg={4}>
										<div
											style={{
												height: "100px",
												width: "100px",
												// border: "solid 5px #21557a",
												borderRadius: "50%",
												margin: "0px 0px 15px",
												display: "flex",
												justifyContent: "center",
												alignItems: "center",
												// overflow: "hidden",
											}}
										>
											<AccountCircleIcon
												onClick={() => {
													setShowContractImagesModal(
														true
													);
												}}
												style={{
													height: "100%",
													width: "100%",
													cursor: "pointer",
												}}
											></AccountCircleIcon>
										</div>
									</Col>
									<Col xs={12} lg={8}>
										<Row>
											<Col
												xs={12}
												className="rto_contract-name"
												style={{
													display: "flex",
													gap: "5px",
												}}
											>
												<span
													style={{
														cursor: "pointer",
													}}
													onClick={() => {
														setShowContractNameEditModal(
															true
														);
													}}
												>
													<svg
														xmlns="http://www.w3.org/2000/svg"
														width="14"
														height="14"
														fill="currentColor"
														className="bi bi-pencil-fill"
														viewBox="0 0 16 16"
													>
														<path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
													</svg>
												</span>
												{contractDetail?.customer?.name
													? contractDetail?.customer
															?.name
													: ""}
											</Col>
										</Row>
									</Col>
								</Row>
								<Row>
									<Col xs={3}>
										<strong>Address:</strong>
									</Col>
									<Col
										xs={9}
										style={{
											display: "flex",
											gap: "5px",
										}}
									>
										<span
											style={{ cursor: "pointer" }}
											onClick={() => {
												setShowContractAddressEditModal(
													true
												);
											}}
										>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="14"
												height="14"
												fill="currentColor"
												className="bi bi-pencil-fill"
												viewBox="0 0 16 16"
											>
												<path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
											</svg>
										</span>
										{contractDetail?.customer?.address_1}
										<p>
											{contractDetail?.customer?.city},{" "}
											{contractDetail?.customer?.state}{" "}
											{contractDetail?.customer?.zip}
										</p>
									</Col>
									<Col xs={3}>
										<strong>DOB:</strong>
									</Col>
									<Col
										xs={9}
										style={{
											display: "flex",
											gap: "5px",
										}}
									>
										<span
											style={{ cursor: "pointer" }}
											onClick={() => {
												setShowContractTextToEditModal(
													true
												);
												setTextToEditData({
													label: "DOB",
													value:
														contractDetail?.customer
															?.date_of_birth ||
														"",
													name: "date_of_birth",
													type: "date",
												});
											}}
										>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="16"
												height="16"
												fill="currentColor"
												className="bi bi-pencil-fill"
												viewBox="0 0 16 16"
											>
												<path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
											</svg>
										</span>
										{formatDate(
											contractDetail?.customer
												?.date_of_birth
										)}
									</Col>
									<Col xs={3}>
										<strong>SSN (Last 4):</strong>
									</Col>
									<Col
										xs={9}
										style={{
											display: "flex",
											gap: "5px",
										}}
									>
										<span
											style={{ cursor: "pointer" }}
											onClick={() => {
												setShowContractTextToEditModal(
													true
												);
												setTextToEditData({
													label: "SSN",
													value:
														contractDetail?.customer
															?.ssn_last_4 || "",
													name: "ssn_last_4",
													type: "ssn",
												});
											}}
										>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="16"
												height="16"
												fill="currentColor"
												className="bi bi-pencil-fill"
												viewBox="0 0 16 16"
											>
												<path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
											</svg>
										</span>
										{contractDetail?.customer?.ssn_last_4}
									</Col>
									<Col xs={3}>
										<strong>Phone:</strong>
									</Col>
									<Col
										xs={9}
										style={{
											display: "flex",
											gap: "5px",
										}}
									>
										<span
											style={{ cursor: "pointer" }}
											onClick={() => {
												setShowContractTextToEditModal(
													true
												);
												setTextToEditData({
													label: "Phone",
													value: `+1${
														contractDetail?.customer
															?.phone_1 || ""
													}`,
													name: "phone_1",
													type: "phone",
												});
											}}
										>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="14"
												height="14"
												fill="currentColor"
												className="bi bi-pencil-fill"
												viewBox="0 0 16 16"
											>
												<path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
											</svg>
										</span>
										{formatPhoneNumber(
											contractDetail?.customer?.phone_1
										)}
									</Col>
									<Col xs={3}>
										<strong>Phone 2:</strong>
									</Col>
									<Col
										xs={9}
										style={{
											display: "flex",
											gap: "5px",
										}}
									>
										<span
											style={{ cursor: "pointer" }}
											onClick={() => {
												setTextToEditData({
													label: "Phone 2",
													value: `+1${
														contractDetail?.customer
															?.phone_2 || ""
													}`,
													name: "phone_2",
													type: "phone",
												});
												setShowContractTextToEditModal(
													true
												);
											}}
										>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="14"
												height="14"
												fill="currentColor"
												className="bi bi-pencil-fill"
												viewBox="0 0 16 16"
											>
												<path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
											</svg>
										</span>
										{formatPhoneNumber(
											contractDetail?.customer?.phone_2
										)}
									</Col>
									<Col xs={3}>
										<strong>Email:</strong>
									</Col>
									<Col
										xs={9}
										style={{
											display: "flex",
											gap: "5px",
										}}
									>
										<span
											style={{ cursor: "pointer" }}
											onClick={() => {
												setTextToEditData({
													label: "Email",
													value:
														contractDetail?.customer
															?.email_address ||
														"",
													name: "email",
													type: "email",
												});
												setShowContractTextToEditModal(
													true
												);
											}}
										>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="14"
												height="14"
												fill="currentColor"
												className="bi bi-pencil-fill"
												viewBox="0 0 16 16"
											>
												<path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
											</svg>
										</span>
										{
											contractDetail?.customer
												?.email_address
										}
									</Col>
								</Row>
								<div
									style={{
										border: "solid 1px #21557a",
										margin: "10px 0px ",
									}}
								></div>
								<Row>
									<Col xs={3}>
										<strong>Device:</strong>
									</Col>
									<Col xs={9} style={{ textAlign: "right" }}>
										{contractDetail?.device?.manufacturer}{" "}
										{contractDetail?.device?.model}
									</Col>
									<Col xs={3}>
										<strong>Serial Number:</strong>
									</Col>
									<Col xs={9} style={{ textAlign: "right" }}>
										{contractDetail?.device?.serial}
									</Col>
								</Row>
								<div
									style={{
										border: "solid 1px #21557a",
										margin: "10px 0px ",
									}}
								></div>
								<Row>
									<Col xs={12}>
										<Row>
											<Col xs={4}>
												<strong>MDN 1:</strong>
											</Col>
											<Col
												xs={8}
												style={{
													textAlign: "right",
												}}
											>
												{contractDetail?.device?.mdn_1}
											</Col>
											<Col xs={4}>
												<strong>IMEI 1:</strong>
											</Col>
											<Col
												xs={8}
												style={{
													textAlign: "right",
												}}
											>
												{contractDetail?.device?.imei_1}
											</Col>
											<Col xs={4}>
												<strong>SERIAL 1:</strong>
											</Col>
											<Col
												xs={8}
												style={{
													textAlign: "right",
												}}
											>
												{contractDetail?.device?.serial}
											</Col>
											<Col xs={4}>
												<strong>SIM 1:</strong>
											</Col>
											<Col
												xs={8}
												style={{
													textAlign: "right",
												}}
											>
												{
													contractDetail?.device
														?.iccid_1
												}
											</Col>
											<Col xs={4}>
												<strong>
													Guarantee Status:
												</strong>
											</Col>
											<Col
												xs={8}
												style={{
													textAlign: "right",
												}}
											>
												{
													contractDetail?.billing
														?.guarantee_status
												}
											</Col>
											<Col xs={4}>
												<strong>Device Memory:</strong>
											</Col>
											<Col
												xs={8}
												style={{
													textAlign: "right",
												}}
											>
												{
													contractDetail?.device
														?.device_memory
												}
												GB
											</Col>
										</Row>
									</Col>
									<Col xs={12}>
										<Row>
											<Col xs={3}>
												<strong>Lock Status</strong>
											</Col>
											<Col
												xs={9}
												style={{
													textAlign: "right",
												}}
											>
												<span className="text-success">
													{
														contractDetail?.device
															?.lock_status
													}
												</span>
											</Col>
											<Col xs={3}>
												<strong>DCS ID:</strong>
											</Col>
											<Col
												xs={9}
												style={{
													textAlign: "right",
												}}
											>
												{contractDetail?.device?.dcs_id}
											</Col>
											<Col xs={3}>
												<strong>Lock Date:</strong>
											</Col>
											<Col
												xs={9}
												style={{
													textAlign: "right",
												}}
											>
												{contractDetail?.device
													?.lock_date &&
													convertDate(
														contractDetail?.device
															?.lock_date
													)}
											</Col>
										</Row>
									</Col>
								</Row>
								<div
									style={{
										border: "solid 1px #21557a",
										margin: "10px 0px ",
									}}
								></div>
								<Row>
									<Col xs={3}>
										<strong>Device Actions:</strong>
									</Col>
									{contractDetail?.buttons?.device_add ===
									false ? (
										<Col
											xs={9}
											style={{
												display: "flex",
												justifyContent: "end",
												gap: "10px",
											}}
										>
											{contractDetail?.buttons
												?.device_change && (
												<MuiButton
													color="success"
													variant="outlined"
													className=""
													disabled={false}
													onClick={openModal}
												>
													<div className="outer-div">
														<div className="bin-button">
															<svg
																className="gear"
																xmlns="http://www.w3.org/2000/svg"
																width="24"
																height="24"
																viewBox="0 0 24 24"
															>
																<path
																	fill="green"
																	d="M24 13.616v-3.232c-1.651-.587-2.694-.752-3.219-2.019v-.001c-.527-1.271.1-2.134.847-3.707l-2.285-2.285c-1.561.742-2.433 1.375-3.707.847h-.001c-1.269-.526-1.435-1.576-2.019-3.219h-3.232c-.582 1.635-.749 2.692-2.019 3.219h-.001c-1.271.528-2.132-.098-3.707-.847l-2.285 2.285c.745 1.568 1.375 2.434.847 3.707-.527 1.271-1.584 1.438-3.219 2.02v3.232c1.632.58 2.692.749 3.219 2.019.53 1.282-.114 2.166-.847 3.707l2.285 2.286c1.562-.743 2.434-1.375 3.707-.847h.001c1.27.526 1.436 1.579 2.019 3.219h3.232c.582-1.636.75-2.69 2.027-3.222h.001c1.262-.524 2.12.101 3.698.851l2.285-2.286c-.744-1.563-1.375-2.433-.848-3.706.527-1.271 1.588-1.44 3.221-2.021zm-12 2.384c-2.209 0-4-1.791-4-4s1.791-4 4-4 4 1.791 4 4-1.791 4-4 4z"
																/>
															</svg>
														</div>

														<span
															style={{
																color: "green",
															}}
														>
															Change Device
														</span>
													</div>
												</MuiButton>
											)}
											{contractDetail?.buttons
												?.device_change && (
												<MuiButton
													color="error"
													variant="outlined"
													className=""
													style={{
														overflow: "hidden",
													}}
													onClick={
														openRemoveDeviceModal
													}
												>
													<div className="outer-div-danger">
														<div className="bin-button-danger">
															<svg
																className="bin-top-danger"
																viewBox="0 0 39 7"
																fill="red"
																xmlns="http://www.w3.org/2000/svg"
															>
																<line
																	y1="5"
																	x2="39"
																	y2="5"
																	stroke="red"
																	strokeWidth="4"
																></line>
																<line
																	x1="12"
																	y1="1.5"
																	x2="26.0357"
																	y2="1.5"
																	stroke="red"
																	strokeWidth="3"
																></line>
															</svg>
															<svg
																className="bin-bottom-danger"
																viewBox="0 0 33 39"
																fill="red"
																xmlns="http://www.w3.org/2000/svg"
															>
																<mask
																	id="path-1-inside-1_8_19"
																	fill="red"
																>
																	<path
																		fill="red"
																		d="M0 0H33V35C33 37.2091 31.2091 39 29 39H4C1.79086 39 0 37.2091 0 35V0Z"
																	></path>
																</mask>
																<path
																	fill="red"
																	d="M0 0H33H0ZM37 35C37 39.4183 33.4183 43 29 43H4C-0.418278 43 -4 39.4183 -4 35H4H29H37ZM4 43C-0.418278 43 -4 39.4183 -4 35V0H4V35V43ZM37 0V35C37 39.4183 33.4183 43 29 43V35V0H37Z"
																	// fill="white"
																	mask="url(#path-1-inside-1_8_19)"
																></path>
																<path
																	d="M12 6L12 29"
																	stroke="red"
																	strokeWidth="4"
																></path>
																<path
																	d="M21 6V29"
																	stroke="red"
																	strokeWidth="4"
																></path>
															</svg>
														</div>

														<span
															style={{
																color: "red",
															}}
														>
															Remove Device
														</span>
													</div>
												</MuiButton>
											)}
										</Col>
									) : (
										<Col
											xs={9}
											style={{
												display: "flex",
												justifyContent: "end",
												gap: "10px",
											}}
										>
											<MuiButton
												variant="contained"
												className=""
												onClick={
													handleOpenAddDeviceModal
												}
											>
												<div className="outer-div">
													<div className="bin-button">
														<span>
															<svg
																className="phone-svg"
																xmlns="http://www.w3.org/2000/svg"
																width="24"
																height="24"
																viewBox="0 0 24 24"
															>
																<path d="M19 2c0-1.104-.896-2-2-2h-10c-1.104 0-2 .896-2 2v20c0 1.104.896 2 2 2h10c1.104 0 2-.896 2-2v-20zm-8.5 0h3c.276 0 .5.224.5.5s-.224.5-.5.5h-3c-.276 0-.5-.224-.5-.5s.224-.5.5-.5zm1.5 20c-.553 0-1-.448-1-1s.447-1 1-1c.552 0 .999.448.999 1s-.447 1-.999 1zm5-3h-10v-14.024h10v14.024z" />
															</svg>
														</span>
													</div>

													<span>Add Device</span>
												</div>
											</MuiButton>
										</Col>
									)}
								</Row>
								<div
									style={{
										border: "solid 1px #21557a",
										margin: "10px 0px ",
									}}
								></div>

								<Row>
									<Col xs={4}>
										<strong>Merchandise Condition:</strong>
									</Col>
									<Col
										xs={8}
										style={{
											display: "flex",
											gap: "5px",
										}}
									>
										<span
											style={{ cursor: "pointer" }}
											onClick={() => {
												setTextToEditData({
													label: "Merchandise Condition",
													value:
														contractDetail?.device
															?.device_condition_id ||
														"",
													name: "merch_condition_id",
													type: "select",
													selectOptions:
														contractDetail
															?.dropdowns
															?.device_conditions,
												});
												setShowContractTextToEditModal(
													true
												);
											}}
										>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="14"
												height="14"
												fill="currentColor"
												className="bi bi-pencil-fill"
												viewBox="0 0 16 16"
											>
												<path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
											</svg>
										</span>
										{
											contractDetail?.device
												?.device_condition_name
										}
									</Col>
									<Col xs={4}>
										<strong>
											Merchandise Description:
										</strong>
									</Col>
									<Col
										xs={8}
										style={{
											display: "flex",
											gap: "5px",
										}}
									>
										<span
											style={{ cursor: "pointer" }}
											onClick={() => {
												setTextToEditData({
													label: "Merchandise Description",
													value:
														contractDetail?.device
															?.description || "",
													name: "merch_description",
													type: "text-required",
												});
												setShowContractTextToEditModal(
													true
												);
											}}
										>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="14"
												height="14"
												fill="currentColor"
												className="bi bi-pencil-fill"
												viewBox="0 0 16 16"
											>
												<path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
											</svg>
										</span>
										{contractDetail?.device?.description}
									</Col>
								</Row>
								<div
									style={{
										border: "solid 1px #21557a",
										margin: "10px 0px ",
									}}
								></div>
								<Row>
									<Col
										style={{
											display: "flex",
											gap: "10px",
										}}
									>
										{contractDetail?.buttons
											?.contract_cancel && (
											<MuiButton
												variant="outlined"
												color="error"
												onClick={openCancelModal}
												style={{ color: "red" }}
												//  className="btn btn-sm btn-danger btn-outline pull-right"
											>
												Cancel Contract
											</MuiButton>
										)}

										{contractDetail?.buttons
											?.contract_default && (
											<MuiButton
												variant="outlined"
												color="error"
												onClick={() => {
													setContractDefaulted();
												}}
												style={{ color: "red" }}
												// className="btn btn-sm btn-info btn-outline"
											>
												Mark As Defaulted
											</MuiButton>
										)}

										{contractDetail?.buttons
											?.contract_reschedule && (
											<MuiButton
												variant="outlined"
												onClick={handleOpenReschedule}
												// className="btn btn-sm btn-info btn-outline"
												style={{ color: "#1565c0" }}
											>
												Reschedule
											</MuiButton>
										)}

										{contractDetail?.buttons
											?.early_payoff && (
											<MuiButton
												variant="outlined"
												color="warning"
												onClick={openEarlyPayoffModal}
											>
												Early Payoff
											</MuiButton>
										)}
									</Col>
								</Row>
							</Col>
							<Col xs={12} lg={6} className="view-right">
								<Row>
									<Col xs={12} lg={6}>
										<h3>Billing Info</h3>
									</Col>
									<Col xs={12} lg={6}>
										{contractDetail?.buttons
											?.change_billing_status ? (
											<div
												// xs={12}
												style={{
													display: "flex",
													justifyContent:
														"space-between",
												}}
											>
												<strong>Billing Status:</strong>
												<span
													style={{
														display: "flex",
														cursor: "pointer",
													}}
													onClick={() => {
														setShowBillingModal(
															true
														);
													}}
												>
													<svg
														xmlns="http://www.w3.org/2000/svg"
														width="16"
														height="16"
														fill="currentColor"
														className="bi bi-pencil-fill"
														viewBox="0 0 16 16"
													>
														<path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
													</svg>
													<div
														style={{
															overflow: "hidden",
															whiteSpace:
																"nowrap",
															textOverflow:
																"ellipsis",
															display: "block",
														}}
													>
														{
															contractDetail
																?.billing
																?.billing_status
														}
													</div>
												</span>
											</div>
										) : (
											<div
												// xs={12}
												style={{
													display: "flex",
													justifyContent:
														"space-between",
												}}
											>
												<strong>Billing Status:</strong>
												<span>
													{
														contractDetail?.billing
															?.billing_status
													}
												</span>
											</div>
										)}
									</Col>
								</Row>
								<Row>
									<Col xs={12} lg={6}>
										<Row>
											<Col
												xs={12}
												style={{
													display: "flex",
													justifyContent:
														"space-between",
												}}
											>
												<strong>
													Contract Start Date:
												</strong>
												<span>
													{contractDetail?.billing
														?.contract_start_date &&
														convertDate(
															contractDetail
																?.billing
																?.contract_start_date
														)}
												</span>
											</Col>
											<Col
												xs={12}
												style={{
													display: "flex",
													justifyContent:
														"space-between",
												}}
											>
												<strong>Retail Value:</strong>
												<span>
													$
													{formatAmountWithComma(
														contractDetail?.billing
															?.retail_value
													)}
												</span>
											</Col>
											<Col
												xs={12}
												style={{
													display: "flex",
													justifyContent:
														"space-between",
												}}
											>
												<strong>Down Payment:</strong>
												<span>
													$
													{formatAmountWithComma(
														contractDetail?.billing
															?.down_payment
													)}
												</span>
											</Col>
											<Col
												xs={12}
												style={{
													display: "flex",
													justifyContent:
														"space-between",
												}}
											>
												<strong>
													Org Contract Amount:
												</strong>
												<span>
													$
													{formatAmountWithComma(
														contractDetail?.billing
															?.orig_contract_amount
													)}
												</span>
											</Col>
											<Col
												xs={12}
												style={{
													display: "flex",
													justifyContent:
														"space-between",
												}}
											>
												<strong>
													Reschedule Fees:
												</strong>
												<span>
													$
													{formatAmountWithComma(
														contractDetail?.billing
															?.reschedule_fees
													)}
												</span>
											</Col>
											<Col
												xs={12}
												style={{
													display: "flex",
													justifyContent:
														"space-between",
												}}
											>
												<strong>
													Total Contract Value:
												</strong>
												<span>
													$
													{formatAmountWithComma(
														contractDetail?.billing
															?.total_contract_value
													)}
												</span>
											</Col>
										</Row>
									</Col>
									<Col xs={12} lg={6}>
										<Row>
											<Col
												xs={12}
												style={{
													display: "flex",
													justifyContent:
														"space-between",
												}}
											>
												<strong>Rental Factor:</strong>
												<span>
													{" "}
													{formatAmountWithComma(
														contractDetail?.billing
															?.rental_factor
													)}
												</span>
											</Col>
											<Col
												xs={12}
												style={{
													display: "flex",
													justifyContent:
														"space-between",
												}}
											>
												<strong>Lease Type:</strong>
												<span>
													{" "}
													{contractDetail?.billing?.lease_type.toLocaleString()}
												</span>
											</Col>
											<Col
												xs={12}
												style={{
													display: "flex",
													justifyContent:
														"space-between",
												}}
											>
												<strong>Term:</strong>
												<span>
													{contractDetail?.billing?.term.toLocaleString()}{" "}
													{contractDetail?.billing
														?.term === 1
														? "Month"
														: "Months"}
												</span>
											</Col>
											<Col
												xs={12}
												style={{
													display: "flex",
													justifyContent:
														"space-between",
												}}
											>
												<strong>Type:</strong>
												<span>
													{contractDetail?.billing
														?.type === 1
														? "Lock"
														: ""}
												</span>
											</Col>
											<Col
												xs={12}
												style={{
													display: "flex",
													justifyContent:
														"space-between",
												}}
											>
												<strong>
													Payments Collected:
												</strong>
												<span>
													$
													{formatAmountWithComma(
														contractDetail?.billing
															?.payments_collected
													)}
												</span>
											</Col>
										</Row>
									</Col>
								</Row>
								<div
									style={{
										border: "solid 1px white",
										margin: "5px 0px 10px",
									}}
								></div>
								<Row>
									<Col xs={12} lg={6}>
										<Row>
											<Col
												xs={12}
												style={{
													display: "flex",
													justifyContent:
														"space-between",
												}}
											>
												<strong>
													Outstanding Balance:
												</strong>
												<span>
													$
													{formatAmountWithComma(
														contractDetail?.billing
															?.outstanding_balance
													)}
												</span>
											</Col>
											<Col
												xs={12}
												style={{
													display: "flex",
													justifyContent:
														"space-between",
												}}
											>
												<strong>
													Next Amount Due:
												</strong>
												<span>
													$
													{formatAmountWithComma(
														contractDetail?.billing
															?.next_amount_due
													)}
												</span>
											</Col>
											<Col
												xs={12}
												style={{
													display: "flex",
													justifyContent:
														"space-between",
												}}
											>
												<strong>Contract Cost:</strong>
												<Row>
													<Col
														xs={6}
														style={{
															display: "flex",
															justifyContent:
																"start",
														}}
													>
														<span
															style={{
																display: "flex",
																cursor: "pointer",
															}}
															onClick={() => {
																setShowContractTextToEditModal(
																	true
																);
																setTextToEditData(
																	{
																		label: "Contract Cost",
																		value:
																			contractDetail
																				?.billing
																				?.contract_cost ||
																			"",
																		name: "contract_cost",
																		type: "currency",
																	}
																);
															}}
														>
															<svg
																xmlns="http://www.w3.org/2000/svg"
																width="16"
																height="16"
																fill="currentColor"
																className="bi bi-pencil-fill"
																viewBox="0 0 16 16"
															>
																<path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
															</svg>
														</span>
													</Col>
													<Col
														xs={6}
														style={{
															display: "flex",
															justifyContent:
																"end",
														}}
													>
														{" "}
														$
														{formatAmountWithComma(
															contractDetail
																?.billing
																?.contract_cost
														)}
													</Col>
												</Row>
											</Col>
											{canViewBackMarketCost && (
												<Col
													xs={12}
													style={{
														display: "flex",
														justifyContent:
															"space-between",
													}}
												>
													<strong>
														Back Market Cost:
													</strong>
													<Row>
														<Col
															xs={6}
															style={{
																display: "flex",
																justifyContent:
																	"start",
															}}
														>
															{canEditBackMarketCost && (
																<span
																	style={{
																		display:
																			"flex",
																		cursor: "pointer",
																	}}
																	onClick={() => {
																		setShowContractTextToEditModal(
																			true
																		);
																		setTextToEditData(
																			{
																				label: "Back Market Cost",
																				value:
																					contractDetail
																						?.billing
																						?.back_market_cost ||
																					"",
																				name: "back_market_cost",
																				type: "currency",
																			}
																		);
																	}}
																>
																	<svg
																		xmlns="http://www.w3.org/2000/svg"
																		width="16"
																		height="16"
																		fill="currentColor"
																		className="bi bi-pencil-fill"
																		viewBox="0 0 16 16"
																	>
																		<path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
																	</svg>
																</span>
															)}
														</Col>
														<Col
															xs={6}
															style={{
																display: "flex",
																justifyContent:
																	"end",
															}}
														>
															{" "}
															$
															{formatAmountWithComma(
																contractDetail
																	?.billing
																	?.back_market_cost
															)}
														</Col>
													</Row>
												</Col>
											)}
											<Col
												xs={12}
												style={{
													display: "flex",

													justifyContent:
														"space-between",
												}}
											>
												<strong>POS Inv #:</strong>
												<Row>
													<Col
														xs={6}
														style={{
															display: "flex",
															justifyContent:
																"start",
														}}
													>
														{" "}
														<span
															style={{
																display: "flex",
																cursor: "pointer",
															}}
															onClick={() => {
																setShowContractTextToEditModal(
																	true
																);
																setTextToEditData(
																	{
																		label: "POS Invoice Number",
																		value:
																			contractDetail
																				?.billing
																				?.pos_invoice_number ||
																			"",
																		name: "pos_invoice_number",
																		type: "number",
																	}
																);
															}}
														>
															<svg
																xmlns="http://www.w3.org/2000/svg"
																width="16"
																height="16"
																fill="currentColor"
																className="bi bi-pencil-fill"
																viewBox="0 0 16 16"
															>
																<path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
															</svg>
														</span>
													</Col>
													<Col
														xs={6}
														style={{
															display: "flex",
															justifyContent:
																"end",
														}}
													>
														{contractDetail?.billing
															?.pos_invoice_number
															? contractDetail
																	?.billing
																	?.pos_invoice_number
															: "--"}
													</Col>
												</Row>
											</Col>
										</Row>
									</Col>
									<Col xs={12} lg={6}>
										<Row>
											<Col
												xs={12}
												style={{
													display: "flex",

													justifyContent:
														"space-between",
												}}
											>
												<strong>Due Today:</strong>
												<span>
													$
													{contractDetail?.billing?.due_today
														?.toFixed(2)
														.toLocaleString()}
												</span>
											</Col>
											<Col
												xs={12}
												style={{
													display: "flex",

													justifyContent:
														"space-between",
												}}
											>
												<strong>Due Date:</strong>
												<span>
													{contractDetail?.billing
														?.due_date &&
														convertDate(
															contractDetail
																?.billing
																?.due_date
														)}
												</span>
											</Col>
										</Row>
									</Col>
								</Row>
							</Col>
						</Row>
						<Row>
							<Col> </Col>
						</Row>
					</div>
					{/* **************************** BOTTOM TABS START ******************************/}
					<div
						style={{
							// backgroundColor: "red",
							width: "100%",
							// height: "100px",
							padding: "10px 0px",
							// background: "red",
							color: "#21557a",
						}}
					>
						<Box sx={{ width: "100%" }}>
							<Box
								sx={{ borderBottom: 1, borderColor: "divider" }}
							>
								<Tabs
									value={value}
									onChange={handleTabChange}
									aria-label="basic tabs example"
									centered={true}
									// variant="scrollable"
									// scrollButtons="auto"
								>
									<Tab label="Make Payment" />
									<Tab label="ABP Management" />
									<Tab label="Journal" />
									<Tab label="Payment Schedule" />
									<Tab label="Fee Schedule" />
									{/* system admion only" /> */}
									<Tab label="Activities" />
									<Tab label="Event Log" />{" "}
									{/* system admion only" /> */}
									{/* <Tab label="E-Sign Contract" /> */}
								</Tabs>
							</Box>
							<CustomTabPanel value={value} index={0}>
								{value === 0 && (
									<div>
										<ContractMakePaymentModal
											show={showContractMakePaymentModal}
											onHide={() => {
												setShowContractMakePaymentModal(
													false
												);
												setValue(null);
											}}
											contractId={contractDetail?.id}
										></ContractMakePaymentModal>
									</div>
								)}
							</CustomTabPanel>
							<CustomTabPanel value={value} index={1}>
								{value === 1 && (
									<div>
										<ContractsAbpTable
											contractDetails={contractDetail}
											loading={loading}
											setLoading={setLoading}
											refreshContract={getContractById}
										></ContractsAbpTable>
									</div>
								)}
							</CustomTabPanel>
							<CustomTabPanel value={value} index={2}>
								{value === 2 && (
									<div>
										<ContractsViewTable
											columns={columns_test}
											data={transactionHistory}
											customToolbar={CustomToolbar}
										></ContractsViewTable>
									</div>
								)}
							</CustomTabPanel>
							<CustomTabPanel value={value} index={3}>
								{value === 3 && <div></div>}
							</CustomTabPanel>

							<CustomTabPanel value={value} index={4}>
								{value === 4 && (
									<div>
										<ContractsViewTable
											columns={columns_fee_schedule}
											data={invoices ? invoices : []}
											customToolbar={null}
										></ContractsViewTable>
									</div>
								)}
							</CustomTabPanel>

							<CustomTabPanel value={value} index={5}>
								{value === 5 && (
									<ActivitiesTable
										modelId={contractDetail?.id}
										model="contracts"
										activeTab="contracts"
										loading={loading}
										setLoading={setLoading}
									/>
								)}
							</CustomTabPanel>
							<CustomTabPanel value={value} index={6}>
								{value === 6 && (
									<div>
										<ContractsViewTable
											columns={columns_events_log}
											data={eventLogData}
											customToolbar={null}
										></ContractsViewTable>
									</div>
								)}
							</CustomTabPanel>

							{/* <CustomTabPanel value={value} index={7}>
								{value === 7 && <div>E-Sign Contract</div>}
							</CustomTabPanel> */}
						</Box>
					</div>
					{/* **************************** BOTTOM TABS END ******************************/}
				</div>
			</div>
		</>
	);
}

export default ContractsView3;
