import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Box } from "@mui/material";
import PromiseGuaranteeClaimService from "../../service/resources/PromiseGuaranteeClaimService";
import SessionService from "../../service/SessionService";
import IndexTable from "../../components/common/IndexTable";

function PromiseGuaranteeClaimIndex({ loading, setLoading }) {
	const pageTitle = "Promise Guarantee Claim Report | RTO Mobile";

	return (
		<>
			<Helmet>
				<title>{pageTitle}</title>
				<meta name="robots" content="noindex,nofollow"></meta>
				<meta property="og:title" content={pageTitle} />
				<meta name="twitter:title" content={pageTitle} />
			</Helmet>

			<div className="rto_form-sections">
				<div className="rto_content-div" style={{ maxWidth: "1600px" }}>
					<Box
						display="flex"
						justifyContent="flex-end"
						backgroundColor="#f5f5f5"
						p={(0.5, 1)}
						borderRadius={1}
					>
						<Breadcrumbs aria-label="breadcrumb">
							<Link to="/dashboard" className="rto_link-2">
								Dashboard
							</Link>
							<Link to="/resources" className="rto_link-2">
								Resources
							</Link>
							<Typography>Promise Guarantee Claim</Typography>
						</Breadcrumbs>
					</Box>
					<div className="rto_title-div">
						<h1 className="rto_page-title">
							Promise Guarantee Claim
						</h1>
					</div>
					<div
						className="card card-outline card-primary"
						style={{
							borderTop: "3px solid #00aaff",
							boxShadow:
								"0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
						}}
					>
						<div className="card-body">
							<IndexTable
								loading={loading}
								setLoading={setLoading}
								service={PromiseGuaranteeClaimService}
								title="Promise Guarantee Claims"
								actionButtons={
									SessionService.canRtoAdmin() &&
									SessionService.canSystemAdmin() && {
										updateLink: {
											path: "/resources/promise-guarantee-claims",
										},
									}
								}
								labels={[
									{
										id: "id",
										label: "ID",
										excluded: true,
									},
									{
										id: "contract_id",
										label: "Contract #",
									},
									{
										id: "struct_iso_name",
										label: "ISO",
										link: "/settings/isos",
									},
									{
										id: "struct_master_agent_name",
										label: "Master Agent",
										link: "/settings/master-agents",
									},
									{
										id: "struct_organization_name",
										label: "Organization",
										link: "/settings/organizations",
									},
									{
										id: "struct_company_name",
										label: "Company",
										link: "/settings/companies",
									},
									{
										id: "struct_store_name",
										label: "Store",
										link: "/settings/stores",
									},
									{
										id: "imei",
										label: "IMEI",
									},
									{
										id: "serial_number",
										label: "Serial #",
									},
									{
										id: "manufacturer_name",
										label: "Manufacturer",
									},
									{
										id: "model_name",
										label: "Model",
									},
									{
										id: "customer",
										label: "Customer",
									},
									{
										id: "promise_guarantee_claim_status_name",
										label: "Status",
									},
									{
										id: "created_name",
										label: "Username",
									},
									{
										id: "created_at",
										label: "Created",
									},
								]}
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default PromiseGuaranteeClaimIndex;
