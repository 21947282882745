import React, {
	useEffect,
	useState,
	forwardRef,
	useImperativeHandle,
	useRef,
} from "react";
import TextField from "@mui/material/TextField";
import ContractService from "../../../../service/contract/ContractService";

/**
 * "contract_id" => [
		"type" => "contract-lookup",
		"label" => "Contract ID#",
		"value" => "",
		"required" => true,
		"readOnly" => false,
		"style" => [
			"textTransform" => "uppercase"
		]
	],
 */
const CustomContractLookupComponent = forwardRef(
	(
		{
			name,
			label,
			type,
			value,
			onChange,
			onBlur,
			className,
			formState,
			loading,
			setLoading,
			...otherProps
		},
		ref
	) => {
		const [internalValue, setInternalValue] = useState(value);
		const [isValid, setIsValid] = useState(false);
		const [errorMessage, setErrorMessage] = useState("");
		const [isTouched, setIsTouched] = useState(false);
		const isTouchedRef = useRef(isTouched);

		const [isMounted, setIsMounted] = useState(false);
		const inputRef = useRef(null);

		useEffect(() => {
			setInternalValue(value);
		}, [value]);

		useEffect(() => {
			const validateInternalValue = async () => {
				if (isMounted) {
					await validate(internalValue);
				} else {
					setIsMounted(true);
				}
			};

			validateInternalValue();
		}, [internalValue]);

		useEffect(() => {
			isTouchedRef.current = isTouched;
		}, [isTouched]);

		useImperativeHandle(ref, () => ({
			validate: async (value) => await validate(value),
			isTouched: () => isTouchedRef.current,
			setIsTouched: (value) => {
				setIsTouched(value);
				isTouchedRef.current = value;
			},
			focus: () => {
				if (inputRef.current) {
					inputRef.current.focus();
				}
			},
		}));

		const handleChange = (newValue) => {
			// Filter out non-numeric characters
			const numericValue = newValue.replace(/[^0-9]/g, "");
			setInternalValue(numericValue);
			onChange(name, numericValue, isValid);
		};

		const handleBlur = async () => {
			setIsTouched(true);
			//validate();
			let trimmedValue = internalValue.trim();
			try {
				setLoading(true);
				const response = await ContractService.fetchById(trimmedValue);

				if (!response.data.isOk) {
					setIsValid(false);
					setErrorMessage("Contract not found.");
					setIsTouched(true);
					setLoading(false);
					resetForm();
					onChange(name, trimmedValue, false);
					return false;
				} else if (
					response?.data?.data?.billing?.guarantee_status ===
					"Disabled"
				) {
					setIsValid(false);
					setErrorMessage("Contract not eligible for claim.");
					setIsTouched(true);
					setLoading(false);
					onChange(name, trimmedValue, false);
					resetForm();
					return false;
				} else {
					setIsValid(true);
					setErrorMessage("");

					// Set default values
					onChange("contract_id", response?.data?.data?.id, true);
					onChange(
						"contract_first_name",
						response?.data?.data?.customer?.first_name,
						true
					);
					onChange(
						"contract_last_name",
						response?.data?.data?.customer?.last_name,
						true
					);
					onChange(
						"manufacturer_name",
						response?.data?.data?.device?.manufacturer,
						true
					);
					onChange(
						"model_name",
						response?.data?.data?.device?.model,
						true
					);
					onChange(
						"device_storage_size",
						parseInt(response?.data?.data?.device?.device_memory),
						true
					);
					onChange(
						"back_market_cost",
						"$ " +
							response?.data?.data?.billing?.back_market_cost.toFixed(
								2
							),
						true
					);
					onChange(name, trimmedValue, true);
				}
			} catch (error) {
				console.error("Error validating Contract Number:", error);
				return false;
			}
			setLoading(false);
			return true;
		};

		const resetForm = () => {
			onChange("contract_first_name", "", true);
			onChange("contract_last_name", "", true);
			onChange("manufacturer_name", "", true);
			onChange("model_name", "", true);
			onChange("device_storage_size", "", true);
			onChange("back_market_cost", "", true);
			onChange("contract_id", "", true);
		};

		const validate = async (valueToValidate) => {
			const value =
				valueToValidate !== undefined ? valueToValidate : internalValue;
			const fieldInfo = getFieldInfo();
			let trimmedValue = value.trim();

			// check readonly
			if (fieldInfo.readOnly) {
				setIsValid(true);
				setErrorMessage("");
				onChange(name, trimmedValue, true);
				return true;
			}

			// Check required
			if (fieldInfo.required && trimmedValue === "") {
				setIsValid(false);
				setErrorMessage("This field is required.");
				setIsTouched(true);
				onChange(name, trimmedValue, false);
				return false;
			}

			setIsValid(true);
			setErrorMessage("");
			return true;
		};

		const handleFocus = async () => {
			setIsTouched(true);
			await validate(internalValue);
		};

		const getFieldInfo = () => {
			return {
				required: Boolean(type && type.required),
				readOnly: type && type.readOnly,
				upperCase: type && type.upperCase,
			};
		};

		return (
			<div className={`${className}`}>
				<TextField
					id={name}
					label={`${label}`}
					variant="standard"
					size="small"
					fullWidth
					type={type && type.type}
					value={internalValue}
					onChange={(e) => handleChange(e.target.value)}
					onBlur={handleBlur}
					onFocus={handleFocus}
					error={!isValid && isTouchedRef.current}
					helperText={
						!isValid && isTouchedRef.current && errorMessage
					}
					InputProps={{
						readOnly: getFieldInfo().readOnly,
					}}
					{...otherProps}
					inputRef={inputRef}
				/>
			</div>
		);
	}
);

export default CustomContractLookupComponent;
