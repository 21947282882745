import React, { useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DownloadIcon from "@mui/icons-material/Download";

/**
 * @doc when we need to copy the content to another textbox
 * "promise_guarantee_documents": {
		"type": "download",
		"downloadApi": "/apiNew/promise-guarantee-claims/download/?uuid=",
		"files": [
			{
				"id": "ec5cd458-1ddb-11ef-b9f0-fa163e59a4c7",
				"document_name": "ULS_U119712810_20240515025428638.pdf"
			},
			{
				"id": "ec61c93f-1ddb-11ef-b9f0-fa163e59a4c7",
				"document_name": "LIPCReport_PremiumCertificate_20240515025231063_U119712810.pdf"
			}
		]
	}
 */
/**
 * Download component
 * @param {string} className
 * @param {array}  files - array of files to download
 * @param {string} downloadApi - download api
 * @param {object} otherProps
 * @returns
 */
const CustomDownloadComponent = ({
	className,
	files,
	downloadApi,
	...otherProps
}) => {
	/*let downloadable =
		(filePath && filePath.length > 0) || (fileData && fileData.length > 0);

	const handleDownload = () => {
		if (!downloadable) {
			return;
		}

		// if direct load via url
		if (filePath) {
			// Simulate downloading the file by creating an anchor element
			const link = document.createElement("a");
			link.href = filePath;
			link.download = filePath.substring(filePath.lastIndexOf("/") + 1);
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		} else {
			//@todo - We need update the logic to handle with file download via ajax
			saveFile(fileType, fileData, fileName);
		}
	};*/

	return (
		<div
			className={`${className}`}
			style={{
				display: "flex",
				justifyContent: "left",
				alignItems: "left",
			}}
		>
			<List>
				{Array.isArray(files) &&
					files.map((option, key) => (
						<ListItem key={key}>
							<ListItemIcon>
								<a
									target="_blank"
									href={`${downloadApi}${option.id}`}
								>
									<DownloadIcon />
								</a>
							</ListItemIcon>
							<ListItemText>{option.document_name}</ListItemText>
						</ListItem>
					))}
			</List>

			{/* Button with Text and Icon
			<Button
				variant="outlined"
				size="small"
				disabled={!downloadable}
				onClick={handleDownload}
				style={{ marginRight: "5px" }}
				{...otherProps}
			>
				<CloudDownloadIcon />
			</Button>
			{fileName} */}
		</div>
	);
};

export default CustomDownloadComponent;
