import ApiService from "../ApiService";

/**
 * @see /docs/api/index.html#PromiseGuaranteeClaims
 */

const PromiseGuaranteeClaimService = {
	/**
	 *
	 * @param {View} View
	 * @returns
	 */
	view: (View) => {
		return ApiService.post("/promise-guarantee-claims/index", View).catch(
			(error) => {
				console.log(error);
				return error;
			}
		);
	},
	/**
	 *
	 * @param {promiseGuaranteeClaimCreate} promiseGuaranteeClaimCreate
	 * @see /docs/api/index.html#jump-PromiseGuaranteeClaims-Create
	 * @returns
	 */
	create: (promiseGuaranteeClaimCreate) => {
		return ApiService.post(
			"/promise-guarantee-claims/create",
			promiseGuaranteeClaimCreate
		).catch((error) => {
			console.log(error);
		});
	},
};

export default PromiseGuaranteeClaimService;
