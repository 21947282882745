import React, {
	useEffect,
	useState,
	forwardRef,
	useImperativeHandle,
	useRef,
} from "react";
import TextField from "@mui/material/TextField";

/**
 * @doc when we need to copy the content to another textbox
 * [
 *	'type' => 'textarea',
 *	'name' => 'address',
 *	'class' => 'col-md-12',
 * ],
 */

const CustomTextareaComponent = forwardRef(
	(
		{
			name,
			label,
			type,
			value,
			onChange,
			onBlur,
			className,
			...otherProps
		},
		ref
	) => {
		const [internalValue, setInternalValue] = useState(value);
		const [isValid, setIsValid] = useState(false);
		const [errorMessage, setErrorMessage] = useState("");
		const [isTouched, setIsTouched] = useState(false);
		const isTouchedRef = useRef(isTouched);
		const [isMounted, setIsMounted] = useState(false);
		const inputRef = useRef(null);

		useEffect(() => {
			setInternalValue(value);
		}, [value]);

		useEffect(() => {
			if (isMounted) {
				validate(internalValue);
			} else {
				setIsMounted(true);
			}
		}, [internalValue]);

		useEffect(() => {
			isTouchedRef.current = isTouched;
		}, [isTouched]);

		useImperativeHandle(ref, () => ({
			validate: (value) => validate(value),
			isTouched: () => isTouchedRef.current,
			setIsTouched: (value) => {
				setIsTouched(value);
				isTouchedRef.current = value;
			},
			focus: () => {
				if (inputRef.current) {
					inputRef.current.focus();
				}
			},
		}));

		const handleChange = (newValue) => {
			setInternalValue(newValue);
			onChange(name, newValue, isValid);
		};

		const validate = (valueToValidate = internalValue) => {
			const fieldInfo = getFieldInfo();

			let trimmedValue = valueToValidate;

			if (fieldInfo.upperCase) {
				trimmedValue = trimmedValue.toUpperCase();
			}

			if (fieldInfo.readOnly) {
				setIsValid(true);
				setErrorMessage("");
				onChange(name, trimmedValue, true);
				return true;
			}

			let valid = true;
			let message = "";

			// Check minLength and maxLength only if the field is not empty
			if (trimmedValue !== "") {
				if (
					fieldInfo.minLength &&
					trimmedValue.length < fieldInfo.minLength
				) {
					valid = false;
					message = `Minimum length is ${fieldInfo.minLength} characters.`;
				} else if (
					fieldInfo.maxLength &&
					trimmedValue.length > fieldInfo.maxLength
				) {
					valid = false;
					message = `Maximum length is ${fieldInfo.maxLength} characters.`;
				}
			}

			// Check required
			if (fieldInfo.required && trimmedValue === "") {
				valid = false;
				message = "This field is required.";
			}

			setIsValid(valid);
			setErrorMessage(message);
			setIsTouched(true);
			onChange(name, trimmedValue, valid);
			return valid;
		};

		const handleFocus = () => {
			setIsTouched(true);
			validate(internalValue);
		};

		const getFieldInfo = () => {
			return {
				required: Boolean(type && type.required),
				minLength: type && type.minLength,
				maxLength: type && type.maxLength,
				readOnly: type && type.readOnly,
				upperCase: type && type.upperCase,
				rows: type && type.rows,
			};
		};

		return (
			<div className={`${className}`}>
				<TextField
					id={name}
					label={`${label}`}
					multiline
					rows={getFieldInfo().rows || 2}
					variant="standard"
					size="small"
					fullWidth
					type={type && type.type}
					value={internalValue}
					onChange={(e) => handleChange(e.target.value)}
					onFocus={handleFocus}
					error={!isValid && isTouchedRef.current}
					helperText={
						!isValid && isTouchedRef.current && errorMessage
					}
					className={getFieldInfo().readOnly ? "readonly-text" : ""}
					inputProps={{
						readOnly: getFieldInfo().readOnly,
						style: {
							textTransform: getFieldInfo().upperCase
								? "uppercase"
								: "none",
						},
					}}
					{...otherProps}
					inputRef={inputRef}
				/>
			</div>
		);
	}
);

export default CustomTextareaComponent;
