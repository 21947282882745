import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link, useParams } from "react-router-dom";
import { Breadcrumb } from "react-bootstrap";
import ContractsCreateForm from "../../components/forms/ContractsCreateForm";
import { createTheme, ThemeProvider } from "@mui/material/styles";
const theme = createTheme({
	palette: {
		default: {
			main: "#ffffff",
			light: "#f7fafc",
			dark: "##f7fafc",
			contrastText: "#2d3748",
		},
		primary: {
			main: "#00ABF9",
			light: "#A3DAFA",
			dark: "#0091d3",
			contrastText: "#fff",
		},
		success: {
			main: "#22c55e",
			light: "#86efac",
			dark: "#16a34a",
			contrastText: "#fff",
		},
		error: {
			main: "#ef4444",
			light: "#fca5a5",
			dark: "#dc2626",
			contrastText: "#fff",
		},
	},
	components: {
		MuiPopover: {
			styleOverrides: {
				paper: {
					padding: "1rem",
					maxWidth: "500px", // Set the width of the dialog window
					maxHeight: "500px", // Set the max height of the dialog window
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				root: {
					fontWeight: "600",
					textTransform: "none",
					whiteSpace: "nowrap",
				},
			},
		},
	},
});

function ContractsCreate({ loading, setLoading }) {
	const pageTitle = "Create New Contract | RTO Mobile";

	return (
		<>
			<ThemeProvider theme={theme}>
				<Helmet>
					<title>{pageTitle}</title>
					<meta name="robots" content="noindex,nofollow"></meta>
					<meta property="og:title" content={pageTitle} />
					<meta name="twitter:title" content={pageTitle} />
				</Helmet>
				<div className="rto_form-sections">
					<div className="rto_content-div">
						<Breadcrumb
							listProps={{
								className: "w-100 justify-content-end",
							}}
						>
							<Breadcrumb.Item
								linkAs={Link}
								linkProps={{ to: "/dashboard" }}
							>
								Dashboard
							</Breadcrumb.Item>
							<Breadcrumb.Item
								linkAs={Link}
								linkProps={{ to: "/contracts" }}
							>
								Contracts
							</Breadcrumb.Item>
							<Breadcrumb.Item active>Create</Breadcrumb.Item>
						</Breadcrumb>
						<ContractsCreateForm
							loading={loading}
							setLoading={setLoading}
						/>
					</div>
				</div>
			</ThemeProvider>
		</>
	);
}

export default ContractsCreate;
