import React, { useRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormControl from "react-bootstrap/FormControl";
// import FormSelect from "react-bootstrap/FormSelect";
import FormGroup from "react-bootstrap/FormGroup";
import FormLabel from "react-bootstrap/FormLabel";
import Feedback from "react-bootstrap/Feedback";
import PhoneInput from "react-phone-number-input/react-hook-form";
import PhoneInputBs5 from "../common/PhoneInputBs5";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { DevTool } from "@hookform/devtools";
import axios from "axios";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { Button } from "react-bootstrap";
// import LeadService from "../../service/lead/LeadService";
import { toast } from "react-toastify";
import ReferralProgramService from "../../service/referralProgram/ReferralProgramService";

const usNumberRegex = /^\+1\d{10}$/;
const allowedCountryRegex = usNumberRegex;

const schema = z.object({
	company_name: z
		.string()
		.trim()
		.nonempty({ message: "Company name cannot be blank!" })
		.max(254, {
			message: "Company name cannot be longer than 254 characters!",
		}),
	first_name: z
		.string()
		.trim()
		.nonempty({ message: "First name cannot be blank!" })
		.max(254, {
			message: "First name cannot be longer than 254 characters!",
		}),
	last_name: z
		.string()
		.trim()
		.nonempty({ message: "Last name cannot be blank!" })
		.max(254, {
			message: "Last name cannot be longer than 254 characters!",
		}),
	email: z
		.string()
		.nonempty({ message: "Email name cannot be blank!" })
		.trim()
		.max(255, {
			message: "Email address cannot be longer than 255 characters!",
		})
		.refine(
			(value) => {
				if (value === "") return true;
				// Regex for more comprehensive email validation
				const emailRegex =
					/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
				return emailRegex.test(value);
			},
			{
				message: "Email address must be a valid email address!",
			}
		)
		.optional(),
	phone: z
		.string({
			invalid_type_error: "Phone number cannot be blank!",
		})
		.trim()
		.nonempty({ message: "Phone number cannot be blank!" })
		.refine(
			(value) => {
				return isPossiblePhoneNumber(value);
			},
			{
				message: "Phone number is invalid!",
			}
		)
		.refine(
			(value) => {
				return allowedCountryRegex.test(value);
			},
			{
				message: "Phone number country is invalid!",
			}
		),
	leads_status_id: z
		.string()
		.trim()
		.refine(
			(value) => {
				if (!value) return true; // Allow it to be blank
				const intValue = parseInt(value);
				return !isNaN(intValue);
			},
			{
				message: "Status must be a number!",
			}
		)
		.refine(
			(value) => {
				if (!value) return true; // Allow it to be blank
				const intValue = parseInt(value);
				return intValue >= 0;
			},
			{
				message: "Status cannot be negative!",
			}
		)
		.refine(
			(value) => {
				return /^[0-9]*$/.test(value); // Allow it to be blank or valid integers
			},
			{
				message: "Status must be a valid integer!",
			}
		)
		.optional(),
	// number of stores is optional but must be a postive integer
	number_of_stores: z
		.string()
		.trim()
		.nonempty({ message: "Number of stores cannot be blank!" })
		.refine(
			(value) => {
				const intValue = parseInt(value);
				return !isNaN(intValue);
			},
			{
				message: "Number of stores must be a number!",
			}
		)
		.refine(
			(value) => {
				const intValue = parseInt(value);
				return intValue >= 0;
			},
			{
				message: "Number of stores cannot be negative!",
			}
		)
		.refine(
			(value) => {
				return /^[0-9]+$/.test(value); // Don't allow it to be blank, only valid integers
			},
			{
				message: "Number of stores must be a valid integer!",
			}
		)
		.refine(
			(value) => {
				return !value.includes("e"); // Ensure 'e' is not present in the input
			},
			{
				message: "Number of stores cannot contain the letter 'e'!",
			}
		),
	address_1: z
		.string()
		.trim()
		.nonempty({ message: "Address cannot be blank!" })
		.max(255, {
			message: "Address cannot be longer than 254 characters!",
		})
		.refine(
			(value) => {
				return /^[A-Za-z0-9\s.,'#-]+$/.test(value);
			},
			{
				message: "Address cannot contain special characters!",
			}
		),
	address_2: z
		.string()
		.trim()
		.max(255, {
			message: "Address 2 cannot be longer than 255 characters!",
		})
		.refine(
			(value) => {
				//if not empty, then validate
				if (value) {
					return /^[A-Za-z0-9\s.'#-]+$/.test(value);
				}
				//if empty, consider it valid
				return true;
			},
			{
				message: "Address 2 cannot contain special characters!",
			}
		)
		.optional(),
	zip: z
		.string()
		.toUpperCase()
		.trim()
		.nonempty({ message: "Zip code cannot be blank!" })
		.length(5, { message: "Zip code must contain 5 characters!" })
		.refine(
			(value) => {
				const intValue = parseInt(value);
				return !isNaN(intValue);
			},
			{
				message: "Zip code must be a number!",
			}
		)
		.refine(
			(value) => {
				const intValue = parseInt(value);
				return intValue >= 0;
			},
			{
				message: "Zip code cannot be negative!",
			}
		)
		.refine(
			(value) => {
				return /^[0-9]+$/.test(value);
			},
			{
				message: "Zip code must be a valid integer!",
			}
		),
	city: z
		.string()
		.toUpperCase()
		.trim()
		.nonempty({ message: "City cannot be blank!" })
		.max(255, { message: "City cannot be longer than 255 characters!" }),
	state: z
		.string()
		.toUpperCase()
		.trim()
		.nonempty({ message: "State cannot be blank!" })
		.max(255, { message: "State cannot be longer than 255 characters!" }),
	// message is optional but must be a string
	message: z.string().trim(),
});

function RefferalForm({ leadData, loading, setLoading, sendEmail }) {
	const {
		register,
		control,
		handleSubmit,
		formState: {
			errors,
			touchedFields,
			isSubmitting,
			isSubmitted,
			// isValid,
			// isDirty,
			// dirtyFields,
		},
		// getValues,
		setValue,
		watch,
		setError,
		// reset,
	} = useForm({
		mode: "onBlur",
		defaultValues: {
			company_name: "",
			first_name: "",
			last_name: "",
			email: "",
			phone: "",
			refer_store: "",
			address_1: "",
			address_2: "",
			zip: "",
			city: "",
			state: "",
			message: "",
			number_of_stores: "",
		},
		resolver: zodResolver(schema),
	});

	const zip = watch("zip");
	const [isZipApiUpdated, setZipApiUpdated] = useState(false);
	const [store, setStore] = useState("");

	const isFirstRender = useRef(true);
	const renderCount = useRef(0);

	useEffect(() => {
		renderCount.current = renderCount.current + 1;

		const fetchLocationData = async () => {
			try {
				const response = await axios.get(
					`https://zip.row.net/zip/${zip}`
				);
				const { places } = response.data;
				if (places && places.length > 0) {
					setValue("city", places[0]["place name"], {
						shouldValidate: true,
					});
					setValue("state", places[0]["state abbreviation"], {
						shouldValidate: true,
					});
				} else {
					setValue("city", "", { shouldValidate: true });
					setValue("state", "", { shouldValidate: true });
				}
				setZipApiUpdated(true);
			} catch (error) {
				setValue("city", "", { shouldValidate: true });
				setValue("state", "", { shouldValidate: true });
			}
		};

		if (zip && zip.length === 5 && /^\d+$/.test(zip)) {
			if (leadData) {
				if (renderCount.current > 2) {
					fetchLocationData();
				}
			} else {
				if (renderCount.current > 1) {
					fetchLocationData();
				}
			}
		} else {
			setValue("city", "", { shouldValidate: true });
			setValue("state", "", { shouldValidate: true });
		}

		if (isFirstRender.current) {
			isFirstRender.current = false;
			return;
		}
	}, [zip, setValue]);

	const navigate = useNavigate();

	const [formSubmitted, setFormSubmitted] = useState(false);

	useEffect(() => {
		// Get data from local storage
		const storedData = localStorage.getItem("userHierarchy");
		const storeId = localStorage.getItem("userStore");
		try {
			const jsonObj = JSON.parse(storedData);
			// console.log(jsonObj);
			const jsonObject = JSON.parse(jsonObj?.sto_list);
			// console.log(jsonObject[storeId]);
			setStore(jsonObject[storeId]);
			// Output would be: { name: 'John', age: 30, city: 'New York' }
		} catch (error) {
			console.error("Error parsing JSON string:", error);
		}
	}, []);

	const onSubmit = async (data) => {
		// Set loading state
		setLoading(true);

		const referralData = {
			ReferralProgramForm: {
				company_name: data.company_name,
				first_name: data.first_name,
				last_name: data.last_name,
				email: data.email,
				phone: data.phone.substring(data.phone.length - 10), // since US only, get phone last 10 digits
				address_1: data.address_1,
				address_2: data.address_2,
				zip: data.zip,
				city: data.city,
				state: data.state,
				refer_store: store,
				number_of_stores: data.number_of_stores,
				message: data.message,
			},
		};

		const response = await ReferralProgramService.create(referralData)
			.then((response) => {
				// console.log(response);
				if (response.status === 200 && response.data.isOk === true) {
					// show success message
					// console.log("Success!");
					toast.success(`Success! Your referral has been received.`, {
						position: "top-center",
						autoClose: 1000,
					});
					// reset();
					setLoading(false);
					setFormSubmitted(true);
					navigate("/dashboard");
				} else {
					setLoading(false);
					//   console.log("Error!");
					toast.error(`Error`, {
						position: "top-center",
						autoClose: 1000,
					});
					// show error messages
					// const errorMappings = {
					//   company_name: "company_name",
					//   first_name: "first_name",
					//   last_name: "last_name",
					//   email: "email",
					//   phone: "phone", // since US only, get phone last 10 digits
					//   address_1: "address_1",
					//   address_2: "address_2",
					//   zip: "zip",
					//   city: "city",
					//   state: "state",
					//   number_of_stores: "number_of_stores",
					//   message: "message",
					// };

					// Object.entries(response.data.message).forEach(([field, message]) => {
					//   const errorType = errorMappings[field];
					//   if (errorType) {
					//     setError(errorType, {
					//       type: "server",
					//       message,
					//     });
					//   }
					// });
				}
			})
			.catch((error) => {
				// console.log(error);
				setError("api", {
					type: "server",
					message: error.response.data.error,
				});
			});

		// Set loading state
		setLoading(false);
	};

	return (
		<>
			{formSubmitted ? (
				<p className="paragraph-2">
					<strong>SUCCESS! YOUR MESSAGE HAS BEEN RECEIVED.</strong>
					<br />
					WE WILL GET IN CONTACT WITH YOU SHORTLY.
				</p>
			) : (
				<form
					id="referral_form"
					name="referral-form"
					onSubmit={handleSubmit(onSubmit)}
					// onSubmit={handleSubmit(
					//     console.log("Validation errors:", errors)
					// )}
					className="form-vertical"
				>
					<Row style={{ marginBottom: "0" }}>
						<Col xs={12}>
							<FormGroup className="form-group">
								<FormLabel
									htmlFor="name"
									className="referral-label"
								>
									Company Name
								</FormLabel>
								<FormControl
									type="text"
									//   maxLength="100"
									name="company_name"
									id="company_name"
									{...register("company_name")}
									isInvalid={
										touchedFields.company_name &&
										!!errors["company_name"]
									}
									isValid={
										touchedFields.company_name &&
										!errors["company_name"]
									}
								/>
								<Feedback type="invalid">
									{errors["company_name"] &&
										errors["company_name"].message}
								</Feedback>
							</FormGroup>
						</Col>
						<Col md="6">
							<FormGroup className="form-group">
								<FormLabel
									htmlFor="name"
									className="referral-label"
								>
									First Name
								</FormLabel>
								<FormControl
									type="text"
									//   maxLength="100"
									name="first_name"
									id="first_name"
									{...register("first_name")}
									isInvalid={
										touchedFields.first_name &&
										!!errors["first_name"]
									}
									isValid={
										touchedFields.first_name &&
										!errors["first_name"]
									}
								/>
								<Feedback type="invalid">
									{errors["first_name"] &&
										errors["first_name"].message}
								</Feedback>
							</FormGroup>
						</Col>
						<Col md="6">
							{" "}
							<FormGroup className="form-group">
								<FormLabel
									htmlFor="name"
									className="referral-label"
								>
									Last Name
								</FormLabel>
								<FormControl
									type="text"
									//   maxLength="100"
									name="last_name"
									id="last_name"
									{...register("last_name")}
									isInvalid={
										touchedFields.last_name &&
										!!errors["last_name"]
									}
									isValid={
										touchedFields.last_name &&
										!errors["last_name"]
									}
								/>
								<Feedback type="invalid">
									{errors["last_name"] &&
										errors["last_name"].message}
								</Feedback>
							</FormGroup>
						</Col>
						<Col md="6">
							{" "}
							<FormGroup className="form-group">
								<FormLabel
									htmlFor="name"
									className="referral-label"
								>
									Email
								</FormLabel>
								<FormControl
									type="text"
									//   maxLength="100"
									name="email"
									id="email"
									{...register("email")}
									isInvalid={
										touchedFields.email && !!errors["email"]
									}
									isValid={
										touchedFields.email && !errors["email"]
									}
								/>
								<Feedback type="invalid">
									{errors["email"] && errors["email"].message}
								</Feedback>
							</FormGroup>
						</Col>
						<Col md="6">
							<FormGroup className="form-group">
								<FormLabel
									htmlFor="name"
									className="referral-label"
								>
									Phone Number
								</FormLabel>
								<PhoneInput
									id="phone"
									name="phone"
									defaultCountry="US"
									// countries={["US", "CA", "PR", "MX"]}
									countries={["US"]}
									addInternationalOption={false}
									control={control}
									inputComponent={PhoneInputBs5}
									isInvalid={
										touchedFields.phone && !!errors["phone"]
									}
									isValid={
										touchedFields.phone && !errors["phone"]
									}
								/>
								{errors.phone && (
									<div className="is-invalid invalid-feedback">
										{errors.phone.message}
									</div>
								)}
							</FormGroup>
						</Col>
						<Col md="6">
							{" "}
							<FormGroup className="form-group">
								<FormLabel
									htmlFor="name"
									className="referral-label"
								>
									Address
								</FormLabel>
								<FormControl
									type="text"
									//   maxLength="100"
									name="address_1"
									id="address_1"
									{...register("address_1")}
									isInvalid={
										touchedFields.address_1 &&
										!!errors["address_1"]
									}
									isValid={
										touchedFields.address_1 &&
										!errors["address_1"]
									}
								/>
								<Feedback type="invalid">
									{errors["address_1"] &&
										errors["address_1"].message}
								</Feedback>
							</FormGroup>
						</Col>
						<Col md="6">
							<FormGroup className="form-group">
								<FormLabel
									htmlFor="name"
									className="referral-label"
								>
									Suite{" "}
								</FormLabel>
								<FormControl
									type="text"
									//   maxLength="100"
									name="address_2"
									id="address_2"
									{...register("address_2")}
									isInvalid={
										touchedFields.address_2 &&
										!!errors["address_2"]
									}
									isValid={
										touchedFields.address_2 &&
										!errors["address_2"]
									}
								/>
								<Feedback type="invalid">
									{errors["address_2"] &&
										errors["address_2"].message}
								</Feedback>
							</FormGroup>
						</Col>
						<Col md="6">
							{" "}
							<FormGroup className="form-group">
								<FormLabel
									htmlFor="name"
									className="referral-label"
								>
									Zip Code
								</FormLabel>
								<FormControl
									type="text"
									//   maxLength="100"
									name="zip"
									id="zip"
									{...register("zip")}
									isInvalid={
										touchedFields.zip && !!errors["zip"]
									}
									isValid={
										touchedFields.zip && !errors["zip"]
									}
								/>
								<Feedback type="invalid">
									{errors["zip"] && errors["zip"].message}
								</Feedback>
							</FormGroup>
						</Col>
						<Col md="6">
							{" "}
							<FormGroup className="form-group">
								<FormLabel
									htmlFor="name"
									className="referral-label"
								>
									City
								</FormLabel>
								<FormControl
									type="text"
									maxLength="255"
									name="city"
									id="city"
									readOnly
									{...register("city")}
									isInvalid={
										(touchedFields.city &&
											!!errors["city"]) ||
										(!leadData &&
											isSubmitted &&
											!!errors["city"]) ||
										(isZipApiUpdated && !!errors["city"])
									}
									isValid={
										(touchedFields.city &&
											!errors["city"]) ||
										(!leadData &&
											isSubmitted &&
											!errors["city"]) ||
										(isZipApiUpdated && !errors["city"])
									}
								/>
								<Feedback type="invalid">
									{errors["city"] && errors["city"].message}
								</Feedback>
							</FormGroup>
						</Col>
						<Col md="6">
							<FormGroup className="form-group">
								<FormLabel
									htmlFor="name"
									className="referral-label"
								>
									State
								</FormLabel>
								<FormControl
									type="text"
									maxLength="255"
									name="state"
									id="state"
									readOnly
									{...register("state")}
									isInvalid={
										(touchedFields.state &&
											!!errors["state"]) ||
										(!leadData &&
											isSubmitted &&
											!!errors["state"]) ||
										(isZipApiUpdated && !!errors["state"])
									}
									isValid={
										(touchedFields.state &&
											!errors["state"]) ||
										(!leadData &&
											isSubmitted &&
											!errors["state"]) ||
										(isZipApiUpdated && !errors["state"])
									}
								/>
								<Feedback type="invalid">
									{errors["state"] && errors["state"].message}
								</Feedback>
							</FormGroup>
						</Col>
						<Col md="6">
							{" "}
							<FormGroup className="form-group">
								<FormLabel
									htmlFor="name"
									className="referral-label"
								>
									Number of Stores
								</FormLabel>
								<FormControl
									type="number"
									//   maxLength="100"
									name="number_of_stores"
									id="number_of_stores"
									{...register("number_of_stores")}
									isInvalid={
										touchedFields.number_of_stores &&
										!!errors["number_of_stores"]
									}
									isValid={
										touchedFields.number_of_stores &&
										!errors["number_of_stores"]
									}
								/>
								<Feedback type="invalid">
									{errors["number_of_stores"] &&
										errors["number_of_stores"].message}
								</Feedback>
							</FormGroup>
						</Col>
						<Col xs={12}>
							<FormGroup className="form-group">
								<FormLabel
									htmlFor="name"
									className="referral-label"
								>
									Message{" "}
								</FormLabel>
								<FormControl
									as={"textarea"}
									rows={3}
									name="message"
									id="message"
									{...register("message")}
									isInvalid={
										touchedFields.message &&
										!!errors["message"]
									}
									isValid={
										touchedFields.message &&
										!errors["message"]
									}
								/>
								<Feedback type="invalid">
									{errors["message"] &&
										errors["message"].message}
								</Feedback>
							</FormGroup>
						</Col>
					</Row>
					{errors.api && (
						<Row>
							<Col lg style={{ marginBottom: "2rem" }}>
								<p className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium Mui-required">
									{errors.api.message}
								</p>
							</Col>
						</Row>
					)}
					<Row>
						<Col className="d-flex justify-content-end align-items-center">
							<Button
								type="submit"
								variant="primary"
								// disabled={isSubmitting || !isValid}
							>
								{isSubmitting ? (
									<>
										<span
											className="spinner-border spinner-border-sm mr-1"
											role="status"
											aria-hidden="true"
										></span>
									</>
								) : (
									<></>
								)}
								Submit
							</Button>
						</Col>
					</Row>
				</form>
			)}
			<DevTool control={control} />
		</>
	);
}

export default RefferalForm;
