import React, {
	useEffect,
	useState,
	forwardRef,
	useImperativeHandle,
	useRef,
} from "react";
import TextField from "@mui/material/TextField";

const CustomInputZipComponent = forwardRef(
	(
		{
			name,
			label,
			type,
			value,
			onChange,
			onBlur,
			className,
			target_city,
			target_state,
			...otherProps
		},
		ref
	) => {
		const [internalValue, setInternalValue] = useState(value);
		const [isValid, setIsValid] = useState(false);
		const [errorMessage, setErrorMessage] = useState("");
		const [isTouched, setIsTouched] = useState(false);
		const isTouchedRef = useRef(isTouched);
		const [zipCity, setZipCity] = useState("");
		const [zipState, setZipState] = useState("");
		const [isMounted, setIsMounted] = useState(false);
		const inputRef = useRef(null);

		// Object to track ongoing validation requests and cache results
		const pendingValidation = useRef({});

		useEffect(() => {
			if (value !== internalValue) {
				setInternalValue(value);
			}
		}, [value]);

		useEffect(() => {
			const validateInternalValue = async () => {
				if (isMounted) {
					await validate(internalValue); // Ensure this is awaited
				} else {
					setIsMounted(true);
				}
			};

			validateInternalValue();
		}, [internalValue]);

		useEffect(() => {
			isTouchedRef.current = isTouched;
		}, [isTouched]);

		useImperativeHandle(ref, () => ({
			validate: async (value) => await validate(value),
			isTouched: () => isTouchedRef.current,
			setIsTouched: (value) => {
				setIsTouched(value);
				isTouchedRef.current = value;
			},
			focus: () => {
				if (inputRef.current) {
					inputRef.current.focus();
				}
			},
		}));

		const handleChange = (newValue) => {
			// Filter out non-numeric characters
			const numericValue = newValue.replace(/[^0-9]/g, "");
			if (numericValue !== newValue) {
				setInternalValue(numericValue);
			}
			onChange(name, numericValue, isValid);
		};

		const handleBlur = async () => {
			if (!getFieldInfo().readOnly) {
				setIsTouched(true);
				await validate(internalValue);
			}
		};

		const validate = async (valueToValidate) => {
			const fieldInfo = getFieldInfo();
			let trimmedValue =
				valueToValidate !== undefined
					? valueToValidate
					: internalValue.trim();

			if (fieldInfo.upperCase) {
				trimmedValue = trimmedValue.toUpperCase();
			}

			if (internalValue !== trimmedValue) {
				setInternalValue(trimmedValue);
			}

			if (fieldInfo.readOnly) {
				setIsValid(true);
				setErrorMessage("");
				onChange(name, trimmedValue, true);
				if (target_city) {
					onChange(target_city, "", true);
				}
				if (target_state) {
					onChange(target_state, "", true);
				}
				return true;
			}

			// Check required
			if (fieldInfo.required && trimmedValue === "") {
				setIsValid(false);
				setErrorMessage("This field is required.");
				setIsTouched(true);
				onChange(name, trimmedValue, false);
				if (target_city) {
					onChange(target_city, "", true);
				}
				if (target_state) {
					onChange(target_state, "", true);
				}
				return false;
			}

			if (fieldInfo.required && trimmedValue.length !== 5) {
				setIsValid(false);
				setErrorMessage("Invalid ZIP code.");
				setIsTouched(true);
				onChange(name, trimmedValue, false);
				if (target_city) {
					onChange(target_city, "", true);
				}
				if (target_state) {
					onChange(target_state, "", true);
				}
				return false;
			}

			if (!fieldInfo.readOnly && trimmedValue.length === 5 && isTouched) {
				try {
					const zipInfo = await validateZipCode(trimmedValue);

					if (zipInfo.isValid) {
						setZipCity(zipInfo.zipCity);
						setZipState(zipInfo.zipState);

						if (target_city) {
							onChange(target_city, zipInfo.zipCity, true);
						}
						if (target_state) {
							onChange(target_state, zipInfo.zipState, true);
						}
					} else {
						setIsValid(false);
						setErrorMessage("Invalid ZIP code.");
						setIsTouched(true);
						if (target_city) {
							onChange(target_city, "", true);
						}
						if (target_state) {
							onChange(target_state, "", true);
						}
						onChange(name, trimmedValue, false);
						return false;
					}
				} catch (error) {
					console.error("Error validating ZIP code:", error);
					setIsValid(false);
					return false;
				}
			} else if (!fieldInfo.readOnly) {
				if (target_city) {
					onChange(target_city, "", true);
				}
				if (target_state) {
					onChange(target_state, "", true);
				}
			}

			setIsValid(true);
			setErrorMessage("");
			onChange(name, trimmedValue, true);
			return true;
		};

		const validateZipCode = async (zipCode) => {
			// Check if a validation request for this ZIP code is already in progress
			if (pendingValidation.current[zipCode]) {
				return pendingValidation.current[zipCode];
			}

			// Create a new validation request
			const validationPromise = (async () => {
				try {
					const response = await fetch(
						`https://zip.row.net/zip/${zipCode}`
					);

					if (!response.ok) {
						console.error(
							"ZIP code validation failed:",
							response.statusText
						);
						return { isValid: false, zipState: "", zipCity: "" };
					}

					const data = await response.json();

					if (data.error) {
						return { isValid: false, zipState: "", zipCity: "" };
					}

					if (
						data &&
						data["post code"] === zipCode &&
						data.places &&
						data.places.length > 0
					) {
						const firstPlace = data.places[0];
						const zipState = firstPlace["state abbreviation"];
						const zipCity = firstPlace["place name"];

						return { isValid: true, zipState, zipCity };
					} else {
						console.error("Invalid ZIP code data format:", data);
						return { isValid: false, zipState: "", zipCity: "" };
					}
				} catch (error) {
					console.error("Error validating ZIP code:", error);
					return { isValid: false, zipState: "", zipCity: "" };
				}
			})();

			// Store the validation request in the pendingValidation object
			pendingValidation.current[zipCode] = validationPromise;

			return validationPromise;
		};

		const getFieldInfo = () => {
			return {
				required: Boolean(type && type.required),
				readOnly: type && type.readOnly,
				upperCase: type && type.upperCase,
			};
		};

		return (
			<div className={`${className}`}>
				<TextField
					id={name}
					label={`${label}`}
					variant="standard"
					size="small"
					fullWidth
					type={type && type.type}
					value={internalValue}
					onChange={(e) => handleChange(e.target.value)}
					onBlur={handleBlur}
					error={!isValid && isTouchedRef.current}
					helperText={
						!isValid && isTouchedRef.current && errorMessage
					}
					className={getFieldInfo().readOnly ? "readonly-text" : ""}
					InputProps={{
						readOnly: getFieldInfo().readOnly,
						// disabled: getFieldInfo().readOnly,
					}}
					{...otherProps}
					inputRef={inputRef}
				/>
			</div>
		);
	}
);

export default CustomInputZipComponent;
