import React, { useState, useEffect } from "react";
import Tooltip from "@mui/material/Tooltip";

function MultiStepFormStepper({ steps, step, onStepClick }) {
	const [currentStep, setCurrentStep] = useState(step || 0);

	useEffect(() => {
		setCurrentStep(step - 1);
	}, [step]);

	const colorTheme = {
		completedBg: "bg-blue-200",
		currentBg: "bg-blue-200",
		futureBg: "bg-gray-200",
		completedText: "text-blue-600 dark:text-blue-500",
		currentText: "text-blue-600 dark:text-blue-500",
		futureText: "text-gray-500 dark:text-gray-100",
		completedBorder: "blue-600",
		currentBorder: "gray-200",
		futureBorder: "gray-200",
	};

	const getStepClass = (index, isLast) => {
		if (index < currentStep) {
			return `${colorTheme.completedText} after:content-[''] after:w-full after:h-1 after:bg-blue-200 after:inline-block`;
		} else if (index === currentStep && !isLast) {
			return `${colorTheme.currentText} after:content-[''] after:w-full after:h-1 after:bg-gray-200 after:inline-block`;
		} else if (index === currentStep && isLast) {
			return colorTheme.currentText;
		} else if (index > currentStep && !isLast) {
			return `${colorTheme.futureText} after:content-[''] after:w-full after:h-1 after:bg-gray-200 after:inline-block`;
		} else {
			return colorTheme.futureText;
		}
	};

	const getBgClass = (index) => {
		if (index < currentStep) {
			return colorTheme.completedBg; // Completed steps
		} else if (index === currentStep) {
			return colorTheme.currentBg; // Current step
		} else {
			return colorTheme.futureBg; // Future steps
		}
	};

	return (
		<>
			<ol className="flex items-center w-full p-0">
				{steps.map((step, index) => {
					const isLast = index === steps.length - 1;
					return (
						<li
							key={index}
							className={`flex items-center cursor-pointer ${
								isLast ? "" : "w-full"
							} ${getStepClass(index, isLast)}`} // Pass zero-based index
						>
							<Tooltip
								title={step.label}
								key={index}
								placement="top"
								onClick={() => onStepClick(index + 1)}
							>
								<span
									className={`flex items-center justify-center w-10 h-10 ${getBgClass(
										index // Pass zero-based index
									)} rounded-full lg:h-10 lg:w-10 shrink-0`}
								>
									{step.icon ? step.icon : step.id}
								</span>
							</Tooltip>
						</li>
					);
				})}
			</ol>
		</>
	);
}

export default MultiStepFormStepper;
