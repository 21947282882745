import React from "react";
import { useState } from "react";
import TopModal from "../../../common/TopModal";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import ContractService from "../../../../service/contract/ContractService";
import CustomInputComponent from "../../../formComponents/rtoDynamicForm/fields/CustomInputComponent";
// import CustomInputZipComponent from "../../../formComponents/rtoDynamicForm/fields/CustomInputZipComponent";

const ContractAddressEditModal = ({
	show,
	onHide,
	contractDetail,
	address,
	setAddress,
	modalLoading,
	setModalLoading,
}) => {
	const [error, setError] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [zipError, setZipError] = useState(false);
	const [zipErrorMessage, setZipErrorMessage] = useState("");
	const [Address1Error, setAddress1Error] = useState(false);
	const [Address1ErrorMessage, setAddress1ErrorMessage] = useState("");

	const handleZipChange = async (event) => {
		const zip = event.target.value;

		const isValidZip = /^\d{5}$/.test(zip);

		setZipError(!isValidZip);
		setZipErrorMessage(isValidZip ? "" : "Invalid Zip Code");
		setAddress((prevState) => ({
			...prevState,
			zip,
			address_1: contractDetail?.customer?.address_1,
			address_2: contractDetail?.customer?.address_2,
		}));
		if (zip.length === 5) {
			try {
				const response = await fetch(
					`https://api.zippopotam.us/us/${zip}`
				);
				if (response.ok) {
					const data = await response.json();
					setAddress({
						zip,
						city: data.places[0]["place name"],
						state: data.places[0]["state abbreviation"],
						address_1: contractDetail?.customer?.address_1,
						address_2: contractDetail?.customer?.address_2,
					});
				} else {
					// Clear city and state if zip code is not found
					setAddress((prevState) => ({
						...prevState,
						city: "",
						state: "",
						address_1: contractDetail?.customer?.address_1,
						address_2: contractDetail?.customer?.address_2,
					}));
				}
			} catch (error) {
				console.error("Error fetching data:", error);
				// Optionally handle the error state here, e.g., showing an error message
			}
		} else {
			// Clear city and state when zip code is less than 5 digits
			setAddress((prevState) => ({
				...prevState,
				city: "",
				state: "",
				address_1: contractDetail?.customer?.address_1,
				address_2: contractDetail?.customer?.address_2,
			}));
		}
	};

	const handleAddress1Change = (fieldName, value, pIsValid) => {
		const address1 = value;
		const isNotValid = pIsValid;

		setAddress1Error(!isNotValid);
		setAddress1ErrorMessage(!isNotValid ? "Address Is Required!" : "");
		setAddress((prevState) => ({ ...prevState, address_1: address1 }));
	};

	const onUpdate = async () => {
		setModalLoading(true);
		const data = {
			contract_id: contractDetail?.id,
			update: address,
		};
		//const response = ContractService.update(data).then((response) => {
		ContractService.update(data).then((response) => {
			if (response.status === 200 && response.data.isOk === true) {
				onHide(true);
				setModalLoading(false);
			} else {
				setError(true);
				setModalLoading(false);
				setErrorMessage(response.data.errors);
			}
		});
	};

	const handleAddress2Change = (fieldName, value, pIsValid) => {
		const address2 = value;
		setAddress((prevState) => ({ ...prevState, address_2: address2 }));
	};
	return (
		<TopModal
			isVisible={show}
			body={
				<>
					{modalLoading && (
						<div className="loading-backdrop">
							<CircularProgress
							// animation="border"
							// variant="primary"
							/>
						</div>
					)}
					{
						<Box sx={{ flexGrow: 1 }}>
							<Grid container spacing={2}>
								<Grid item xs={6}>
									<CustomInputComponent
										name={"Address_1"}
										label={"Address 1"}
										value={address.address_1}
										type={{ required: true }}
										onChange={handleAddress1Change}
										className={""}
										required={true}
										error={Address1Error}
										helperText={Address1ErrorMessage}
										// style={fieldInfo.style}
									/>
									{/*
									<TextField
										required
										disabled={modalLoading}
										id="outlined-basic"
										label="Address 1"
										variant="standard"
										value={address.address_1}
										placeholder="Address 1"
										fullWidth
										onChange={handleAddress1Change}
										error={Address1Error}
										helperText={Address1ErrorMessage}
									/> */}
								</Grid>
								<Grid item xs={6}>
									<TextField
										required
										disabled={modalLoading}
										error={zipError}
										helperText={zipErrorMessage}
										id="outlined-basic"
										label="Zip"
										variant="standard"
										// margin=""
										fullWidth
										value={address.zip}
										onChange={handleZipChange}
									/>
									{/* <CustomInputZipComponent
										name={"zip"}
										label={"Zip"}
										type={{ required: true }}
										value={address.zip}
										className={""}
										required={true}
										onChange={(
											fieldName,
											value,
											pIsValid
										) => {
											console.log(fieldName);
										}}
										// style={fieldInfo.style}
										// target_city={item.targetCity}
										// target_state={item.targetState}
									/> */}
								</Grid>
								<Grid item xs={6}>
									{/* <TextField
										id="outlined-basic"
										label="Address 2"
										variant="standard"
										disabled={modalLoading}
										value={address.address_2}
										placeholder="Address 2"
										fullWidth
										onChange={handleAddress2Change}
										error={error}
										helperText={errorMessage}
									/> */}
									<CustomInputComponent
										name={"Address_2"}
										label={"Address 2"}
										value={address.address_2}
										type={{ required: false }}
										onChange={handleAddress2Change}
										className={""}
										required={false}
										error={error}
										helperText={errorMessage}
										disabled={modalLoading}
										// style={fieldInfo.style}
									/>
								</Grid>
								<Grid item xs={6}>
									<TextField
										id="outlined-basic"
										label="City"
										variant="standard"
										margin="normal"
										fullWidth
										disabled
										InputProps={{ readOnly: true }}
										value={address.city}
										error={error}
										helperText={errorMessage}
									/>
								</Grid>
								<Grid item xs={6}></Grid>
								<Grid item xs={6}>
									<TextField
										id="outlined-basic"
										label="State"
										variant="standard"
										margin="normal"
										placeholder="State"
										fullWidth
										disabled
										InputProps={{ readOnly: true }}
										value={address.state}
										error={error}
										helperText={errorMessage}
									/>
								</Grid>
							</Grid>
						</Box>
					}
				</>
			}
			title={`Edit Address`}
			footer={
				<>
					<Button
						variant="contained"
						onClick={onUpdate}
						disabled={modalLoading || zipError || Address1Error}
					>
						{" "}
						Update{" "}
					</Button>
				</>
			}
			onClose={() => {
				setAddress({
					zip: contractDetail.customer?.zip,
					city: contractDetail.customer?.city,
					state: contractDetail.customer?.state,
					address_1: contractDetail.customer?.address_1,
					address_2: contractDetail.customer?.address_2,
				});
				setZipError(false);
				setZipErrorMessage("");
				setAddress1Error(false);
				setAddress1ErrorMessage("");
				onHide();
			}}
			// loading={modalLoading}
		></TopModal>
	);
};

export default ContractAddressEditModal;
