import React, {
	useEffect,
	useState,
	forwardRef,
	useImperativeHandle,
	useRef,
} from "react";
import TextField from "@mui/material/TextField";

/**
 * @doc when we need to copy the content to another textbox
 * [
 *	'type' => 'email',
 *	'name' => 'email',
 *	'class' => 'col-md-12',
 *	'targetTextComponent' => 'username',  // Optional property to copy the content to another textbox
 * ],
 */
const CustomInputEmailComponent = forwardRef(
	(
		{
			name,
			label,
			type,
			value,
			onChange,
			onBlur,
			className,
			targetTextComponent,
			...otherProps
		},
		ref
	) => {
		const [internalValue, setInternalValue] = useState(value);
		const [isValid, setIsValid] = useState(true);
		const [errorMessage, setErrorMessage] = useState("");
		const [isTouched, setIsTouched] = useState(false);
		const isTouchedRef = useRef(isTouched);
		const [isMounted, setIsMounted] = useState(false);
		const inputRef = useRef(null);

		useEffect(() => {
			setInternalValue(value);
		}, [value]);

		useEffect(() => {
			if (isMounted) {
				validate(internalValue);
			} else {
				setIsMounted(true);
			}
		}, [internalValue]);

		useEffect(() => {
			isTouchedRef.current = isTouched;
		}, [isTouched]);

		useImperativeHandle(ref, () => ({
			validate: (value) => validate(value),
			isTouched: () => isTouchedRef.current,
			setIsTouched: (value) => {
				setIsTouched(value);
				isTouchedRef.current = value;
			},
			focus: () => {
				if (inputRef.current) {
					inputRef.current.focus();
				}
			},
		}));

		const handleChange = (newValue) => {
			setInternalValue(newValue);
			onChange(name, newValue, isValid);
		};

		const validate = (valueToValidate) => {
			const value =
				valueToValidate !== undefined ? valueToValidate : internalValue;
			const fieldInfo = getFieldInfo();
			const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

			let trimmedValue = value;

			setInternalValue(trimmedValue);

			if (fieldInfo.readOnly) {
				setIsValid(true);
				setErrorMessage("");
				onChange(name, trimmedValue, true);
				return true;
			}

			if (fieldInfo.required && !emailRegex.test(trimmedValue)) {
				setIsValid(false);
				setErrorMessage("Invalid email address.");
				setIsTouched(true);
				onChange(name, trimmedValue, false);

				if (targetTextComponent) {
					onChange(targetTextComponent, trimmedValue, false);
				}
				return false;
			}

			if (
				fieldInfo.required &&
				fieldInfo.minLength &&
				trimmedValue.length < fieldInfo.minLength
			) {
				setIsValid(false);
				setErrorMessage(
					`Minimum length is ${fieldInfo.minLength} characters.`
				);
				setIsTouched(true);
				onChange(name, trimmedValue, false);
				return false;
			}

			if (
				fieldInfo.required &&
				fieldInfo.maxLength &&
				trimmedValue.length > fieldInfo.maxLength
			) {
				setIsValid(false);
				setErrorMessage(
					`Maximum length is ${fieldInfo.maxLength} characters.`
				);
				setIsTouched(true);
				onChange(name, trimmedValue, false);
				return false;
			}

			setIsValid(true);
			setErrorMessage("");
			onChange(name, trimmedValue, true);

			if (targetTextComponent) {
				onChange(targetTextComponent, trimmedValue, true);
			}
			return true;
		};

		const handleFocus = () => {
			setIsTouched(true);
			validate(internalValue);
		};

		const getFieldInfo = () => {
			return {
				required: Boolean(type && type.required),
				minLength: type && type.minLength,
				maxLength: type && type.maxLength,
				readOnly: type && type.readOnly,
				upperCase: type && type.upperCase,
			};
		};

		return (
			<div className={`${className}`}>
				<TextField
					id={name}
					label={label}
					variant="standard"
					size="small"
					fullWidth
					type={type && type.type}
					value={internalValue}
					onChange={(e) => handleChange(e.target.value)}
					onFocus={handleFocus}
					onBlur={onBlur}
					error={!isValid && isTouchedRef.current}
					helperText={
						!isValid && isTouchedRef.current ? errorMessage : ""
					}
					className={getFieldInfo().readOnly ? "readonly-text" : ""}
					inputProps={{
						readOnly: getFieldInfo().readOnly,
						style: {
							textTransform: getFieldInfo().upperCase
								? "uppercase"
								: "none",
						},
					}}
					{...otherProps}
					inputRef={inputRef}
				/>
			</div>
		);
	}
);

export default CustomInputEmailComponent;
