import React, { useEffect, useState } from "react";
import TopModal from "../../../common/TopModal";
import CircularProgress from "@mui/material/CircularProgress";
import { Divider, Button as MuiButton, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import PaymentService from "../../../../service/payment/PaymentService";
import CashPaymentBalanceCalculatorForm from "../../../formComponents/CashPaymentBalanceCalculatorForm";
import { formatDate, formatAmount } from "../../../../helpers/Utils";
import CreditCardAddForm from "../../../formComponents/CreditCardAddForm";
import { toast } from "react-toastify";
import SelectPaymentMethodsForm from "../../../formComponents/SelectPaymentMethodsForm";

const ContractEarlyPayoffModal = ({
	isEarlyPayoffVisible,
	closeEarlyPayoffModal,
	contractId,
	contractDetail,
	setLoading,
}) => {
	const [modalLoading, setModalLoading] = useState(false);
	const [cancelModalOpen, setCancelModalOpen] = useState(false);
	const [paymentInfo, setPaymentInfo] = useState({});
	const [selectedPayment, setSelectedPayment] = useState("");
	const [tokenizerUrl, setTokenizerUrl] = useState("");
	const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
	const [addCardInfo, setAddCardInfo] = useState({
		first_name: "",
		last_name: "",
		month: " ",
		year: " ",
		cvv: "",
		billing_address: "",
		zip: "",
		city: "",
		state: "",
		token: "",
	});

	const handleClose = () => {
		setCancelModalOpen(false);
		closeEarlyPayoffModal();
		setSelectedPayment("");
	};

	const handleSubmit = async () => {
		//setModalLoading(true);
		console.log("Submit Payment");
		// @todo: Add payment logic here

		let taxAmount = formatAmount(
			(parseFloat(contractDetail?.billing?.payoff_balance_due) / 100) *
				parseFloat(paymentInfo?.payment?.tax_rate)
		);
		let totalAmount = formatAmount(
			parseFloat(contractDetail?.billing?.payoff_balance_due) +
				parseFloat(taxAmount) +
				parseFloat(paymentInfo?.payment?.fee)
		);

		/* Todo: Following code not yet tested. */
		if (selectedPayment === "CC") {
			// Add new credit card payment logic here
			setLoading(true);
			return await PaymentService.contractPaymentProcess({
				contract_id: contractId,
				payment_method_id: "CC",
				payment_action: "early_payoff",
				payment: {
					net: parseFloat(
						contractDetail?.billing?.payoff_balance_due
					),
					tax: taxAmount,
					fee: parseFloat(paymentInfo?.payment?.fee),
					total: totalAmount,
				},
				payment_method: {
					billing_address: addCardInfo?.billing_address,
					card_cvv: addCardInfo?.cvv,
					card_first_name: addCardInfo?.first_name,
					card_last_name: addCardInfo?.last_name,
					card_month: addCardInfo?.month,
					card_year: addCardInfo?.year,
					city: addCardInfo?.city,
					state: addCardInfo?.state,
					token: addCardInfo?.token,
					zip: addCardInfo?.zip,
				},
			})
				.then((response) => {
					if (
						response.status === 200 &&
						response.data.isOk === true
					) {
						setLoading(false);
						toast.info(`Payment completed!`, {
							position: "top-center",
							autoClose: 1000,
						});
					} else {
						toast.error(response.data.msg, {
							position: "top-center",
							autoClose: 1000,
						});
						setLoading(false);
					}
					setLoading(false);
				})
				.catch((error) => {
					toast.error(`Error: ${error.response.data.error}`, {
						position: "top-center",
						autoClose: 1000,
					});
					setLoading(false);
				});
		} else {
			return await PaymentService.contractPaymentProcess({
				contract_id: contractId,
				payment_method_id: selectedPayment,
				payment_action: "early_payoff",
				payment: {
					net: parseFloat(
						contractDetail?.billing?.payoff_balance_due
					),
					tax: taxAmount,
					fee: parseFloat(paymentInfo?.payment?.fee),
					total: totalAmount,
				},
			})
				.then((response) => {
					if (
						response.status === 200 &&
						response.data.isOk === true
					) {
						toast.info(`Payment completed!`, {
							position: "top-center",
							autoClose: 1000,
						});
					} else {
						toast.error(
							`Error: ${
								response?.data?.msg
									? response?.data?.msg
									: "Unknown Error!"
							}`,
							{
								position: "top-center",
								autoClose: 1000,
							}
						);
					}
				})
				.catch((error) => {
					toast.error(`Error: ${error.response.data.error}`, {
						position: "top-center",
						autoClose: 1000,
					});
				});
		}

		console.log("selectedPayment", selectedPayment);
		console.log("addCardInfo", addCardInfo);
		console.log("paymentInfo", paymentInfo);
	};

	// useEffect hook to log to console when isVisible changes
	useEffect(() => {
		if (isEarlyPayoffVisible) {
			contractPaymentGet();
			setCancelModalOpen(true);
		}
	}, [isEarlyPayoffVisible]);

	const contractPaymentGet = async () => {
		setLoading(true);
		return await PaymentService.contractPaymentGet(contractId)
			.then((response) => {
				if (response.status === 200 && response.data.isOk === true) {
					setPaymentInfo(response?.data?.data);
					setTokenizerUrl(
						response?.data?.data?.payment_methods?.CC?.tokenizer_url
					);
					setLoading(false);
				}
				setLoading(false);
			})
			.catch((error) => {
				console.log(error);
				setLoading(false);
			});
	};

	// Define the body content for the modal
	let taxAmount = formatAmount(
		(parseFloat(contractDetail?.billing?.payoff_balance_due) / 100) *
			parseFloat(paymentInfo?.payment?.tax_rate)
	);
	let totalAmount = formatAmount(
		parseFloat(contractDetail?.billing?.payoff_balance_due) +
			parseFloat(taxAmount) +
			parseFloat(paymentInfo?.payment?.fee)
	);

	const modalBody = (
		<>
			{/* Your body content here */}
			{
				<div style={{ padding: "0px 50px", color: "#21557a" }}>
					<Box sx={{ flexGrow: 1 }} marginTop={2}>
						<Grid container spacing={1}>
							<Grid
								item
								xs={6}
								sx={{
									textAlign: "left",
								}}
							>
								Retail Value:{" "}
								{formatDate(
									contractDetail?.billing?.payoff_date
								)}
							</Grid>
							<Grid
								item
								xs={6}
								sx={{
									textAlign: "right",
								}}
							>
								{contractDetail?.billing?.payoff_date} Day(s)
								remaining
							</Grid>
						</Grid>
					</Box>
					<Divider
						sx={{
							borderColor: "#145d77",
							margin: "30px 0px",
							borderWidth: "1px 0px",
						}}
					/>
					<Box sx={{ flexGrow: 1 }} marginTop={2}>
						<Grid container spacing={1}>
							<Grid
								item
								xs={6}
								sx={{
									textAlign: "left",
								}}
							>
								Early Payoff Option Amount:
							</Grid>
							<Grid
								item
								xs={6}
								sx={{
									textAlign: "right",
								}}
							>
								{formatAmount(
									contractDetail?.billing
										?.payoff_original_amount
								)}
							</Grid>
							<Grid
								item
								xs={6}
								sx={{
									textAlign: "left",
								}}
							>
								Payment(s) Collected:
							</Grid>
							<Grid
								item
								xs={6}
								sx={{
									textAlign: "right",
								}}
							>
								{formatAmount(
									contractDetail?.billing?.payments_collected
								)}
							</Grid>
							<Grid
								item
								xs={6}
								sx={{
									textAlign: "left",
								}}
							>
								Amount Due:
							</Grid>
							<Grid
								item
								xs={6}
								sx={{
									textAlign: "right",
								}}
							>
								{formatAmount(
									contractDetail?.billing?.payoff_balance_due
								)}
							</Grid>
						</Grid>
					</Box>
					<Box
						sx={{
							flexGrow: 1,
							background: "#F5F5F5",
							borderRadius: "10px",
							color: "#21557a",
							padding: "10px",
						}}
						marginTop={2}
					>
						<Grid container rowSpacing={2} columnSpacing={1}>
							<Grid
								item
								xs={6}
								sx={{
									textAlign: "left",
								}}
							>
								{`Tax [${paymentInfo?.payment?.tax_rate}%]:`}
							</Grid>
							<Grid
								item
								xs={6}
								sx={{
									textAlign: "right",
								}}
							>
								${taxAmount}
							</Grid>
							<Grid
								item
								xs={6}
								sx={{
									textAlign: "left",
								}}
							>
								{"Payment Fee:"}
							</Grid>
							<Grid
								item
								xs={6}
								sx={{
									textAlign: "right",
								}}
							>
								${paymentInfo?.payment?.fee}
							</Grid>
							<Grid
								item
								xs={6}
								sx={{
									textAlign: "left",
								}}
							>
								{"Total Payment Amount:"}
							</Grid>
							<Grid
								item
								xs={6}
								sx={{
									textAlign: "right",
								}}
							>
								${parseFloat(totalAmount)}
							</Grid>
						</Grid>
					</Box>

					<Divider
						sx={{
							borderColor: "#145d77",
							margin: "30px 0px",
							borderWidth: "1px 0px",
						}}
					/>

					<SelectPaymentMethodsForm
						paymentMethods={paymentInfo?.payment_methods}
						selectedPayment={selectedPayment}
						setSelectedPayment={setSelectedPayment}
						setIsSubmitDisabled={setIsSubmitDisabled}
					/>

					<Divider
						sx={{
							borderColor: "#145d77",
							margin: "30px 0px",
							borderWidth: "1px 0px",
						}}
					/>

					{selectedPayment === "CASH" && (
						<CashPaymentBalanceCalculatorForm
							paymentAmount={totalAmount}
							setIsSubmitDisabled={setIsSubmitDisabled}
						/>
					)}

					{selectedPayment === "CC" && (
						<CreditCardAddForm
							tokenizerUrl={tokenizerUrl}
							setAddCardInfo={setAddCardInfo}
							addCardInfo={addCardInfo}
							setIsSubmitDisabled={setIsSubmitDisabled}
						/>
					)}
				</div>
			}

			{modalLoading && (
				<div className="loading-backdrop">
					<CircularProgress />
				</div>
			)}
		</>
	);

	// Define the header content for the modal
	const modalHeader = <>Contract Early Payoff</>;

	// Define the footer content for the modal
	const modalFooter = (
		<div
			style={{
				display: "flex",
				justifyContent: "space-between",
				width: "100%",
			}}
		>
			<MuiButton
				color="error"
				variant="contained"
				onClick={handleClose}
				disabled={modalLoading}
			>
				Cancel
			</MuiButton>
			<MuiButton
				color="success"
				variant="contained"
				onClick={handleSubmit}
				disabled={isSubmitDisabled}
			>
				{"Submit & Complete "}
			</MuiButton>
		</div>
	);

	return (
		<TopModal
			isVisible={cancelModalOpen}
			body={modalBody}
			footer={modalFooter}
			title={modalHeader}
			onClose={handleClose}
			loading={modalLoading}
		></TopModal>
	);
};

export default ContractEarlyPayoffModal;
