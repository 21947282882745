import React, { useEffect, useState, useCallback } from "react";
import InputLabel from "@mui/material/InputLabel";

const CustomInputCCNTokenizerComponent = ({
	name,
	label,
	type,
	value,
	onChange,
	className,
	targetToken,
	tokenizerUrl = null,
	...otherProps
}) => {
	const [token, setToken] = useState("");
	const [isValid, setIsValid] = useState(false);
	const [iframeLoaded, setIframeLoaded] = useState(false); // New state to track iframe loading

	const iframeUrl = tokenizerUrl
		? tokenizerUrl +
		  "?css=input%7Bbackground-color%3Atransparent%3Bborder%3Anone%3Bborder-bottom%3A1px%20solid%20%239e9e9e%3Bborder-radius%3A0%3Boutline%3Anone%3Bheight%3A2rem%3Bwidth%3A100%25%3Bfont-size%3A16px%3Bmargin%3A-12px%200%208px%200%3Bpadding%3A0%3B-webkit-box-shadow%3Anone%3Bbox-shadow%3Anone%3B-webkit-box-sizing%3Acontent-box%3Bbox-sizing%3Acontent-box%3B-webkit-transition%3Aborder%20.3s%2C%20-webkit-box-shadow%20.3s%3Btransition%3Aborder%20.3s%2C%20-webkit-box-shadow%20.3s%3Btransition%3A%20box-shadow%20.3s%2Cborder%20.3s%3B%20transition%3A%20box-shadow%20.3s%2Cborder%20.3s%2C%20-webkit-box-shadow%20.3s%3B%7D.error%7Bborder-bottom%3A1px%20solid%20%23F44336%3B-webkit-box-shadow%3A0%201px%200%200%20%23F44336%3Bbox-shadow%3A0%201px%200%200%20%23F44336%3B%7D.valid%7Bborder-bottom%3A1px%20solid%20%234CAF50%3B-webkit-box-shadow%3A0%201px%200%200%20%234CAF50%3Bbox-shadow%3A0%201px%200%200%20%234CAF50%3B%7Dinput%3Afocus%7Bborder-bottom%3A1px%20solid%20%2300ABF9%20!important%3Bbox-shadow%3A%200%201px%200%200%20%2300ABF9%20!important%3B%7Dbody%7Bmargin%3A0%3B%7D&invalidinputevent=true&placeholder=xxxx%20xxxx%20xxxx%20xxxx"
		: null;

	const handleTokenChange = useCallback(
		(newToken, isValid) => {
			setIsValid(isValid);
			setToken(newToken);
			onChange(name, newToken, isValid);
			if (targetToken) {
				onChange(targetToken, newToken, isValid);
			}
		},
		[name, onChange]
	);

	useEffect(() => {
		const receiveMessage = (event) => {
			try {
				const data = JSON.parse(event.data);
				if (data && data.message) {
					handleTokenChange(data.message, true);
				}
				if (data && data.validationError === "Invalid Account Number") {
					onChange(name, "", false);
					if (targetToken) {
						onChange(targetToken, "", false);
					}
				}
			} catch (error) {
				// console.error("Error parsing message from iFrame:", error);
			}
		};

		window.addEventListener("message", receiveMessage, false);

		return () => {
			window.removeEventListener("message", receiveMessage, false);
		};
	}, [name, handleTokenChange]);

	const handleIframeLoad = () => {
		setIframeLoaded(true);
	};

	const getFieldInfo = () => {
		return {
			required: Boolean(type && type.required),
			minLength: type && type.minLength,
			maxLength: type && type.maxLength,
			readOnly: type && type.readOnly,
			upperCase: type && type.upperCase,
		};
	};

	return (
		<div>
			{iframeUrl ? (
				<div
					style={{
						display: iframeLoaded ? "block" : "none",
						width: "100%",
					}}
				>
					<InputLabel
						shrink
						htmlFor="tokenFrame"
						style={{
							marginBottom: "-4.5px",
							textTransform:
								type && type.upperCase ? "uppercase" : "none",
						}}
					>
						{label} {type && type.required ? "*" : ""}
					</InputLabel>
					<iframe
						title="tokenFrame"
						id="tokenFrame"
						name="tokenFrame"
						src={iframeUrl}
						frameBorder="0"
						scrolling="no"
						style={{ width: "100%", height: "100%" }}
						onLoad={handleIframeLoad}
						className={className}
						{...otherProps}
					/>
				</div>
			) : (
				<p style={{ color: "red", fontSize: ".8rem" }}>
					Tokenizer URL is not available. Please check store settings
					and try again later.
				</p>
			)}
		</div>
	);
};

export default CustomInputCCNTokenizerComponent;
