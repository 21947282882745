import React, { useEffect, useState } from "react";
import TopModal from "../../../common/TopModal";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import ContractService from "../../../../service/contract/ContractService";
import dayjs from "dayjs";
import CustomSelectComponent from "../../../formComponents/rtoDynamicForm/fields/CustomSelectComponent";
import { format } from "date-fns";
import CustomDateInputComponent from "../../../formComponents/rtoDynamicForm/fields/CustomDateInputComponent";
import CustomInputComponent from "../../../formComponents/rtoDynamicForm/fields/CustomInputComponent";
import CustomInputPhoneComponent from "../../../formComponents/rtoDynamicForm/fields/CustomInputPhoneComponent";
import CustomInputNumberComponent from "../../../formComponents/rtoDynamicForm/fields/CustomInputNumberComponent";
import CustomInputEmailComponent from "../../../formComponents/rtoDynamicForm/fields/CustomInputEmailComponent";

const ContractTextToEditModal = ({
	show,
	onHide,
	setTextToEditData,
	textToEditData,
	contractId,
	selectOptions,
}) => {
	const [error, setError] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [disabled, setDisabled] = useState(false);
	const onUpdate = () => {
		let data = {};
		if (textToEditData?.type === "date") {
			console.log("textToEditData?.value -> ", textToEditData?.value);
			// const formattedDate = textToEditData?.value
			// 	? format(new Date(textToEditData?.value), "yyyy-MM-dd")
			// 	: "";
			data = {
				contract_id: contractId,
				update: {
					[textToEditData?.name]: textToEditData?.value,
				},
			};
		} else if (textToEditData?.type === "phone") {
			// Remove the +1 from the phone number
			const phone = textToEditData?.value.startsWith("+1")
				? textToEditData?.value.slice(2)
				: textToEditData?.value;
			data = {
				contract_id: contractId,
				update: {
					[textToEditData?.name]: phone,
				},
			};
		} else {
			data = {
				contract_id: contractId,
				update: {
					[textToEditData?.name]: textToEditData?.value,
				},
			};
		}

		//const response = ContractService.update(data).then((response) => {
		ContractService.update(data).then((response) => {
			if (response.status === 200 && response.data.isOk === true) {
				onHide(true);
				setError(false);
				setErrorMessage("");
			} else {
				setError(true);
				//setErrorMessage(response.data.errors[`${textToEditData.name}`]);
			}
		});
	};

	const handleInputChange = (fieldName, value, pIsValid) => {
		setTextToEditData({
			...textToEditData,
			value: value,
		});
		setDisabled(!pIsValid);

		if (textToEditData?.type === "ssn") {
			if (!value.match(/^\d{4}$/i)) {
				setError(true);
				setErrorMessage("SSN must be a 4-digit number.");
			} else {
				setError(false);
				setErrorMessage("");
			}
		}
	};

	useEffect(() => {
		isDisabled();
	}, [textToEditData]);

	const isDisabled = () => {
		if (textToEditData?.type === "date") {
			if (
				textToEditData?.value === "" ||
				textToEditData?.value === null
			) {
				setDisabled(true);
			} else {
				setDisabled(false);
			}
		}
	};

	return (
		<TopModal
			isVisible={show}
			body={
				<>
					{(() => {
						if (textToEditData?.type === "date") {
							return (
								<div
									style={{
										width: "100%",
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
									}}
								>
									<div className="custom-date-input-container">
										<CustomDateInputComponent
											name={textToEditData?.name}
											value={dayjs(textToEditData?.value)}
											onChange={handleInputChange}
											maxDate={dayjs(new Date())}
										></CustomDateInputComponent>
									</div>
								</div>
							);
						} else if (textToEditData?.type === "phone") {
							return (
								<div
									style={{
										width: "100%",
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
									}}
								>
									<div className="custom-date-input-container">
										<CustomInputPhoneComponent
											name={textToEditData?.name}
											label={textToEditData?.label}
											value={textToEditData?.value}
											onChange={handleInputChange}
											onBlur={handleInputChange}
											className={""}
											type={{ required: true }}
										/>
									</div>
								</div>
							);
						} else if (textToEditData?.type === "select") {
							return (
								<>
									<FormControl
										sx={{ m: 1, width: "80%" }}
										size="small"
									>
										<CustomSelectComponent
											name={textToEditData?.name}
											label={textToEditData?.label}
											type={{
												options:
													textToEditData?.selectOptions,
											}}
											value={textToEditData?.value}
											onChange={(
												fieldName,
												value,
												pIsValid
											) =>
												handleInputChange(
													fieldName,
													value,
													pIsValid
												)
											}
											className={""}
											required={true}
										/>
									</FormControl>
								</>
							);
						} else if (textToEditData?.type === "number") {
							return (
								<CustomInputNumberComponent
									name={textToEditData?.name}
									label={textToEditData?.label}
									value={textToEditData?.value}
									type={{ required: true }}
									InputLabelProps={{
										shrink: true,
									}}
									onChange={(fieldName, value, pIsValid) => {
										handleInputChange(
											fieldName,
											value,
											pIsValid
										);
									}}
									className={""}
									required={true}
									error={error}
									helperText={errorMessage}
								/>
							);
						} else if (textToEditData?.type === "currency") {
							return (
								<CustomInputNumberComponent
									name={textToEditData?.name}
									label={textToEditData?.label}
									value={textToEditData?.value}
									type={{
										required: "true",
										decimal: "true",
										decimalLimit: 2,
										prefix: "$",
									}}
									InputLabelProps={{
										shrink: true,
									}}
									onChange={(fieldName, value, pIsValid) => {
										handleInputChange(
											fieldName,
											value,
											pIsValid
										);
									}}
									className={""}
									required={true}
									error={error}
									helperText={errorMessage}
								/>
							);
						} else if (textToEditData?.type === "email") {
							return (
								<CustomInputEmailComponent
									name={textToEditData?.name}
									label={textToEditData?.label}
									value={textToEditData?.value}
									type={{ required: true, upperCase: true }}
									onChange={(fieldName, value, pIsValid) => {
										handleInputChange(
											fieldName,
											value,
											pIsValid
										);
									}}
									required={"true"}
									targetTextComponent={false}
								/>
							);
						} else {
							return (
								<CustomInputComponent
									name={textToEditData?.name}
									label={textToEditData?.label}
									value={textToEditData?.value}
									type={{ required: true }}
									InputLabelProps={{
										shrink: true,
									}}
									onChange={(fieldName, value, pIsValid) => {
										handleInputChange(
											fieldName,
											value,
											pIsValid
										);
									}}
									className={""}
									required={true}
									error={error}
									helperText={errorMessage}
								/>
							);
						}
					})()}
				</>
			}
			title={`Edit ${textToEditData?.label}`}
			footer={
				<>
					<Button
						disabled={disabled}
						variant="contained"
						onClick={onUpdate}
					>
						{" "}
						Update{" "}
					</Button>
				</>
			}
			onClose={onHide}
			// loading={modalLoading}
		></TopModal>
	);
};

export default ContractTextToEditModal;
