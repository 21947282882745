import React from "react";
import { useState } from "react";
import TopModal from "../../../common/TopModal";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import ContractService from "../../../../service/contract/ContractService";
import CustomInputComponent from "../../../formComponents/rtoDynamicForm/fields/CustomInputComponent";

const ContractNameEditModal = ({
	show,
	onHide,
	contractDetail,
	namePayload,
	setNamePayload,
	modalLoading,
	setModalLoading,
}) => {
	const [errorMessage, setErrorMessage] = useState("");
	const [firstNameError, setFirstNameError] = useState(false);
	const [firstNameErrorMessage, setFirstNameErrorMessage] = useState("");
	const [lastNameError, setLastNameError] = useState(false);
	const [lastNameErrorMessage, setLastNameErrorMessage] = useState("");

	const onUpdate = async () => {
		setModalLoading(true);
		const data = {
			contract_id: contractDetail?.id,
			update: namePayload,
		};
		//const response = ContractService.update(data).then((response) => {
		ContractService.update(data).then((response) => {
			if (response.status === 200 && response.data.isOk === true) {
				onHide(true);
				setModalLoading(false);
			} else {
				setModalLoading(false);
				setErrorMessage(response.data.errors);
			}
		});
	};

	const handleFirstNameOnChange = (fieldName, value, pIsValid) => {
		const isNotValid = pIsValid;
		setFirstNameError(!isNotValid);
		setFirstNameErrorMessage(!isNotValid ? "First name is required!" : "");
		setNamePayload((prevState) => ({ ...prevState, first_name: value }));
	};

	const handleLastNameOnChange = (fieldName, value, pIsValid) => {
		const isNotValid = pIsValid;
		setLastNameError(!isNotValid);
		setLastNameErrorMessage(!isNotValid ? "Last name is required!" : "");
		setNamePayload((prevState) => ({ ...prevState, last_name: value }));
	};

	return (
		<TopModal
			isVisible={show}
			body={
				<>
					{modalLoading && (
						<div className="loading-backdrop">
							<CircularProgress
							// animation="border"
							// variant="primary"
							/>
						</div>
					)}
					{
						<Box sx={{ flexGrow: 1 }}>
							<Grid container spacing={2}>
								<Grid item xs={6}>
									<CustomInputComponent
										name={"first_name"}
										label={"First Name"}
										value={namePayload?.first_name}
										type={{ required: true }}
										onChange={handleFirstNameOnChange}
										className={""}
										required={true}
										inputProps={{
											style: {
												textTransform: "uppercase",
											},
											maxLength: 255,
										}}
										error={firstNameError}
										helperText={firstNameErrorMessage}
									/>
								</Grid>
								<Grid item xs={6}>
									<CustomInputComponent
										name={"last_name"}
										label={"Last Name"}
										value={namePayload?.last_name}
										type={{ required: true }}
										onChange={handleLastNameOnChange}
										className={""}
										required={true}
										inputProps={{
											style: {
												textTransform: "uppercase",
											},
											maxLength: 255,
										}}
										error={lastNameError}
										helperText={lastNameErrorMessage}
									/>
								</Grid>
							</Grid>
						</Box>
					}
				</>
			}
			title={`Edit Name`}
			footer={
				<>
					<Button
						variant="contained"
						onClick={onUpdate}
						disabled={
							modalLoading || firstNameError || lastNameError
						}
					>
						{" "}
						Update{" "}
					</Button>
				</>
			}
			onClose={() => {
				onHide();
			}}
		></TopModal>
	);
};

export default ContractNameEditModal;
