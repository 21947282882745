import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Row, Col } from "react-bootstrap";
import ActivateAccountForm from "../../components/forms/ActivateAccountForm";

function ActivateAccount() {
	const pageTitle = "Activate Account | RTO Mobile";
	const pageImage = "https://www.rtomobile.com/images/rto-open_graph.png";

	useEffect(() => {
		const html = document.getElementsByTagName("html")[0];
		html.setAttribute("data-wf-page", "5fc816a3dc392e6ecca3f7a6");
		html.setAttribute("data-wf-site", "5fa41afe5cdaf5586f4cea1d");

		window.Webflow && window.Webflow.destroy();
		window.Webflow && window.Webflow.ready();
		window.Webflow && window.Webflow.require("ix2").init();
		document.dispatchEvent(new Event("readystatechange"));
	});

	return (
		<>
			<Helmet>
				<title>{pageTitle}</title>
				<meta name="robots" content="noindex,nofollow"></meta>
				<meta property="og:title" content={pageTitle} />
				<meta property="og:image" content={pageImage} />
				<meta name="twitter:title" content={pageTitle} />
				<meta name="twitter:image" content={pageImage} />
			</Helmet>
			<div
				className="site-login rto_form-sections"
				style={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					padding: "0 1rem",
					backgroundColor: "#22557b",
					backgroundImage:
						"linear-gradient(45deg, #22557b, #25689a 79%)",
				}}
			>
				<div className="rto_modal">
					<div className="rto_title-div">
						<h1 className="rto_page-title">Activate Account</h1>
					</div>
					<Row>
						<Col className="col-lg-12">
							<p>Set a password to activate your account:</p>
							<br />
							<ActivateAccountForm />
						</Col>
					</Row>
				</div>
			</div>
		</>
	);
}

export default ActivateAccount;
