import React, { useState, useEffect } from "react";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import DynamicForm from "../../formComponents/DynamicForm";
import RtoCompanyPaymentsService from "../../../service/rtoCompanyPayments/RtoCompanyPaymentsService";
import RtoCompanyPaymentMethodsService from "../../../service/rtoCompanyPaymentMethods/RtoCompanyPaymentMethodsService";
import PaymentMethod from "../../common/PaymentMethod";
import { toast } from "react-toastify";
import { FaCreditCard } from "react-icons/fa";
import { BiSolidBank } from "react-icons/bi";
import { RiCheckboxCircleFill, RiCloseCircleFill } from "react-icons/ri";
import {
	addCreditCardFormDefinition,
	addACHFormDefinition,
	updateCreditCardFormDefinition,
	updateACHFormDefinition,
	createAuthorizationFormDefinition,
} from "../../formComponents/rtoDynamicForm/definitions/storePaymentFormDefinition";

function CCNInfoForm({ modelId, model, fetchCount, loading, setLoading }) {
	const [selectedButton, setSelectedButton] = useState(null);
	const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
	const [profileId, setProfileId] = useState(null);
	const [paymentMethods, setPaymentMethods] = useState([]);
	const [tokenizerUrl, setTokenizerUrl] = useState("");
	const [extraMethodData, setExtraMethodData] = useState({
		struct_store_id: modelId,
		profile_id: profileId,
		acct_id: null, // Default new Account. This can be dynamically updated as needed
		type: "ACH", // Default ACH. This can be dynamically updated as needed
		get_uat: process.env.REACT_APP_CCN_GET_UAT === "true" ? 1 : 0,
	});

	const [pmtMessage, setPmtMessage] = useState("");
	const [pmtRetRef, setPmtRetRef] = useState("");
	const [pmtTotal, setPmtTotal] = useState("");

	const [definitionMethodOverride, setDefinitionMethodOverride] =
		useState(addACHFormDefinition);
	const [definitionTransactOverride, setDefinitionTransactOverride] =
		useState(createAuthorizationFormDefinition);

	const handleSelectPaymentMethod = (cardId) => {
		const selectedMethod = paymentMethods.find(
			(method) => method.id === cardId
		);
		if (selectedMethod) {
			setSelectedPaymentMethod(selectedMethod);
			setSelectedButton(null);

			// Update definition based on the payment type and values
			let methodDefinitionToUse;
			if (selectedMethod.acct_type !== "ECHK") {
				methodDefinitionToUse = updateMethodDefinitionWithValues(
					selectedMethod,
					updateCreditCardFormDefinition
				);

				let transactDefinitionToUse;
				transactDefinitionToUse = updateTransactionDefinitionWithValues(
					selectedMethod,
					createAuthorizationFormDefinition
				);

				setDefinitionTransactOverride(transactDefinitionToUse);
			} else {
				methodDefinitionToUse = updateMethodDefinitionWithValues(
					selectedMethod,
					updateACHFormDefinition
				);

				let transactDefinitionToUse;
				transactDefinitionToUse = updateTransactionDefinitionWithValues(
					selectedMethod,
					createAuthorizationFormDefinition
				);

				setDefinitionTransactOverride(transactDefinitionToUse);
			}

			setDefinitionMethodOverride(methodDefinitionToUse);
		}
	};

	const updateMethodDefinitionWithValues = (
		selectedMethod,
		baseDefinition
	) => {
		const updatedDefinition = { ...baseDefinition };

		// Assuming `selectedMethod` has properties like `card_number`, `expiration_date`, etc.
		if (selectedMethod.acct_type !== "ECHK") {
			updatedDefinition.fields.card_number.value =
				selectedMethod.token || "";
			// Splitting the expiry date into month and year
			const expiry = selectedMethod.expiry || "";
			if (expiry.length === 4) {
				updatedDefinition.fields.card_month.value = expiry.substring(
					0,
					2
				);
				updatedDefinition.fields.card_year.value =
					"20" + expiry.substring(2);
			} else {
				updatedDefinition.fields.card_month.value = "";
				updatedDefinition.fields.card_year.value = "";
			}
			updatedDefinition.fields.card_cvv.value =
				selectedMethod.cc_cvv || "";

			extraMethodData.acct_id = selectedMethod.acct_id;
			extraMethodData.profile_id = selectedMethod.profile_id;

			//dynamically set the values for the fields if they exist in selectedMethod
			for (const fieldName in updatedDefinition.fields) {
				if (selectedMethod[fieldName]) {
					updatedDefinition.fields[fieldName].value =
						selectedMethod[fieldName] || "";
				}
			}
		} else {
			updatedDefinition.fields.account_number.value =
				selectedMethod.token || "";

			extraMethodData.acct_id = selectedMethod.acct_id;
			extraMethodData.profile_id = selectedMethod.profile_id;

			//dynamically set the values for the fields if they exist in selectedMethod
			for (const fieldName in updatedDefinition.fields) {
				if (selectedMethod[fieldName]) {
					updatedDefinition.fields[fieldName].value =
						selectedMethod[fieldName] || "";
				}
			}
		}

		setMethodResetCounter(methodResetCounter + 1); // Increment to force re-render

		return updatedDefinition;
	};

	const updateTransactionDefinitionWithValues = (
		selectedMethod,
		baseDefinition
	) => {
		const updatedDefinition = { ...baseDefinition };

		updatedDefinition.fields.struct_store_id.value = modelId || "";
		updatedDefinition.fields.profile_id.value = profileId || "";
		updatedDefinition.fields.token.value = selectedMethod.token || "";

		//dynamically set the values for the fields if they exist in selectedMethod
		for (const fieldName in updatedDefinition.fields) {
			if (selectedMethod[fieldName]) {
				updatedDefinition.fields[fieldName].value =
					selectedMethod[fieldName] || "";
			}
		}

		setTransactionResetCounter(transactionResetCounter + 1); // Increment to force re-render

		return updatedDefinition;
	};

	const handleButtonSelect = (buttonType) => {
		setSelectedButton(buttonType);
		setSelectedPaymentMethod(null);

		// Set the form definition based on the button type
		let methodDefinitionToUse;
		if (buttonType === "CC") {
			methodDefinitionToUse = addCreditCardFormDefinition;
			extraMethodData.type = "CC";
			extraMethodData.acct_id = null;
		} else if (buttonType === "ACH") {
			methodDefinitionToUse = addACHFormDefinition;
			extraMethodData.type = "ACH";
			extraMethodData.acct_id = null;
		}

		setDefinitionMethodOverride(methodDefinitionToUse);
	};

	const fetchPaymentMethods = () => {
		setLoading(true);
		RtoCompanyPaymentsService.getStorePaymentMethods({
			struct_store_id: modelId,
			get_uat: process.env.REACT_APP_CCN_GET_UAT === "true" ? 1 : 0,
		})
			.then((response) => {
				setLoading(false);
				if (response.status === 200 && response.data) {
					setPaymentMethods(response.data.data);
					setTokenizerUrl(response.data.store.tokenizer_url);
					if (response.data.data && response.data.data.length > 0) {
						setProfileId(response.data?.data[0]?.profile_id);
						setExtraMethodData({
							...extraMethodData,
							profile_id: response.data?.data[0]?.profile_id,
						});
					} else {
						setProfileId(null);
						setExtraMethodData({
							...extraMethodData,
							profile_id: null,
							acct_id: null,
						});
						setDefinitionMethodOverride(addACHFormDefinition);
						setSelectedButton("ACH");
					}
				} else {
					console.error(
						"Error fetching payment methods. Unexpected response status:",
						response.status
					);
					toast.error(
						`Error fetching payment methods. Unexpected response status: ${response.status}`,
						{
							position: "top-center",
							autoClose: 1000,
						}
					);
				}
			})
			.catch((error) => {
				setLoading(false);
				toast.error(`Error fetching payment methods: ${error}`, {
					position: "top-center",
					autoClose: 1000,
				});
			});
	};

	const findDefaultPaymentMethod = (paymentMethods) => {
		const defaultCard = paymentMethods.find(
			(method) => method.default_acct === "Y"
		);
		if (defaultCard) {
			handleSelectPaymentMethod(defaultCard.id);
		}
	};

	useEffect(() => {
		fetchPaymentMethods();
		setSelectedButton("ACH");
	}, []);

	useEffect(() => {
		if (paymentMethods && paymentMethods.length > 0) {
			findDefaultPaymentMethod(paymentMethods);
		}
	}, [paymentMethods]);

	const [openModal, setOpenModal] = useState(false);
    const [modalContent, setModalContent] = useState('');
    const [onConfirm, setOnConfirm] = useState(() => () => {});

	const handleOpenModal = (message, onConfirmAction) => {
		setModalContent(message);
		setOnConfirm(() => onConfirmAction);
		setOpenModal(true);
	};
	
	const handleConfirmModal = () => {
		onConfirm();
		setOpenModal(false);
	};

	const handleMakeDefaultPaymentMethod = (method) => {
		RtoCompanyPaymentsService.changeDefaultPaymentMethod({
			struct_store_id: modelId,
			get_uat: process.env.REACT_APP_CCN_GET_UAT === "true" ? 1 : 0,
			data: {
				profile_id: method.profile_id,
				acct_id: method.acct_id,
				default: "Y",
			},
		})
			.then((response) => {
				if (response.status === 200 && response.data) {
					toast.success(
						"Payment method set as default successfully.",
						{
							position: "top-center",
							autoClose: 1000,
						}
					);
					fetchPaymentMethods();
				} else {
					console.error(
						"Error setting payment method as default. Unexpected response status:",
						response.status
					);
					toast.error(
						`Error setting payment method as default. Unexpected response status: ${response.status}`,
						{
							position: "top-center",
							autoClose: 1000,
						}
					);
				}
			})
			.catch((error) => {
				toast.error(
					`Error setting payment method as default: ${error}`,
					{
						position: "top-center",
						autoClose: 1000,
					}
				);
			});
	};

	const handleDeletePaymentMethod = (method) => {
		RtoCompanyPaymentsService.deletePaymentMethod({
			data: {
				struct_store_id: modelId,
				profile_id: method.profile_id,
				acct_id: method.acct_id,
			},
		})
			.then((response) => {
				console.log(response, "response");
				if (response.status === 200 && response.data) {
					if (response.data.isOk) {
						toast.success("Payment method deleted successfully.", {
							position: "top-center",
							autoClose: 1000,
						});
						fetchPaymentMethods();
					} else {
						console.error(
							"Error deleting payment method. Message:",
							response.data.msg
						);
						toast.error(response.data.msg, {
							position: "top-center",
							autoClose: 1000,
						});
					}
				} else {
					console.error(
						"Error deleting payment method. Unexpected response status:",
						response.status
					);
					toast.error(
						`Error deleting payment method. Unexpected response status: ${response.status}`,
						{
							position: "top-center",
							autoClose: 1000,
						}
					);
				}
			})
			.catch((error) => {
				toast.error(`Error deleting payment method: ${error}`, {
					position: "top-center",
					autoClose: 1000,
				});
			});
	};

	const handleDeleteCustomer = (method) => {
		RtoCompanyPaymentsService.deleteCustomer({
			data: {
				struct_store_id: modelId,
				profile_id: method.profile_id,
			},
		})
			.then((response) => {
				if (response.status === 200 && response.data) {
					if (response.data.isOk) {
						toast.success("Customer deleted successfully.", {
							position: "top-center",
							autoClose: 1000,
						});
						fetchPaymentMethods();
					} else {
						console.error(
							"Error deleting customer. Message:",
							response.data.msg
						);
						toast.error(response.data.msg, {
							position: "top-center",
							autoClose: 1000,
						});
					}	
				} else {
					console.error(
						"Error deleting customer. Unexpected response status:",
						response.status
					);
					toast.error(
						`Error deleting customer. Unexpected response status: ${response.status}`,
						{
							position: "top-center",
							autoClose: 1000,
						}
					);
				}
			})
			.catch((error) => {
				toast.error(`Error deleting customer: ${error}`, {
					position: "top-center",
					autoClose: 1000,
				});
			});
	};

	const [methodResetCounter, setMethodResetCounter] = useState(0);
	const [transactionResetCounter, setTransactionResetCounter] = useState(0);

	return (
		<div className="sm:p-3">
			<div className="row">
				<div className="col-md-8">
					{profileId && selectedPaymentMethod && (
						<div className="row">
							<div className="col-lg-12">
								<div className="flex gap-2 mb-2">
									<Button
										variant="contained"
										size="small"
										// disabled
										onClick={() =>
											handleOpenModal(
												"Are you sure you want to delete this customer?",
												() =>
													handleDeleteCustomer(
														selectedPaymentMethod
													)
											)
										}
									>
										<div className="d-flex align-items-center">
											<RiCloseCircleFill />
											&nbsp;
											<span className="font-medium">
												Delete Customer
											</span>
										</div>
									</Button>

									{selectedPaymentMethod &&
										selectedPaymentMethod.default_acct !==
											"Y" && (
											<Button
												variant="contained"
												size="small"
												// disabled
												onClick={() =>
													handleOpenModal(
														"Are you sure you want to make this payment method default?",
														() =>
															handleMakeDefaultPaymentMethod(
																selectedPaymentMethod
															)
													)
												}
											>
												<div className="d-flex align-items-center">
													<RiCheckboxCircleFill />
													&nbsp;
													<span className="font-medium">
														Make Default
													</span>
												</div>
											</Button>
										)}

									{selectedPaymentMethod &&
										selectedPaymentMethod.default_acct !==
											"Y" && (
											<Button
												variant="contained"
												size="small"
												// disabled
												onClick={() =>
													handleOpenModal(
														"Are you sure you want to delete this payment method?",
														() =>
															handleDeletePaymentMethod(
																selectedPaymentMethod
															)
													)
												}
											>
												<div className="d-flex align-items-center">
													<RiCloseCircleFill />
													&nbsp;
													<span className="font-medium">
														Delete Payment Method
													</span>
												</div>
											</Button>
										)}
								</div>
								<hr />
							</div>
						</div>
					)}

					<div className="flex gap-2 mb-4">
						<p className="text-sm text-gray-500 font-medium mb-1">
							<span className="bg-gray-100 px-2 py-1 rounded">
								Profile ID:
							</span>
						</p>
						{profileId && (
							<p className="text-sm text-gray-700 font-medium mb-0">
								<span className="bg-gray-100 px-2 py-1 rounded">
									{profileId}
								</span>
							</p>
						)}
						{!profileId && (
							<p className="text-sm text-gray-500 font-medium mb-0">
								<span className="bg-gray-100 px-2 py-1 rounded">
									No profile ID found.
								</span>
							</p>
						)}
					</div>
					<DynamicForm
						loading={loading}
						setLoading={setLoading}
						model="rto_company_payment_methods"
						modelLabel="RTO Company Payment Methods"
						service={RtoCompanyPaymentMethodsService}
						apiBase="/rto-company-payment-methods"
						// successRedirectBase="settings/rto-company-payment-methods"
						scenario="create"
						//key={modelId + "ccn_info"}
						key={methodResetCounter + "ccn_info"}
						display="partial"
						createEndpointOverride="/rto-company-payments/store-payment-methods-add"
						updateEndpointOverride="/rto-company-payments/store-payment-methods-update"
						definitionOverride={definitionMethodOverride}
						fetchData={fetchPaymentMethods}
						extraData={extraMethodData}
						tokenizerUrl={tokenizerUrl}
						// scenario={isUpdateMode ? "update" : "create"}
						// updateId={updateId}
						// fetchData={fetchData}
					/>
					{selectedPaymentMethod && (
						<DynamicForm
							loading={loading}
							setLoading={setLoading}
							model="rto_company_payments"
							modelLabel="RTO Company Payments"
							service={RtoCompanyPaymentsService}
							apiBase="/rto-company-payments"
							// successRedirectBase="settings/rto-company-payments"
							scenario="create"
							key={transactionResetCounter + "ccn_transaction"}
							display="partial"
							createEndpointOverride="/rto-company-payments/store-payment-process"
							updateEndpointOverride="/rto-company-payments/store-payment-process"
							definitionOverride={definitionTransactOverride}
							fetchData={(responseData, formState) => {
								// fetchPaymentMethods();
								updateTransactionDefinitionWithValues(
									[],
									createAuthorizationFormDefinition
								);
								setSelectedPaymentMethod(null);
								console.log(selectedPaymentMethod, "selectedPaymentMethod");
								const data = responseData.data.data;
								setPmtMessage(data.resp_text);
								setPmtRetRef(data.retref);
								setPmtTotal(data.total);
							}}
							// extraData={{
							// 	struct_store_id: modelId,
							// 	profile_id: profileId,
							// 	acct_id: selectedPaymentMethod,
							// 	type: "ACH",
							// 	get_uat: 1,
							// }}
							// scenario={isUpdateMode ? "update" : "create"}
							// updateId={updateId}
							// fetchData={fetchData}
						/>
					)}
					{pmtMessage && pmtRetRef && pmtTotal && (
						<div className="row">
							<div className="col-lg-12">
								<hr />
								<div className="flex gap-2 mb-2">
									<p className="text-sm text-gray-500 font-medium mb-1">
										<span className="bg-gray-100 px-2 py-1 rounded">
											Message:
										</span>
									</p>
									<p className="text-sm text-gray-700 font-medium mb-0">
										<span className="bg-gray-100 px-2 py-1 rounded">
											{pmtMessage}
										</span>
									</p>
								</div>
								<div className="flex gap-2 mb-2">
									<p className="text-sm text-gray-500 font-medium mb-1">
										<span className="bg-gray-100 px-2 py-1 rounded">
											RetRef:
										</span>
									</p>
									<p className="text-sm text-gray-700 font-medium mb-0">
										<span className="bg-gray-100 px-2 py-1 rounded">
											{pmtRetRef}
										</span>
									</p>
								</div>
								<div className="flex gap-2 mb-2">
									<p className="text-sm text-gray-500 font-medium mb-1">
										<span className="bg-gray-100 px-2 py-1 rounded">
											Total:
										</span>
									</p>
									<p className="text-sm text-gray-700 font-medium mb-0">
										<span className="bg-gray-100 px-2 py-1 rounded">
											${pmtTotal}
										</span>
									</p>
								</div>
							</div>
						</div>
					)}
				</div>
				<div className="col-md-4">
					<div className="rto_bank-details">
						<div className="flex justify-between mb-4">
							<div
								className={
									selectedButton === "CC"
										? "rounded ring-4 ring-offset-1 ring-[#00ABF9] transiation-all"
										: "transition-all"
								}
							>
								<Button
									variant="contained"
									size="small"
									// disabled
									onClick={() => handleButtonSelect("CC")}
								>
									<div className="d-flex align-items-center">
										<FaCreditCard />
										&nbsp;
										<span className="font-medium">
											Add New CC
										</span>
									</div>
								</Button>
							</div>
							<div
								className={
									selectedButton === "ACH"
										? "rounded ring-4 ring-offset-1 ring-[#00ABF9] transiation-all"
										: "transition-all"
								}
							>
								<Button
									variant="contained"
									size="small"
									onClick={() => handleButtonSelect("ACH")}
								>
									<div className="d-flex align-items-center">
										<BiSolidBank />
										&nbsp;
										<span className="font-medium">
											Add New ACH
										</span>
									</div>
								</Button>
							</div>
						</div>

						<div className="my-0 space-y-6">
							{paymentMethods?.length > 0 &&
								paymentMethods.map((method) => (
									<PaymentMethod
										key={method.id}
										method={method}
										isSelected={
											selectedPaymentMethod &&
											selectedPaymentMethod.id ===
												method.id
										}
										onSelect={() =>
											handleSelectPaymentMethod(method.id)
										}
									/>
								))}
							{!paymentMethods?.length && (
								<p className="text-sm text-gray-500 font-medium">
									<span className="bg-gray-100 px-2 py-1 rounded">
										No payment methods found.
									</span>
								</p>
							)}
						</div>
					</div>
				</div>
			</div>
			<Dialog
				open={openModal}
				onClose={() => setOpenModal(false)}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					{"Are you sure?"}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						{modalContent}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setOpenModal(false)} color="primary">
						Cancel
					</Button>
					<Button
						onClick={handleConfirmModal}
						color="primary"
						autoFocus
					>
						Confirm
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}

export default CCNInfoForm;
