import React, { useState } from "react";
import { message, Upload } from "antd";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

/**
 * Upload component for the form
 * @ref https://ant.design/components/upload/
 * @doc API response format
 * "upload" => [
		"type" => "file",
		"name" => "referral_logo",
		"class" => "col-md-12",
		'maxCount' => 1,
		'allowedFileExtensions' => ['jpg', 'jpeg', 'png', 'gif'],
		'maxFileSize' => 100, // KB
		'listType' => 'text',
 *	],
 *
 *
 * @param {string} name
 * @param {string} label
 * @param {string} className
 * @param {array} allowedFileExtensions [
		".jpeg",
		".jpg",
		".png",
		".gif",
		".pdf",
		".doc",
		".docx", etc
	];
 * @param {number} maxCount
 * @param {number} maxFileSize values in KB
 * @param {string} listType - text, picture, picture-card,picture-circle
 * @returns
 */
const CustomFileComponent = ({
	name,
	label,
	className,
	allowedFileExtensions,
	maxCount,
	maxFileSize,
	listType,
	onChange,
	...otherProps
}) => {
	const [isValid, setIsValid] = useState(false);
	const [selectedFiles, setSelectedFiles] = useState([]);

	const handleCustomRequest = async ({ file, onSuccess, onError }) => {
		onSuccess(
			{
				success: true,
			},
			file
		);

		setSelectedFiles((prevSelectedFiles) => {
			const updatedFiles = [...prevSelectedFiles, file];
			console.log("Selected Files", updatedFiles);
			// We can call the onChange function here to update the form state
			onChange(name, updatedFiles, true);
			return updatedFiles;
		});
	};

	const beforeUpload = (file, onSuccess, onError) => {
		let isAllowedExtension = false;

		// If the allowedFileExtensions array is empty, allow the upload
		if (allowedFileExtensions.length === 0) {
			isAllowedExtension = true;
		} else {
			let fileName = file.name.toLowerCase();
			let extension = fileName.substring(fileName.lastIndexOf(".") + 1);
			isAllowedExtension = allowedFileExtensions.includes(extension);
			if (!isAllowedExtension) {
				message.error(
					"You can only upload " +
						allowedFileExtensions.join(", ") +
						" files!"
				);
			}
		}

		if (isAllowedExtension) {
			// Check file size
			if (maxFileSize) {
				if (file.size / 1024 > maxFileSize) {
					file.staus = "error";
					message.error(
						`File must be smaller than ${maxFileSize}KB!`
					);
					isAllowedExtension = false;
				}
			}
		}
		setIsValid(isAllowedExtension);
		// Check
		return isAllowedExtension; // Return true if the file extension is allowed, false otherwise
	};

	const handleRemove = async (file) => {
		console.log("Delete File", file);
	};

	return (
		<div
			className={`${className}`}
			style={{ display: "flex", justifyContent: "left" }}
		>
			<Upload
				name={name}
				customRequest={handleCustomRequest}
				onRemove={handleRemove}
				maxCount={maxCount ? maxCount : 1}
				beforeUpload={beforeUpload}
				listType={listType ? listType : "text"}
				multiple={true}
			>
				<Button variant="outlined" size="small">
					<CloudUploadIcon
						style={
							label?.length > 0 ? { paddingRight: "5px" } : null
						}
					/>
					{label}
				</Button>
			</Upload>
		</div>
	);
};

export default CustomFileComponent;
