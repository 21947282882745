import ApiService from "../ApiService";

/**
 * @see /docs/api/index.html#PayoffEarlyPolicy
 */

const PayoffEarlyPolicyService = {
	/**
	 * View payoff early policies
	 * @param array data pass serach filter values if needed
	 * @see /docs/api/index.html#jump-PayoffEarlyPolicy-Index
	 * @returns
	 */
	view: (data) => {
		return ApiService.post("/payoff-early-policy/index", data).catch((error) => {
			console.log(error);
			return error;
		});
	},

	/**
	 * Data filter by ID
	 * @param {int} id
	 * @see /docs/api/index.html#jump-PayoffEarlyPolicy-FetchById
	 * @returns JSON
	 */
	fetchById: (id) => {
		return ApiService.get("/payoff-early-policy/fetch-by-id", { id: id }).catch(
			(error) => {
				console.log(error);
			}
		);
	},

	/**
	 * @param int modelId
	 * @param string modelName
	 * @param string date
	 * @param string description
	 * @param int assignedTo
	 * @see /docs/api/index.html#jump-PayoffEarlyPolicy-Create
	 * @returns JSON
	 */
	create: (
		modelName,
		modelId,
	) => {
		return ApiService.post("/payoff-early-policy/create", {
			modelId: modelId,
			modelName: modelName,
		}).catch((error) => {
			console.log(error);
		});
	},

	/**
	 * Update service
	 * @param json data {payoffEarlyPolicyId: 1, name:Text, createdAt:2021-01-01,}
	 * @see /docs/api/index.html#jump-PayoffEarlyPolicy-Update
	 * @returns
	 */
	update: (data) => {
		return ApiService.post("/payoff-early-policy/update", data).catch((error) => {
			console.log(error);
		});
	},

	/**
	 * 
	 * @param {*} data
	 * @see /docs/api/index.html#jump-PayoffEarlyPolicy-Delete
	 * @returns
	 */
	delete: (data) => {
		return ApiService.post("/payoff-early-policy/delete", {data : {id : data}}).catch((error) => {
			console.log(error);
		});
	},
};

export default PayoffEarlyPolicyService;
