import ApiService from "../ApiService";

/**
 * @see /docs/api/index.html#Activity
 */

const ActivityService = {
	/**
	 * @see /docs/api/index.html#jump-Activity-Index
	 * @param {*} data
	 * @returns
	 */
	view: (data) => {
		return ApiService.post("/activity/index", data).catch((error) => {
			console.log(error);
			return error;
		});
	},
	/**
	 * @deprecated
	 * @param int modelId
	 * @param string modelName
	 * @param array data
	 * @see /docs/api/index.html#jump-Activity-FetchByModel
	 * @returns
	 */
	/*fetchByIdModel: (data) => {
		return ApiService.post("/activity/fetch-by-model", data).catch((error) => {
			console.log(error);
			return error;
		});
	},*/

	/**
	 * Data filter by ID
	 * @param {int} id
	 * @see /docs/api/index.html#jump-Activity-FetchById
	 * @returns
	 */
	fetchById: (id) => {
		return ApiService.get("/activity/fetch-by-id", { id: id }).catch(
			(error) => {
				console.log(error);
			}
		);
	},

	/**
	 *
	 * @param {int} modelId
	 * @param {string} modelName
	 * @param {int} activities_type_id
	 * @param {string} description
	 * @see /docs/api/index.html#jump-Activity-Create
	 * @returns
	 */
	create: (modelId, modelName, activities_type_id, description) => {
		return ApiService.post("/activity/create", {
			modelId: modelId,
			modelName: modelName,
			activities_type_id: activities_type_id,
			description: description,
		}).catch((error) => {
			console.log(error);
		});
	},

	/**
	 *
	 * @param int activityId
	 * @param int modelId
	 * @param string modelName
	 * @param nt activityTypeId
	 * @param string description
	 * @see /docs/api/index.html#jump-Activity-Update
	 * @returns
	 */
	update: (activityId, modelId, modelName, activityTypeId, description) => {
		return ApiService.post("/activity/update", {
			activityId: activityId,
			modelId: modelId,
			modelName: modelName,
			activityTypeId: activityTypeId,
			description: description,
		}).catch((error) => {
			console.log(error);
		});
	},

	/**
	 *
	 * @param {*} data
	 * @see /docs/api/index.html#jump-Activity-Delete
	 * @returns
	 */
	delete: (data) => {
		return ApiService.post("/activity/delete", {
			data: { id: data },
		}).catch((error) => {
			console.log(error);
		});
	},
};

export default ActivityService;
