import ApiService from "../ApiService";

/**
 * @see /docs/api/index.html
 */

const UserService = {
	/**
	 *
	 * @param {View} View
	 * @returns
	 */
	view: (View, param, signal) => {
		return ApiService.post("/user/index", View, param, signal).catch(
			(error) => {
				console.log(error);
				return error;
			}
		);
	},

	/**
	 * User data filter by ID
	 * @param {int} id
	 * @returns
	 */
	fetchById: (id) => {
		return ApiService.get("/user/fetch-by-id", { id: id }).catch(
			(error) => {
				console.log(error);
			}
		);
	},

	/**
	 *
	 * @param {Create} Create
	 * @returns
	 */
	create: (Create) => {
		return ApiService.post("/user/create", Create).catch((error) => {
			console.log(error);
		});
	},

	/**
	 * Update service
	 * @param json data {UserId : 1, name: 'xxxx', address_1: 'ADD1' ...}
	 * @returns
	 */
	update: (data) => {
		return ApiService.postFormData("/user/update", data).catch((error) => {
			console.log(error);
		});
	},

	/**
	 *
	 * @param {Integer} UserId
	 * @returns
	 */
	delete: (userId) => {
		return ApiService.post("/user/delete", {
			userId: userId,
		}).catch((error) => {
			console.log(error);
		});
	},
};

export default UserService;
