import React, { useEffect, useState } from "react";
import {
	Box,
	FormControlLabel,
	Radio,
	RadioGroup,
	FormControl,
} from "@mui/material";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
} from "@mui/material";
import { InputLabel, MenuItem, Select } from "@mui/material";
import { formatDate } from "../../../helpers/Utils";
import CustomSelectComponent from "../../formComponents/rtoDynamicForm/fields/CustomSelectComponent";

/**
 * @param {*} availablePlans array of payment plans
 * @param {*} planSelectedValue selected payment plan
 * @param {*} leaseTypeId lease type id
 * @param {*} planSelectedDay selected day
 * @param {*} planSelectDays array of days
 * @param {*} planSelectedDate selected date
 * @param {*} planSelectDates array of dates
 * @param {*} paymentTermOnChange function to handle payment term change
 * @param {*} PlanDayOnChange function to handle day change
 * @param {*} PlanDateOnChange function to handle date change
 * @param {*} viewOnly boolean to determine if the form is view only
 */
const PaymentTermsForm = ({
	availablePlans = {},
	planSelectedValue = null,
	leaseTypeId = null,
	planSelectedDay = null,
	planSelectDays = {},
	planSelectedDate = null,
	planSelectDates = {},
	paymentTermOnChange = null,
	PlanDayOnChange = null,
	PlanDateOnChange = null,
	viewOnly = false,
}) => {
	return (
		<div>
			<TableContainer component={Paper}>
				<Table sx={{ minWidth: 650 }} aria-label="lease plans table">
					<TableHead>
						<TableRow>
							<TableCell sx={{ padding: "5px" }} align="center">
								Lease Term
							</TableCell>
							<TableCell sx={{ padding: "5px" }} align="center">
								Weekly Lease
							</TableCell>
							<TableCell sx={{ padding: "5px" }} align="center">
								Bi-Weekly Lease
							</TableCell>
							<TableCell sx={{ padding: "5px" }} align="center">
								Monthly Lease
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{availablePlans?.months?.map((month, index) => (
							<TableRow
								key={month}
								sx={{
									"&:last-child td, &:last-child th": {
										border: 0,
									},
									backgroundColor:
										index % 2 === 0 ? "#F9FDFF" : "#FFFFFF",
								}}
							>
								<TableCell
									sx={{ padding: "5px" }}
									align="center"
									component="th"
									scope="row"
								>
									{month}
									{" month"}
								</TableCell>
								<TableCell
									sx={{ padding: "5px" }}
									align="center"
								>
									<Box display="flex" justifyContent="center">
										{!viewOnly ? (
											<RadioGroup
												row
												sx={{
													margin: "0px !important",
												}}
												name={`payment-net-${month}`}
												value={planSelectedValue}
												onChange={(e) =>
													paymentTermOnChange(
														e,
														month,
														2
													)
												}
											>
												<FormControlLabel
													sx={{
														margin: "0px !important",
													}}
													value={`2-${month}`}
													control={
														<Radio size="small" />
													}
													label={
														<Box
															display="flex"
															alignItems="center"
														>
															<div variant="body1">
																$
																{
																	availablePlans
																		.plans[
																		month
																	]?.Weekly
																		?.payment_amount
																}
															</div>{" "}
															<span
																variant="caption"
																style={{
																	marginLeft: 4,
																	fontSize:
																		"smaller",
																}}
															>
																x{" "}
																{
																	availablePlans
																		.plans[
																		month
																	]?.Weekly
																		?.num_payments
																}
															</span>
														</Box>
													}
												/>
											</RadioGroup>
										) : (
											<Box
												display="flex"
												alignItems="center"
											>
												<div variant="body1">
													$
													{
														availablePlans.plans[
															month
														]?.Weekly
															?.payment_amount
													}
												</div>{" "}
												<span
													variant="caption"
													style={{
														marginLeft: 4,
														fontSize: "smaller",
													}}
												>
													x{" "}
													{
														availablePlans.plans[
															month
														]?.Weekly?.num_payments
													}
												</span>
											</Box>
										)}
									</Box>
								</TableCell>
								<TableCell
									sx={{ padding: "5px" }}
									align="center"
								>
									<Box display="flex" justifyContent="center">
										{!viewOnly ? (
											<RadioGroup
												row
												sx={{
													margin: "0px !important",
												}}
												name={`payment-net-${month}`}
												value={planSelectedValue}
												onChange={(e) =>
													paymentTermOnChange(
														e,
														month,
														3
													)
												}
											>
												<FormControlLabel
													sx={{
														margin: "0px !important",
													}}
													value={`3-${month}`}
													control={
														<Radio size="small" />
													}
													label={
														<Box
															display="flex"
															alignItems="center"
														>
															<div variant="body1">
																$
																{
																	availablePlans
																		.plans[
																		month
																	]?.Biweekly
																		?.payment_amount
																}
															</div>{" "}
															<span
																variant="caption"
																style={{
																	marginLeft: 4,
																	fontSize:
																		"smaller",
																}}
															>
																x{" "}
																{
																	availablePlans
																		.plans[
																		month
																	]?.Biweekly
																		?.num_payments
																}
															</span>
														</Box>
													}
												/>
											</RadioGroup>
										) : (
											<Box
												display="flex"
												alignItems="center"
											>
												<div variant="body1">
													$
													{
														availablePlans.plans[
															month
														]?.Biweekly
															?.payment_amount
													}
												</div>{" "}
												<span
													variant="caption"
													style={{
														marginLeft: 4,
														fontSize: "smaller",
													}}
												>
													x{" "}
													{
														availablePlans.plans[
															month
														]?.Biweekly
															?.num_payments
													}
												</span>
											</Box>
										)}
									</Box>
								</TableCell>
								<TableCell
									sx={{ padding: "5px" }}
									align="center"
								>
									<Box display="flex" justifyContent="center">
										{!viewOnly ? (
											<RadioGroup
												row
												name={`payment-net-${month}`}
												value={planSelectedValue}
												onChange={(e) =>
													paymentTermOnChange(
														e,
														month,
														1
													)
												}
											>
												<FormControlLabel
													sx={{
														margin: "0px !important",
													}}
													value={`1-${month}`}
													control={
														<Radio size="small" />
													}
													label={
														<Box
															display="flex"
															alignItems="center"
														>
															<div variant="body1">
																$
																{
																	availablePlans
																		.plans[
																		month
																	]?.Monthly
																		?.payment_amount
																}
															</div>{" "}
															<span
																variant="caption"
																style={{
																	marginLeft: 4,
																	fontSize:
																		"smaller",
																}}
															>
																x{" "}
																{
																	availablePlans
																		.plans[
																		month
																	]?.Monthly
																		?.num_payments
																}
															</span>
														</Box>
													}
												/>
											</RadioGroup>
										) : (
											<Box
												display="flex"
												alignItems="center"
											>
												<div variant="body1">
													$
													{
														availablePlans.plans[
															month
														]?.Monthly
															?.payment_amount
													}
												</div>{" "}
												<span
													variant="caption"
													style={{
														marginLeft: 4,
														fontSize: "smaller",
													}}
												>
													x{" "}
													{
														availablePlans.plans[
															month
														]?.Monthly?.num_payments
													}
												</span>
											</Box>
										)}
									</Box>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			{!viewOnly && (
				<div
					style={{
						display: "flex",
						justifyContent: "space-between",
						marginTop: "20px",
					}}
				>
					<FormControl fullWidth sx={{ width: 160 }}>
						<CustomSelectComponent
							name="lease_day"
							label={
								leaseTypeId === "1"
									? "Day of Month"
									: "Day of Week"
							}
							type={{
								options: planSelectDays,
							}}
							value={planSelectedDay || ""}
							onChange={(fieldName, value, pIsValid) =>
								PlanDayOnChange(fieldName, value, pIsValid)
							}
							className={""}
							required={true}
						/>
					</FormControl>
					<FormControl
						fullWidth={true}
						size="small"
						sx={{ width: 160 }}
					>
						<CustomSelectComponent
							name="lease_date"
							label="Start Date"
							type={{
								options: planSelectDates,
							}}
							value={planSelectedDate || ""}
							onChange={(fieldName, value, pIsValid) =>
								PlanDateOnChange(fieldName, value, pIsValid)
							}
							className={""}
							required={true}
						/>
					</FormControl>
				</div>
			)}
		</div>
	);
};
export default PaymentTermsForm;
