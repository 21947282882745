export const Constants = {
	userRole: {
		SYSTEM_ADMIN: "system_admin",
		RTO_ADMIN: "rto_admin",
		ISO_ADMIN: "iso_admin",
		MA_ADMIN: "ma_admin",
		ORG_ADMIN: "org_admin",
		COMPANY_ADMIN: "company_admin",
		STORE_ADMIN: "store_admin",
		STORE_MANAGER: "store_manager",
		STORE_EMPLOYEE: "store_employee",
	},
};

export const Months = [
	{ value: "01", label: "January".toUpperCase() },
	{ value: "02", label: "February".toUpperCase() },
	{ value: "03", label: "March".toUpperCase() },
	{ value: "04", label: "April".toUpperCase() },
	{ value: "05", label: "May".toUpperCase() },
	{ value: "06", label: "June".toUpperCase() },
	{ value: "07", label: "July".toUpperCase() },
	{ value: "08", label: "August".toUpperCase() },
	{ value: "09", label: "September".toUpperCase() },
	{ value: "10", label: "October".toUpperCase() },
	{ value: "11", label: "November".toUpperCase() },
	{ value: "12", label: "December".toUpperCase() },
];

export const SelectMonthsArray = [
	{ id: "01", name: "January".toUpperCase() },
	{ id: "02", name: "February".toUpperCase() },
	{ id: "03", name: "March".toUpperCase() },
	{ id: "04", name: "April".toUpperCase() },
	{ id: "05", name: "May".toUpperCase() },
	{ id: "06", name: "June".toUpperCase() },
	{ id: "07", name: "July".toUpperCase() },
	{ id: "08", name: "August".toUpperCase() },
	{ id: "09", name: "September".toUpperCase() },
	{ id: "10", name: "October".toUpperCase() },
	{ id: "11", name: "November".toUpperCase() },
	{ id: "12", name: "December".toUpperCase() },
];

// Generate an array of the next 30 years
export const CreditCardYears = Array.from({ length: 31 }, (_, i) => {
	const year = new Date().getFullYear() + i;
	return { value: year.toString(), label: year.toString() };
});

export const SelectCreditCardYearsArray = Array.from({ length: 31 }, (_, i) => {
	const year = new Date().getFullYear() + i;
	return { id: year.toString(), name: year.toString() };
});
