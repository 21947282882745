import React from "react";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Box } from "@mui/material";
import "./Resources.css";
import contractMaintenanceFees from "../../images/contract-maintenance-fees.png";

function RtoMobileFees({ loading, setLoading }) {
	return (
		<>
			<div className="rto_form-sections">
				<div className="rto_content-div" style={{ maxWidth: "1600px" }}>
					<Box
						display="flex"
						justifyContent="flex-end"
						backgroundColor="#f5f5f5"
						p={(0.5, 1)}
						borderRadius={1}
					>
						<Breadcrumbs aria-label="breadcrumb">
							<Link to="/dashboard" className="rto_link-2">
								Dashboard
							</Link>
							<Link to="/resources" className="rto_link-2">
								Resources
							</Link>
							<Typography>RTO Mobile Fees</Typography>
						</Breadcrumbs>
					</Box>

					<div
						className="rto_title-div"
						style={{ textAlign: "left" }}
					>
						<h1>Fee Schedule and Explanations</h1>
						<p>Publication Date: 6/1/2024</p>
						<p>
							The purpose of this document is to provide you with
							a full explanation of the amounts and timing of RTO
							Mobile fees charged to you under the terms and
							conditions that you agreed when signing your
							application and which appear in the RETAIL MERCHANT
							SITE ACCESS AND USE AGREEMENT.
						</p>
						<p style={{ fontWeight: "bold" }}>INTRODUCTION:</p>
						<p>
							RTO Mobile Fees are designed with the aim to be:
							<ul className="ul-square">
								<li>simple to understand,</li>
								<li>fully transparent (no “hidden” fees),</li>
								<li>pay-as-you-go,</li>
								<li>
									fair to merchants in comparison to total
									contract profit potential.
								</li>
							</ul>
						</p>
						<p style={{ fontWeight: "bold" }}>
							OVERVIEW OF RTO MOBILE SERVICE FEES AND TIMING OF
							BILLING:
						</p>
						<p>
							RTO Mobile Platform Service Fees are collected in
							the evening on the day a charge occurs by ACH
							transaction from the bank account you provided to
							RTO Mobile during onboarding. Simultaneously, fees
							charged are summarized for you via an email sent to
							the email of record for your account in the morning
							after the day upon which an ACH charge occurs.
						</p>
						<p>
							<u>Billing of Charges</u>
							<br /> The charges billed to you by RTO Mobile are
							in accordance with the Fee Schedule presented below
							and in accordance with the Terms and Conditions that
							you originally agreed to in your RTO Mobile
							application. Additionally, pursuant to the Terms and
							Conditions of the{" "}
							<strong>
								RETAIL MERCHANT SITE ACCESS AND USE AGREEMENT
							</strong>{" "}
							available at{" "}
							<a
								href="https://www.rtomobile.com/terms-conditions"
								target="_blank"
								rel="noreferrer"
							>
								www.RTOMobile.com
							</a>{" "}
							each time you, or a user authorized by you, uses the
							SITE, you are agreeing to the Fee Schedule presented
							below.
						</p>
						<p>
							<u>Fee Events and Types of Charges</u>
							<br /> RTO Mobile fees for its software-as-a-service
							are charged at certain times and for certain
							services. Charges happen upon the following events.
							<table className="bordered-table">
								<tr>
									<td>
										<strong>EVENTS</strong>
									</td>
									<td>
										<strong>
											PLATFORM SERVICE FEE TYPE
										</strong>
									</td>
								</tr>
								<tr>
									<td>upon each contract origination</td>
									<td>Origination Fee</td>
								</tr>
								<tr>
									<td>
										upon each customer scheduled payment due
										date
									</td>
									<td>Contract Maintenance Fees *</td>
								</tr>
								<tr>
									<td>upon rescheduling a contract</td>
									<td>Rescheduling Fee</td>
								</tr>
								<tr>
									<td>
										upon the cancellation of a contract
										earlier than its original end date
									</td>
									<td>Early Termination Fee</td>
								</tr>
							</table>
						</p>
						<p>
							*As soon as you deem that a past due contract is
							uncollectable, you should change the billing status
							of the contract in the RTO Mobile platform to
							“defaulted”. No further Contract Maintenance Fees
							are charged for “defaulted” contracts. As a
							courtesy, if the contract is 90 days past due and
							you forget to change the contract billing status to
							“defaulted”, RTO Mobile assists you by automatically
							marking past due contracts as “defaulted”. We notify
							you by email when such automatic status
							reclassification occurs. Until a contract is
							classified and marked as “defaulted”, Contract
							Maintenance Fees for each contract continue to be
							charged to you on the date of each customers
							scheduled payment, even if the customer does not pay
							you. It is in your best interest to quickly decide
							if the customer with a “past due” contract will keep
							their promise to pay. If you decide they will not,
							then mark the contract as “defaulted” as soon as
							possible to avoid additional contract maintenance
							fees.
						</p>
						<p>
							<u>MORE ABOUT CONTRACT MAINTENANCE FEES:</u>
							<br /> All customer contracts have a Customer
							Payment Frequency Option associated with the
							contract that you select for your customer when you
							originate the contract. The Customer Payment
							Frequency Options are:
							<ul className="ul-square">
								<li>WEEKLY PAY,</li>
								<li>Bi-WEEKLY PAY, or</li>
								<li>MONTHLY PAY</li>
							</ul>
							Contract Maintenance Fee timing and amounts are
							determined by three factors, which are:
							<ul className="ul-decimal">
								<li>
									the Customer Payment Frequency Option
									selected; and
								</li>
								<li>
									whether a customer contract originated with,
									or without, device locking technology, and
								</li>
								<li>
									the duration of the contract in terms of the
									number of months.
								</li>
							</ul>
						</p>
						<p>
							The bill for Contract Maintenance Fees is charged by
							ACH to your store and the amount depends on the
							above three factors. Details of fees, timing, and
							offsets to fees are found in Platform Service Fees
							below in the Fee Schedule, SECTION B2.
						</p>
						<p>
							Here is the bill timing of Contract Maintenance Fees
							for the various Payment Frequency options.
							<ul className="ul-square">
								<li>
									Contract Maintenance Fees for WEEKLY PAY
									contracts are billed to you once each week
									on the same day as the customer’s scheduled
									payment due date.
								</li>
								<li>
									Bi-WEEKLY PAY contracts are billed to you
									once every two weeks, on the same day as the
									customer’s scheduled payment due date.
								</li>
								<li>
									MONTHLY PAY contracts are billed to you once
									per month, on the same day as the customer’s
									scheduled payment due date.
								</li>
							</ul>
						</p>

						<h3>FEE SCHEDULE</h3>

						<p>
							<ul className="ul-upper-alpha">
								<li style={{ fontWeight: "bold" }}>
									STORE START UP FEES:
								</li>
								<ul className="ul-decimal">
									<li>
										<strong>
											Application Processing Fee:
										</strong>{" "}
										When you apply to become an RTO Mobile
										merchant, your business documentation is
										reviewed carefully by RTO Mobile and by
										CloverConnect (the merchant processor)
										used for Auto-Bill payment.
										<table className="bordered-table">
											<tr>
												<td>
													<strong>
														Billing Description:
													</strong>
												</td>
												<td>
													<strong>Amount:</strong>
												</td>
												<td>
													<strong>
														Bill Timing:
													</strong>
												</td>
											</tr>
											<tr>
												<td>
													Application Processing Fee
												</td>
												<td>$200</td>
												<td>Upon Application</td>
											</tr>
										</table>
									</li>
									<li>
										<strong>Portal Setup Services:</strong>{" "}
										<br />
										When your application to become an RTO
										Mobile Merchant is approved, your portal
										credentials are assigned, the portal is
										customized for your store, and the
										device lock screens are updated to
										reflect your store address and phone
										number.
										<table className="bordered-table">
											<tr>
												<td>
													<strong>
														Billing Description:
													</strong>
												</td>
												<td>
													<strong>Amount:</strong>
												</td>
												<td>
													<strong>
														Bill Timing:
													</strong>
												</td>
											</tr>
											<tr>
												<td>
													Portal Customization Fee
												</td>
												<td>No Charge</td>
												<td>
													Included in Application Fee
												</td>
											</tr>
										</table>
									</li>
									<li>
										<strong>Education and Training:</strong>{" "}
										<br />
										Store owners, managers, front-line sales
										representatives, and back-office
										personnel are offered UNLIMITED training
										at no additional charge. At a minimum,
										two training sessions are required,
										namely: Portal Implementation Training
										and Device Lock Installation Training.
										Both are hosted by RTO Mobile training
										professionals, in person, or via a Zoom.
										<table className="bordered-table">
											<tr>
												<td>
													<strong>
														Billing Description:
													</strong>
												</td>
												<td>
													<strong>Amount:</strong>
												</td>
												<td>
													<strong>
														Billed When?:
													</strong>
												</td>
											</tr>
											<tr>
												<td>Training Services</td>
												<td>No Charge</td>
												<td>
													Included in Application Fee
												</td>
											</tr>
										</table>
									</li>
								</ul>
								<li style={{ fontWeight: "bold" }}>
									PLATFORM SERVICE FEES:
								</li>
								<ul className="ul-decimal">
									<li>
										Contract Origination Fees are platform
										usage fees for software-as-a-service to
										originate a contract on the RTO Mobile
										Platform.
										<table className="bordered-table">
											<tr>
												<td>
													<strong>
														Billing Description
													</strong>
												</td>
												<td>
													<strong>Amount</strong>
												</td>
												<td>
													<strong>
														Bill Timing?
													</strong>
												</td>
												<td>
													<strong>Offset By</strong>
												</td>
											</tr>
											<tr>
												<td>
													Contract Origination for a
													contract with a software
													device lock
												</td>
												<td>$20</td>
												<td>
													Once, on the day when a
													contract is originated
												</td>
												<td>
													The down payment you
													received from the customer
												</td>
											</tr>
											<tr>
												<td>
													Contract Origination for a
													contract without a software
													device lock
												</td>
												<td>$10</td>
												<td>
													Once, on the day when a
													contract is originated
												</td>
												<td>
													The down payment you
													received from the customer
												</td>
											</tr>
										</table>
									</li>
									<li>
										Contract Maintenance Fees are platform
										usage fees for software-as-a-service to
										maintain a customer contract on the RTO
										Mobile Platform. These services include:
										<ul className="ul-square">
											<li>
												Monitoring the device lock with
												automatic locking when payment
												is past due with text message
												payment reminders for customers.
											</li>
											<li>
												Complete and real-time contract
												record keeping and instant
												access to contract records and
												management reporting.
											</li>
											<li>
												Payment journal posting for Auto
												Bill Payment (ABP) using
												customer stored debit or credit
												cards and cash posting for cash
												payments.
											</li>
										</ul>
										<p>
											In part, these fees are offset by a
											$3.00 Payment Fee automatically
											charged to your customer with each
											payment. You collect the Payment Fee
											and keep it. Contract Maintenance
											Fees are billed and withdrawn by ACH
											from your store bank account on the
											day of each customer's payment due
											date.
											<br />
											<br />
											<div>
												<img
													alt=""
													src={
														contractMaintenanceFees
													}
												/>
											</div>
										</p>
									</li>
									<li>
										Rescheduling Fee - A platform usage fee
										for the software-as-a-service performed
										to reschedule a contract. Rescheduling
										allows the duration, payment frequency,
										payment dates, and amounts of a customer
										contract to be revised at any time after
										the date following the origination date.
										<table className="bordered-table">
											<tr>
												<td>
													<strong>
														Billing Description
													</strong>
												</td>
												<td>
													<strong>Amount</strong>
												</td>
												<td>
													<strong>Bill Timing</strong>
												</td>
												<td>
													<strong>Offset By</strong>
												</td>
											</tr>
											<tr>
												<td>Reschedule Fee</td>
												<td>
													$7.50 each rescheduling
													event
												</td>
												<td>
													A one-time charge on the
													date a contract is
													rescheduled
												</td>
												<td>
													A powerful RTO Mobile
													Platform feature allows you
													to charge your customer any
													amount of your choice for
													the rescheduling service.
												</td>
											</tr>
										</table>
									</li>
									<li>
										Early Termination Fee - A platform usage
										fee for the software-as-a-service to
										support the pay-off of a customer
										contract earlier than when scheduled to
										be paid in full.
										<table className="bordered-table">
											<tr>
												<td>
													<strong>
														Billing Description
													</strong>
												</td>
												<td>
													<strong>Amount</strong>
												</td>
												<td>
													<strong>Bill Timing</strong>
												</td>
												<td>
													<strong>Offset By</strong>
												</td>
											</tr>
											<tr>
												<td>
													Contract Early Termination
													Fee
												</td>
												<td>$20</td>
												<td>
													A one-time charge whenever a
													contract is cancelled early.
												</td>
												<td>
													Including the early
													termination fee amount in
													the early pay off price that
													you quote to your customer.
												</td>
											</tr>
										</table>
									</li>
								</ul>

								<li style={{ fontWeight: "bold" }}>
									PROMISE GUARANTEE PREMIUMS:
								</li>
								<ul className="ul-decimal">
									<li>
										The Promise Guarantee Program (the
										“Program”) provisions, terms, and
										conditions appear in the{" "}
										<b>
											RETAIL MERCHANT SITE ACCESS AND USE
											AGREEMENT
										</b>{" "}
										available under Terms and Conditions at{" "}
										<a
											href="https://www.rtomobile.com/terms-conditions"
											target="_blank"
											rel="noreferrer"
										>
											www.RTOMobile.com
										</a>{" "}
										and in other materials available in the
										Resources section of the Site. If your
										store is enrolled in the Promise
										Guarantee Program, the premiums for
										contracts under the Program are as
										follows:
										<table className="bordered-table">
											<tr>
												<td>
													<strong>
														Payment Plan Retail
														Price Range
													</strong>
												</td>
												<td>
													<strong>
														Guarantee Permium
													</strong>
												</td>
												<td>
													<strong>
														Billing Frequency
													</strong>
												</td>
												<td>
													<strong>Deductible</strong>
												</td>
											</tr>
											<tr>
												<td>$1.00 - $499.99</td>
												<td>$3.50</td>
												<td>Weekly</td>
												<td>$50</td>
											</tr>
											<tr>
												<td>$500.00 - $749.99</td>
												<td>$4.50</td>
												<td>Weekly</td>
												<td>$100</td>
											</tr>
											<tr>
												<td>$750.00 - $1,500.00</td>
												<td>$5.50</td>
												<td>Weekly</td>
												<td>$150</td>
											</tr>
										</table>
									</li>
								</ul>

								<li style={{ fontWeight: "bold" }}>
									NSF FEE (Returned ACH){" "}
									<span style={{ fontWeight: "normal" }}>
										- in the event apayment by you via ACH
										to your bank account of record is
										returned or refused for reasons of
										insufficient funds, an NSF Fee is
										charged. In this event, your RTO Mobile
										account is suspended until the NSF is
										resolved by payment including the NSF
										Charge.
									</span>
								</li>
								<table className="bordered-table">
									<tr>
										<td>
											<strong>Billing Description</strong>
										</td>
										<td>
											<strong>Amount</strong>
										</td>
										<td>
											<strong>Bill Timing</strong>
										</td>
										<td>
											<strong>Offset By</strong>
										</td>
									</tr>
									<tr>
										<td>NSF Charge</td>
										<td>$25 per incident</td>
										<td>
											Upon a returned or refused ACH
											attempt
										</td>
										<td>
											Keep funds available to avoid NSF
											charges
										</td>
									</tr>
								</table>
								<li style={{ fontWeight: "bold" }}>
									CARD PROCESSING FEES (from CloverConnect):
								</li>
								<p>
									As with any credit/debit card processing
									service, there are fees for processing
									cards. RTO Mobile and CloverConnect
									(formerly, CardConnect), a FiServ Company,
									have partnered and worked closely together
									by integrating their platform with ours to
									bring you the convenience of
									Auto-Bill-Payment and other card processing
									services under your unique Merchant ID.
								</p>
								<p>
									The CloverConnect / RTO Mobile integration
									provides you with world-class merchant
									services that include:
								</p>
								<ul className="ul-square">
									<li>
										Secure and PCI Compliant handling of
										Customer Information including card
										specific data.
									</li>
									<li>
										Card processing “built-in” to the RTO
										Mobile Platform delivering real-time
										customer payment card verification for
										instore payments or payments taken by
										phone call. No separate card processing
										terminal is required, therefore there is
										no cost of additional equipment to
										purchase.
									</li>
									<li>
										Auto Bill Payment (ABP), the most
										convenient form of customer bill
										payment, using stored customer debit or
										credit cards. As the term ABP implies,
										customer payments are automatically
										charged to the card-on-file on the day
										the payment is due. Funds collected are
										electronically deposited in your store’s
										bank account overnight and are available
										as “good funds” by early morning the
										following day.
										<table className="bordered-table">
											<tr>
												<td>
													<strong>
														Billing Description
													</strong>
												</td>
												<td>
													<strong>Amount</strong>
												</td>
												<td>
													<strong>Bill Timing</strong>
												</td>
												<td>
													<strong>Offset By</strong>
												</td>
											</tr>
											<tr>
												<td>
													Card processing services
													billed to you by
													CloverConnect
												</td>
												<td>
													2.95% +$0.30 per transaction
												</td>
												<td>
													Billed monthly on the 1
													<sup>st</sup> of each month
													for the prior month
													processing activity. Charges
													appear on your CloveConnect
													statement.
												</td>
												<td>
													Offset in part by the $3.00
													Payment Fee automatically
													added to your customer’s
													payment that you collect and
													keep.
												</td>
											</tr>
											<tr>
												<td>
													Monthly Statement and
													Reporting Fee
												</td>
												<td>$25.00</td>
												<td>
													Billed on the 1<sup>st</sup>{" "}
													of each month for the prior
													month.
												</td>
												<td>
													The profit from your
													in-house payment plans on
													the RTO Mobile Portal
												</td>
											</tr>
										</table>
										<p>
											For further details about Card
											Processing Fees, see your
											CloverConnect statement or call
											CloverConnect Customer Service at
											(855) 853-8340 or login to{" "}
											<a href="https://www.clover.com/en-US/help">
												https://www.clover.com/en-US/help
											</a>{" "}
											with your CloverConnect credentials.
										</p>
										<p>
											For additional help with any
											questions about RTO Mobile Fees or
											any other questions, please reach
											out to your RTO Mobile Account
											Manager, or reach Support at during
											any of the hours posted below.
										</p>
										<p>
											Just call 210-809-4800 or write to{" "}
											<a href="mailto:support@rtomobile.com">
												support@rtomobile.com
											</a>
											.
										</p>
									</li>
								</ul>
							</ul>
						</p>
					</div>

					<div>
						<div className="rto_results-container"></div>
					</div>
				</div>
			</div>
		</>
	);
}

export default RtoMobileFees;
