import ApiService from "./ApiService";
import { Constants } from "../Constants";

/**
 * Services that is designed to encapsulate logics, related to current session
 */
const SessionService = {
	/**
	 * Clear the PHPSESSID cookie
	 */
	clearPHPSESSID: function () {
		const domain = process.env.REACT_APP_COOKIE_DOMAIN;
		document.cookie =
			"PHPSESSID=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
		document.cookie = `user_hierarchy=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${domain}`;
	},
	/**
	 * Clear session storage and PHPSESSID cookie
	 */
	clearSession: function () {
		localStorage.clear();
		SessionService.clearPHPSESSID();
	},
	/**
	 * Check for PHPSESSID and clear session storage if it's not found
	 */
	checkPHPSESSID: () => {
		let value = "; " + document.cookie;
		let parts = value.split("; PHPSESSID=");
		if (parts.length !== 2) {
			SessionService.clearSession();
		}
		// check if token exists in session storage and if not, clear session storage
		if (!localStorage.getItem("token")) {
			SessionService.clearSession();
		}
	},
	/**
	 * set token
	 */
	setJWTToken: (token) => {
		localStorage.setItem("token", token);
	},
	/**
	 * get token
	 */
	getJWTToken: () => {
		if (process.env.REACT_APP_ENV !== "local") {
			SessionService.checkPHPSESSID();
		}
		return localStorage.getItem("token");
		// // get token from cookie name token
		// const cookieValue = document.cookie
		//     .split("; ")
		//     .find((row) => row.startsWith("token="))
		//     ?.split("=")[1];
		// return cookieValue ? cookieValue : null;
	},
	/**
	 * Set user info
	 */
	setUserInfo: (user) => {
		localStorage.setItem("userInfo", JSON.stringify(user));
	},
	getUserInfo: () => {
		return JSON.parse(localStorage.getItem("userInfo"));
	},
	/**
	 * Set user roles
	 */
	setUserRoles: (user) => {
		let userRoles = [];
		userRoles.push(user.role);
		userRoles = userRoles.concat(user.child_roles);
		const roleLabels = {
			system_admin: "System Administrator",
			rto_admin: "RTO Administrator",
			vendor: "Vendor",
			iso_admin: "ISO Administrator",
			ma_admin: "Master Agent Administrator",
			org_admin: "Organization Administrator",
			company_admin: "Company Administrator",
			store_admin: "Store Administrator",
			store_employee: "Store Employee",
		};
		const Roles = userRoles.reduce((acc, role) => {
			acc[role] = roleLabels[role] || "";
			return acc;
		}, {});
		// remove empty roles with no label (e.g. store_manager)
		Object.keys(Roles).forEach((key) => {
			if (Roles[key] === "") {
				delete Roles[key];
			}
		});
		localStorage.setItem("userRoles", JSON.stringify(Roles));
	},
	getUserRoles: () => {
		return JSON.parse(localStorage.getItem("userRoles"));
	},
	setUserRoutes: (userRoutes) => {
		localStorage.setItem("userRoutes", JSON.stringify(userRoutes));
	},
	getUserRoutes: () => {
		const userRoutesString = localStorage.getItem("userRoutes");
		if (userRoutesString && userRoutesString !== "undefined") {
			return JSON.parse(userRoutesString);
		}
		return {}; // or return [] if an array is expected
	},
	setIsVpn: (isVpn) => {
		localStorage.setItem("isVpn", isVpn);
	},
	getIsVpn: () => {
		return localStorage.getItem("isVpn");
	},
	/**
	 * Set user hierarchy
	 */
	setUserHierarchy: (userHierarchy) => {
		localStorage.setItem("userHierarchy", JSON.stringify(userHierarchy));
	},
	getUserHierarchy: () => {
		return JSON.parse(localStorage.getItem("userHierarchy"));
	},
	/**
	 * Reset user userHierarchy
	 * @see index.html#jump-Ungrouped-UserHierarchy
	 * @returns {Promise<void>}
	 */
	resetUserHierarchy: () => {
		ApiService.get("/user-hierarchy/index").then(
			(response) => {
				console.log("Reset user hierarchy");
				SessionService.setUserHierarchy(response.data.userHierarchy);
			},
			(error) => {
				console.log(error);
			}
		);
	},
	/**
	 * Set selected user store
	 */
	setUserStore: (store) => {
		localStorage.setItem("userStore", JSON.stringify(store));
	},
	getUserStore: () => {
		return JSON.parse(localStorage.getItem("userStore"));
	},
	getUserStoreName: () => {
		const storeId = SessionService.getUserStore();
		const userHierarchy = SessionService.getUserHierarchy();
		const storeList = JSON.parse(userHierarchy.sto_list || "{}");
		return storeList[storeId] || null;
	},
	setStoreReportFlags: (flags) => {
		localStorage.setItem("storeReportFlags", JSON.stringify(flags));
	},
	getStoreReportFlags: () => {
		return JSON.parse(localStorage.getItem("storeReportFlags"));
	},
	canSystemAdmin: () => {
		const user_info = SessionService.getUserInfo();

		// Check if the user has the "system_admin" role
		if (user_info.role === Constants.userRole.SYSTEM_ADMIN) {
			return true;
		}

		//  Don't need to check for child roles because system_admin is the highest role

		// If the user doesn't have "system_admin" role
		return false;
	},
	canRtoAdmin: () => {
		const user_info = SessionService.getUserInfo();

		// Check if the user has the "rto_admin" role
		if (user_info.role === Constants.userRole.RTO_ADMIN) {
			return true;
		}

		// Check if any of the child roles is "rto_admin"
		if (user_info.child_roles?.includes(Constants.userRole.RTO_ADMIN)) {
			return true;
		}

		// If neither the user nor any of their child roles have "rto_admin" role
		return false;
	},
	canIsoAdmin: () => {
		const user_info = SessionService.getUserInfo();

		// Check if the user has the "iso_admin" role
		if (user_info.role === Constants.userRole.ISO_ADMIN) {
			return true;
		}

		// Check if any of the child roles is "iso_admin"
		if (user_info.child_roles?.includes(Constants.userRole.ISO_ADMIN)) {
			return true;
		}

		// If neither the user nor any of their child roles have "iso_admin" role
		return false;
	},
	canMasterAgentAdmin: () => {
		const user_info = SessionService.getUserInfo();

		// Check if the user has the "ma_admin" role
		if (user_info.role === Constants.userRole.MA_ADMIN) {
			return true;
		}

		// Check if any of the child roles is "ma_admin"
		if (user_info.child_roles?.includes(Constants.userRole.MA_ADMIN)) {
			return true;
		}

		// If neither the user nor any of their child roles have "ma_admin" role
		return false;
	},
	canOrganizationAdmin: () => {
		const user_info = SessionService.getUserInfo();

		// Check if the user has the "org_admin" role
		if (user_info.role === Constants.userRole.ORG_ADMIN) {
			return true;
		}

		// Check if any of the child roles is "org_admin"
		if (user_info.child_roles?.includes(Constants.userRole.ORG_ADMIN)) {
			return true;
		}

		// If neither the user nor any of their child roles have "org_admin" role
		return false;
	},
	canCompanyAdmin: () => {
		const user_info = SessionService.getUserInfo();

		// Check if the user has the "company_admin" role
		if (user_info.role === Constants.userRole.COMPANY_ADMIN) {
			return true;
		}

		// Check if any of the child roles is "company_admin"
		if (user_info.child_roles?.includes(Constants.userRole.COMPANY_ADMIN)) {
			return true;
		}

		// If neither the user nor any of their child roles have "company_admin" role
		return false;
	},
	canStoreAdmin: () => {
		const user_info = SessionService.getUserInfo();

		// Check if the user has the "store_admin" role
		if (user_info.role === Constants.userRole.STORE_ADMIN) {
			return true;
		}

		// Check if any of the child roles is "store_admin"
		if (user_info.child_roles?.includes(Constants.userRole.STORE_ADMIN)) {
			return true;
		}

		// If neither the user nor any of their child roles have "store_admin" role
		return false;
	},
	canStoreManager: () => {
		const user_info = SessionService.getUserInfo();

		// Check if the user has the "store_manager" role
		if (user_info.role === Constants.userRole.STORE_MANAGER) {
			return true;
		}

		// Check if any of the child roles is "store_manager"
		if (user_info.child_roles?.includes(Constants.userRole.STORE_MANAGER)) {
			return true;
		}

		// If neither the user nor any of their child roles have "store_manager" role
		return false;
	},
	// Store employee is the lowest role, so we don't need to check
	// canStoreEmployee: () => {
	//     const user_info = SessionService.getUserInfo();

	//     // Check if the user has the "store_employee" role
	//     if (user_info.role === Constants.userRole.STORE_EMPLOYEE) {
	//         return true;
	//     }

	//     // Check if any of the child roles is "store_employee"
	//     if (user_info.child_roles?.includes(Constants.userRole.STORE_EMPLOYEE)) {
	//         return true;
	//     }

	//     // If neither the user nor any of their child roles have "store_employee" role
	//     return false;
	// },
};
export default SessionService;
