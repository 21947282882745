import React, { useState, useEffect } from "react";
import {
	TextField,
	Button,
	Typography,
	Box,
	MenuItem,
	Select,
	FormControl,
	InputLabel,
} from "@mui/material";
import CustomInputPhoneComponent from "./rtoDynamicForm/fields/CustomInputPhoneComponent";

function RtoVerificationField({
	handleContactSubmit,
	isLinkSent,
	setIsLinkSent,
	selector,
}) {
	const [verificationType, setVerificationType] = useState("");
	const [contact, setContact] = useState("");

	const sortedKeys = Object.keys(selector).sort((a, b) => {
		const order = ["user", "store", "custom"];
		const typeA = a.split("_")[0];
		const typeB = b.split("_")[0];
		const isPhoneA = a.includes("phone");
		const isPhoneB = b.includes("phone");

		if (typeA === typeB) {
			return isPhoneA === isPhoneB ? 0 : isPhoneA ? -1 : 1;
		}
		return order.indexOf(typeA) - order.indexOf(typeB);
	});

	useEffect(() => {
		if (verificationType) {
			if (
				verificationType.includes("phone") &&
				!verificationType.includes("custom_phone")
			) {
				setContact(formatContact(selector[verificationType] || ""));
			} else {
				setContact(selector[verificationType] || "");
			}
		}
	}, [verificationType, selector]);

	const handleContactSubmitInternal = () => {
		handleContactSubmit(contact, verificationType);
	};

	const handleReset = () => {
		setVerificationType("");
		setContact("");
		setIsLinkSent(false);
	};

	const formatLabel = (type) => {
		return type.replace("_", " ").replace(/\b\w/g, (l) => l.toLowerCase());
	};

	const formatContact = (value) => {
		const formattedValue = value.replace(/[^0-9]/g, "");
		return `+1${formattedValue}`;
	};

	useEffect(() => {
		const style = document.createElement("style");
		style.innerHTML = `
			.PhoneInputCountry {
			    left: 14px !important;
				height: 36px !important;
				align-self: center !important;
				position: relative !important;
				display: flex !important;
				align-items: center !important;
				margin-right: var(--PhoneInputCountrySelect-marginRight) !important;
			}
		`;
		document.head.appendChild(style);

		return () => {
			document.head.removeChild(style);
		};
	}, []);

	return (
		<Box
			sx={{
				maxWidth: 400,
				margin: "auto",
				padding: 2,
				textAlign: "center",
			}}
		>
			<Typography
				variant="h6"
				gutterBottom
				className="text-center py-0 text-3xl text-cyan-800 font-light font-sans"
			>
				How would you like to receive a link to upload images of the
				customers ID?
			</Typography>
			<br />
			<FormControl fullWidth sx={{ marginBottom: 2 }}>
				<InputLabel id="verification-type-label">
					Send Link via
				</InputLabel>
				<Select
					labelId="verification-type-label"
					value={verificationType}
					onChange={(e) => setVerificationType(e.target.value)}
					label="Verification Type"
					disabled={isLinkSent}
				>
					{sortedKeys.map((key) => (
						<MenuItem key={key} value={key}>
							{key
								.replace("_", " ")
								.replace(/\b\w/g, (l) => l.toUpperCase())}{" "}
							{selector[key] &&
								`(${
									key.includes("email")
										? selector[key].toUpperCase()
										: selector[key]
								})`}
						</MenuItem>
					))}
				</Select>
			</FormControl>
			{verificationType && (
				<>
					<br />
					<Box
						sx={{
							display: "flex",
							alignItems: "start",
							width: "100%",
						}}
					>
						{verificationType.includes("phone") ? (
							<CustomInputPhoneComponent
								className="w-full"
								name="contact"
								label={`Please enter ${formatLabel(
									verificationType
								)}`}
								value={contact}
								onChange={(fieldName, value, pIsValid) =>
									setContact(value)
								}
								variant="outlined"
								readonlyoverride={
									!verificationType.includes("custom")
								}
								styleoverride={{
									flexGrow: 1,
									"& .MuiInputLabel-shrink": {
										marginLeft: "-35px",
									},
									"& fieldset": {
										marginLeft: "-35px",
										borderTopRightRadius: 0,
										borderBottomRightRadius: 0,
									},
								}}
							/>
						) : (
							<TextField
								fullWidth
								variant="outlined"
								label={`Please enter ${formatLabel(
									verificationType
								)}`}
								value={contact}
								onChange={(e) => setContact(e.target.value)}
								margin="none"
								disabled={!verificationType.includes("custom")}
								sx={{
									flexGrow: 1,
									"& fieldset": {
										borderTopRightRadius: 0,
										borderBottomRightRadius: 0,
									},
								}}
							/>
						)}
						<Button
							variant="contained"
							color="primary"
							onClick={handleContactSubmitInternal}
							disableElevation
							sx={{
								height: "56px",
								marginLeft: "-1px",
								borderTopLeftRadius: 0,
								borderBottomLeftRadius: 0,
							}}
							disabled={isLinkSent}
						>
							SUBMIT
						</Button>
					</Box>
				</>
			)}
			{isLinkSent && (
				<>
					<br />
					<Typography variant="body1" gutterBottom>
						A link has been sent to: {contact}
					</Typography>
					<Box
						sx={{
							display: "flex",
							justifyContent: "space-between",
							marginTop: 2,
						}}
					>
						<Button color="primary" onClick={handleReset}>
							<Typography sx={{ fontSize: "0.75rem" }}>
								Reset & Start Over
							</Typography>
						</Button>
						<Button
							color="primary"
							onClick={handleContactSubmitInternal}
						>
							<Typography sx={{ fontSize: "0.75rem" }}>
								Resend Link
							</Typography>
						</Button>
					</Box>
				</>
			)}
		</Box>
	);
}

export default RtoVerificationField;
