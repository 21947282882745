import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import CustomInputNumberComponent from "./rtoDynamicForm/fields/CustomInputNumberComponent";

const CashPaymentBalanceCalculatorForm = ({
	paymentAmount,
	setIsSubmitDisabled,
}) => {
	const [errorMessage, setErrorMessage] = useState("");
	const [cashBalance, setCashBalance] = useState("");
	const [cashBalanceErrorMessage, setCashBalanceErrorMessage] = useState("");
	const [tenderValue, setTenderValue] = useState("");
	const [cashInput, setCashInput] = useState("");
	const inputRef = useRef(null);

	useEffect(() => {
		setIsSubmitDisabled(true);
	}, []);

	const calculateCashChange = (name, value, isValid) => {
		setCashInput(value); // Update cashInput state
		if (isValid && parseFloat(value) >= parseFloat(paymentAmount)) {
			let balance = parseFloat(value) - parseFloat(paymentAmount);
			setCashBalance(balance.toFixed(2));
			setCashBalanceErrorMessage("");
			setIsSubmitDisabled(false);
		} else {
			setCashBalance("");
			setCashBalanceErrorMessage(
				"Amount received must be greater than or equal to total"
			);
			setIsSubmitDisabled(true);
		}
	};

	return (
		<div>
			<Grid
				item
				xs={10}
				marginTop={2}
				style={{
					textAlign: "right",
				}}
			>
				Amount Tendered:
				{cashBalanceErrorMessage && (
					<p className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium Mui-required">
						{cashBalanceErrorMessage}
					</p>
				)}
			</Grid>
			<Grid
				item
				xs={2}
				marginTop={1}
				paddingLeft={1}
				style={{ textAlign: "right" }}
			>
				<CustomInputNumberComponent
					name="cashInput"
					value={cashInput}
					onChange={calculateCashChange}
					onBlur={(event) =>
						calculateCashChange(
							"cashInput",
							event.target.value,
							true
						)
					}
					type={{
						decimalLimit: 2,
						prefix: "$",
						placeholder: "0.00",
						required: true,
					}}
					ref={inputRef}
				/>
			</Grid>
			<Grid item xs={10} marginTop={1} style={{ textAlign: "right" }}>
				Change Due:
			</Grid>
			<Grid item xs={2} marginTop={1} style={{ textAlign: "right" }}>
				${Number(cashBalance).toFixed(2).toLocaleString()}
			</Grid>
		</div>
	);
};

export default CashPaymentBalanceCalculatorForm;
