import React, { useState } from "react";
import { Link } from "react-router-dom";
// import Modal from 'react-bootstrap/Modal';
// import Button from 'react-bootstrap/Button';
// import Container from 'react-bootstrap/Container';
// import Row from 'react-bootstrap/Row';
// import Col from 'react-bootstrap/Col';
// import FormGroup from "react-bootstrap/FormGroup";
// import FormLabel from "react-bootstrap/FormLabel";
// import Feedback from "react-bootstrap/Feedback";
// import FormControl from "react-bootstrap/FormControl";
// import { Form}from 'react-bootstrap';
import BluePlusIcon from "../../images/blue-plus.svg";
import CreditCardIcon from "../../images/credit-card.svg";
import CalculatorModal from "../../components/common/CalculatorModal";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import DashboardService from "../../service/dashboard/DashboardService";
import { useNavigate } from "react-router-dom";
import AnnouncementAlert from "../../components/common/AnnouncementAlert";
import { FaRegCreditCard } from "react-icons/fa";
import { LuPlusCircle, LuEye } from "react-icons/lu";
import { createTheme, ThemeProvider } from "@mui/material/styles";
const theme = createTheme({
	palette: {
		default: {
			main: "#ffffff",
			light: "#f7fafc",
			dark: "##f7fafc",
			contrastText: "#2d3748",
		},
		primary: {
			main: "#00ABF9",
			light: "#A3DAFA",
			dark: "#0091d3",
			contrastText: "#fff",
		},
		success: {
			main: "#22c55e",
			light: "#86efac",
			dark: "#16a34a",
			contrastText: "#fff",
		},
		error: {
			main: "#ef4444",
			light: "#fca5a5",
			dark: "#dc2626",
			contrastText: "#fff",
		},
	},
	components: {
		MuiPopover: {
			styleOverrides: {
				paper: {
					padding: "1rem",
					maxWidth: "500px", // Set the width of the dialog window
					maxHeight: "500px", // Set the max height of the dialog window
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				root: {
					fontWeight: "600",
					textTransform: "none",
					whiteSpace: "nowrap",
				},
			},
		},
	},
});

function Dashboard({ loading, setLoading }) {
	const [showModal, setShowModal] = useState(false);
	const [animationClass, setAnimationClass] = useState("");
	// const [modalLoading, setModalLoading] = useState(false);
	// const [formData, setFormData] = useState({
	// 	invoice_total: 100.0,
	// 	amount_down: 20.0,
	// 	finance_factor: 2.0,
	// 	display_type: 1,
	// });
	const navigate = useNavigate();
	// const [discountPercent, setDiscountPercent] = useState({
	// 	min: 70,
	// 	max: 10,
	// 	def: 20,
	// });
	const [searchLoading, setSearchLoading] = useState(false);

	// const [res, setRes] = useState({
	// 	isOk: true,
	// 	table: "",
	// 	down_payment_pre_tax: 0,
	// 	down_payment_tax: 0,
	// 	down_payment_percent: "0",
	// 	down_percent: 0,
	// 	down_net: 0,
	// 	down_tax: 0,
	// 	down_fee: "0",
	// 	gross_total: 0,
	// 	tax_rate: "0",
	// 	d_def: "0",
	// 	d_min: "0",
	// 	d_max: "0",
	// 	dan_o: 0,
	// 	text: "",
	// 	text_down_payment: "",
	// 	text_rental_factor: "",
	// });

	// useEffect(() => {
	//   const fetchData = async () => {
	//     setModalLoading(true);
	//     try {
	//       const result = await DashboardService.getCalculator(formData);
	//       // console.log(result);
	//       setModalLoading(false);
	//       setRes(result?.data);

	//       setDiscountPercent({
	//         min: result?.data?.d_min ? parseInt(result?.data?.d_min) : 10,
	//         max: result?.data?.d_max ? parseInt(result?.data?.d_max) : 70,
	//         def: result?.data?.d_def ? parseInt(result?.data?.d_def) : 20,
	//       });
	//     } catch (error) {
	//       console.error("Error fetching data:", error);
	//       setModalLoading(false);
	//     }
	//   };
	//   if (showModal) {
	//     fetchData();
	//   }
	// }, [showModal]);

	const handleModalShow = () => {
		setAnimationClass("modal-dialog-transition modal-enter");
		setShowModal(true);
	};

	//   const handleModalClose = () => {
	//     setAnimationClass("modal-dialog-transition modal-exit");

	//     setFormData({
	//       invoice_total: 100.0,
	//       amount_down: 20.0,
	//       finance_factor: 2.0,
	//       display_type: 1,
	//     });
	//     // Set a timeout equal to the animation duration before hiding the modal

	//     setTimeout(() => {
	//       setShowModal(false);
	//       setAnimationClass(""); // Reset the animation class
	//     }, 500); // Match this to the animation-duration
	//   };

	/*const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData((prevState) => ({
			...prevState,
			[name]: value,
		}));
	};*/

	// const handleBlur = async () => {
	//   // Destructure necessary fields from formData
	//   const { invoice_total, amount_down } = formData;
	//   let payload = formData;
	//   // Calculate 10%, 20%, and 70% of the invoice_total
	//   const tenPercent = invoice_total * (discountPercent.min / 100).toFixed(2);
	//   const twentyPercent =
	//     invoice_total * (discountPercent.def / 100).toFixed(2);
	//   const seventyPercent =
	//     invoice_total * (discountPercent.max / 100).toFixed(2);

	//   // New value for amount_down
	//   let newAmountDown;

	//   // Check conditions and update amount_down accordingly
	//   if (amount_down < tenPercent) {
	//     newAmountDown = tenPercent;
	//   } else if (amount_down > seventyPercent) {
	//     newAmountDown = seventyPercent;
	//   } else if (amount_down === 0) {
	//     newAmountDown = twentyPercent;
	//   } else {
	//     // If none of the conditions are met, no need to update
	//     return;
	//   }
	//   payload.amount_down = newAmountDown;
	//   // Update the formData state
	//   setFormData({
	//     ...formData,
	//     amount_down: newAmountDown,
	//   });
	//   setModalLoading(true);
	//   try {
	//     // Additional logic for 'amount_down'
	//     const result = await DashboardService.getCalculator(payload);
	//     // setFormData((prevState) => ({
	//     //   ...prevState,
	//     //   amount_down: result?.down_fee,
	//     // }));

	//     setRes(result?.data);
	//     setDiscountPercent({
	//       min: result?.data?.d_min ? parseInt(result?.data?.d_min) : 10,
	//       max: result?.data?.d_max ? parseInt(result?.data?.d_max) : 70,
	//       def: result?.data?.d_def ? parseInt(result?.data?.d_def) : 20,
	//     });
	//     setModalLoading(false);
	//   } catch (error) {
	//     console.error("Error on API call:", error);
	//     setModalLoading(false);
	//   }
	// };

	/*const handleOptionChange = async (e) => {
		setModalLoading(true);
		const newDisplayType = e.target.id === "allInclusive" ? 1 : 2;
		const newFormData = { ...formData, display_type: newDisplayType };
		setFormData(newFormData);

		try {
			const result = await DashboardService.getCalculator(newFormData);
			// console.log(result);
			setRes(result);
			setModalLoading(false);
		} catch (error) {
			console.error("Error on API call:", error);
			setModalLoading(false);
		}
	};*/

	const [searchQuery, setSearchQuery] = useState("");

	const handleInputChange = (event) => {
		setSearchQuery(event.target.value);
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		// Navigate to '/search-results' and pass `searchQuery` as state
		setSearchLoading(true);
		try {
			const response = await DashboardService.contractsSearch(
				searchQuery
			);

			if (response.status === 200) {
				setSearchLoading(false);
				// Assuming the response data is what you want to pass
				navigate("/search-results", { state: { data: response.data } });
				// Log the successful result
			} else {
				setSearchLoading(false);
				// Handle non-200 status codes
				console.error(
					"Error: Received non-200 status code",
					response.status
				);
			}
		} catch (error) {
			setSearchLoading(false);
			// Handle network errors, parsing errors, etc.
			console.error("Error on API call:", error);
		}
		// console.log(result);
	};

	return (
		<>
			<ThemeProvider theme={theme}>
				<CalculatorModal
					show={showModal}
					setShow={setShowModal}
					animation={animationClass}
					setAnimation={setAnimationClass}
				></CalculatorModal>
				{/* <Modal
        show={showModal}
        onHide={handleModalClose}
        size="lg"
        backdrop="static"
        centered
        dialogClassName={animationClass}
        onExited={() => setAnimationClass("")}
      >
        <Modal.Header closeButton>
          <Modal.Title className="custom-modal-title">
            Contract Calculator
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {modalLoading && (
            <div className="modal-backdrop-spinner">
              <Spinner
                className="custom-spinner"
                animation="border"
                role="status"
                variant="primary"
                size="lg"
              >
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          )}

          <Form>
            <Row className="mb-3 mt-3">
              <Col md={6} sm={12}>
                <Form.Group as={Row}>
                  <Form.Label column sm={7} className="input-label">
                    Retail Value $:
                  </Form.Label>
                  <Col sm={5}>
                    <Form.Control
                      type="number"
                      placeholder="0.00"
                      name="invoice_total"
                      value={parseFloat(formData.invoice_total)}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col md={6} sm={12}>
                <Form.Group as={Row}>
                  <Form.Label column sm={7} className="input-label">
                    {Math.round(
                      (formData.amount_down / formData.invoice_total) * 100
                    )}
                    % Down Payment $:
                  </Form.Label>
                  <Col sm={5}>
                    <Form.Control
                      type="number"
                      placeholder="0.00"
                      value={parseFloat(formData.amount_down)}
                      name="amount_down"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={6}></Col>
              <Col
                sm={12}
                md={6}
                style={{
                  fontSize: "0.7rem",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {res?.text_down_payment}
              </Col>
            </Row>
            <Row className="mb-3 mt-3">
              <Col md={6} sm={12}>
                <Form.Group as={Row}>
                  <Form.Label column sm={7} className="input-label">
                    Pricing Factor:
                  </Form.Label>
                  <Col sm={5}>
                    <Form.Control
                      type="number"
                      name="finance_factor"
                      value={parseFloat(formData.finance_factor)}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      defaultValue="2.00"
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col md={6} sm={12}>
                <Form.Group as={Row}>
                  <Form.Label column sm={7} className="input-label">
                    Total Contract Value $:
                  </Form.Label>
                  <Col sm={5}>
                    <Form.Control
                      type="number"
                      value={parseFloat(
                        formData.finance_factor *
                          (formData.invoice_total - formData.amount_down)
                      )}
                      placeholder="0.00"
                      disabled={true}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col
                sm={12}
                md={6}
                style={{
                  fontSize: "0.7rem",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {res?.text_rental_factor}
              </Col>
              <Col sm={12} md={6}></Col>
            </Row>
          </Form>
          <hr></hr>
          <Row>
            <Form.Group as={Col} md={12}>
              <Col xs={12}>
                <Row>
                  <Col xs={12}>
                    <Form.Check
                      type="radio"
                      label="All inclusive (Contract Payment, Sales Tax & Payment Fee)"
                      name="paymentOptions"
                      id="allInclusive"
                      className="mb-2 mb-md-0 me-md-2 input-label radio-pointer"
                      checked={formData.display_type === 1}
                      onChange={handleOptionChange}
                    />
                  </Col>
                  <Col xs={12} className="mt-2">
                    <Form.Check
                      type="radio"
                      label="Contract Payments Only"
                      name="paymentOptions"
                      id="paymentsOnly"
                      className="mb-2 mb-md-0 input-label radio-pointer"
                      checked={formData.display_type === 2}
                      onChange={handleOptionChange}
                    />
                  </Col>
                </Row>
              </Col>
            </Form.Group>
          </Row>
          <hr></hr>
          <Row>
            <Col
              xs={12}
              style={{
                fontSize: "0.8rem",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {res?.text}
            </Col>
            <Col xs={12} className="mt-2">
              <Table responsive="lg" className="rounded-table">
                <thead className="blue-header">
                  <tr className="blue-header">
                    <th className="blue-header">Lease Term</th>
                    <th className="blue-header">Weekly Lease</th>
                    <th className="blue-header">Bi-Weekly Lease</th>
                    <th className="blue-header">Monthly Lease</th>
                  </tr>
                </thead>
                <tbody>
                  <></>
                </tbody>
              </Table>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            style={{ backgroundColor: "#22557B", border: "none" }}
            onClick={handleModalClose}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal> */}
				<div className="rto_home-section">
					<div className="rto_div-block-6"></div>
					<div className="rto_content-div">
						<div
							className="rto_search-container"
							style={{ marginTop: "0px" }}
						>
							<AnnouncementAlert />
							<br />
							<div className="rto_form-block">
								{/* <a
                              href="https://docs.google.com/forms/d/e/1FAIpQLSfdWDlJuV0Ut_XpedxPf55pOFi3OP3h_BEhLa4olPBYKqxnsw/viewform"
                              target="_blank"
                              className="rto_next-button w-button"
                              style={{
                                  backgroundImage: "none",
                                  paddingRight: "25px",
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                  marginBottom: "50px",
                              }}
                          >
                              Receive $20 Amazon Gift Card for every New
                              Contract Created, register your Contracts HERE
                          </a> */}
								{/* <form
                id="dashboard-search-form"
                className="rto_form w-clearfix"
                name="dashboard-search-form"
                // action="/search-results"
                // method="post"
              > */}
								<form
									onSubmit={handleSubmit}
									className="rto_form w-clearfix"
								>
									<input
										type="text"
										className="rto_searchbar w-input"
										maxLength="256"
										name="ContractsDashboardSearchSearch[rto_search]"
										placeholder="Search"
										id="rto_search"
										value={searchQuery}
										onChange={handleInputChange}
									/>
									<Button
										variant="contained"
										disabled={searchLoading}
										type="submit"
										value="Search"
										disableElevation
										className="rto_searchdrop w-button"
										style={{
											fontWeight: "400",
											textTransform: "uppercase",
											borderRadius: "0px",
											borderTopRightRadius: ".375rem",
											borderBottomRightRadius: ".375rem",
										}}
									>
										{searchLoading ? (
											<CircularProgress
												size={24}
												color="inherit"
											/>
										) : (
											"Search"
										)}
									</Button>
								</form>

								<div className="rto_successsearch w-form-done"></div>
							</div>
						</div>
						<div className="rto_options-container">
							<a
								href="#"
								className="rto_link-block w-inline-block"
								onClick={() => {
									handleModalShow();
								}}
								// onClick=(open_calc(); return false;}
							>
								<div className="rto_div-block-4">
									<FaRegCreditCard color="#00abf9" />
									<div className="rto_text-block-2">
										Contract Calculator
									</div>
								</div>
							</a>
							<Link
								to="/contracts/create-new"
								className="rto_link-block w-inline-block"
								style={{
									marginLeft: "auto",
									marginRight: "auto",
								}}
							>
								<div className="rto_div-block-4">
									<LuPlusCircle color="#00abf9" />
									<div className="rto_text-block-2">
										Create New Contract
									</div>
								</div>
							</Link>
							<a
								href="/reports"
								className="rto_link-block w-inline-block"
							>
								<div className="rto_div-block-4">
									<LuEye color="#00abf9" />
									<div className="rto_text-block-2">
										View Your Reports
									</div>
								</div>
							</a>
						</div>
						<div className="row" style={{ marginTop: "20px" }}>
							<div className="col-lg-12">
								<div className="rto_options-container"></div>
							</div>
						</div>
					</div>
				</div>
			</ThemeProvider>
		</>
	);
}

export default Dashboard;
