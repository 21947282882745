import React, {
	useState,
	useEffect,
	forwardRef,
	useImperativeHandle,
	useRef,
} from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";

dayjs.extend(customParseFormat);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

const CustomDateInputComponent = forwardRef(
	(
		{
			name,
			label,
			type,
			value,
			onChange,
			onBlur,
			className,
			required,
			maxDate,
			inputFormat,
			...otherProps
		},
		ref
	) => {
		const formatValue = (valueToValidate) => {
			return valueToValidate ? dayjs(valueToValidate) : null;
		};

		const [internalValue, setInternalValue] = useState(formatValue(value));
		const [isValid, setIsValid] = useState(true); // Assume valid by default
		const [errorMessage, setErrorMessage] = useState("");
		const [isTouched, setIsTouched] = useState(false);
		const isTouchedRef = useRef(isTouched);
		const [isMounted, setIsMounted] = useState(false);
		const inputRef = useRef(null);

		useEffect(() => {
			setInternalValue(formatValue(value));
			// Validate the initial value
			// validate(formatValue(value));
		}, [value]);

		useEffect(() => {
			if (isMounted) {
				validate(internalValue);
			} else {
				setIsMounted(true);
			}
		}, [internalValue]);

		useEffect(() => {
			isTouchedRef.current = isTouched;
		}, [isTouched]);

		useImperativeHandle(ref, () => ({
			validate: (value) => validate(formatValue(value)),
			isTouched: () => isTouchedRef.current,
			setIsTouched: (value) => {
				setIsTouched(value);
				isTouchedRef.current = value;
			},
			focus: () => {
				if (inputRef.current) {
					inputRef.current.focus();
				}
			},
		}));

		const validate = (valueToValidate) => {
			let valid = true;
			let message = "";
			// Example validation: check if the date is required and present
			if (required && !valueToValidate) {
				valid = false;
				message = "This field is required.";
				setIsTouched(true);
			}
			// Additional validations can be added here (e.g., checking against maxDate)
			setIsValid(valid);
			setErrorMessage(message);
			// Ensure to propagate the validation state
			if (onChange)
				onChange(
					name,
					valueToValidate ? valueToValidate.format("YYYY-MM-DD") : "",
					valid
				);
			return valid;
		};

		const handleOnChange = (newValue) => {
			setIsTouched(true);
			const formattedDate = newValue
				? dayjs(newValue).format("YYYY-MM-DD")
				: "";
			setInternalValue(newValue); // Store the Dayjs object as internal value
			validate(formatValue(newValue)); // Validate using the Dayjs object
		};

		const handleOnBlur = (e) => {
			setIsTouched(true);
			validate(internalValue);
			if (onBlur) onBlur(e);
		};

		let inputFormatValue = inputFormat || "MM/DD/YYYY";
		let viewsValue = ["year", "month", "day"];

		if (inputFormat === "YYYY") {
			inputFormatValue = "YYYY";
			viewsValue = ["year"];
		} else if (inputFormat === "MM/YYYY") {
			inputFormatValue = "MM/YYYY";
			viewsValue = ["month", "year"];
		}

		const fieldInfo = {
			upperCase: type && type.upperCase,
			style: type && type.style,
			readOnly: type && type.readOnly,
		};

		const transformedLabel = fieldInfo.upperCase
			? label.toUpperCase()
			: label;

		return (
			<LocalizationProvider dateAdapter={AdapterDayjs}>
				<DatePicker
					className={className}
					// sx={{ marginTop: "20px", width: "100% !important" }}
					sx={{ width: "100% !important" }}
					label={transformedLabel}
					value={internalValue}
					onChange={handleOnChange}
					onBlur={handleOnBlur}
					maxDate={maxDate ? dayjs(maxDate) : undefined}
					slotProps={{
						textField: {
							clearable: true,
							variant: "standard",
							error: !isValid && isTouchedRef.current,
							helperText:
								!isValid && isTouchedRef.current
									? fieldInfo.upperCase
										? errorMessage.toUpperCase()
										: errorMessage
									: "",
						},
					}}
					views={viewsValue}
					inputFormat={inputFormatValue}
					readOnly={fieldInfo.readOnly}
					{...otherProps}
					inputRef={inputRef}
				/>
			</LocalizationProvider>
		);
	}
);

export default CustomDateInputComponent;
