import React from "react";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Box } from "@mui/material";
import DynamicForm from "../../components/formComponents/DynamicForm";
import PromiseGuaranteeClaimService from "../../service/resources/PromiseGuaranteeClaimService";
import "./Resources.css";

function PromiseGuaranteeClaim({ loading, setLoading }) {
	return (
		<>
			<div className="rto_form-sections">
				<div className="rto_content-div">
					<Box
						display="flex"
						justifyContent="flex-end"
						backgroundColor="#f5f5f5"
						p={(0.5, 1)}
						borderRadius={1}
					>
						<Breadcrumbs aria-label="breadcrumb">
							<Link to="/dashboard" className="rto_link-2">
								Dashboard
							</Link>
							<Link to="/resources" className="rto_link-2">
								Resources
							</Link>
							<Typography>Promise Guarantee Claim</Typography>
						</Breadcrumbs>
					</Box>
					<div className="rto_title-div">
						<h1 className="rto_page-title">
							Promise Guarantee Claim
						</h1>
					</div>
					<DynamicForm
						loading={loading}
						setLoading={setLoading}
						model="promise-guarantee-claims"
						modelLabel="Promise Guarantee Claims"
						service={PromiseGuaranteeClaimService}
						apiBase="/promise-guarantee-claims"
						successRedirectBase="resources"
						scenario="create"
						//key={modelId + "ccn_info"}
						//key={methodResetCounter + "ccn_info"}
						display="partial"
						//createEndpointOverride="/rto-company-payments/store-payment-methods-add"
						//updateEndpointOverride="/rto-company-payments/store-payment-methods-update"
						//definitionOverride={definitionMethodOverride}
						//fetchData={fetchPaymentMethods}
						//extraData={extraMethodData}
						// updateId={updateId}
						// fetchData={toast.success("", {
						// 	position: "top-center",
						// 	autoClose: 1000,
						// })}
						isSupportFileUpload={true}
						// suppressSuccessToast={true}
					/>
				</div>
			</div>
		</>
	);
}

export default PromiseGuaranteeClaim;
