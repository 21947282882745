import React, {
	useEffect,
	useState,
	forwardRef,
	useImperativeHandle,
	useRef,
} from "react";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import PhoneInputMui from "../../../common/PhoneInputMui";

const CustomInputPhoneComponent = forwardRef(
	(
		{
			name,
			label,
			type,
			value,
			onChange,
			onBlur,
			className,
			required,
			variant,
			readonlyoverride,
			styleoverride,
			...otherProps
		},
		ref
	) => {
		const [internalValue, setInternalValue] = useState(value);
		const [isValid, setIsValid] = useState(false);
		const [errorMessage, setErrorMessage] = useState("");
		const [isTouched, setIsTouched] = useState(false);
		const isTouchedRef = useRef(isTouched);
		const [isMounted, setIsMounted] = useState(false);
		const inputRef = useRef(null);

		useEffect(() => {
			setInternalValue(value);
		}, [value]);

		useEffect(() => {
			if (isMounted) {
				validate(internalValue);
			} else {
				setIsMounted(true);
			}
		}, [internalValue]);

		useEffect(() => {
			isTouchedRef.current = isTouched;
		}, [isTouched]);

		useImperativeHandle(ref, () => ({
			validate: (value) => validate(value),
			isTouched: () => isTouchedRef.current,
			setIsTouched: (value) => {
				setIsTouched(value);
				isTouchedRef.current = value;
			},
			focus: () => {
				if (inputRef.current) {
					inputRef.current.focus();
				}
			},
		}));

		const handleChange = (newValue) => {
			setInternalValue(newValue);
			onChange(name, newValue, isValid);
		};

		const validate = (valueToValidate) => {
			const value =
				valueToValidate !== undefined ? valueToValidate : internalValue;
			const validPhoneNumber = value ? isValidPhoneNumber(value) : "";
			const fieldInfo = getFieldInfo();

			if (fieldInfo.readOnly) {
				setIsValid(true);
				setErrorMessage("");
				onChange(name, value, true);
				return true;
			}

			if (fieldInfo.required) {
				if (value && value.length === 0) {
					setIsValid(false);
					setErrorMessage("This field is required.");
					setIsTouched(true);
					onChange(name, value, false);
					return false;
				} else {
					if (validPhoneNumber) {
						setIsValid(true);
						setErrorMessage("");
						onChange(name, value, true);
						return true;
					} else {
						setIsValid(false);
						setErrorMessage("Invalid Phone Number.");
						setIsTouched(true);
						onChange(name, value, false);
						return false;
					}
				}
			} else {
				if (value && value.length === 0) {
					setIsValid(true);
					setErrorMessage("");
					return true;
				} else {
					if (validPhoneNumber) {
						setIsValid(true);
						setErrorMessage("");
						onChange(name, value, true);
						return true;
					} else {
						setIsValid(false);
						setErrorMessage("Invalid Phone Number.");
						setIsTouched(true);
						return false;
					}
				}
			}
		};

		const handleFocus = () => {
			setIsTouched(true);
			validate(internalValue);
		};

		const getFieldInfo = () => {
			return {
				required: Boolean(type && type.required),
				minLength: type && type.minLength,
				maxLength: type && type.maxLength,
				readOnly: type && type.readOnly,
				upperCase: type && type.upperCase,
			};
		};

		return (
			<>
				<div className={`${className}`}>
					<PhoneInput
						id={name}
						label={`${label}`}
						required={required}
						defaultCountry="US"
						countries={["US"]}
						addInternationalOption={false}
						displayInitialValueAsLocalNumber={true}
						international={false}
						inputComponent={PhoneInputMui}
						onChange={(e) => handleChange(e)}
						onFocus={handleFocus}
						value={internalValue}
						error={!isValid && isTouchedRef.current}
						inputProps={{
							readOnly:
								getFieldInfo().readOnly || readonlyoverride,
						}}
						variant={variant}
						readonlyoverride={readonlyoverride ? "true" : "false"}
						styleoverride={styleoverride}
						// sx={{
						// 	color: "rgba(0, 0, 0, 0.87) !important", // This should change the text color of the input
						// 	fontFamily:
						// 		'"Roboto", "Helvetica", "Arial", sans-serif !important', // This should change the font family of the input
						// 	// Add any other styles you need here
						// }}
						{...otherProps}
						ref={inputRef}
					/>
					{isTouchedRef.current && !isValid && (
						<p
							className={`MuiFormHelperText-root Mui-error MuiFormHelperText-sizeSmall Mui-required ${
								otherProps.style?.textTransform
									? otherProps.style?.textTransform
									: ""
							}`}
						>
							{errorMessage}
						</p>
					)}
				</div>
			</>
		);
	}
);

export default CustomInputPhoneComponent;
