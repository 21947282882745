import React, {
	useEffect,
	useState,
	forwardRef,
	useImperativeHandle,
	useRef,
} from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";

const CustomSelectComponent = forwardRef(
	(
		{
			name,
			label,
			type,
			value,
			onChange,
			onBlur,
			className,
			variant,
			errorMessage: propErrorMessage,
			isValid: propIsValid,
			isTouched: propIsTouched,
			...otherProps
		},
		ref
	) => {
		const [internalValue, setInternalValue] = useState(
			value !== null && value !== undefined ? value : ""
		);
		const [isValid, setIsValid] = useState(propIsValid || false);
		const [errorMessage, setErrorMessage] = useState(
			propErrorMessage || ""
		);
		const [isTouched, setIsTouched] = useState(propIsTouched || false);
		const isTouchedRef = useRef(isTouched);
		const [isMounted, setIsMounted] = useState(false);
		const inputRef = useRef(null);

		useEffect(() => {
			setInternalValue(
				value !== null && value !== undefined ? value : ""
			);
		}, [value]);

		useEffect(() => {
			if (isMounted) {
				validate(internalValue);
			} else {
				setIsMounted(true);
			}
		}, [internalValue]);

		useEffect(() => {
			isTouchedRef.current = isTouched;
		}, [isTouched]);

		useImperativeHandle(ref, () => ({
			validate: (value) => validate(value),
			isTouched: () => isTouchedRef.current,
			setIsTouched: (value) => {
				setIsTouched(value);
				isTouchedRef.current = value;
			},
			focus: () => {
				if (inputRef.current) {
					inputRef.current.focus();
				}
			},
		}));

		const handleChange = (newValue) => {
			setInternalValue(
				newValue !== null && newValue !== undefined ? newValue : ""
			);
			onChange(name, newValue, isValid);
		};

		const validate = (valueToValidate) => {
			const value =
				valueToValidate !== undefined ? valueToValidate : internalValue;
			const fieldInfo = getFieldInfo();

			let trimmedValue = value;

			if (trimmedValue.length === 0) {
				trimmedValue = ""; //handle 0 value
			}

			setInternalValue(trimmedValue);

			if (fieldInfo.readOnly) {
				setIsValid(true);
				setErrorMessage("");
				onChange(name, trimmedValue, true);
				return true;
			}

			// Check required
			if (fieldInfo.required && trimmedValue === "") {
				setIsValid(false);
				setErrorMessage("This field is required.");
				setIsTouched(true);
				onChange(name, trimmedValue, false);
				return false;
			}

			setIsValid(true);
			setErrorMessage("");
			onChange(name, trimmedValue, true);
			return true;
		};

		const handleFocus = () => {
			setIsTouched(true);
			// validate();
		};

		const getFieldInfo = () => {
			return {
				required: Boolean(type && type.required),
				readOnly: type && type.readOnly,
				upperCase: type && type.upperCase,
				options: type && type.options,
			};
		};

		return (
			<div className={`${className}`}>
				<FormControl
					variant={variant || "standard"}
					fullWidth
					error={!isValid && isTouchedRef.current}
					size="small"
					sx={{ color: "rgba(0, 0, 0, 0.87)" }} // Adjust the color to match other fields
					{...otherProps}
				>
					<InputLabel
						id={`${name}-label`}
						shrink={internalValue.toString().length !== 0}
						className={`${
							otherProps.style?.textTransform
								? otherProps.style?.textTransform
								: ""
						}`}
					>
						{label}
					</InputLabel>
					<Select
						labelId={`${name}-label`}
						id={name}
						value={internalValue}
						onChange={(e) => handleChange(e.target.value)}
						label={label}
						readOnly={getFieldInfo().readOnly}
						onFocus={handleFocus}
						MenuProps={{
							PaperProps: {
								sx: {
									// This targets the dropdown items
									".MuiMenuItem-root": {
										color: "rgba(0, 0, 0, 0.87) !important", // This should change the text color of the dropdown items
										fontFamily:
											'"Roboto", "Helvetica", "Arial", sans-serif !important',
									},
								},
							},
						}}
						// Use the sx prop for more specific styling
						sx={{
							// This targets the text of the selected item
							".MuiSelect-select": {
								color: "rgba(0, 0, 0, 0.87) !important", // This should change the text color of the selected item
								fontFamily:
									'"Roboto", "Helvetica", "Arial", sans-serif !important',
							},
							// This targets the dropdown icon
							"& .MuiSvgIcon-root": {
								color: "rgba(0, 0, 0, 0.87) !important", // This changes the color of the dropdown icon
							},
							// This targets the input element directly for the text color
							"& .MuiInputBase-input": {
								color: "rgba(0, 0, 0, 0.87) !important", // This should change the text color of the input
								fontFamily:
									'"Roboto", "Helvetica", "Arial", sans-serif !important', // This should change the font family of the input
							},
						}}
						{...otherProps}
						inputRef={inputRef}
					>
						{Array.isArray(type?.options) &&
							type.options.map((option, index) => (
								<MenuItem
									key={`${option.id}-${index}`}
									value={option.id}
									className={`${
										otherProps.style?.textTransform
											? otherProps.style?.textTransform
											: ""
									}`}
									style={
										otherProps.style &&
										otherProps.style?.textTransform
											? {
													textTransform:
														otherProps.style
															?.textTransform,
											  }
											: {}
									}
								>
									{option.name}
								</MenuItem>
							))}
					</Select>
					{!isValid && isTouchedRef.current && (
						<FormHelperText
							className={`${
								otherProps.style?.textTransform
									? otherProps.style?.textTransform
									: ""
							}`}
						>
							{errorMessage}
						</FormHelperText>
					)}
				</FormControl>
			</div>
		);
	}
);

export default CustomSelectComponent;
