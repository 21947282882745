import React, { useEffect, useState } from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { getPaymentImage } from "../../helpers/Utils";
import CustomSelectComponent from "./rtoDynamicForm/fields/CustomSelectComponent";

/**
 * @param {*} paymentMethods array of payment methods
 * Example:
 * {
    "CASH": {
        "id": null,
        "payment_name": "Cash Payment",
        "integration": "CASH",
        "profile_id": null,
        "acct_id": null,
        "token": null,
        "accountholder_name": null,
        "account_type": null,
        "cc_last_four": null,
        "cc_expiration": null,
        "default_account": null,
        "tokenizer_url": null
    },
    "CC": {
        "id": null,
        "payment_name": "New Credit Card",
        "integration": "CC",
        "profile_id": null,
        "acct_id": null,
        "token": null,
        "accountholder_name": null,
        "account_type": null,
        "cc_last_four": null,
        "cc_expiration": null,
        "default_account": null,
        "tokenizer_url": "https://fts.cardconnect.com/itoke/ajax-tokenizer.html"
    }}
 * @param {*} selectedPayment selected payment method use for parent component
 * @param {*} setSelectedPayment set selected payment method use for parent component
 * @param {*} setIsSubmitDisabled set is submit button disable use for parent component
 * @returns
 */
const SelectPaymentMethodsForm = ({
	paymentMethods = {},
	selectedPayment = null,
	setSelectedPayment = null,
	setIsSubmitDisabled = null,
}) => {
	const [paymentInfo, setPaymentInfo] = useState({});

	const handleChange = (name, value, isValid) => {
		setSelectedPayment(value);
		if (value !== "CASH") {
			setIsSubmitDisabled(false);
		}
	};

	const options = Object.keys(paymentMethods)
		.map((key) => ({
			id: key,
			name: (
				<>
					{paymentMethods[key]?.id &&
						getPaymentImage(paymentMethods[key]?.account_type)}
					{paymentMethods[key]?.payment_name}
				</>
			),
		}))
		.filter((option) => option.id !== "");

	/*useEffect(() => {
		//contractPaymentGet();
	}, [paymentMethods]);
	*/
	//console.log(", paymentMethods);
	return (
		<CustomSelectComponent
			variant="outlined"
			name="paymentMethod"
			label="Payment Method"
			value={selectedPayment}
			onChange={handleChange}
			type={{
				options: options,
				required: true,
			}}
			sx={{
				width: "100%",
				color: "#21557a",
			}}
		/>
	);
};
export default SelectPaymentMethodsForm;
