import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import _, { update } from "lodash";
import DynamicForm from "../formComponents/DynamicForm";
import SessionService from "../../service/SessionService";
import ContractsCreateService from "../../service/contractsCreate/ContractsCreateService";
import RtoEmailVerificationField from "../formComponents/RtoEmailVerificationField";
import RtoVerificationField from "../formComponents/RtoVerificationField";
import {
	contractsCreateStep0Definition,
	contractsCreateStep1Definition,
	contractsCreateStep1DummyResponse,
	contractsCreateStep2Definition,
	contractsCreateStep2DummyResponse,
	contractsCreateStep3Definition,
	contractsCreateStep3DummyResponse,
	contractsCreateStep4Definition,
	contractsCreateStep4DummyResponse,
	contractsCreateStep5Definition,
	contractsCreateStep5DummyResponse,
	contractsCreateStep6Definition,
	contractsCreateStep7Definition,
	contractsCreateStep8Definition,
	stateOptions,
} from "../formComponents/rtoDynamicForm/definitions/contractsCreateFormDefinition";
import MultiStepFormStepper from "../formComponents/rtoDynamicForm/MultiStepFormStepper";
import TmpContractsIndexTable from "../common/TmpContractsIndexTable";
import PaymentTermsForm from "../backend/contracts/PaymentTermsForm";
import ContractSummary from "../../components/backend/contracts/ContractSummary";
import SelectPaymentMethodsForm from "../formComponents/SelectPaymentMethodsForm";
import CashPaymentBalanceCalculatorForm from "../formComponents/CashPaymentBalanceCalculatorForm";
import CreditCardAddForm from "../formComponents/CreditCardAddForm";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import { Button } from "@mui/material";
import { MdCheck } from "react-icons/md";
import TopModal from "../common/TopModal";
import { parseNumeric } from "../../helpers/Utils";

const steps = [
	{ id: 1, label: "Contract Security" },
	{ id: 2, label: "Customer Information" },
	{ id: 3, label: "ID Verification" },
	{ id: 4, label: "Contract Details" },
	{ id: 5, label: "Contract Summary" },
	{ id: 6, label: "Payment Collection" },
	{ id: 7, label: "ABP Enrollment" },
	{ id: 8, label: "Contract Completion" },
];

const stepDefinitions = [
	contractsCreateStep0Definition,
	contractsCreateStep1Definition,
	contractsCreateStep2Definition,
	contractsCreateStep3Definition,
	contractsCreateStep4Definition,
	contractsCreateStep5Definition,
	contractsCreateStep6Definition,
	contractsCreateStep7Definition,
	contractsCreateStep8Definition,
];

function ContractsCreateForm({ loading, setLoading }) {
	const navigate = useNavigate();
	const formRef = useRef(null);

	const [step, setStep] = useState(0);
	const [nextStep, setNextStep] = useState();

	const [tmpContracts, setTmpContracts] = useState([]);
	const [tmpContractId, setTmpContractId] = useState(null);

	const [extraContractCreateData, setExtraContractCreateData] = useState({
		step: nextStep,
		action: "new",
	});
	const [sendEmptyContractCreateData, setSendEmptyContractCreateData] =
		useState(false);

	const [
		definitionContractCreateOverride,
		setDefinitionContractCreateOverride,
	] = useState(_.cloneDeep(contractsCreateStep0Definition));

	const [formState, setFormState] = useState({});
	const formStateRef = useRef(formState);
	const [canContinue, setCanContinue] = useState(true);

	// step 0 state
	const [storeOptions, setStoreOptions] = useState([]);
	const [selectedStore, setSelectedStore] = useState(
		SessionService.getUserStore()
	);
	const [triggerBackStep0, setTriggerBackStep0] = useState(false);

	// step 1 state
	const [step1EnabledLockRequired, setStep1EnabledLockRequired] =
		useState(false);
	const [step1EnabledLockEnabled, setStep1EnabledLockEnabled] =
		useState(false);
	const [step1EnabledAbpRequired, setStep1EnabledAbpRequired] =
		useState(false);
	const [step1EnabledAbpEnabled, setStep1EnabledAbpEnabled] = useState(false);
	const [triggerFetchStep1, setTriggerFetchStep1] = useState(false);

	// step 2 state
	const [step2IsVerifyAddressVisible, setStep2IsVerifyAddressVisible] =
		useState(false);
	const [
		step2IsEmailVerificationVisible,
		setStep2IsEmailVerificationVisible,
	] = useState(false);
	const step2ReadOnlyFields = [
		"first_name",
		"last_name",
		"phone_number",
		"date_of_birth",
		"ssn_last_four",
		"address_1",
		"address_2",
		"zip",
		"city",
		"state",
	];
	const [step2IsEmailSent, setStep2IsEmailSent] = useState(false);
	const [step2EmailCode, setStep2EmailCode] = useState(null);
	const [step2IsModalOpen, setStep2IsModalOpen] = useState(false);
	const [step2EnteredAddress, setStep2EnteredAddress] = useState({});
	const [step2FoundAddress, setStep2FoundAddress] = useState({});
	const [step2IsAddressUpdated, setStep2IsAddressUpdated] = useState(false);
	const [
		step2IsEmailVerificationDisabled,
		setStep2IsEmailVerificationDisabled,
	] = useState(false);
	const [triggerFetchStep2, setTriggerFetchStep2] = useState(false);

	// step 3 state
	const [
		step3StoreRequireIdVerification,
		setStep3StoreRequireIdVerification,
	] = useState(false);
	const [step3TriggerFormUpdate, setStep3TriggerFormUpdate] = useState(false);
	const [step3IdType, setStep3IdType] = useState(undefined);
	const [step3Selector, setStep3Selector] = useState({});
	const [step3IsLinkSent, setStep3IsLinkSent] = useState(false);
	const [step3ImageRefreshInterval, setStep3ImageRefreshInterval] =
		useState(null);
	const [step3ImageCount, setStep3ImageCount] = useState(0);
	const [step3Image1, setStep3Image1] = useState(null);
	const [step3Image2, setStep3Image2] = useState(null);
	const [step3Image3, setStep3Image3] = useState(null);
	const [triggerFetchStep3, setTriggerFetchStep3] = useState(false);

	// step 4 state
	const [step4TriggerFormUpdate, setStep4TriggerFormUpdate] = useState(false);
	const [step4SelectedPlan, setStep4SelectedPlan] = useState(null);
	const [step4LeaseTypeId, setStep4LeaseTypeId] = useState(null);
	const [step4LeaseLength, setStep4LeaseLength] = useState(null);
	const [step4LeaseDay, setStep4LeaseDay] = useState(null);
	const [step4LeaseDate, setStep4LeaseDate] = useState(null);
	const [triggerFetchStep4, setTriggerFetchStep4] = useState(false);

	// step 5 state
	const [triggerFetchStep5, setTriggerFetchStep5] = useState(false);

	// step 6 state
	const [creditCardInfo, setCreditCardInfo] = useState({
		first_name: "",
		last_name: "",
		month: " ",
		year: " ",
		cvv: "",
		billing_address: "",
		zip: "",
		city: "",
		state: "",
		token: "",
	});

	// admin state for bypassing form submission
	const [createResetCounter, setCreateResetCounter] = useState(0);
	const [bypassFormSubmission, setBypassFormSubmission] = useState(true); // Add a state to control bypass

	const handleStepClick = async (newStep) => {
		if (bypassFormSubmission) {
			setLoading(true);
			setStep(newStep);
			setNextStep("step_" + (newStep + 1));
			setDefinitionContractCreateOverride(stepDefinitions[newStep]);

			if (newStep === 1) {
				// updateContractCreateDefinitionWithValues(
				// 	[
				// 		step1EnabledLockRequired,
				// 		step1EnabledLockEnabled,
				// 		step1EnabledAbpRequired,
				// 		step1EnabledAbpEnabled,
				// 	],
				// 	formState,
				// 	_.cloneDeep(contractsCreateStep1Definition)
				// );
				setTriggerFetchStep1(true);
			}

			if (newStep === 2) {
				setTriggerFetchStep2(true);
			}

			if (newStep === 3) {
				setTriggerFetchStep3(true);
			}

			// @todo : We need to integrate this logic into next Step action
			if (newStep === 4) {
				// @todo replace the id with tmp_contracts_id
				// fetchTmpContractData(tmpContractId, "step_4");
				// fetchTmpContractData(18345, "step_4");
				// updateContractCreateDefinitionWithValues(
				// 	[],
				// 	formState,
				// 	_.cloneDeep(contractsCreateStep4Definition)
				// );
				setTriggerFetchStep4(true);
			}

			if (newStep === 5) {
				setTriggerFetchStep5(true);
			}

			if (newStep === 6) {
				// setTriggerFetchStep6(true);
			}

			if (newStep === 7) {
				// setTriggerFetchStep7(true);
			}

			if (newStep === 8) {
				// setTriggerFetchStep8(true);
			}

			setTimeout(() => {
				setLoading(false);
			}, 200);
		}
	};

	const [tmpContractCreateData, setTmpContractCreateData] = useState("");

	const fetchTmpContractData = async (id, step) => {
		setLoading(true);
		const response = await ContractsCreateService.index({
			tmp_contracts_id: id,
			step: step,
		});
		if (response && response.data) {
			setTmpContractCreateData(response.data.data || []);
		}
		setLoading(false);
	};

	// shared logic for all steps
	const handleFormChange = (updatedFormState) => {
		setFormState(updatedFormState);
		formStateRef.current = updatedFormState; // Update the ref whenever formState changes
	};

	useEffect(() => {
		formStateRef.current = formState; // Update the ref whenever formState changes
	}, [formState]);

	const handleResume = async (tmpContractId, step) => {
		setLoading(true);
		try {
			//@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
			const response = await ContractsCreateService.index({
				tmp_contracts_id: tmpContractId,
				step: `step_${step}`,
			});

			// const response = {};
			// response.data = contractsCreateStep1DummyResponse;

			if (response && response.data) {
				setTmpContractId(tmpContractId);
				setStep(step);
				setNextStep(`step_${step + 1}`);
				// setFormState(response.data.data.fields || {});
				// setCanContinue(response.data.can_continue);

				const rest = { ...extraContractCreateData };
				delete rest.action;
				setExtraContractCreateData({
					...rest,
					step: `step_${step}`,
					tmp_contracts_id: tmpContractId,
				});

				setDefinitionContractCreateOverride(
					_.cloneDeep(stepDefinitions[step])
				);

				// Handle step-specific logic here (similar to handleNextStepResponse)
				// For example:
				if (step === 1) {
					// setStep1EnabledLockRequired(
					// 	response.data.data.lock_required
					// );
					// setStep1EnabledLockEnabled(response.data.data.lock_enabled);
					// setStep1EnabledAbpRequired(response.data.data.abp_required);
					// setStep1EnabledAbpEnabled(response.data.data.abp_enabled);

					// // Set form state with the resumed data
					// let localState = {
					// 	lock_enabled: {
					// 		value:
					// 			String(response.data.data.lock_enabled) === "1",
					// 		isValid: true,
					// 	},
					// 	abp_enabled: {
					// 		value:
					// 			String(response.data.data.abp_enabled) === "1",
					// 		isValid: true,
					// 	},
					// 	device_identifier: {
					// 		value:
					// 			response.data.data.device_identifier ||
					// 			response.data.data.device_data.imei ||
					// 			"",
					// 		isValid: true,
					// 	},
					// 	device_model: {
					// 		value:
					// 			`${response.data.data.device_data.make} ${response.data.data.device_data.model}` ||
					// 			"",
					// 		isValid: true,
					// 	},
					// };
					// setFormState(localState);

					// // Update the definition with the resumed values
					// updateContractCreateDefinitionWithValues(
					// 	[
					// 		response.data.data.lock_required,
					// 		response.data.data.lock_enabled,
					// 		response.data.data.abp_required,
					// 		response.data.data.abp_enabled,
					// 	],
					// 	localState,
					// 	_.cloneDeep(contractsCreateStep1Definition)
					// );

					setTriggerFetchStep1(true);
				}

				if (step === 2) {
					// console.log("step 2 response", response.data);
					// // Set form state with the resumed data
					// let localState = {
					// 	first_name: {
					// 		value: response.data.data.personnel.first_name,
					// 		isValid: true,
					// 	},
					// 	last_name: {
					// 		value: response.data.data.personnel.last_name,
					// 		isValid: true,
					// 	},
					// 	phone_number: {
					// 		value:
					// 			"+1" +
					// 			response.data.data.personnel.phone_number,
					// 		isValid: true,
					// 	},
					// 	date_of_birth: {
					// 		value: response.data.data.personnel.date_of_birth,
					// 		isValid: true,
					// 	},
					// 	ssn_last_four: {
					// 		value: response.data.data.personnel.ssn_last_4,
					// 		isValid: true,
					// 	},
					// 	address_1: {
					// 		value: response.data.data.address.address_1,
					// 		isValid: true,
					// 	},
					// 	address_2: {
					// 		value: response.data.data.address.address_2,
					// 		isValid: true,
					// 		readOnly: false,
					// 	},
					// 	city: {
					// 		value: response.data.data.address.city,
					// 		isValid: true,
					// 	},
					// 	state: {
					// 		value: response.data.data.address.state,
					// 		isValid: true,
					// 	},
					// 	zip: {
					// 		value: response.data.data.address.zip,
					// 		isValid: true,
					// 	},
					// };
					// setFormState(localState);

					// setCanContinue(response.data.can_continue);

					// const rest2 = { ...extraContractCreateData };
					// delete rest2.action;
					// setExtraContractCreateData({
					// 	...rest2,
					// 	step: "step_2",
					// 	tmp_contracts_id: response.data.data.tmp_contracts_id,
					// });

					// // Check if address needs verification
					// const addressNeedsVerification =
					// 	response.data.data.address_in_match_usps !== true;

					// // Set visibility based on verification status
					// setStep2IsVerifyAddressVisible(addressNeedsVerification);
					// setStep2IsEmailVerificationVisible(
					// 	!addressNeedsVerification
					// );

					// // Update the definition with the resumed values
					// updateContractCreateDefinitionWithValues(
					// 	[],
					// 	localState,
					// 	_.cloneDeep(contractsCreateStep2Definition)
					// );

					// console.log(
					// 	"!addressNeedsVerification",
					// 	!addressNeedsVerification
					// );
					// if (!addressNeedsVerification) {
					// 	// Set fields to read-only
					// 	step2UpdateFieldsToReadOnly(
					// 		step2ReadOnlyFields,
					// 		localState,
					// 		setDefinitionContractCreateOverride,
					// 		setFormState
					// 	);
					// }

					// // Handle email verification status
					// const emailProvided =
					// 	!!response.data.data.email.email_address;
					// const emailVerified =
					// 	!!response.data.data.email.email_address_code;

					// setStep2IsEmailVerificationDisabled(emailVerified);
					// setStep2IsEmailSent(emailProvided);

					// if (emailVerified) {
					// 	setStep2EmailCode(
					// 		response.data.data.email.email_address_code
					// 	);
					// }

					// // Show email verification field if address is verified
					// setStep2IsEmailVerificationVisible(
					// 	!addressNeedsVerification
					// );

					// // Show newsletter field if email is verified
					// if (emailVerified) {
					// 	updateContractCreateDefinitionWithValues(
					// 		["addNewsletterField"],
					// 		localState,
					// 		_.cloneDeep(contractsCreateStep2Definition)
					// 	);
					// }

					setTriggerFetchStep2(true);
				}

				if (step === 3) {
					setTriggerFetchStep3(true);
				}

				if (step === 4) {
					setTriggerFetchStep4(true);
				}

				if (step === 5) {
					setTriggerFetchStep5(true);
				}

				if (step === 6) {
					// setTriggerFetchStep6(true);
				}

				if (step === 7) {
					// setTriggerFetchStep7(true);
				}

				if (step === 8) {
					// setTriggerFetchStep8(true);
				}

				// Add similar logic for other steps as needed
			}
		} catch (error) {
			console.error("Error resuming contract:", error);
			toast.error("Failed to resume contract. Please try again.");
		}
		setLoading(false);
	};

	const actionHandlers = {
		handleCancel: () => navigate("/dashboard"),
		handleDelete: () => handleDeleteAction(tmpContractId),
		handleBack: async () => {
			if (step > 0) {
				setLoading(true);
				const previousStep = step - 1;
				setStep(previousStep);
				setNextStep("step_" + step);
				setFormState({});
				setCanContinue(true);

				setDefinitionContractCreateOverride(
					_.cloneDeep(stepDefinitions[previousStep])
				);

				if (previousStep === 0) {
					setExtraContractCreateData({
						action: "new",
					});
					setTmpContractId(null);

					setTriggerBackStep0(true);
				} else if (previousStep === 1) {
					setTriggerFetchStep1(true);
				} else if (previousStep === 2) {
					setTriggerFetchStep2(true);
				} else if (previousStep === 3) {
					setTriggerFetchStep3(true);
				} else if (previousStep === 4) {
					setTriggerFetchStep4(true);
				} else if (previousStep === 5) {
					setTriggerFetchStep5(true);
				} else if (previousStep === 6) {
					// setTriggerFetchStep6(true);
				} else if (previousStep === 7) {
					// setTriggerFetchStep7(true);
				} else {
					setTimeout(() => {
						setLoading(false);
					}, 200);
				}
			}
		},
		handleAnotherAction: () => {
			// console.log("Another action triggered");
		},
	};

	useEffect(() => {
		if (triggerBackStep0) {
			// Fetch store options and temporary contracts when navigating back to step 0
			fetchTmpContracts();
			setTriggerBackStep0(false);
		}
	}, [triggerBackStep0, formState]);

	useEffect(() => {
		const fetchStep1Data = async () => {
			if (triggerFetchStep1) {
				try {
					// Make network call to fetch step 1 data
					const response = await ContractsCreateService.index({
						step: "step_1",
						tmp_contracts_id: tmpContractId,
					});

					if (response && response.data) {
						// Populate step 1 state variables from the response
						// setTmpContractId(response.data.data.tmp_contracts_id);
						let localState = {
							lock_enabled: {
								value:
									String(response.data.data.lock_enabled) ===
									"1",
								isValid: true,
							},
							abp_enabled: {
								value:
									String(response.data.data.abp_enabled) ===
									"1",
								isValid: true,
							},
							device_identifier: {
								value:
									response.data.data.device_identifier ||
									response.data.data.device_data.imei ||
									"",
								isValid: true,
							},
							device_model: {
								value:
									`${response.data.data.device_data.make} ${response.data.data.device_data.model}` ||
									"",
								isValid: true,
							},
						};
						setFormState(localState);

						setCanContinue(response.data.can_continue);

						const rest1 = { ...extraContractCreateData };
						delete rest1.action;
						setExtraContractCreateData({
							...rest1,
							step: "step_1",
							tmp_contracts_id: tmpContractId,
							// response.data.data.tmp_contracts_id,
						});

						setStep1EnabledLockRequired(
							String(response.data.data.lock_required)
						);
						setStep1EnabledLockEnabled(
							String(response.data.data.lock_enabled)
						);
						setStep1EnabledAbpRequired(
							String(response.data.data.abp_required)
						);
						setStep1EnabledAbpEnabled(
							String(response.data.data.abp_enabled)
						);

						updateContractCreateDefinitionWithValues(
							[
								response.data.data.lock_required,
								response.data.data.lock_enabled,
								response.data.data.abp_required,
								response.data.data.abp_enabled,
							],
							localState,
							_.cloneDeep(contractsCreateStep1Definition)
						);
					}
				} catch (error) {
					console.error("Error fetching step 1 data:", error);
				} finally {
					setLoading(false);
					setTriggerFetchStep1(false); // Reset the trigger
				}
			}
		};

		fetchStep1Data();
	}, [triggerFetchStep1]);

	useEffect(() => {
		const fetchStep2Data = async () => {
			if (triggerFetchStep2) {
				try {
					// Make network call to fetch step 2 data
					//@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
					const response = await ContractsCreateService.index({
						step: "step_2",
						tmp_contracts_id: tmpContractId,
					});

					// const response = {};
					// response.data = contractsCreateStep2DummyResponse;

					if (response && response.data) {
						// console.log("response.data", response.data);
						// Populate step 2 state variables from the response
						let localState = {
							first_name: {
								value: response.data.data.personnel.first_name,
								isValid: true,
							},
							last_name: {
								value: response.data.data.personnel.last_name,
								isValid: true,
							},
							phone_number: {
								value: response.data.data.personnel.phone_number
									? "+1" +
									  response.data.data.personnel.phone_number
									: "",
								isValid: true,
							},
							date_of_birth: {
								value: response.data.data.personnel
									.date_of_birth,
								isValid: true,
							},
							ssn_last_four: {
								value: response.data.data.personnel.ssn_last_4,
								isValid: true,
							},
							address_1: {
								value: response.data.data.address.address_1,
								isValid: true,
							},
							address_2: {
								value: response.data.data.address.address_2,
								isValid: true,
								readOnly: false,
							},
							city: {
								value: response.data.data.address.city,
								isValid: true,
							},
							state: {
								value: response.data.data.address.state,
								isValid: true,
							},
							zip: {
								value: response.data.data.address.zip,
								isValid: true,
							},
							newsletter_enabled: {
								value: response.data.data.newsletter_enabled,
							},
						};
						setFormState(localState);

						// setCanContinue(response.data.can_continue);

						const rest2 = { ...extraContractCreateData };
						delete rest2.action;
						setExtraContractCreateData({
							...rest2,
							step: "step_2",
							tmp_contracts_id: tmpContractId,
							// response.data.data.tmp_contracts_id,
						});

						// Check if address needs verification
						const addressNeedsVerification =
							response.data.data.address_in_match_usps !== true ||
							(response.data.data.address_in_match_usps ===
								true &&
								response.data.data.address.address_1 === "");

						// Set visibility based on verification status
						setStep2IsVerifyAddressVisible(
							addressNeedsVerification
						);
						setStep2IsEmailVerificationVisible(
							!addressNeedsVerification
						);

						// Update the definition with the resumed values
						updateContractCreateDefinitionWithValues(
							[],
							localState,
							_.cloneDeep(contractsCreateStep2Definition)
						);

						console.log(
							"!addressNeedsVerification",
							!addressNeedsVerification
						);
						if (!addressNeedsVerification) {
							// Set fields to read-only
							step2UpdateFieldsToReadOnly(
								step2ReadOnlyFields,
								localState,
								setDefinitionContractCreateOverride,
								setFormState
							);
						}

						// Handle email verification status
						const emailProvided =
							!!response.data.data.email.email_address;
						const emailVerified =
							!!response.data.data.email.email_address_code;

						setStep2IsEmailVerificationDisabled(emailVerified);
						setStep2IsEmailSent(emailProvided);

						if (emailVerified) {
							setStep2EmailCode(
								response.data.data.email.email_address_code
							);
						}

						// Show email verification field if address is verified
						setStep2IsEmailVerificationVisible(
							!addressNeedsVerification
						);

						// // Show newsletter field if email is verified
						// if (emailVerified) {
						// 	updateContractCreateDefinitionWithValues(
						// 		["addNewsletterField"],
						// 		localState,
						// 		_.cloneDeep(contractsCreateStep2Definition)
						// 	);
						// }
						// Show newsletter field if email is verified
						if (emailVerified) {
							setDefinitionContractCreateOverride(
								(prevDefinition) => {
									const updatedDefinition =
										_.cloneDeep(prevDefinition);
									updateContractCreateDefinitionWithValues(
										["addNewsletterField"],
										localState,
										updatedDefinition
									);
									return updatedDefinition;
								}
							);
						}
					}
				} catch (error) {
					console.error("Error fetching step 2 data:", error);
				} finally {
					setLoading(false);
					setTriggerFetchStep2(false); // Reset the trigger
				}
			}
		};

		fetchStep2Data();
	}, [triggerFetchStep2]);

	useEffect(() => {
		const fetchStep3Data = async () => {
			if (triggerFetchStep3) {
				try {
					// Make network call to fetch step 3 data
					//@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@					// const response = await ContractsCreateService.index({
					const response = await ContractsCreateService.index({
						step: "step_3",
						tmp_contracts_id: tmpContractId,
					});

					// const response = {};
					// response.data = contractsCreateStep3DummyResponse;

					if (response && response.data) {
						// console.log("response.data", response.data);
						// Populate step 3 state variables from the response
						let localState = {
							customer_identification_type_id: {
								value: response.data.data
									.customer_identification_type_id,
								isValid: true,
							},
							customer_identification_state_id: {
								value: response.data.data
									.customer_identification_state_id,
								isValid: true,
							},
							customer_identification_number: {
								value: response.data.data
									.customer_identification_number,
								isValid: true,
							},
						};

						setFormState(localState);

						setCanContinue(response.data.can_continue);

						const rest3 = { ...extraContractCreateData };
						delete rest3.action;
						setExtraContractCreateData({
							...rest3,
							step: "step_3",
							tmp_contracts_id: tmpContractId,
							// response.data.data.tmp_contracts_id,
						});

						// Check if ID verification is required
						const idVerificationRequired =
							response.data.data.store_require_id_verification;

						// Set visibility based on verification status
						setStep3StoreRequireIdVerification(
							idVerificationRequired
						);

						// Set image count and images
						setStep3ImageCount(response.data.data.image_count);
						setStep3Image1(response.data.data.images.image_1);
						setStep3Image2(response.data.data.images.image_2);
						setStep3Image3(response.data.data.images.image_3);

						// Set selector values
						setStep3Selector(response.data.data.selector);

						// Update the definition with the resumed values and selects
						updateContractCreateDefinitionWithValues(
							[
								{
									idType: response.data.data
										.customer_identification_type_id,
								},
								idVerificationRequired
									? "storeRequireIdVerification"
									: null,
							],
							localState,
							_.cloneDeep(contractsCreateStep3Definition)
						);

						// Update select options
						setDefinitionContractCreateOverride(
							(prevDefinition) => {
								const newDefinition = { ...prevDefinition };
								newDefinition.fields.customer_identification_type_id.options =
									response.data.data.selects.customer_identification_type_id;
								newDefinition.fields.customer_identification_state_id.options =
									response.data.data.selects.customer_identification_state_id;
								return newDefinition;
							}
						);

						// Set fields to required if ID verification is required
						if (idVerificationRequired) {
							setDefinitionContractCreateOverride(
								(prevDefinition) => {
									const newDefinition = { ...prevDefinition };
									newDefinition.fields.customer_identification_type_id.required = true;
									newDefinition.fields.customer_identification_state_id.required = true;
									newDefinition.fields.customer_identification_number.required = true;
									return newDefinition;
								}
							);
						}
					}
				} catch (error) {
					console.error("Error fetching step 3 data:", error);
				} finally {
					setLoading(false);
					setTriggerFetchStep3(false); // Reset the trigger
				}
			}
		};

		fetchStep3Data();
	}, [triggerFetchStep3]);

	useEffect(() => {
		const fetchStep4Data = async () => {
			if (triggerFetchStep4) {
				try {
					// Make network call to fetch step 4 data
					//@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
					const response = await ContractsCreateService.index({
						step: "step_4",
						tmp_contracts_id: tmpContractId,
					});

					// const response = {};
					// response.data = contractsCreateStep4DummyResponse;

					if (response && response.data) {
						// console.log("response.data", response.data);
						// Populate step 4 state variables from the response
						let localState = {
							// id_type: {
							// 	value: response.data.data.id_type,
							// 	isValid: true,
							// },
						};

						setFormState(localState);

						setCanContinue(response.data.can_continue);

						const rest4 = { ...extraContractCreateData };
						delete rest4.action;
						setExtraContractCreateData({
							...rest4,
							step: "step_4",
							tmp_contracts_id: tmpContractId,
							// response.data.data.tmp_contracts_id,
						});

						// Update the definition with the resumed values
						updateContractCreateDefinitionWithValues(
							[],
							localState,
							_.cloneDeep(contractsCreateStep4Definition)
						);
					}
				} catch (error) {
					console.error("Error fetching step 4 data:", error);
				} finally {
					setLoading(false);
					setTriggerFetchStep4(false); // Reset the trigger
				}
			}
		};

		fetchStep4Data();
	}, [triggerFetchStep4]);

	useEffect(() => {
		const fetchStep5Data = async () => {
			if (triggerFetchStep5) {
				try {
					// Make network call to fetch step 5 data
					//@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
					const response = await ContractsCreateService.index({
						step: "step_5",
						tmp_contracts_id: tmpContractId,
					});

					// const response = {};
					// response.data = contractsCreateStep5DummyResponse;

					if (response && response.data) {
						// console.log("response.data", response.data);
						// Populate step 5 state variables from the response
						let localState = {
							// id_type: {
							// 	value: response.data.data.id_type,
							// 	isValid: true,
							// },
						};

						setFormState(localState);

						setCanContinue(response.data.can_continue);

						const rest5 = { ...extraContractCreateData };
						delete rest5.action;
						setExtraContractCreateData({
							...rest5,
							step: "step_5",
							tmp_contracts_id: tmpContractId,
							// response.data.data.tmp_contracts_id,
						});

						// Update the definition with the resumed values
						updateContractCreateDefinitionWithValues(
							[],
							localState,
							_.cloneDeep(contractsCreateStep5Definition)
						);
					}
				} catch (error) {
					console.error("Error fetching step 5 data:", error);
				} finally {
					setLoading(false);
					setTriggerFetchStep4(false); // Reset the trigger
				}
			}
		};

		fetchStep5Data();
	}, [triggerFetchStep5]);

	const renderConditionalElement = () => {
		if (step === 0) {
			if (formState.create_or_resume?.value === "resume") {
				return {
					conditionalElementPlaceholder: tmpContracts.length > 0 && (
						<TmpContractsIndexTable
							tmpContracts={tmpContracts}
							onDelete={deleteTmpContractRecord}
							onResume={handleResume}
						/>
					),
				};
			}
		}

		if (step === 2) {
			return {
				conditionalElementPlaceholder1: step2IsVerifyAddressVisible && (
					<>
						<br />
						<div className="flex flex-col items-center justify-center">
							<Button
								variant="contained"
								color="primary"
								onClick={step2HandleAddressSubmit}
								disableElevation
								sx={{ height: "56px" }}
								startIcon={<MdCheck />}
							>
								VERIFY ADDRESS
							</Button>
							<br />
						</div>
					</>
				),
				conditionalElementPlaceholder2:
					step2IsEmailVerificationVisible && (
						<RtoEmailVerificationField
							handleEmailSubmit={step2HandleEmailSubmit}
							isEmailSent={step2IsEmailSent}
							setIsEmailSent={setStep2IsEmailSent}
							handleCodeSubmit={step2HandleCodeSubmit}
							disabled={step2IsEmailVerificationDisabled}
							defaultCode={step2EmailCode}
							setStep2EmailCode={setStep2EmailCode}
						/>
					),
			};
		}

		if (step === 3) {
			return {
				conditionalElementPlaceholder1: (
					<RtoVerificationField
						handleContactSubmit={step3HandleContactSubmit}
						isLinkSent={step3IsLinkSent}
						setIsLinkSent={setStep3IsLinkSent}
						verificationType="email"
						selector={step3Selector}
					/>
				),
				conditionalElementPlaceholder2: (
					<div>
						<div className="text-zinc-800 float-left text-sm pb-7 px-3 pt-3 w-full bg-slate-100">
							<div
								className="items-center flex-wrap justify-start py-4 px-3 text-center flex w-auto m-auto"
								id="div-1"
							>
								<div className="items-center justify-start flex-col flex">
									<div className="w-52 min-h-[9.8rem] max-w-full my-1 mx-3 border-1 bg-slate-200/[0.5] border-slate-300 border-solid rounded-md overflow-hidden">
										{step3Image1 && (
											<img
												src={`data:image/jpeg;base64,${step3Image1}`}
												alt="I.D. Front"
												className="w-full h-full object-cover rounded"
											/>
										)}
									</div>

									<div className="text-slate-500/[0.45] font-normal">
										I.D. Front
									</div>

									{step3Image1 && (
										<Button
											onClick={() =>
												step3RecaptureImage(
													"recapture_image_1"
												)
											}
											variant="contained"
											disableElevation
											sx={{
												backgroundColor: "#e11d48",
												fontWeight: 400,
												color: "white",
												cursor: "pointer",
												padding: "0 12px",
												height: "20px",
												marginTop: "8px",
												borderRadius: "12px",
												"&:hover": {
													backgroundColor: "#be123c",
												},
											}}
										>
											Recapture
										</Button>
									)}
								</div>

								<div className="items-center flex-col flex">
									<div className="w-52 min-h-[9.8rem] max-w-full my-1 mx-3 border-1 bg-slate-200/[0.5] border-slate-300 border-solid rounded-md overflow-hidden">
										{step3Image2 && (
											<img
												src={`data:image/jpeg;base64,${step3Image2}`}
												alt="I.D. Back"
												className="w-full h-full object-cover rounded"
											/>
										)}
									</div>

									<div className="text-slate-500/[0.45] font-normal">
										I.D. Back
									</div>

									{step3Image2 && (
										<Button
											onClick={() =>
												step3RecaptureImage(
													"recapture_image_2"
												)
											}
											variant="contained"
											disableElevation
											sx={{
												backgroundColor: "#e11d48",
												fontWeight: 400,
												color: "white",
												cursor: "pointer",
												padding: "0 12px",
												height: "20px",
												marginTop: "8px",
												borderRadius: "12px",
												"&:hover": {
													backgroundColor: "#be123c",
												},
											}}
										>
											Recapture
										</Button>
									)}
								</div>

								<div className="items-center flex-col flex">
									<div className="w-52 min-h-[9.8rem] max-w-full my-1 mx-3 border-1 bg-slate-200/[0.5] border-slate-300 border-solid rounded-md overflow-hidden">
										{step3Image3 && (
											<img
												src={`data:image/jpeg;base64,${step3Image3}`}
												alt="Customer"
												className="w-full h-full object-cover rounded"
											/>
										)}
									</div>

									<div className="text-slate-500/[0.45] font-normal">
										Customer
									</div>

									{step3Image3 && (
										<Button
											onClick={() =>
												step3RecaptureImage(
													"recapture_image_3"
												)
											}
											variant="contained"
											disableElevation
											sx={{
												backgroundColor: "#e11d48",
												fontWeight: 400,
												color: "white",
												cursor: "pointer",
												padding: "0 12px",
												height: "20px",
												marginTop: "8px",
												borderRadius: "12px",
												"&:hover": {
													backgroundColor: "#be123c",
												},
											}}
										>
											Recapture
										</Button>
									)}
								</div>
							</div>
						</div>
					</div>
				),
			};
		}

		if (step === 4) {
			return {
				conditionalElementPlaceholder: (
					<PaymentTermsForm
						loading={loading}
						setLoading={setLoading}
						availablePlans={tmpContractCreateData?.available_plans}
						planSelectedValue={step4SelectedPlan}
						leaseTypeId={
							tmpContractCreateData?.fields?.lease_type_id
						}
						planSelectedDay={
							tmpContractCreateData?.fields?.lease_day
						}
						planSelectDays={
							tmpContractCreateData?.selects?.lease_day || []
						}
						planSelectedDate={
							tmpContractCreateData?.fields?.lease_date
						}
						planSelectDates={
							tmpContractCreateData?.selects?.lease_date || []
						}
						paymentTermOnChange={step4PaymentTermOnChange}
						PlanDayOnChange={step4PlanDayOnChange}
						PlanDateOnChange={step4PlanDateOnChange}
						// viewOnly={viewOnly}
					/>
				),
			};
		}

		if (step === 5) {
			const step5DummyData = {
				balance_after_payment: "430.50",
				invoices: {
					data: {
						invoice_count: "7",
						invoices: [
							{
								date: "2024-07-23",
								net: "61.50",
								tax_rate: "6.00",
								tax: "3.69",
								fee: "0.00",
								total: "65.19",
								running_balance: "61.50",
							},
							{
								date: "2024-08-23",
								net: "61.50",
								tax_rate: "6.00",
								tax: "3.69",
								fee: "0.00",
								total: "65.19",
								running_balance: "61.50",
							},
							{
								date: "2024-09-23",
								net: "61.50",
								tax_rate: "6.00",
								tax: "3.69",
								fee: "0.00",
								total: "65.19",
								running_balance: "61.50",
							},
							{
								date: "2024-10-23",
								net: "61.50",
								tax_rate: "6.00",
								tax: "3.69",
								fee: "0.00",
								total: "65.19",
								running_balance: "61.50",
							},
							{
								date: "2024-11-23",
								net: "61.50",
								tax_rate: "6.00",
								tax: "3.69",
								fee: "0.00",
								total: "65.19",
								running_balance: "61.50",
							},
							{
								date: "2024-12-23",
								net: "61.50",
								tax_rate: "6.00",
								tax: "3.69",
								fee: "0.00",
								total: "65.19",
								running_balance: "61.50",
							},
							{
								date: "2025-01-23",
								net: "61.50",
								tax_rate: "6.00",
								tax: "3.69",
								fee: "0.00",
								total: "65.19",
								running_balance: "123.00",
							},
						],
					},
				},
			};

			return {
				conditionalElementPlaceholder: (
					<ContractSummary paymentPlans={step5DummyData} />
				),
			};
		}

		if (step === 6) {
			const step6DummyData = {
				payment_methods: {
					CC9256: {
						id: 9256,
						name: "CC9256",
						payment_name: "VISA xxxx xxxx xxxx 0919 Exp 06/27",
						integration: "CC",
						profile_id: "19215101388929974508",
						acct_id: "1",
						token: "9417647321810919",
						accountholder_name: "NAYELI SALAZAR",
						account_type: "VISA",
						cc_last_four: "0919",
						cc_expiration: "0627",
						default_account: "Y",
						tokenizer_url: null,
					},
					CASH: {
						id: null,
						payment_name: "Cash Payment",
						integration: "CASH",
						profile_id: null,
						acct_id: null,
						token: null,
						accountholder_name: null,
						account_type: null,
						cc_last_four: null,
						cc_expiration: null,
						default_account: null,
						tokenizer_url: null,
					},
					CC: {
						id: null,
						payment_name: "New Credit Card",
						integration: "CC",
						profile_id: null,
						acct_id: null,
						token: null,
						accountholder_name: null,
						account_type: null,
						cc_last_four: null,
						cc_expiration: null,
						default_account: null,
						tokenizer_url:
							"https://fts.cardconnect.com/itoke/ajax-tokenizer.html",
					},
				},
			};

			const setIsSubmitDisabled = () => {
				console.log("setIsSubmitDisabled");
			};

			const setAddCardInfo = () => {
				console.log("setAddCardInfo");
			};

			return {
				conditionalElementPlaceholder1: (
					<SelectPaymentMethodsForm
						paymentMethods={step6DummyData.payment_methods}
					/>
				),
				conditionalElementPlaceholder2: (
					<CashPaymentBalanceCalculatorForm
						paymentAmount={300}
						setIsSubmitDisabled={setIsSubmitDisabled}
					/>
				),
				conditionalElementPlaceholder3: (
					<CreditCardAddForm
						loading={loading}
						setLoading={setLoading}
						//@todo : replace tokenizerUrl with actual URL from API
						tokenizerUrl="https://fts.cardconnect.com/itoke/ajax-tokenizer.html"
						setIsSubmitDisabled={setIsSubmitDisabled}
						addCardInfo={creditCardInfo}
						setAddCardInfo={setCreditCardInfo}
					/>
				),
			};
		}

		if (step === 7) {
			const step7DummyData = {
				payment_methods: {
					CC: {
						id: null,
						payment_name: "New Credit Card",
						integration: "CC",
						profile_id: null,
						acct_id: null,
						token: null,
						accountholder_name: null,
						account_type: null,
						cc_last_four: null,
						cc_expiration: null,
						default_account: null,
						tokenizer_url:
							"https://fts.cardconnect.com/itoke/ajax-tokenizer.html",
					},
				},
			};

			const setIsSubmitDisabled = () => {
				console.log("setIsSubmitDisabled");
			};

			const setAddCardInfo = () => {
				console.log("setAddCardInfo");
			};

			return {
				conditionalElementPlaceholder1: (
					<>
						<div className="text-emerald-500 border-2 rounded-md text-sm p-2 text-center border-emerald-200 border-solid bg-emerald-100">
							ABP is neither required nor enabled for this
							contract, but we encourage you to enable it for the
							customer's convenience.
						</div>
						<br />
						<hr />
						<div className="flex justify-center">
							<span className="text-cyan-800">
								Please select Credit / Debit Card for ABP
							</span>
						</div>

						<br />
					</>
				),
				conditionalElementPlaceholder2: (
					<SelectPaymentMethodsForm
						paymentMethods={step7DummyData.payment_methods}
					/>
				),
				conditionalElementPlaceholder3: (
					<>
						<br />
						<div className="text-zinc-800 text-sm rounded-xl p-3 bg-slate-200">
							<div className="col-12">
								<div className="text-center text-red-600">
									<h4>
										Authorization with immediate void for
										ABP card validation
									</h4>
								</div>

								<div className="flex justify-between">
									<div className="px-5 text-left">
										<b>Authorization Amount</b>
									</div>

									<div className="px-5 text-right">
										<b>$1.00</b>
									</div>
								</div>
							</div>
						</div>
					</>
				),
				conditionalElementPlaceholder4: (
					<CreditCardAddForm
						loading={loading}
						setLoading={setLoading}
						tokenizerUrl="https://fts.cardconnect.com/itoke/ajax-tokenizer.html"
						setIsSubmitDisabled={setIsSubmitDisabled}
						addCardInfo={console.log("addCardInfo")}
						setAddCardInfo={setAddCardInfo}
					/>
				),
			};
		}

		if (step === 8) {
			return {
				conditionalElementPlaceholder1: (
					<div className="flex flex-col items-center justify-center">
						<div className="col-md-10">
							<div className="row">
								<div className="col-md-1">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										xmlnsXlink="http://www.w3.org/1999/xlink"
										version="1.0"
										width="2em"
										height="2em"
										viewBox="0 0 128 128"
										xmlSpace="preserve"
									>
										<g>
											<path
												d="M75.4 126.63a11.43 11.43 0 0 1-2.1-22.65 40.9 40.9 0 0 0 30.5-30.6 11.4 11.4 0 1 1 22.27 4.87h.02a63.77 63.77 0 0 1-47.8 48.05v-.02a11.38 11.38 0 0 1-2.93.37z"
												fill="green"
											/>
											<animateTransform
												attributeName="transform"
												type="rotate"
												from="0 64 64"
												to="360 64 64"
												dur="1000ms"
												repeatCount="indefinite"
											></animateTransform>
										</g>
									</svg>
								</div>
								<div
									className="col-md-11"
									style={{
										verticalAlign: "center",
										textAlign: "center",
									}}
								>
									<div className="rto_paragraph">
										<b>
											Please Wait.... We're Processing
											Your Contract....
										</b>
									</div>
								</div>
							</div>
						</div>
						<br />
						<div className="col-md-10">
							<div className="row">
								<div className="col-md-1">
									<div
										className="vcenter"
										style={{ width: "2.5em" }}
										id="app_div_enrollment_check"
									>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="2em"
											height="2em"
											fill="green"
											className="bi bi-check-circle"
											viewBox="0 0 16 16"
										>
											<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
											<path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
										</svg>
									</div>
								</div>
								<div className="col-md-11 vcenter">
									<div className="row">
										<div className="col-md-12 text-center">
											<button
												className="btn btn-primary"
												onclick="submit_step_8_new(); return false;"
												style={{ width: "50%" }}
											>
												Continue
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				),
			};
		}

		return {};
	};

	const extractFormData = (passedFormState, definition) => {
		return Object.fromEntries(
			Object.entries(passedFormState).map(([fieldName, field]) => {
				const myPrefix = definition.fields[fieldName]?.prefix;
				if (myPrefix !== undefined) {
					if (field.value?.length > 0) {
						return [fieldName, field.value.replace(myPrefix, "")];
					} else {
						return [fieldName, field.value];
					}
				} else {
					return [fieldName, field.value];
				}
			})
		);
	};

	const handleNextStepResponse = async (response) => {
		setLoading(true);
		// console.log("step", step);
		// console.log("handleNextStepResponse", response);
		if (response && response.data) {
			switch (step) {
				case 4:
					if (response.data.can_continue === true) {
						let continue_response =
							await ContractsCreateService.index({
								step: "step_4",
								tmp_contracts_id: tmpContractId,
								action: "continue",
							});

						setNextStep("step_6");
						setStep(5);

						setTriggerFetchStep5(true);
					}
					break;
				case 3:
					if (response.data.max_step === "step_6") {
						let continue_response =
							await ContractsCreateService.index({
								step: "step_3",
								tmp_contracts_id: tmpContractId,
								action: "continue",
							});

						setNextStep("step_5");
						setStep(4);

						// setFormState({});

						// setStep4TriggerFormUpdate(true);

						// setExtraContractCreateData({
						// 	...extraContractCreateData,
						// 	step: "step_4",
						// });

						setTriggerFetchStep4(true);
					}

					break;
				case 2:
					setCanContinue(response.data.can_continue);
					if (response.data.can_continue === true) {
						let continue_response =
							await ContractsCreateService.index({
								step: "step_2",
								tmp_contracts_id: tmpContractId,
								action: "continue",
							});

						setNextStep("step_4");
						setStep(3);

						// setDefinitionContractCreateOverride(
						// 	_.cloneDeep(contractsCreateStep3Definition)
						// );

						// setExtraContractCreateData({
						// 	...extraContractCreateData,
						// 	step: "step_3",
						// });

						// setLoading(true);
						// const response = await ContractsCreateService.index({
						// 	...extraContractCreateData,
						// 	step: "step_3",
						// });

						// // Set the selector values
						// setStep3Selector(response.data.data.selector);

						// // Enable continue button if store_require_id_verification is 0
						// setStep3StoreRequireIdVerification(
						// 	response.data.data.store_require_id_verification
						// );
						// if (
						// 	response.data.data.store_require_id_verification ===
						// 	0
						// ) {
						// 	setCanContinue(true);
						// 	setExtraContractCreateData({
						// 		...extraContractCreateData,
						// 		step: "step_4",
						// 	});
						// 	setSendEmptyContractCreateData(true);
						// } else {
						// 	setCanContinue(false);
						// 	setExtraContractCreateData({
						// 		...extraContractCreateData,
						// 		step: "step_3",
						// 	});
						// 	setStep3TriggerFormUpdate(true);
						// }

						setTriggerFetchStep3(true);
					}

					break;
				case 1:
					setCanContinue(response.data.can_continue);

					if (response.data.can_continue === true) {
						// Reset form state and read-only status
						// step2ResetFormState();

						setNextStep("step_3");
						setStep(2);

						// const rest2 = { ...extraContractCreateData };
						// delete rest2.action;
						// setExtraContractCreateData({
						// 	...rest2,
						// 	step: "step_2",
						// 	tmp_contracts_id:
						// 		response.data.data.tmp_contracts_id, // Add tmp_contracts_id to extraContractCreateData
						// });
						// setDefinitionContractCreateOverride(
						// 	_.cloneDeep(contractsCreateStep2Definition)
						// );

						// // reset conditional visibility
						// setStep2IsVerifyAddressVisible(true);
						// setStep2IsEmailVerificationVisible(false);

						// // make sure continue button is disabled
						// setCanContinue(false);

						let continue_response =
							await ContractsCreateService.index({
								step: "step_1",
								tmp_contracts_id: tmpContractId,
								action: "continue",
							});

						setTriggerFetchStep2(true);
					} else {
						const rest2 = { ...extraContractCreateData };
						delete rest2.action;
						setExtraContractCreateData({
							...rest2,
							step: "step_2",
							tmp_contracts_id:
								response.data.data.tmp_contracts_id, // Add tmp_contracts_id to extraContractCreateData
						});
						setDefinitionContractCreateOverride(
							_.cloneDeep(contractsCreateStep1Definition)
						);
					}

					break;
				case 0:
					setNextStep("step_2");
					setStep(1);

					setTmpContractId(response.data.data.tmp_contracts_id);
					setFormState((prevState) => ({
						...prevState,
						// Reset lock_enabled when moving to the next step
						lock_enabled: {
							value: response.data.data.lock_enabled === 1,
							isValid: true,
						},
					}));

					setCanContinue(response.data.can_continue);

					const rest1 = { ...extraContractCreateData };
					delete rest1.action;
					setExtraContractCreateData({
						...rest1,
						step: "step_1",
						tmp_contracts_id: response.data.data.tmp_contracts_id, // Add tmp_contracts_id to extraContractCreateData
					});

					setStep1EnabledLockRequired(
						response.data.data.lock_required
					);
					setStep1EnabledLockEnabled(response.data.data.lock_enabled);
					setStep1EnabledAbpRequired(response.data.data.abp_required);
					setStep1EnabledAbpEnabled(response.data.data.abp_enabled);

					updateContractCreateDefinitionWithValues(
						[
							response.data.data.lock_required,
							response.data.data.lock_enabled,
							response.data.data.abp_required,
							response.data.data.abp_enabled,
						],
						formState,
						_.cloneDeep(contractsCreateStep1Definition)
					);
					break;
				default:
					setTmpContracts(response.data.data.tmp_contracts || []);
					setDefinitionContractCreateOverride(
						_.cloneDeep(contractsCreateStep0Definition)
					);
					setCanContinue(true);
					break;
			}
		}
		setTimeout(() => {
			setLoading(false);
		}, 200);
	};

	const handleDeleteAction = (tmpContractId) => {
		confirmAlert({
			title: "Are you sure?",
			message:
				"Are you sure you want to delete this temporary contract? This action cannot be reversed.",
			buttons: [
				{
					label: "Yes",
					onClick: () => {
						deleteTmpContractRecord(tmpContractId);
					},
				},
				{
					label: "No",
				},
			],
		});
	};

	const deleteTmpContractRecord = async (tmpContractId) => {
		setLoading(true);
		const response = await ContractsCreateService.delete(tmpContractId);
		if (response && response.data) {
			if (response.data.isOk) {
				toast.success(response.data.msg, {
					position: "top-center",
					autoClose: 1000,
				});

				const updatedTmpContracts =
					response.data.data.tmp_contracts || [];
				setTmpContracts(updatedTmpContracts);
				setStep(0);
				setNextStep("step_1");
				setTmpContracts(response.data.data.tmp_contracts || []);

				// set options for struct_store_id
				const options = response.data.data.selects.struct_store_id.map(
					(store) => ({
						id: store.id,
						name: store.name,
					})
				);

				setDefinitionContractCreateOverride(() => {
					const newDefinition = {
						..._.cloneDeep(contractsCreateStep0Definition),
					};
					if (newDefinition.fields.struct_store_id) {
						newDefinition.fields.struct_store_id.options = options;
						newDefinition.fields.struct_store_id.value =
							formStateRef.current?.struct_store_id?.value ||
							selectedStore;
						newDefinition.fields.create_or_resume.value =
							formStateRef.current?.create_or_resume?.value;
					}
					// console.log("newDefinition", newDefinition);
					return newDefinition;
				});

				setCanContinue(true);
				// Remove tmp_contracts_id from extraContractCreateData and reset action to "new"
				setExtraContractCreateData((prevData) => {
					const { tmp_contracts_id, ...rest } = prevData;
					return { ...rest, action: "new", step: "step_0" };
				});
			} else {
				toast.error(response.data.msg, {
					position: "top-center",
					autoClose: 1000,
				});
			}
		}
		setLoading(false);
	};

	const updateContractCreateDefinitionWithValues = (
		data,
		formState,
		baseDefinition
	) => {
		// console.log(
		// 	"updateContractCreateDefinitionWithValues",
		// 	data,
		// 	formState,
		// 	baseDefinition,
		// 	step
		// );
		const updatedDefinition = _.cloneDeep(baseDefinition);

		// step 1 specific logic
		if (step === 1) {
			// Recursively remove existing 'device_identifier' and 'device_model' fields from layout
			updatedDefinition.layout = updatedDefinition.layout.map(
				(layoutItem) => {
					if (layoutItem.items) {
						layoutItem.items = removeFields(layoutItem.items, [
							"device_identifier",
							"device_model",
						]);
					}
					return layoutItem;
				}
			);

			const lockRequiredComputed = data[0] === 1 || data[0] === "1";
			const lockEnabledComputed = data[1] === 1 || data[1] === "1";
			const abpRequiredComputed = data[2] === 1 || data[2] === "1";
			const abpEnabledComputed = data[3] === 1 || data[3] === "1";

			// Ensure the lock_enabled value is preserved in the new definition
			updatedDefinition.fields.lock_enabled = {
				...updatedDefinition.fields.lock_enabled,
				value: lockEnabledComputed, // Set value based on lockEnabled
				readOnly: lockRequiredComputed, // Set readOnly based on lockRequired
			};
			updatedDefinition.fields.abp_enabled = {
				...updatedDefinition.fields.abp_enabled,
				value: abpEnabledComputed, // Set value based on abpEnabled
				readOnly: abpRequiredComputed, // Set readOnly based on abpRequired
			};

			// Ensure the tmp_contracts_id value is set in the new definition
			if (updatedDefinition.fields && formState.tmp_contracts_id) {
				updatedDefinition.fields.tmp_contracts_id = {
					...updatedDefinition.fields.tmp_contracts_id,
					value: formState.tmp_contracts_id.value,
				};
			}

			if (formState.lock_enabled?.value === true) {
				// Example of adding fields back, adjust according to where they need to be added
				const newFields = [
					{
						type: "div",
						class: "",
						items: [
							{
								type: "div",
								class: "row mb-3",
								items: [
									{
										type: "input",
										name: "device_identifier",
										class: "col-md-12",
										label: "IMEI / Serial",
										value:
											formState.device_identifier
												?.value || "",
										required: true,
										readOnly: false,
										maxLength: 20,
										upperCase: true,
										onBlur: step1HandleImeiBlur,
									},
								],
							},
						],
					},
					{
						type: "div",
						class: "",
						items: [
							{
								type: "div",
								class: "row mb-3",
								items: [
									{
										type: "input",
										name: "device_model",
										class: "col-md-12",
										label: "Device Manufacturer / Model",
										value:
											formState.device_model?.value ||
											"N/A",
										required: true,
										readOnly: true,
										upperCase: true,
									},
								],
							},
						],
					},
				];

				const result = findIndexAndParentRecursive(
					updatedDefinition.layout[0].items,
					"lock_enabled"
				);

				if (result !== null) {
					result.parent.splice(result.index + 1, 0, ...newFields);
				} else {
					// Fallback if lock_enabled is not found
					updatedDefinition.layout[0].items.push(...newFields);
				}

				// Update fields object
				updatedDefinition.fields = {
					...updatedDefinition.fields,
					device_identifier: {
						type: "input",
						label: "IMEI / Serial",
						// value: "",
						value: formState.device_identifier?.value || "",
						required: true,
						readOnly: false,
						upperCase: true,
						maxLength: 20,
					},
					device_model: {
						type: "input",
						label: "Device Manufacturer / Model",
						// value: "N/A",
						value: formState.device_model?.value || "N/A",
						required: true,
						readOnly: true,
						upperCase: true,
					},
				};
			}
		}

		// step 2 specific logic
		if (step === 2) {
			const addNewsletterField = data.includes("addNewsletterField");

			if (addNewsletterField) {
				const newsletterField = {
					type: "div",
					class: "col-12",
					items: [
						{
							type: "div",
							class: "row mb-3",
							items: [
								{
									type: "checkbox",
									name: "newsletter_enabled",
									class: "col-md-12",
								},
							],
						},
					],
				};

				const result = findIndexAndParentRecursive(
					updatedDefinition.layout[0].items,
					"conditionalElementPlaceholder2"
				);

				if (result !== null) {
					result.parent.splice(result.index + 1, 0, newsletterField);
				} else {
					// Fallback if conditionalElementPlaceholder2 is not found
					updatedDefinition.layout[0].items.push(newsletterField);
				}

				// Update fields object
				updatedDefinition.fields = {
					...updatedDefinition.fields,
					newsletter_enabled: {
						type: "checkbox",
						label: `Yes, sign me up! By checking this box, I agree and I want to receive news, offers, payment reminders, and account alerts from RTO Mobile & ${SessionService.getUserStoreName()}, including by email, phone, sms, and mail to the contact information I am submitting. I consent to RTO Mobile & ${SessionService.getUserStoreName()}, its affiliates and service provides process my personal data for these purposes as described in the Privacy Policy. I understand that I can withdraw my consent at any time.`,
						value: false,
						required: true,
						readOnly: false,
						upperCase: false,
					},
				};
			}

			// Preserve existing form field values
			Object.keys(formState).forEach((key) => {
				if (updatedDefinition.fields[key]) {
					updatedDefinition.fields[key].value = formState[key].value;
				}
			});
		}

		// step 3 specific logic
		if (step === 3) {
			const storeRequireIdVerification = data.includes(
				"storeRequireIdVerification"
			);

			if (storeRequireIdVerification) {
				// set customer_identification_type_id and customer_identification_number fields to required
				if (updatedDefinition.fields.customer_identification_type_id) {
					updatedDefinition.fields.customer_identification_type_id.required = true;
				}
				if (updatedDefinition.fields.customer_identification_number) {
					updatedDefinition.fields.customer_identification_number.required = true;
				}
			}

			// Add customer_identification_state_id field if customer_identification_type_id is 1 or 2
			// Extract idType from data array
			const idTypeValue = data.find(
				(item) => item.idType !== undefined
			)?.idType;
			if (idTypeValue === "1" || idTypeValue === "2") {
				// Update layout object
				updatedDefinition.layout = [
					{
						type: "div",
						class: "row",
						items: [
							{
								type: "div",
								class: "col-10 offset-lg-1",
								items: [
									{
										type: "div",
										class: "row mb-3",
										items: [
											{
												type: "typography",
												name: "step_title",
												class: "col-md-12",
											},
										],
									},
									{
										type: "div",
										class: "row mb-3",
										items: [
											{
												type: "select",
												name: "customer_identification_type_id",
												class: "col-md-6",
											},
											{
												type: "select",
												name: "customer_identification_state_id",
												class: "col-md-3",
											},
											{
												type: "input",
												name: "customer_identification_number",
												class: "col-md-3",
											},
										],
									},
									{
										type: "div",
										class: "row mb-3",
										items: [
											{
												type: "typography",
												text: "How would you like to receive a link to upload images of the customers ID?",
												variant: "p",
												className:
													"text-center py-3 font-light font-sans",
												class: "col-md-12",
											},
										],
									},
									{
										type: "div",
										name: "conditionalElementPlaceholder1",
									},
									{
										type: "div",
										name: "conditionalElementPlaceholder2",
									},
								],
							},
						],
					},
				];

				// Update fields object
				updatedDefinition.fields = {
					...updatedDefinition.fields,
					customer_identification_state_id: {
						type: "select",
						label: "State",
						options: stateOptions,
						required: true,
						uppercase: true,
						style: {
							textTransform: "uppercase",
						},
					},
				};
			} else if (idTypeValue === "" && !storeRequireIdVerification) {
				// Reset fields if customer_identification_type_id is unselected and store_require_id_verification is 0
				if (updatedDefinition.fields.customer_identification_type_id) {
					updatedDefinition.fields.customer_identification_type_id.value =
						"";
					updatedDefinition.fields.customer_identification_type_id.required = false;
				}
				if (updatedDefinition.fields.customer_identification_state_id) {
					updatedDefinition.fields.customer_identification_state_id.value =
						"";
					updatedDefinition.fields.customer_identification_state_id.required = false;
				}
				if (updatedDefinition.fields.customer_identification_number) {
					updatedDefinition.fields.customer_identification_number.value =
						"";
					updatedDefinition.fields.customer_identification_number.required = false;
				}
			}

			// Preserve existing form field values
			Object.keys(formState).forEach((key) => {
				if (updatedDefinition.fields[key]) {
					// Reset customer_identification_number value if customer_identification_type_id is unselected and store_require_id_verification is 0
					if (
						key === "customer_identification_number" &&
						idTypeValue === "" &&
						!storeRequireIdVerification
					) {
						updatedDefinition.fields[key].value = "";
					} else {
						updatedDefinition.fields[key].value =
							formState[key].value;
					}
				}
			});

			// Set fields to required if customer_identification_type_id is defined
			if (idTypeValue !== undefined && idTypeValue !== "") {
				if (updatedDefinition.fields.customer_identification_type_id) {
					updatedDefinition.fields.customer_identification_type_id.required = true;
				}
				if (updatedDefinition.fields.customer_identification_state_id) {
					updatedDefinition.fields.customer_identification_state_id.required = true;
				}
				if (updatedDefinition.fields.customer_identification_number) {
					updatedDefinition.fields.customer_identification_number.required = true;
				}
			}
		}

		// step 4 specific logic
		if (step === 4) {
			// Update fields object

			let retailValueCaption = "";
			if (data[0]?.retail_value_caption) {
				retailValueCaption = data[0]?.retail_value_caption?.text;
			} else {
				retailValueCaption = `Retail max value: $${tmpContractCreateData?.store_limits?.contract_max_value}`;
			}

			updatedDefinition.fields = {
				...updatedDefinition.fields,
				payoff_early_policy_id: {
					type: "select",
					label: "Early Payoff Policy",
					value: "",
					required: false,
					readOnly: false,
					upperCase: true,
					style: {
						textTransform: "uppercase",
					},
					options: [
						{ id: "", name: "No Early Payoff Policy Selected..." },
						...(data[0]?.selects?.payoff_early_policy_id || []),
					],
				},
				device_condition_id: {
					type: "select",
					label: "Select Merchandise Condition",
					value: "",
					required: true,
					readOnly: false,
					upperCase: true,
					style: {
						textTransform: "uppercase",
					},
					options: data[0]?.selects?.device_condition_id,
				},
				merchandise_description: {
					type: "input",
					label: "Merchandise Description",
					value: data[0]?.fields?.merchandise_description,
					required: true,
					readOnly: false,
					upperCase: true,
					style: {
						textTransform: "uppercase",
					},
				},
				retail_value: {
					type: "number",
					label: "Retail Value",
					value: tmpContractCreateData?.fields?.retail_value,
					prefix: "$",
					required: true,
					readOnly: false,
					decimalLimit: 2,
					integerOnly: false,
					maxValue: parseFloat(
						data[0]?.store_limits?.contract_max_value.replace(
							/,/g,
							""
						)
					),
					minValue: "100",
					onBlur: step4HandleRetailValueBlur,
					//@todo: Add onblur event
				},
				retail_value_caption: {
					type: "typography",
					text: retailValueCaption,
					variant: "caption",
					className:
						"text-center py-2 text-cyan-800 font-light font-sans",
				},
				down_payment_amount: {
					type: "number",
					label: `${data[0]?.store_limits?.down_payment?.actual}% Down Payment`,
					value: data[0]?.fields?.down_payment_amount,
					prefix: "$",
					required: true,
					readOnly: false,
					decimalLimit: 2,
					integerOnly: false,
					maxValue: parseFloat(
						data[0]?.store_limits?.down_payment_amounts?.max.replace(
							/,/g,
							""
						)
					),
					minValue: parseFloat(
						data[0]?.store_limits?.down_payment_amounts?.min.replace(
							/,/g,
							""
						)
					),
					onBlur: step4HandleRetailValueBlur,
				},
				down_payment_amount_caption: {
					type: "typography",
					text: `$${tmpContractCreateData?.store_limits?.down_payment_amounts?.min} min ${tmpContractCreateData?.store_limits?.down_payment?.min}% |
						$${tmpContractCreateData?.store_limits?.down_payment_amounts?.max} max ${tmpContractCreateData?.store_limits?.down_payment?.max}% |
						$${tmpContractCreateData?.store_limits?.down_payment_amounts?.default} default ${tmpContractCreateData?.store_limits?.down_payment?.default}%`,
					variant: "caption",
					className:
						"text-center py-2 text-cyan-800 font-light font-sans",
				},
				rental_factor: {
					type: "number",
					label: "Rental Factor",
					value: data[0]?.fields?.rental_factor,
					required: true,
					readOnly: false,
					decimalLimit: 2,
					integerOnly: false,
					maxValue: parseFloat(
						data[0]?.store_limits?.finance?.max.replace(/,/g, "")
					),
					minValue: parseFloat(
						data[0]?.store_limits?.finance?.min.replace(/,/g, "")
					),
					onBlur: step4HandleRetailValueBlur,
				},
				rental_factor_caption: {
					type: "typography",
					text: `min ${tmpContractCreateData?.store_limits?.finance?.min} |
						max ${tmpContractCreateData?.store_limits?.finance?.max} |
						default ${tmpContractCreateData?.store_limits?.finance?.default}`,
					variant: "caption",
					className:
						"text-center py-2 text-cyan-800 font-light font-sans",
				},
				total_contract_value: {
					type: "number",
					label: "Total Contract Value",
					value: tmpContractCreateData?.fields?.total_contract_value,
					prefix: "$",
					required: false,
					readOnly: true,
					decimalLimit: 2,
					integerOnly: false,
				},
			};
			// Preserve existing form field values
			Object.keys(formState).forEach((key) => {
				if (updatedDefinition.fields[key]) {
					updatedDefinition.fields[key].value = formState[key].value;
				}
			});
		}

		// console.log("updatedDefinition final", updatedDefinition);

		// Update the state with the new definition
		setDefinitionContractCreateOverride(updatedDefinition);
	};

	// step 0 logic
	useEffect(() => {
		fetchTmpContracts();
	}, []);

	const fetchTmpContracts = async () => {
		setLoading(true);
		const response = await ContractsCreateService.index({
			// initial call without any params
		});
		if (response && response.data) {
			setNextStep("step_1");
			setTmpContracts(response.data.data.tmp_contracts || []);
			setExtraContractCreateData({
				...extraContractCreateData,
				step: response.data.step,
			});
			// Set options for struct_store_id
			const options = response.data.data.selects.struct_store_id.map(
				(store) => ({
					id: store.id,
					name: store.name,
				})
			);

			setDefinitionContractCreateOverride((prevDefinition) => {
				const newDefinition = { ...prevDefinition };
				if (newDefinition.fields.struct_store_id) {
					newDefinition.fields.struct_store_id.options = options;
					newDefinition.fields.struct_store_id.value = selectedStore;
				}
				return newDefinition;
			});
		}
		setLoading(false);
	};

	const removeFields = (items, fieldNames) => {
		return items
			.map((item) => {
				// Recursively filter nested items if they exist
				if (item.items) {
					item.items = removeFields(item.items, fieldNames);
				}
				return item;
			})
			.filter((item) => {
				// Keep the item if it's not one of the specified fields to remove
				if (!fieldNames.includes(item.name)) {
					// Also keep it if it's a container that still has items
					if (item.type === "div") {
						return item.items && item.items.length > 0;
					}
					return true;
				}
				// Otherwise, filter it out
				return false;
			});
	};

	const findIndexAndParentRecursive = (
		items,
		fieldName,
		parentContainer = null,
		grandParentContainer = null
	) => {
		for (let i = 0; i < items.length; i++) {
			if (items[i].name === fieldName) {
				// Return the grandparent container's items array, if available, otherwise fallback to parent or current items
				return {
					parent: grandParentContainer || parentContainer || items,
					index: i,
				};
			}
			if (items[i].items) {
				// Update the lineage of containers: current item's items become the new parent, current parent becomes the new grandparent
				const result = findIndexAndParentRecursive(
					items[i].items,
					fieldName,
					items[i].items,
					parentContainer
				);
				if (result !== null) return result;
			}
		}
		return null;
	};

	// step 1 logic
	const step1HandleImeiBlur = async () => {
		// Extract form data from formState
		const formDataObject = extractFormData(
			formStateRef.current,
			definitionContractCreateOverride
		);

		// Check if the device_identifier field is valid
		if (!formStateRef.current.device_identifier?.isValid) {
			step1ClearDeviceModelField();
			setCanContinue(false);
			return;
		}

		// Construct the payload
		const payload = {
			step: "step_1",
			tmp_contracts_id: tmpContractId,
			data: formDataObject, // Include all form fields within the data object
		};

		// Send the payload using ContractsCreateService.view
		setLoading(true);
		const response = await ContractsCreateService.index(payload);
		setLoading(false);

		if (response.data?.isOk) {
			// Update the device_model field with the make and model from the response
			const deviceData = response.data.data.device_data;
			const deviceModelValue = `${deviceData.make} ${deviceData.model}`;
			step1UpdateDeviceModelField(deviceModelValue);
		} else {
			// Display error messages
			if (response.data?.errors) {
				response.data.errors.forEach((error) => {
					toast.error(`Error: ${error.msg}`, {
						position: "top-center",
						autoClose: 5000,
					});
				});
			}
			step1ClearDeviceModelField();
		}

		setCanContinue(response.data.can_continue === true);
	};

	const step1ClearDeviceModelField = () => {
		const currentAbpEnabled = formStateRef.current.abp_enabled?.value;
		const currentDeviceIdentifier =
			formStateRef.current.device_identifier?.value;

		setFormState((prevState) => ({
			...prevState,
			device_model: {
				...prevState.device_model,
				value: "",
			},
			device_identifier: {
				...prevState.device_identifier,
				value: currentDeviceIdentifier,
			},
		}));

		setDefinitionContractCreateOverride((prevDefinition) => {
			const newDefinition = { ...prevDefinition };
			if (newDefinition.fields.abp_enabled) {
				newDefinition.fields.abp_enabled.value = currentAbpEnabled;
			}
			if (newDefinition.fields.device_model) {
				newDefinition.fields.device_model.value = "";
			}
			if (newDefinition.fields.device_identifier) {
				newDefinition.fields.device_identifier.value =
					currentDeviceIdentifier;
			}
			return newDefinition;
		});
	};

	const step1UpdateDeviceModelField = (deviceModelValue) => {
		const currentAbpEnabled = formStateRef.current.abp_enabled?.value;
		const currentDeviceIdentifier =
			formStateRef.current.device_identifier?.value;

		setFormState((prevState) => ({
			...prevState,
			device_model: {
				...prevState.device_model,
				value: deviceModelValue,
			},
			device_identifier: {
				...prevState.device_identifier,
				value: currentDeviceIdentifier,
			},
		}));

		setDefinitionContractCreateOverride((prevDefinition) => {
			const newDefinition = { ...prevDefinition };
			if (newDefinition.fields.abp_enabled) {
				newDefinition.fields.abp_enabled.value = currentAbpEnabled;
			}
			if (newDefinition.fields.device_model) {
				newDefinition.fields.device_model.value = deviceModelValue;
			}
			if (newDefinition.fields.device_identifier) {
				newDefinition.fields.device_identifier.value =
					currentDeviceIdentifier;
			}
			return newDefinition;
		});
	};

	useEffect(() => {
		if (step === 1) {
			updateContractCreateDefinitionWithValues(
				[
					step1EnabledLockRequired,
					step1EnabledLockEnabled,
					step1EnabledAbpRequired,
					step1EnabledAbpEnabled,
				],
				formState,
				_.cloneDeep(contractsCreateStep1Definition)
			);
		}
	}, [step1EnabledLockEnabled, step]);

	const prevEnabledLockValue = useRef(formState?.lock_enabled?.value);
	const prevEnabledAbpValue = useRef(formState?.abp_enabled?.value);

	useEffect(() => {
		if (step === 1 && formState?.lock_enabled?.value !== undefined) {
			if (formState.lock_enabled.value !== prevEnabledLockValue.current) {
				setStep1EnabledLockEnabled(
					formState.lock_enabled.value ? 1 : 0
				);
				prevEnabledLockValue.current = formState.lock_enabled.value;
			}
		}
	}, [formState?.lock_enabled?.value, step]);

	useEffect(() => {
		if (step === 1 && formState?.abp_enabled?.value !== undefined) {
			if (formState.abp_enabled.value !== prevEnabledAbpValue.current) {
				setStep1EnabledAbpEnabled(formState.abp_enabled.value ? 1 : 0);
				prevEnabledAbpValue.current = formState.abp_enabled.value;
			}
		}
	}, [formState?.abp_enabled?.value, step]);

	// step 2 logic
	const step2UpdateFieldsToReadOnly = (
		fields,
		formState,
		setDefinitionContractCreateOverride,
		setFormState
	) => {
		// console.log(
		// 	"step2UpdateFieldsToReadOnly",
		// 	fields,
		// 	formState,
		// 	setDefinitionContractCreateOverride,
		// 	setFormState
		// );
		setDefinitionContractCreateOverride((prevDefinition) => {
			// console.log("prevDefinition", prevDefinition);
			const newDefinition = { ...prevDefinition };
			fields.forEach((fieldName) => {
				if (newDefinition.fields[fieldName]) {
					newDefinition.fields[fieldName] = {
						...newDefinition.fields[fieldName],
						readOnly: true,
						value:
							formState[fieldName]?.value ||
							newDefinition.fields[fieldName].value,
					};
				}
			});
			return newDefinition;
		});

		// Update formState to reflect the changes
		setFormState((prevFormState) => {
			const updatedFormState = { ...prevFormState };
			fields.forEach((fieldName) => {
				if (updatedFormState[fieldName]) {
					updatedFormState[fieldName] = {
						...updatedFormState[fieldName],
						value: formState[fieldName]?.value,
						readOnly: true,
					};
				}
			});
			// console.log("updatedFormState", updatedFormState);
			return updatedFormState;
		});
	};

	const step2HandleAddressSubmit = async () => {
		if (formRef.current) {
			const currentFormState = formStateRef.current;
			setLoading(true);
			const isValid = await formRef.current.validateAllFields(
				true,
				"step2HandleAddressSubmit"
			); // Call the validation function
			if (isValid) {
				// Extract form data from formState
				const formDataObject = extractFormData(
					currentFormState,
					definitionContractCreateOverride
				);

				//@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
				const response = await ContractsCreateService.verifyPersonnel(
					formDataObject,
					tmpContractId
				);
				// const response = {};
				// response.data = contractsCreateStep2DummyResponse;

				// console.log("response verify personnel", response);
				// console.log("response.data.isOk", response.data.isOk);

				if (response.data.isOk) {
					// Extract form data from formState
					const formDataObject = extractFormData(
						currentFormState,
						definitionContractCreateOverride
					);

					//@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
					const response = await ContractsCreateService.verifyAddress(
						formDataObject,
						tmpContractId
					);
					// const response = {};
					// response.data = contractsCreateStep2DummyResponse;

					// console.log("response verify address", response);

					// if (response.data.isOk) {
					// console.log("!response.data.errors", !response.data.errors);
					// console.log(
					// 	"response.data.errors.length === 0",
					// 	response.data.errors?.length === 0
					// );
					if (
						!response.data.errors ||
						response.data.errors?.length === 0
					) {
						if (response.data.data.address_in_match_usps !== true) {
							// Update the state with the address data
							setStep2EnteredAddress(
								response.data.data.address_in
							);
							setStep2FoundAddress(
								response.data.data.address_usps
							);

							// display modal
							setStep2IsModalOpen(true); // Display modal

							return false;
						}

						setStep2IsVerifyAddressVisible(false);
						setStep2IsEmailVerificationVisible(true);

						step2UpdateFieldsToReadOnly(
							step2ReadOnlyFields,
							currentFormState,
							setDefinitionContractCreateOverride,
							setFormState
						);
					} else {
						// Show errors
						if (response.data.errors) {
							//for each error, show a toast
							response.data.errors.forEach((error, index) => {
								setTimeout(() => {
									toast.error(`Error: ${error.msg}`, {
										position: "top-center",
										autoClose: 5000,
									});
								}, index * 1000);
							});
						}
					}
				} else {
					// Show errors
					if (response.data.errors) {
						//for each error, show a toast
						response.data.errors.forEach((error, index) => {
							setTimeout(() => {
								toast.error(`Error: ${error.msg}`, {
									position: "top-center",
									autoClose: 5000,
								});
							}, index * 1000);
						});
					}
				}
			}
			setTimeout(() => {
				setLoading(false);
			}, 200);
		}
	};

	const step2HandleEmailSubmit = async (email) => {
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		if (!email) {
			toast.error(`Error: Email address is required.`, {
				position: "top-center",
				autoClose: 1000,
			});
			return;
		}
		if (!emailRegex.test(email)) {
			toast.error(`Error: Email address is invalid.`, {
				position: "top-center",
				autoClose: 1000,
			});
			return;
		}

		setLoading(true);
		//@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
		const response = await ContractsCreateService.verifyEmail(
			email,
			tmpContractId
		);

		// const response = {};
		// response.data = contractsCreateStep2DummyResponse;

		if (response.data.isOk) {
			// need to set isEmailSent to true
			setStep2IsEmailSent(true);

			// if user is system admin set code value
			if (SessionService.canSystemAdmin()) {
				setStep2EmailCode(response.data.data.email.email_address_code);
			} else {
				setStep2EmailCode("");
			}
		} else {
			// Show errors
			if (response.data.errors) {
				//for each error, show a toast
				response.data.errors.forEach((error, index) => {
					setTimeout(() => {
						toast.error(`Error: ${error.msg}`, {
							position: "top-center",
							autoClose: 5000,
						});
					}, index * 1000);
				});
			}
		}

		setLoading(false);
	};

	const step2HandleCodeSubmit = async (email, code) => {
		if (!code) {
			toast.error(`Error: Verification code is required.`, {
				position: "top-center",
				autoClose: 1000,
			});
			return;
		}

		setLoading(true);
		//@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
		const response = await ContractsCreateService.verifyEmailCode(
			email,
			code,
			tmpContractId
		);

		// const response = {};
		// response.data = contractsCreateStep2DummyResponse;
		//@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
		// console.log(
		// 	"response.data.email_address_code_match",
		// 	response.data.data.email_address_code_match
		// );
		if (response.data.data.email_address_code_match === true) {
			// Add the newsletter_enabled checkbox to the form
			updateContractCreateDefinitionWithValues(
				["addNewsletterField"],
				formStateRef.current,
				_.cloneDeep(contractsCreateStep2Definition)
			);
			//@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
			setCanContinue(response.data.email_address_code_match);

			// Disable the email verification field
			setStep2IsEmailVerificationDisabled(true);

			step2UpdateFieldsToReadOnly(
				step2ReadOnlyFields,
				formState,
				setDefinitionContractCreateOverride,
				setFormState
			);
		} else {
			// Show errors
			if (response.data.errors) {
				//for each error, show a toast
				response.data.errors.forEach((error, index) => {
					setTimeout(() => {
						toast.error(`Error: ${error.msg}`, {
							position: "top-center",
							autoClose: 5000,
						});
					}, index * 1000);
				});
			}
		}

		setLoading(false);
	};

	const step2HandleModalClose = (disableLoading = true) => {
		setStep2IsModalOpen(false);
		if (disableLoading) {
			setLoading(false);
		}
	};

	const step2HandleContinueAddress = () => {
		setStep2IsVerifyAddressVisible(false);
		setStep2IsEmailVerificationVisible(true);

		step2UpdateFieldsToReadOnly(
			step2ReadOnlyFields,
			formStateRef.current,
			setDefinitionContractCreateOverride,
			setFormState
		);
		step2HandleModalClose();
	};

	const step2HandleUpdateAddress = () => {
		const updatedFormState = {
			...formState,
			address_1: {
				...formState.address_1,
				value: step2FoundAddress.address_1,
			},
			address_2: {
				...formState.address_2,
				value: step2FoundAddress.address_2,
			},
			city: { ...formState.city, value: step2FoundAddress.city },
			state: { ...formState.state, value: step2FoundAddress.state },
			zip: { ...formState.zip, value: step2FoundAddress.zip },
		};

		setFormState(updatedFormState);
		setStep2IsAddressUpdated(true);
	};

	useEffect(() => {
		if (step2IsAddressUpdated) {
			formStateRef.current = formState;

			updateContractCreateDefinitionWithValues(
				[],
				formState,
				_.cloneDeep(contractsCreateStep2Definition)
			);

			step2HandleAddressSubmit();
			step2HandleModalClose(false);
			setStep2IsAddressUpdated(false);
		}
	}, [step2IsAddressUpdated, formState]);

	const step2ResetFormState = () => {
		setDefinitionContractCreateOverride(
			_.cloneDeep(contractsCreateStep2Definition)
		);
		setFormState({});
		setStep2IsVerifyAddressVisible(true);
		setStep2IsEmailVerificationVisible(false);
		setStep2IsEmailSent(false);
		setStep2IsEmailVerificationDisabled(false);
	};

	const step2ModalBody = (
		<div className="mt-3 row">
			<div className="col-md-6">
				<div className="mb-3 border-2 border-sky-200 border-solid rounded-md">
					<div className="text-sky-500 bg-sky-100 border-b-2 rounded-tl rounded-tr py-3 px-4 border-sky-200 border-solid">
						<h6 className="text-center font-normal mb-0">
							Entered Address
						</h6>
					</div>

					<div className="p-4">
						Address: {step2EnteredAddress.address_1}
						<br />
						Suite / Apt: {step2EnteredAddress.address_2}
						<br />
						City: {step2EnteredAddress.city}
						<br />
						State: {step2EnteredAddress.state}
						<br />
						Zip: {step2EnteredAddress.zip}
						<br />
					</div>
				</div>
			</div>

			<div className="col-md-6">
				<div className="mb-3 border-2 border-emerald-200 border-solid rounded-md">
					<div className="text-emerald-500 bg-emerald-100 border-b-2 rounded-tl rounded-tr py-3 px-4 border-emerald-200 border-solid">
						<h6 className="text-center font-normal mb-0">
							Found Address
						</h6>
					</div>

					<div className="p-4">
						Address: {step2FoundAddress.address_1}
						<br />
						Suite / Apt: {step2FoundAddress.address_2}
						<br />
						City: {step2FoundAddress.city}
						<br />
						State: {step2FoundAddress.state}
						<br />
						Zip: {step2FoundAddress.zip}
						<br />
					</div>
				</div>
			</div>
		</div>
	);

	// step 3 logic
	useEffect(() => {
		if (step3TriggerFormUpdate) {
			updateContractCreateDefinitionWithValues(
				[
					step3StoreRequireIdVerification
						? "storeRequireIdVerification"
						: "",
				],
				formState,
				_.cloneDeep(contractsCreateStep3Definition)
			);
			setStep3TriggerFormUpdate(false); // Reset the trigger
		}
	}, [step3TriggerFormUpdate]);

	useEffect(() => {
		if (step === 3 && step3IdType !== undefined) {
			updateContractCreateDefinitionWithValues(
				[
					step3StoreRequireIdVerification
						? "storeRequireIdVerification"
						: "",
					{ idType: step3IdType },
				],
				formState,
				_.cloneDeep(contractsCreateStep3Definition)
			);
		}
	}, [step3IdType, step]);

	const prevIdTypeValue = useRef(
		formState?.customer_identification_type_id?.value
	);

	useEffect(() => {
		if (
			step === 3 &&
			formState?.customer_identification_type_id?.value !== undefined
		) {
			if (
				formState.customer_identification_type_id.value !==
				prevIdTypeValue.current
			) {
				setStep3IdType(formState.customer_identification_type_id.value);
				prevIdTypeValue.current =
					formState.customer_identification_type_id.value;
			}
		}
	}, [formState?.customer_identification_type_id?.value, step]);

	const step3HandleContactSubmit = async (contact, verificationType) => {
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		const isEmail = verificationType.includes("email");
		const contactType = isEmail ? "email" : "phone";

		if (!contact) {
			toast.error(`Error: Custom ${contactType} is required.`, {
				position: "top-center",
				autoClose: 1000,
			});
			return;
		}

		if (isEmail && !emailRegex.test(contact)) {
			toast.error(`Error: Custom email is invalid.`, {
				position: "top-center",
				autoClose: 1000,
			});
			return;
		}

		setLoading(true);
		const response = await ContractsCreateService.sendIdLink(
			formState?.customer_identification_type_id?.value,
			formState?.customer_identification_number?.value,
			formState?.customer_identification_state_id?.value,
			verificationType,
			contact,
			tmpContractId
		);

		if (response.data.isOk) {
			// need to set isLinkSent to true
			setStep3IsLinkSent(true);

			const response = await ContractsCreateService.index({
				step: "step_3",
				tmp_contracts_id: tmpContractId,
			});

			// set image refresh interval
			if (response.data.refresh_interval) {
				setStep3ImageRefreshInterval(response.data.refresh_interval);
			}

			// set image count
			if (response.data.data.image_count) {
				setStep3ImageCount(response.data.data.image_count);
			}
		} else {
			// Show errors
			if (response.data.errors) {
				//for each error, show a toast
				response.data.errors.forEach((error, index) => {
					setTimeout(() => {
						toast.error(`Error: ${error.msg}`, {
							position: "top-center",
							autoClose: 5000,
						});
					}, index * 1000);
				});
			}
		}

		setLoading(false);
	};

	useEffect(() => {
		let intervalId;

		const fetchImages = async () => {
			// console.log("Fetching images...");
			try {
				const response = await ContractsCreateService.index({
					step: "step_3",
					tmp_contracts_id: tmpContractId,
				});

				if (response.data.data.images) {
					const images = response.data.data.images;
					setStep3Image1(images.image_1 || null);
					setStep3Image2(images.image_2 || null);
					setStep3Image3(images.image_3 || null);
				}

				// Check image count and update canContinue
				if (response.data.data.image_count !== undefined) {
					const imageCount = response.data.data.image_count;
					setStep3ImageCount(imageCount);

					// Update canContinue based on image count and store requirement
					if (imageCount >= 1 && imageCount < 3) {
						setCanContinue(false);
					} else if (imageCount === 0) {
						setCanContinue(!step3StoreRequireIdVerification);
					} else if (imageCount >= 3) {
						setCanContinue(true);
					}

					// Additional check for when store doesn't require verification but images exist and customer_identification_type_id is not set
					if (
						!step3StoreRequireIdVerification &&
						imageCount > 0 &&
						!formState.customer_identification_type_id?.value
					) {
						setCanContinue(false);
					}

					if (imageCount >= 3) {
						// console.log("All images received. Stopping refresh.");
						clearInterval(intervalId);
					}
				}
			} catch (error) {
				console.error("Error fetching images:", error);
			}
		};

		if (step3ImageRefreshInterval && step === 3) {
			// Initial fetch
			fetchImages();

			// Set up interval
			intervalId = setInterval(fetchImages, step3ImageRefreshInterval);
		}

		// Cleanup function
		return () => {
			if (intervalId) {
				clearInterval(intervalId);
			}
		};
	}, [
		step3ImageRefreshInterval,
		step,
		tmpContractId,
		step3StoreRequireIdVerification,
		formState.customer_identification_type_id?.value,
		step3ImageCount,
	]);

	const step3RecaptureImage = async (imageNumber) => {
		setLoading(true);
		const response = await ContractsCreateService.recaptureImage(
			imageNumber,
			tmpContractId
		);

		if (
			!response.data.isOk &&
			(response.data.errors[0].msg === "Please upload all 3 images!" ||
				response.data.errors[0].msg ===
					"Please upload all 3 images! [2]")
		) {
			const response = await ContractsCreateService.index({
				step: "step_3",
				tmp_contracts_id: tmpContractId,
			});

			// set image refresh interval
			if (response.data.refresh_interval) {
				setStep3ImageRefreshInterval(response.data.refresh_interval);
			}

			// set image count
			if (response.data.data.image_count) {
				setStep3ImageCount(response.data.data.image_count);
			}
		} else {
			// Show errors
			if (response.data.errors) {
				//for each error, show a toast
				response.data.errors.forEach((error, index) => {
					setTimeout(() => {
						toast.error(`Error: ${error.msg}`, {
							position: "top-center",
							autoClose: 5000,
						});
					}, index * 1000);
				});
			}
		}

		setLoading(false);
	};

	// step 4 logic
	const updateStep4FormState = (response, triggerBy) => {
		setCanContinue(response.data.can_continue);
		if (response && response.data) {
			setTmpContractCreateData(response.data.data || []);
			const storeLimits = response.data.data.store_limits;
			const updatedFormState = {
				...formState,
				retail_value: {
					...formState.retail_value,
					value: String(
						parseNumeric(response?.data?.data?.fields?.retail_value)
					),
				},
				down_payment_amount: {
					...formState.down_payment_amount,
					label: `${storeLimits?.down_payment?.actual}% Down Payment`,
					value: String(
						parseNumeric(
							response?.data?.data?.fields?.down_payment_amount
						)
					),
				},
				rental_factor: {
					...formState.rental_factor,
					value: String(
						parseNumeric(
							response?.data?.data?.fields?.rental_factor
						)
					),
				},
				total_contract_value: {
					...formState.total_contract_value,
					value: String(
						parseNumeric(
							response?.data?.data?.fields?.total_contract_value
						)
					),
				},
				retail_value_caption: {
					...formState.retail_value_caption,
					value: `Retail max value: $${storeLimits?.contract_max_value}`,
				},
				down_payment_amount_caption: {
					...formState.down_payment_amount_caption,
					value: `$${response?.data?.data?.store_limits?.down_payment_amounts?.min} min ${response?.data?.data?.store_limits?.down_payment?.min}% |
					$${response?.data?.data?.store_limits?.down_payment_amounts?.max} max ${response?.data?.data?.store_limits?.down_payment?.max}% |
					$${response?.data?.data?.store_limits?.down_payment_amounts?.default} default ${response?.data?.data?.store_limits?.down_payment?.default}%`,
				},
				rental_factor_caption: {
					...formState.rental_factor_caption,
					value: `min ${response?.data?.data?.store_limits?.finance?.min} |
					max ${response?.data?.data?.store_limits?.finance?.max} |
					default ${response?.data?.data?.store_limits?.finance?.default}`,
				},
				device_condition_id: {
					...formState.device_condition_id,
					options: response?.data?.data?.selects?.device_condition_id,
				},
				merchandise_description: {
					...formState.merchandise_description,
					value: response?.data?.data?.in?.merchandise_description,
				},
			};

			updateContractCreateDefinitionWithValues(
				[response?.data?.data],
				updatedFormState,
				_.cloneDeep(contractsCreateStep4Definition)
			);
		}
	};

	useEffect(() => {
		if (step4TriggerFormUpdate) {
			const updateForm = async () => {
				// Construct the payload
				const payload = {
					step: "step_4",
					tmp_contracts_id: tmpContractId,
				};
				setLoading(true);
				const response = await ContractsCreateService.index(payload);
				updateStep4FormState(response, "useEffect");
				setLoading(false);
			};
			updateForm();
			setStep4TriggerFormUpdate(false); // Reset the trigger
		}
	}, [step4TriggerFormUpdate]);

	const step4HandleRetailValueBlur = async () => {
		// Extract form data from formState
		const formDataObject = extractFormData(
			formStateRef.current,
			definitionContractCreateOverride
		);

		// Construct the payload
		const payload = {
			step: "step_4",
			tmp_contracts_id: tmpContractId,
			data: formDataObject, // Include all form fields within the data object
		};
		setLoading(true);
		const response = await ContractsCreateService.index(payload);
		updateStep4FormState(response, "step4HandleRetailValueBlur");
		setLoading(false);
	};

	const step4PaymentTermOnChange = async (event, month, period) => {
		setStep4SelectedPlan(`${period}-${month}`);

		// Extract form data from formState
		const formDataObject = extractFormData(
			formStateRef.current,
			definitionContractCreateOverride
		);

		setStep4LeaseTypeId(String(period));
		setStep4LeaseLength(String(month));

		// Add lease_type_id and lease_length to formDataObject
		formDataObject.lease_type_id = String(period);
		formDataObject.lease_length = String(month);

		// Construct the payload
		const payload = {
			step: "step_4",
			tmp_contracts_id: tmpContractId,
			data: formDataObject, // Include all form fields within the data object
		};
		const response = await ContractsCreateService.index(payload);
		updateStep4FormState(response, "step4PaymentTermOnChange");
		setLoading(false);
	};

	const step4PlanDayOnChange = async (fieldName, value, pIsValid) => {
		// Extract form data from formState
		const formDataObject = extractFormData(
			formStateRef.current,
			definitionContractCreateOverride
		);

		setStep4LeaseDay(String(value));

		// Add lease_type_id and lease_length to formDataObject
		formDataObject.lease_type_id = step4LeaseTypeId;
		formDataObject.lease_length = step4LeaseLength;

		formDataObject.lease_day = String(value);

		// Only send payload if lease day is not empty or changed
		if (String(value) === "" || String(value) === step4LeaseDay) {
			return;
		}

		// Construct the payload
		const payload = {
			step: "step_4",
			tmp_contracts_id: tmpContractId,
			data: formDataObject, // Include all form fields within the data object
		};
		const response = await ContractsCreateService.index(payload);
		updateStep4FormState(response, "step4PlanDayOnChange");
		setLoading(false);
	};

	const step4PlanDateOnChange = async (fieldName, value, pIsValid) => {
		// Extract form data from formState
		const formDataObject = extractFormData(
			formStateRef.current,
			definitionContractCreateOverride
		);

		setStep4LeaseDate(String(value));

		// Add lease_type_id and lease_length to formDataObject
		formDataObject.lease_type_id = step4LeaseTypeId;
		formDataObject.lease_length = step4LeaseLength;

		formDataObject.lease_day = step4LeaseDay;
		formDataObject.lease_date = String(value);

		// Only send payload if lease date is not empty or changed
		if (String(value) === "" || String(value) === step4LeaseDate) {
			return;
		}

		// Construct the payload
		const payload = {
			step: "step_4",
			tmp_contracts_id: tmpContractId,
			data: formDataObject, // Include all form fields within the data object
		};
		const response = await ContractsCreateService.index(payload);
		updateStep4FormState(response, "step4PlanDateOnChange");
		setLoading(false);
	};

	return (
		<div className="sm:p-3 text-zinc-800 text-sm bg-slate-100 rounded">
			<br />
			{step !== 0 && (
				<div className="mx-[150px]">
					<MultiStepFormStepper
						steps={steps}
						step={step}
						onStepClick={handleStepClick}
					/>
				</div>
			)}
			<TopModal
				title="Confirm Address"
				body={step2ModalBody}
				footer={
					<>
						<Button
							onClick={step2HandleContinueAddress}
							variant="contained"
							color="primary"
							disableElevation
						>
							<span className="font-normal">Continue</span>
						</Button>
						<Button
							onClick={step2HandleUpdateAddress}
							variant="contained"
							color="success"
							disableElevation
						>
							<span className="font-normal">Update Address</span>
						</Button>
					</>
				}
				isVisible={step2IsModalOpen}
				onClose={step2HandleModalClose}
				loading={loading}
			/>
			<DynamicForm
				loading={loading}
				setLoading={setLoading}
				model="contracts_create"
				modelLabel="Create Contract"
				service={ContractsCreateService}
				apiBase="/contracts/contract-create"
				// successRedirectBase="settings/rto-company-payment-methods"
				scenario="create"
				// key={modelId + "contracts_create"}
				key={createResetCounter + "contracts_create"}
				display="partial"
				createEndpointOverride="/contracts/contract-create"
				updateEndpointOverride="/contracts/contract-create"
				definitionOverride={definitionContractCreateOverride}
				extraData={extraContractCreateData}
				sendEmptyData={sendEmptyContractCreateData}
				// scenario={isUpdateMode ? "update" : "create"}
				// updateId={updateId}
				fetchData={handleNextStepResponse}
				suppressSuccessToast={true}
				onFormChange={handleFormChange}
				renderConditionalElement={renderConditionalElement}
				actionHandlers={actionHandlers}
				canContinue={canContinue}
				ref={formRef}
			/>
		</div>
	);
}

export default ContractsCreateForm;
