import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Breadcrumb } from "react-bootstrap";
import DynamicForm from "../../components/formComponents/DynamicForm";
import {
	simplePaymentFormStep1Definition,
	simplePaymentFormStep2Definition,
} from "../../components/formComponents/rtoDynamicForm/definitions/simplePaymentFormDefinition";
import { toast } from "react-toastify";

function SimplePayment({ loading, setLoading }) {
	const pageTitle = "Simple Payment | RTO Mobile";
	const [profileId, setProfileId] = useState(null);
	const [storeName, setStoreName] = useState("");
	const [paymentProfileName, setPaymentProfileName] = useState("");

	const [pmtMessage, setPmtMessage] = useState("");
	const [pmtRetRef, setPmtRetRef] = useState("");
	const [pmtTotal, setPmtTotal] = useState("");

	const [definitionStep1Override, setDefinitionStep1Override] = useState(
		simplePaymentFormStep1Definition
	);
	const [definitionStep2Override, setDefinitionStep2Override] = useState(
		simplePaymentFormStep2Definition
	);

	const [isStep2Disabled, setIsStep2Disabled] = useState(true);
	const step2Style = {
		opacity: isStep2Disabled ? 0.5 : 1,
		cursor: isStep2Disabled ? "not-allowed" : "initial",
		pointerEvents: isStep2Disabled ? "none" : "auto",
	};

	const handleStep1Response = (response, formState) => {
		if (response && response.data && response.data.data) {
			const data = response.data.data;
            // Find the record with default_acct set to 'Y'
            const defaultAccount = data.find(item => item.default_acct === 'Y');

			console.log("defaultAccount", defaultAccount);

            if (defaultAccount && defaultAccount.profile_id) {
                setStoreName(defaultAccount.struct_store_name);
                setPaymentProfileName(defaultAccount.name);
                setProfileId(defaultAccount.profile_id);
                updateStep2DefinitionWithValues(
                    data,
                    formState,
                    simplePaymentFormStep2Definition
                );
                setIsStep2Disabled(false);
			}
		} else {
			setStoreName(null);
			setPaymentProfileName(null);
			setProfileId(null);
			updateStep2DefinitionWithValues(
				[],
				formState,
				simplePaymentFormStep2Definition
			);
			setIsStep2Disabled(true);
			toast.error("No CCN Customer Profile ID Found!", {
				position: "top-center",
				autoClose: 1000,
			});
		}
		setPmtMessage(null);
		setPmtRetRef(null);
		setPmtTotal(null);
	};

	const updateStep2DefinitionWithValues = (
		data,
		formState,
		baseDefinition
	) => {
		const updatedDefinition = { ...baseDefinition };
		updatedDefinition.fields["token"].options = data.map((item) => ({
			id: item.token,
			name: `${item.token.slice(-4)} - ${item.token}`,
		}));

		// Find the default account
		const defaultAccount = data.find(item => item.default_acct === 'Y');

		if (defaultAccount) {
			updatedDefinition.fields["token"].value = defaultAccount.token;
		} else if (data.length !== 0) {
			// Fallback to the first token if no default account is found
			updatedDefinition.fields["token"].value = data[0].token;
		}

		updatedDefinition.fields["struct_store_id"].value =
			formState?.struct_store_id?.value || "";
		updatedDefinition.fields["profile_id"].value =
			data.length > 0 && data[0].profile_id ? data[0].profile_id : "";

		setDefinitionStep2Override(updatedDefinition);
	};

	const updateStep1DefinitionWithValues = (
		data,
		formState,
		baseDefinition
	) => {
		const updatedDefinition = { ...baseDefinition };
		setDefinitionStep1Override(updatedDefinition);
		setResetCounter(resetCounter + 1); // Increment to force re-render
	};

	const [resetCounter, setResetCounter] = useState(0);

	return (
		<>
			<Helmet>
				<title>{pageTitle}</title>
				<meta name="robots" content="noindex,nofollow"></meta>
				<meta property="og:title" content={pageTitle} />
				<meta name="twitter:title" content={pageTitle} />
			</Helmet>
			<div className="rto_form-sections">
				<div className="rto_content-div">
					<Breadcrumb
						listProps={{ className: "w-100 justify-content-end" }}
					>
						<Breadcrumb.Item
							linkAs={Link}
							linkProps={{ to: "/dashboard" }}
						>
							Dashboard
						</Breadcrumb.Item>
						<Breadcrumb.Item
							linkAs={Link}
							linkProps={{ to: "/admintools" }}
						>
							Admin Tools
						</Breadcrumb.Item>
						<Breadcrumb.Item active>Simple Payment</Breadcrumb.Item>
					</Breadcrumb>
					<div className="rto_title-div">
						<h1 className="rto_page-title">Simple Payment</h1>
					</div>
					<div className="row">
						<div className="col-md-8 offset-md-2">
							<hr />
							<DynamicForm
								key={resetCounter}
								loading={loading}
								setLoading={setLoading}
								model="rto_company_payments"
								modelLabel="Simple Payment Step 1"
								// service={RtoCompanyPaymentMethodsService}
								apiBase="/rto-company-payments"
								// successRedirectBase="admintools/simple-payment"
								scenario="create"
								// key={modelId + "ccn_transaction"}
								display="partial"
								definitionOverride={definitionStep1Override}
								createEndpointOverride="/rto-company-payments/store-payment-methods-get"
								extraData={{ get_uat: process.env.REACT_APP_CCN_GET_UAT === "true" ? 1 : 0 }}
								sendAsFlatStructure={true}
								fetchData={handleStep1Response}
								suppressSuccessToast={true}
								// updateId={updateId}
								// fetchData={fetchData}
							/>
						</div>
					</div>
					<div className="row">
						<div
							className="col-md-8 offset-md-2"
							style={step2Style}
						>
							<hr />
							<div className="flex gap-2 mb-2">
								<p className="text-sm text-gray-500 font-medium mb-1">
									<span className="bg-gray-100 px-2 py-1 rounded">
										CCN Customer Profile ID:
									</span>
								</p>
								{profileId && (
									<p className="text-sm text-gray-700 font-medium mb-0">
										<span className="bg-gray-100 px-2 py-1 rounded">
											{profileId}
										</span>
									</p>
								)}
								{!profileId && (
									<p className="text-sm text-gray-500 font-medium mb-0">
										<span className="bg-gray-100 px-2 py-1 rounded">
											Not found.
										</span>
									</p>
								)}
							</div>
							<div className="flex gap-2 mb-2">
								<p className="text-sm text-gray-500 font-medium mb-1">
									<span className="bg-gray-100 px-2 py-1 rounded">
										Store Name:
									</span>
								</p>
								{storeName && (
									<p className="text-sm text-gray-700 font-medium mb-0">
										<span className="bg-gray-100 px-2 py-1 rounded">
											{storeName}
										</span>
									</p>
								)}
								{!storeName && (
									<p className="text-sm text-gray-500 font-medium mb-0">
										<span className="bg-gray-100 px-2 py-1 rounded">
											Not found.
										</span>
									</p>
								)}
							</div>
							<div className="flex gap-2 mb-2">
								<p className="text-sm text-gray-500 font-medium mb-1">
									<span className="bg-gray-100 px-2 py-1 rounded">
										Payment Profile Name:
									</span>
								</p>
								{paymentProfileName && (
									<p className="text-sm text-gray-700 font-medium mb-0">
										<span className="bg-gray-100 px-2 py-1 rounded">
											{paymentProfileName}
										</span>
									</p>
								)}
								{!paymentProfileName && (
									<p className="text-sm text-gray-500 font-medium mb-0">
										<span className="bg-gray-100 px-2 py-1 rounded">
											Not found.
										</span>
									</p>
								)}
							</div>
						</div>
					</div>
					<div className="row">
						<div
							className="col-md-8 offset-md-2"
							style={step2Style}
						>
							<DynamicForm
							    key={resetCounter}
								loading={loading}
								setLoading={setLoading}
								model="rto_company_payments"
								modelLabel="Simple Payment"
								// service={RtoCompanyPaymentMethodsService}
								apiBase="/rto-company-payments"
								// successRedirectBase="admintools/simple-payment"
								scenario="create"
								// key={modelId + "ccn_transaction"}
								display="partial"
								definitionOverride={definitionStep2Override}
								createEndpointOverride="/rto-company-payments/store-payment-process"
								fetchData={(responseData, formState) => {
									setStoreName(null);
									setPaymentProfileName(null);
									setProfileId(null);
									updateStep1DefinitionWithValues(
										[],
										null,
										simplePaymentFormStep1Definition
									);
									setIsStep2Disabled(true);
									updateStep2DefinitionWithValues(
										[],
										null,
										simplePaymentFormStep2Definition
									);
									const data = responseData.data.data;
									setPmtMessage(data.resp_text);
									setPmtRetRef(data.retref);
									setPmtTotal(data.total);
								}}
								// scenario={isUpdateMode ? "update" : "create"}
								// updateId={updateId}
								// fetchData={fetchData}
							/>
						</div>
					</div>
					{pmtMessage && pmtRetRef && pmtTotal && (
						<div className="row">
							<div className="col-md-8 offset-md-2">
								<hr />
								<div className="flex gap-2 mb-2">
									<p className="text-sm text-gray-500 font-medium mb-1">
										<span className="bg-gray-100 px-2 py-1 rounded">
											Message:
										</span>
									</p>
									<p className="text-sm text-gray-700 font-medium mb-0">
										<span className="bg-gray-100 px-2 py-1 rounded">
											{pmtMessage}
										</span>
									</p>
								</div>
								<div className="flex gap-2 mb-2">
									<p className="text-sm text-gray-500 font-medium mb-1">
										<span className="bg-gray-100 px-2 py-1 rounded">
											RetRef:
										</span>
									</p>
									<p className="text-sm text-gray-700 font-medium mb-0">
										<span className="bg-gray-100 px-2 py-1 rounded">
											{pmtRetRef}
										</span>
									</p>
								</div>
								<div className="flex gap-2 mb-2">
									<p className="text-sm text-gray-500 font-medium mb-1">
										<span className="bg-gray-100 px-2 py-1 rounded">
											Total:
										</span>
									</p>
									<p className="text-sm text-gray-700 font-medium mb-0">
										<span className="bg-gray-100 px-2 py-1 rounded">
											${pmtTotal}
										</span>
									</p>
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
		</>
	);
}

export default SimplePayment;
