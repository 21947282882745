import SessionService from "../service/SessionService";
import { Constants } from "../Constants";
import { Link } from "react-router-dom";

export function canUserActionAccess() {
	let user_info = SessionService.getUserInfo();
	return (
		user_info.role === Constants.userRole.SYSTEM_ADMIN ||
		user_info.role === Constants.userRole.RTO_ADMIN ||
		user_info.role === Constants.userRole.ORG_ADMIN ||
		user_info.role === Constants.userRole.COMPANY_ADMIN ||
		user_info.role === Constants.userRole.STORE_ADMIN
	);
}

export function createGridStructLink(hrefText, text) {
	if (SessionService.canSystemAdmin() || SessionService.canRtoAdmin()) {
		return <Link to={`${hrefText}`}>{text}</Link>;
	} else {
		return text;
	}
}

export function generateGridStructOptions(
	columnName,
	tableColumns,
	initialDisplay = true,
	filterDisplay = true,
	columnsDisplay = true,
	excluded = false
) {
	if (tableColumns) {
		return {
			...(excluded
				? { display: false }
				: tableColumns === undefined ||
				  Object.keys(tableColumns).length === 0
				? { display: initialDisplay }
				: tableColumns.hasOwnProperty(columnName)
				? {}
				: { display: false }),
			filter:
				tableColumns.hasOwnProperty(columnName) &&
				filterDisplay &&
				!excluded,
			viewColumns:
				tableColumns.hasOwnProperty(columnName) &&
				columnsDisplay &&
				!excluded,
		};
	}
}

/*
 * This function is used to parse a date string in the format YYYY-MM-DD
 * into a JavaScript Date object
 *
 * @param dateString: a date string in the format YYYY-MM-DD
 * @returns a JavaScript Date object
 */
export function parseDateString(dateString) {
	const [year, month, day] = dateString.split("-").map(Number);
	// Please note that months in JavaScript's Date object are 0-indexed
	return new Date(year, month - 1, day);
}

export function formatDate(date) {
	// Check for the specific "0000-00-00" case
	if (date === "0000-00-00") return "0000/00/00";

	if (!date) return ""; // return empty string if date is not valid
	const d = new Date(date);

	// Check if date is Invalid Date
	if (isNaN(d.getTime())) return ""; // return empty string if date is invalid

	const month = String(d.getMonth() + 1).padStart(2, "0"); // getMonth() is zero-based, pad with leading 0 if necessary
	const day = String(d.getDate()).padStart(2, "0"); // pad with leading 0 if necessary
	const year = d.getFullYear();
	return `${month}/${day}/${year}`;
}

export function formatAmount(amount) {
	if (amount) {
		return Number(amount)?.toFixed(2).toLocaleString();
	}
	return "0.00";
}

export function formatAmountWithComma(amount) {
	if (amount !== undefined && amount !== null && !isNaN(amount)) {
		// Convert to number and fix to 2 decimal places
		const fixedAmount = Number(amount).toFixed(2);
		// Convert to a string and use regular expression for formatting
		const parts = fixedAmount.split(".");
		parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		return parts.join(".");
	}
	return "0.00";
}

export function formatPhoneNumber(phoneNumber) {
	if (phoneNumber && phoneNumber.length === 10) {
		return phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
	}
	return phoneNumber;
}

export function parseErrorObject(errorObject) {
	let errorMessage = "";
	for (const key in errorObject) {
		if (errorObject.hasOwnProperty(key)) {
			errorMessage += errorObject[key] + "\n";
		}
	}
	return errorMessage;
}

export function getPaymentImage(key) {
	switch (key) {
		case "MC":
			return (
				<img
					src="/images/card_master.png"
					className="rto_card-logo-sm"
					alt=""
				/>
			);
		case "VISA":
			return (
				<img
					src="/images/card_visa.png"
					className="rto_card-logo-sm"
					alt=""
				/>
			);
		case "DISC":
			return (
				<img
					src="/images/card_discover.png"
					className="rto_card-logo-sm"
					alt=""
				/>
			);
		case "AMEX":
			return (
				<img
					src="/images/card_amex.png"
					className="rto_card-logo-sm"
					alt=""
				/>
			);
		default:
			return (
				<img
					src="/images/card_default.png"
					className="rto_card-logo-sm"
					alt=""
				/>
			);
	}
}

export function saveFile(fileContentType, data, fileName) {
	var blob = new Blob([s2ab(atob(data))], { type: fileContentType });

	if (window.navigator.msSaveOrOpenBlob) {
		window.navigator.msSaveBlob(blob, fileName);
	} else {
		var elem = window.document.createElement("a");
		elem.href = window.URL.createObjectURL(blob);
		elem.download = fileName;
		document.body.appendChild(elem);
		elem.click();
		document.body.removeChild(elem);
	}
}

export function s2ab(s) {
	var buf = new ArrayBuffer(s.length);
	var view = new Uint8Array(buf);

	for (var i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
	return buf;
}

export function convertedToKeyValueArray(array) {
	return array.map((item) => {
		const key = Object.keys(item)[0];
		const value = item[key];
		return { id: key, name: value };
	});
}

export function parseNumeric(value) {
	// Remove any non-numeric characters except for the decimal point
	const cleanedValue = String(value).replace(/[^0-9.]/g, "");
	return cleanedValue;
}
