import { MdChevronLeft, MdChevronRight, MdClose } from "react-icons/md";

export const contractsCreateStep0Definition = {
	form: {
		state: false,
		class: "form-vertical",
	},
	fields: {
		start_question: {
			type: "typography",
			text: "Do you want to create a new contract or pickup where you left off previously?",
			variant: "h5",
			className:
				"text-center py-3 text-3xl text-cyan-800 font-light font-sans",
		},
		struct_store_id: {
			type: "select",
			label: "Select Store",
			value: "",
			required: true,
			readOnly: false,
			upperCase: true,
			style: {
				textTransform: "uppercase",
			},
			options: [],
		},
		create_or_resume: {
			type: "radio",
			name: "create_or_resume",
			value: "",
			label: "Contract Options",
			required: true,
			readOnly: false,
			upperCase: true,
			options: [
				{ label: "Create New Contract", value: "create" },
				{ label: "Resume Contract", value: "resume" },
			],
			style: {
				textTransform: "uppercase",
			},
			displayAsSwitches: true,
		},
	},
	layout: [
		{
			type: "div",
			class: "row",
			items: [
				{
					type: "div",
					class: "col-12",
					items: [
						{
							type: "div",
							class: "row mb-3",
							items: [
								{
									type: "typography",
									name: "start_question",
									class: "col-md-12",
								},
							],
						},
					],
				},
				{
					type: "div",
					class: "col-lg-8 offset-lg-2",
					items: [
						{
							type: "div",
							class: "row mb-3",
							items: [
								{
									type: "select",
									name: "struct_store_id",
									class: "col-md-12",
								},
							],
						},
					],
				},
				{
					type: "div",
					class: "col-lg-8 offset-lg-2",
					items: [
						{
							type: "div",
							class: "row mb-3",
							items: [
								{
									type: "radio",
									name: "create_or_resume",
									class: "col-md-12",
								},
							],
						},
					],
				},
				{ type: "div", name: "conditionalElementPlaceholder" },
			],
		},
	],
	buttons: [
		{
			type: "button",
			label: "Cancel",
			align: "left",
			color: "default",
			action: "handleCancel",
			disableElevation: true,
			startIcon: <MdChevronLeft />,
		},
		{
			type: "submit",
			label: "Continue",
			align: "right",
			color: "primary",
			disableElevation: true,
			endIcon: <MdChevronRight />,
		},
	],
};

export const contractsCreateStep1Definition = {
	form: {
		state: false,
		class: "form-vertical",
	},
	fields: {
		step_title: {
			type: "typography",
			text: "Contract Security",
			variant: "h5",
			className:
				"text-center py-3 text-3xl text-cyan-800 font-light font-sans",
		},
		lock_title: {
			type: "typography",
			text: "Do you want to secure this contract with a device lock?",
			variant: "h5",
			className:
				"text-left py-3 text-3xl text-cyan-800 font-light font-sans",
		},
		lock_enabled: {
			type: "switch",
			label: "Enable",
			value: false,
			required: false,
			readOnly: false,
			upperCase: true,
			style: {
				textTransform: "uppercase",
			},
		},
		abp_title: {
			type: "typography",
			text: "Do you want to secure this contract with Automatic Bill Pay?",
			variant: "h5",
			className:
				"text-left py-3 text-3xl text-cyan-800 font-light font-sans",
		},
		abp_enabled: {
			type: "switch",
			label: "Enable",
			value: false,
			required: false,
			readOnly: false,
			upperCase: true,
			style: {
				textTransform: "uppercase",
			},
		},
		device_identifier: {
			type: "input",
			label: "Device Identifier",
			value: "",
			required: false,
			readOnly: false,
			upperCase: false,
			maxLength: 20,
			style: {
				textTransform: "uppercase",
			},
		},
	},
	layout: [
		{
			type: "div",
			class: "row",
			items: [
				{
					type: "div",
					class: "col-12",
					items: [
						{
							type: "div",
							class: "row mb-3",
							items: [
								{
									type: "typography",
									name: "step_title",
									class: "col-md-12",
								},
							],
						},
					],
				},
				{
					type: "div",
					class: "col-8 offset-lg-2",
					items: [
						{
							type: "div",
							class: "row mb-3",
							items: [
								{
									type: "typography",
									name: "lock_title",
									class: "col-md-12",
								},
							],
						},
					],
				},
				{
					type: "div",
					class: "col-6 offset-lg-3",
					items: [
						{
							type: "div",
							class: "row mb-3",
							items: [
								{
									type: "switch",
									name: "lock_enabled",
									class: "col-md-12",
								},
							],
						},
					],
				},
				{
					type: "div",
					class: "col-8 offset-lg-2",
					items: [
						{
							type: "div",
							class: "row mb-3",
							items: [
								{
									type: "typography",
									name: "abp_title",
									class: "col-md-12",
								},
							],
						},
					],
				},
				{
					type: "div",
					class: "col-6 offset-lg-3",
					items: [
						{
							type: "div",
							class: "row mb-3",
							items: [
								{
									type: "switch",
									name: "abp_enabled",
									class: "col-md-12",
								},
							],
						},
					],
				},
				{
					type: "div",
					class: "col-8 offset-lg-2",
					items: [
						{
							type: "div",
							class: "row mb-3",
							items: [
								{
									type: "input",
									name: "device_identifier",
									class: "col-md-12",
								},
							],
						},
					],
				},
			],
		},
	],
	buttons: [
		{
			type: "button",
			label: "Back",
			align: "left",
			color: "default",
			action: "handleBack",
			disableElevation: true,
			startIcon: <MdChevronLeft />,
		},
		{
			type: "button",
			label: "Cancel & Delete",
			align: "center",
			color: "default",
			action: "handleDelete",
			disableElevation: true,
			startIcon: <MdClose style={{ color: "#ef4444" }} />,
		},
		{
			type: "submit",
			label: "Continue",
			align: "right",
			color: "primary",
			disableElevation: true,
			endIcon: <MdChevronRight />,
		},
	],
};

export const contractsCreateStep1DummyResponse = {
	isOk: true,
	msg: "SUCCESS",
	errors: [],
	step: "step_1",
	max_step: "step_1",
	can_continue: true,
	data: {
		tmp_contracts_id: "18384",
		lock_required: "0",
		lock_enabled: "1",
		abp_required: "0",
		abp_enabled: "1",
		device_identifier: "354918091997465",
		device_data: {
			imei: "354918091997465",
			serial: "FX5ZJ05BHG6X",
			make: "APPLE",
			model: "iPhone 7",
			memory: "32",
			token: "0dabba9f591332ee519d4c77d474762a",
		},
	},
	actions: {
		back: {
			step: "step_0",
		},
		delete: {
			step: "step_0",
			action: "delete",
			tmp_contracts_id: "18384",
		},
		continue: {
			step: "step_1",
			action: "continue",
			tmp_contracts_id: "18384",
		},
	},
};

export const contractsCreateStep2Definition = {
	form: {
		state: false,
		class: "form-vertical",
	},
	fields: {
		step_title: {
			type: "typography",
			text: "Customer Information",
			variant: "h5",
			className:
				"text-center py-3 text-3xl text-cyan-800 font-light font-sans",
		},
		first_name: {
			type: "input",
			label: "First Name",
			value: "",
			required: true,
			readOnly: false,
			upperCase: true,
			style: {
				textTransform: "uppercase",
			},
		},
		last_name: {
			type: "input",
			label: "Last Name",
			value: "",
			required: true,
			readOnly: false,
			upperCase: true,
			style: {
				textTransform: "uppercase",
			},
		},
		phone_number: {
			type: "tel",
			label: "Phone Number",
			value: "",
			required: true,
			readOnly: false,
			upperCase: false,
			style: {
				textTransform: "uppercase",
			},
		},
		date_of_birth: {
			type: "date",
			label: "Date of Birth",
			value: "",
			required: true,
			readOnly: false,
			upperCase: true,
			style: {
				textTransform: "uppercase",
			},
		},
		ssn_last_four: {
			type: "number",
			label: "Last 4 SSN",
			value: "",
			required: true,
			readOnly: false,
			upperCase: false,
			minLength: 4,
			maxLength: 4,
			style: {
				textTransform: "uppercase",
			},
		},
		address_1: {
			type: "input",
			label: "Address",
			value: "",
			required: true,
			readOnly: false,
			upperCase: true,
			style: {
				textTransform: "uppercase",
			},
		},
		address_2: {
			type: "input",
			label: "Suite",
			value: "",
			required: false,
			readOnly: false,
			upperCase: true,
			style: {
				textTransform: "uppercase",
			},
		},
		zip: {
			type: "zip",
			label: "Zip",
			value: "",
			required: true,
			readOnly: false,
			style: {
				textTransform: "uppercase",
			},
		},
		city: {
			type: "text",
			label: "City",
			value: "",
			required: true,
			readOnly: true,
			upperCase: true,
			style: {
				textTransform: "uppercase",
			},
		},
		state: {
			type: "text",
			label: "State",
			value: "",
			required: true,
			readOnly: true,
			upperCase: true,
			style: {
				textTransform: "uppercase",
			},
		},
		// newsletter_enabled: {
		// 	type: "checkbox",
		// 	label: "Yes, sign me up! By checking this box, I agree and I want to receive news, offers, payment reminders, and account alerts from RTO Mobile & RTO MOBILE - 123 EXAMPLE ST, including by email, phone, sms, and mail to the contact information I am submitting. I consent to RTO Mobile & RTO MOBILE - 123 EXAMPLE ST, its affiliates and service provides process my personal data for these purposes as described in the Privacy Policy. I understand that I can withdraw my consent at any time.",
		// 	value: false,
		// 	required: true,
		// 	readOnly: false,
		// 	upperCase: false,
		// 	// style: {
		// 	// 	textTransform: "uppercase",
		// 	// },
		// },
	},
	layout: [
		{
			type: "div",
			class: "row",
			items: [
				{
					type: "div",
					class: "col-10 offset-lg-1",
					items: [
						{
							type: "div",
							class: "row",
							items: [
								{
									type: "div",
									class: "col-12",
									items: [
										{
											type: "div",
											class: "row mb-3",
											items: [
												{
													type: "typography",
													name: "step_title",
													class: "col-md-12",
												},
											],
										},
									],
								},
								{
									type: "div",
									class: "col-4",
									items: [
										{
											type: "div",
											class: "row mb-3",
											items: [
												{
													type: "input",
													name: "first_name",
													class: "col-md-12",
												},
											],
										},
									],
								},
								{
									type: "div",
									class: "col-4",
									items: [
										{
											type: "div",
											class: "row mb-3",
											items: [
												{
													type: "input",
													name: "last_name",
													class: "col-md-12",
												},
											],
										},
									],
								},
								{
									type: "div",
									class: "col-4",
									items: [
										{
											type: "div",
											class: "row mb-3",
											items: [
												{
													type: "phone",
													name: "phone_number",
													class: "col-md-12",
												},
											],
										},
									],
								},
								{
									type: "div",
									class: "col-6",
									items: [
										{
											type: "div",
											class: "row mb-3",
											items: [
												{
													type: "date",
													name: "date_of_birth",
													class: "col-md-12",
												},
											],
										},
									],
								},
								{
									type: "div",
									class: "col-6",
									items: [
										{
											type: "div",
											class: "row mb-3",
											items: [
												{
													type: "input",
													name: "ssn_last_four",
													class: "col-md-12",
												},
											],
										},
									],
								},
								{
									type: "div",
									class: "col-6",
									items: [
										{
											type: "div",
											class: "row mb-3",
											items: [
												{
													type: "input",
													name: "address_1",
													class: "col-md-12",
												},
											],
										},
									],
								},
								{
									type: "div",
									class: "col-6",
									items: [
										{
											type: "div",
											class: "row mb-3",
											items: [
												{
													type: "input",
													name: "address_2",
													class: "col-md-12",
												},
											],
										},
									],
								},
								{
									type: "div",
									class: "col-md-12",
									items: [
										{
											type: "div",
											class: "row",
											items: [
												{
													type: "zip",
													name: "zip",
													targetCity: "city",
													targetState: "state",
													class: "col-md-4 mb-3",
												},
												{
													type: "input",
													name: "city",
													class: "col-md-4 mb-3",
												},
												{
													type: "input",
													name: "state",
													class: "col-md-4 mb-3",
												},
											],
										},
									],
								},
								{
									type: "div",
									name: "conditionalElementPlaceholder1",
								},
								{
									type: "div",
									name: "conditionalElementPlaceholder2",
								},
								// {
								// 	type: "div",
								// 	// class: "col-6 offset-lg-3",
								// 	class: "col-12",
								// 	items: [
								// 		{
								// 			type: "div",
								// 			class: "row mb-3",
								// 			items: [
								// 				{
								// 					type: "checkbox",
								// 					name: "newsletter_enabled",
								// 					class: "col-md-12",
								// 				},
								// 			],
								// 		},
								// 	],
								// },
							],
						},
					],
				},
			],
		},
	],
	buttons: [
		{
			type: "button",
			label: "Back",
			align: "left",
			color: "default",
			action: "handleBack",
			disableElevation: true,
			startIcon: <MdChevronLeft />,
		},
		{
			type: "button",
			label: "Cancel & Delete",
			align: "center",
			color: "default",
			action: "handleDelete",
			disableElevation: true,
			startIcon: <MdClose style={{ color: "#ef4444" }} />,
		},
		{
			type: "submit",
			label: "Continue",
			align: "right",
			color: "primary",
			disableElevation: true,
			endIcon: <MdChevronRight />,
		},
	],
};

export const contractsCreateStep2DummyResponse = {
	isOk: true,
	msg: "SUCCESS",
	errors: [],
	step: "step_2",
	max_step: "step_4",
	can_continue: true,
	data: {
		store: {
			name: "RTO MOBILE - 123 EXAMPLE ST",
		},
		personnel: {
			first_name: "TEST",
			last_name: "TESTING",
			phone_number: "8888888888",
			date_of_birth: "1995-03-28",
			ssn_last_4: "9999",
		},
		address: {
			address_1: "15211 WOODCHESTER DR",
			address_2: "",
			city: "SAN ANTONIO",
			zip: "78232",
			state: "TX",
		},
		address_in: {
			address_1: "15211 WOODCHESTER DR",
			address_2: "",
			city: "SAN ANTONIO",
			zip: "78232",
			state: "TX",
		},
		address_usps: {
			address_1: "15211 WOODCHESTER ST",
			address_2: "",
			city: "SAN ANTONIO",
			zip: "78232",
			state: "TX",
		},
		address_in_match_usps: true,
		email: {
			email_address: "matthew@row.net",
			email_address_code: "989898",
		},
	},
	actions: {
		back: {
			step: "step_1",
		},
		delete: {
			step: "step_0",
			action: "delete",
			tmp_contracts_id: "18384",
		},
		continue: {
			step: "step_3",
			action: "continue",
			tmp_contracts_id: "18384",
		},
	},
};

export const contractsCreateStep3Definition = {
	form: {
		state: false,
		class: "form-vertical",
	},
	fields: {
		step_title: {
			type: "typography",
			text: "ID Verification",
			variant: "h5",
			className:
				"text-center py-3 text-3xl text-cyan-800 font-light font-sans",
		},
		customer_identification_type_id: {
			type: "select",
			label: "ID Type",
			value: "",
			required: false,
			readOnly: false,
			upperCase: true,
			style: {
				textTransform: "uppercase",
			},
			options: [
				{ id: "", name: "Select ID Type..." },
				{ id: "3", name: "Federal Issued ID" },
				{ id: "6", name: "Matricula ID" },
				{ id: "4", name: "Passport" },
				{ id: "2", name: "State Issued Drivers License" },
				{ id: "1", name: "State Issued ID" },
				{ id: "5", name: "Other" },
			],
		},
		customer_identification_number: {
			type: "input",
			label: "ID Number",
			value: "",
			required: false,
			readOnly: false,
			upperCase: false,
			style: {
				textTransform: "uppercase",
			},
		},
	},
	layout: [
		{
			type: "div",
			class: "row",
			items: [
				{
					type: "div",
					class: "col-10 offset-lg-1",
					items: [
						{
							type: "div",
							class: "row mb-3",
							items: [
								{
									type: "typography",
									name: "step_title",
									class: "col-md-12",
								},
							],
						},
						{
							type: "div",
							class: "row mb-3",
							items: [
								{
									type: "select",
									name: "customer_identification_type_id",
									class: "col-md-6",
								},
								{
									type: "input",
									name: "customer_identification_number",
									class: "col-md-6",
								},
							],
						},
						{
							type: "div",
							class: "row mb-3",
							items: [
								{
									type: "typography",
									text: "How would you like to receive a link to upload images of the customers ID?",
									variant: "p",
									className:
										"text-center py-3 font-light font-sans",
									class: "col-md-12",
								},
							],
						},
						{ type: "div", name: "conditionalElementPlaceholder1" },
						{ type: "div", name: "conditionalElementPlaceholder2" },
					],
				},
			],
		},
	],
	buttons: [
		{
			type: "button",
			label: "Back",
			align: "left",
			color: "default",
			action: "handleBack",
			disableElevation: true,
			startIcon: <MdChevronLeft />,
		},
		{
			type: "button",
			label: "Cancel & Delete",
			align: "center",
			color: "default",
			action: "handleDelete",
			disableElevation: true,
			startIcon: <MdClose style={{ color: "#ef4444" }} />,
		},
		{
			type: "submit",
			label: "Continue",
			align: "right",
			color: "primary",
			disableElevation: true,
			endIcon: <MdChevronRight />,
		},
	],
};

export const contractsCreateStep3DummyResponse = {
	isOk: true,
	msg: "SUCCESS",
	errors: [],
	step: "step_3",
	max_step: "step_5",
	can_continue: true,
	refresh_interval: 10000,
	data: {
		store_require_id_verification: true,
		customer_identification_type_id: 1,
		customer_identification_state_id: 5,
		customer_identification_number: "123456789",
		image_count: 3,
		images: {
			image_1: "image1.jpg",
			image_2: "image2.jpg",
			image_3: "image3.jpg",
		},
		selector: {
			user_email: "user@example.com",
			user_phone: "888-888-8888",
			store_email: "store@example.com",
			store_phone: "999-999-9999",
			custom_email: "",
			custom_phone: "",
		},
		selects: {
			customer_identification_type_id: [
				{ id: "1", name: "Driver's License", state_required: "1" },
				{ id: "2", name: "Passport", state_required: "0" },
				{ id: "3", name: "State ID", state_required: "1" },
			],
			customer_identification_state_id: [
				{ id: "1", name: "Alabama" },
				{ id: "2", name: "Alaska" },
				{ id: "5", name: "California" },
			],
		},
	},
	actions: {
		back: {
			step: "step_2",
			tmp_contracts_id: "18384",
		},
		delete: {
			step: "step_0",
			action: "delete",
			tmp_contracts_id: "18384",
		},
		continue: {
			step: "step_4",
			action: "continue",
			tmp_contracts_id: "18384",
		},
	},
};

export const contractsCreateStep4Definition = {
	form: {
		state: false,
		class: "form-vertical",
	},
	fields: {
		step_title: {
			type: "typography",
			text: "Contract Details",
			variant: "h5",
			className:
				"text-center py-3 text-3xl text-cyan-800 font-light font-sans",
		},
		retail_value: {
			type: "number",
			label: "Retail Value",
			value: "",
			prefix: "$",
			required: true,
			readOnly: false,
			upperCase: true,
			style: {
				textTransform: "uppercase",
			},
		},
		retail_value_caption: {
			type: "typography",
			text: "Retail max value: $0.00",
			variant: "caption",
			className: "text-center font-light font-sans",
		},
		down_payment_amount: {
			type: "number",
			label: "0.00% Down Payment",
			value: "",
			suffix: "%",
			required: true,
			readOnly: false,
			upperCase: true,
			style: {
				textTransform: "uppercase",
			},
		},
		down_payment_amount_caption: {
			type: "typography",
			text: "$0.00 min 0.00% | $0.00 max 70.00% | $0.00 default 20.00%",
			variant: "caption",
			className: "text-center font-light font-sans",
		},
		rental_factor: {
			type: "number",
			label: "Rental Factor",
			value: "",
			required: true,
			readOnly: false,
			upperCase: true,
			style: {
				textTransform: "uppercase",
			},
		},
		rental_factor_caption: {
			type: "typography",
			text: "0.00 min | 0.00 max | 0.00 default",
			variant: "caption",
			className: "text-center font-light font-sans",
		},
		total_contract_value: {
			type: "number",
			label: "Total Contract Value",
			value: "11.00",
			prefix: "$",
			required: true,
			readOnly: false,
			decimalLimit: 2,
			integerOnly: false,
			minValue: 0,
			maxValue: 2000,
		},
		device_condition_id: {
			type: "select",
			label: "Select Merchandise Condition",
			value: "",
			required: true,
			readOnly: false,
			upperCase: true,
			style: {
				textTransform: "uppercase",
			},
			options: [],
		},
		merchandise_description: {
			type: "textarea",
			label: "Merchandise Description",
			value: "",
			required: true,
			readOnly: false,
		},
		payoff_early_policy_id: {
			type: "select",
			label: "Early Payoff Options",
			value: "",
			required: false,
			readOnly: false,
			upperCase: true,
			style: {
				textTransform: "uppercase",
			},
			options: [{ id: "", name: "No Early Payoff Policy Selected..." }],
		},
	},
	layout: [
		{
			type: "div",
			class: "row",
			items: [
				{
					type: "div",
					class: "col-10 offset-lg-1",
					items: [
						{
							type: "div",
							class: "row mb-3",
							items: [
								{
									type: "typography",
									name: "step_title",
									class: "col-md-12",
								},
							],
						},
						{
							type: "div",
							class: "row mb-3",
							items: [
								{
									type: "div",
									class: "col-6",
									items: [
										{
											type: "div",
											class: "row mb-3",
											items: [
												{
													type: "number",
													name: "retail_value",
													class: "col-md-12",
												},
												{
													type: "typography",
													name: "retail_value_caption",
													class: "col-md-12",
												},
											],
										},
									],
								},
								{
									type: "div",
									class: "col-6",
									items: [
										{
											type: "div",
											class: "row mb-3",
											items: [
												{
													type: "number",
													name: "down_payment_amount",
													class: "col-md-12",
												},
												{
													type: "typography",
													name: "down_payment_amount_caption",
													class: "col-md-12",
												},
											],
										},
									],
								},
								{
									type: "div",
									class: "col-6",
									items: [
										{
											type: "div",
											class: "row mb-3",
											items: [
												{
													type: "number",
													name: "rental_factor",
													class: "col-md-12",
												},
												{
													type: "typography",
													name: "rental_factor_caption",
													class: "col-md-12",
												},
											],
										},
									],
								},
								{
									type: "div",
									class: "col-6",
									items: [
										{
											type: "div",
											class: "row mb-3",
											items: [
												{
													type: "number",
													name: "total_contract_value",
													class: "col-md-12",
												},
											],
										},
									],
								},
								{
									type: "div",
									class: "col-6",
									items: [
										{
											type: "div",
											class: "row mb-3",
											items: [
												{
													type: "select",
													name: "device_condition_id",
													class: "col-md-12",
												},
											],
										},
									],
								},
								{
									type: "div",
									class: "col-6",
									items: [
										{
											type: "div",
											class: "row mb-3",
											items: [
												{
													type: "select",
													name: "payoff_early_policy_id",
													class: "col-md-12",
												},
											],
										},
									],
								},
								{
									type: "div",
									class: "col-12",
									items: [
										{
											type: "div",
											class: "row mb-3",
											items: [
												{
													type: "textarea",
													name: "merchandise_description",
													class: "col-md-12",
												},
											],
										},
									],
								},
							],
						},
						{ type: "div", name: "conditionalElementPlaceholder" },
					],
				},
			],
		},
	],
	buttons: [
		{
			type: "button",
			label: "Back",
			align: "left",
			color: "default",
			action: "handleBack",
			disableElevation: true,
			startIcon: <MdChevronLeft />,
		},
		{
			type: "button",
			label: "Cancel & Delete",
			align: "center",
			color: "default",
			action: "handleDelete",
			disableElevation: true,
			startIcon: <MdClose style={{ color: "#ef4444" }} />,
		},
		{
			type: "submit",
			label: "Continue",
			align: "right",
			color: "primary",
			disableElevation: true,
			endIcon: <MdChevronRight />,
		},
	],
};

export const contractsCreateStep4DummyResponse = {
	isOk: true,
	msg: "SUCCESS",
	errors: [],
	step: "step_4",
	max_step: "step_6",
	can_continue: true,
	data: {
		in: null,
		fields: {
			retail_value: "0.00",
			down_payment_percent: "0.00",
			down_payment_amount: "0.00",
			rental_factor: "2.10",
			total_contract_value: "0.00",
			device_condition_id: null,
			lease_type_id: null,
			lease_length: null,
			lease_day: null,
			lease_date: null,
			payoff_early_policy_id: null,
		},
		store_limits: {
			sales_tax: "8.25",
			finance: {
				min: "1.00",
				max: "5.00",
				default: "2.10",
			},
			down_payment: {
				min: "0.00",
				max: "70.00",
				default: "20.00",
				actual: "0.00",
			},
			down_payment_amounts: {
				min: "0.00",
				max: "0.00",
				default: "0.00",
			},
			contract_max_value: "2,000.00",
			payment_fee: "3.00",
		},
		selects: {
			device_condition_id: [
				{
					id: 1,
					name: "New",
				},
				{
					id: 2,
					name: "Used",
				},
				{
					id: 3,
					name: "Refurbished",
				},
			],
			payoff_early_policy_id: [
				{
					id: 3,
					name: "RTO MOBILE - 123 EXAMPLE ST - Payoff #1",
				},
				{
					id: 4,
					name: "RTO MOBILE - 123 EXAMPLE ST - Payoff #2",
				},
			],
			lease_day: null,
			lease_date: null,
		},
		available_plans: {
			months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 18, 24, 30, 36],
			plans: {
				1: {
					Weekly: {
						num_payments: "4",
						payment_amount: "3.00",
					},
					Biweekly: {
						num_payments: "2",
						payment_amount: "3.00",
					},
					Monthly: {
						num_payments: "1",
						payment_amount: "3.00",
					},
				},
				2: {
					Weekly: {
						num_payments: "8",
						payment_amount: "3.00",
					},
					Biweekly: {
						num_payments: "4",
						payment_amount: "3.00",
					},
					Monthly: {
						num_payments: "2",
						payment_amount: "3.00",
					},
				},
				3: {
					Weekly: {
						num_payments: "13",
						payment_amount: "3.00",
					},
					Biweekly: {
						num_payments: "6",
						payment_amount: "3.00",
					},
					Monthly: {
						num_payments: "3",
						payment_amount: "3.00",
					},
				},
				4: {
					Weekly: {
						num_payments: "17",
						payment_amount: "3.00",
					},
					Biweekly: {
						num_payments: "8",
						payment_amount: "3.00",
					},
					Monthly: {
						num_payments: "4",
						payment_amount: "3.00",
					},
				},
				5: {
					Weekly: {
						num_payments: "21",
						payment_amount: "3.00",
					},
					Biweekly: {
						num_payments: "10",
						payment_amount: "3.00",
					},
					Monthly: {
						num_payments: "5",
						payment_amount: "3.00",
					},
				},
				6: {
					Weekly: {
						num_payments: "26",
						payment_amount: "3.00",
					},
					Biweekly: {
						num_payments: "13",
						payment_amount: "3.00",
					},
					Monthly: {
						num_payments: "6",
						payment_amount: "3.00",
					},
				},
				7: {
					Weekly: {
						num_payments: "30",
						payment_amount: "3.00",
					},
					Biweekly: {
						num_payments: "15",
						payment_amount: "3.00",
					},
					Monthly: {
						num_payments: "7",
						payment_amount: "3.00",
					},
				},
				8: {
					Weekly: {
						num_payments: "34",
						payment_amount: "3.00",
					},
					Biweekly: {
						num_payments: "17",
						payment_amount: "3.00",
					},
					Monthly: {
						num_payments: "8",
						payment_amount: "3.00",
					},
				},
				9: {
					Weekly: {
						num_payments: "39",
						payment_amount: "3.00",
					},
					Biweekly: {
						num_payments: "19",
						payment_amount: "3.00",
					},
					Monthly: {
						num_payments: "9",
						payment_amount: "3.00",
					},
				},
				10: {
					Weekly: {
						num_payments: "43",
						payment_amount: "3.00",
					},
					Biweekly: {
						num_payments: "21",
						payment_amount: "3.00",
					},
					Monthly: {
						num_payments: "10",
						payment_amount: "3.00",
					},
				},
				11: {
					Weekly: {
						num_payments: "47",
						payment_amount: "3.00",
					},
					Biweekly: {
						num_payments: "23",
						payment_amount: "3.00",
					},
					Monthly: {
						num_payments: "11",
						payment_amount: "3.00",
					},
				},
				12: {
					Weekly: {
						num_payments: "52",
						payment_amount: "3.00",
					},
					Biweekly: {
						num_payments: "26",
						payment_amount: "3.00",
					},
					Monthly: {
						num_payments: "12",
						payment_amount: "3.00",
					},
				},
				18: {
					Weekly: {
						num_payments: "78",
						payment_amount: "3.00",
					},
					Biweekly: {
						num_payments: "39",
						payment_amount: "3.00",
					},
					Monthly: {
						num_payments: "18",
						payment_amount: "3.00",
					},
				},
				24: {
					Weekly: {
						num_payments: "104",
						payment_amount: "3.00",
					},
					Biweekly: {
						num_payments: "52",
						payment_amount: "3.00",
					},
					Monthly: {
						num_payments: "24",
						payment_amount: "3.00",
					},
				},
				30: {
					Weekly: {
						num_payments: "130",
						payment_amount: "3.00",
					},
					Biweekly: {
						num_payments: "65",
						payment_amount: "3.00",
					},
					Monthly: {
						num_payments: "30",
						payment_amount: "3.00",
					},
				},
				36: {
					Weekly: {
						num_payments: "156",
						payment_amount: "3.00",
					},
					Biweekly: {
						num_payments: "78",
						payment_amount: "3.00",
					},
					Monthly: {
						num_payments: "36",
						payment_amount: "3.00",
					},
				},
			},
		},
	},
	actions: {
		back: {
			step: "step_3",
			tmp_contracts_id: "18384",
		},
		delete: {
			step: "step_0",
			action: "delete",
			tmp_contracts_id: "18384",
		},
		continue: {
			step: "step_5",
			action: "continue",
			tmp_contracts_id: "18384",
		},
	},
};

export const contractsCreateStep5Definition = {
	form: {
		state: false,
		class: "form-vertical",
	},
	fields: {
		step_title: {
			type: "typography",
			text: "Contract Summary",
			variant: "h5",
			className:
				"text-center py-3 text-3xl text-cyan-800 font-light font-sans",
		},
	},
	layout: [
		{
			type: "div",
			class: "row",
			items: [
				{
					type: "div",
					class: "col-10 offset-lg-1",
					items: [
						{
							type: "div",
							class: "row mb-3",
							items: [
								{
									type: "typography",
									name: "step_title",
									class: "col-md-12",
								},
							],
						},
						{ type: "div", name: "conditionalElementPlaceholder" },
					],
				},
			],
		},
	],
	buttons: [
		{
			type: "button",
			label: "Back",
			align: "left",
			color: "default",
			action: "handleBack",
			disableElevation: true,
			startIcon: <MdChevronLeft />,
		},
		{
			type: "button",
			label: "Cancel & Delete",
			align: "center",
			color: "default",
			action: "handleDelete",
			disableElevation: true,
			startIcon: <MdClose style={{ color: "#ef4444" }} />,
		},
		{
			type: "submit",
			label: "Continue",
			align: "right",
			color: "primary",
			disableElevation: true,
			endIcon: <MdChevronRight />,
		},
	],
};

export const contractsCreateStep5DummyResponse = {
	isOk: true,
	msg: "SUCCESS",
	step: "step_5",
	max_step: "step_5",
	data: {
		payment_amount_required: null,
		payment_amount_received: "0.00",
		payments_received: null,
		payments_amount_delta: null,
		payment_methods: {
			CASH: {
				id: null,
				payment_name: "Cash Payment",
				integration: "CASH",
				profile_id: null,
				acct_id: null,
				token: null,
				accountholder_name: null,
				account_type: null,
				cc_last_four: null,
				cc_expiration: null,
				default_account: null,
				tokenizer_url: null,
			},
			CC: {
				id: null,
				payment_name: "New Credit Card",
				integration: "CC",
				profile_id: null,
				acct_id: null,
				token: null,
				accountholder_name: null,
				account_type: null,
				cc_last_four: null,
				cc_expiration: null,
				default_account: null,
				tokenizer_url:
					"https://fts.cardconnect.com/itoke/ajax-tokenizer.html",
			},
		},
		payments: [
			"187263",
			{
				type: "CASH",
				last_4: "",
				total: 100,
				abp_enabled: 0,
				can_void: 1,
				can_abp: 0,
			},
		],
	},
	actions: {
		submit_payment: {
			step: "step_5",
			action: "payment",
			tmp_contracts_id: "18338",
			data: [],
		},
		delete: {
			step: "step_0",
			action: "delete",
			tmp_contracts_id: "18338",
		},
		continue: {
			step: "step_6",
			tmp_contracts_id: "18338",
		},
		back: {
			step: "step_4",
			tmp_contracts_id: "18338",
		},
	},
};

export const contractsCreateStep6Definition = {
	form: {
		state: false,
		class: "form-vertical",
	},
	fields: {
		step_title: {
			type: "typography",
			text: "Payment Collection",
			variant: "h5",
			className:
				"text-center py-3 text-3xl text-cyan-800 font-light font-sans",
		},
	},
	layout: [
		{
			type: "div",
			class: "row",
			items: [
				{
					type: "div",
					class: "col-10 offset-lg-1",
					items: [
						{
							type: "div",
							class: "row mb-3",
							items: [
								{
									type: "typography",
									name: "step_title",
									class: "col-md-12",
								},
							],
						},
						{ type: "div", name: "conditionalElementPlaceholder1" },
						{ type: "div", name: "conditionalElementPlaceholder2" },
						{ type: "div", name: "conditionalElementPlaceholder3" },
					],
				},
			],
		},
	],
	buttons: [
		{
			type: "button",
			label: "Back",
			align: "left",
			color: "default",
			action: "handleBack",
			disableElevation: true,
			startIcon: <MdChevronLeft />,
		},
		{
			type: "button",
			label: "Cancel & Delete",
			align: "center",
			color: "default",
			action: "handleDelete",
			disableElevation: true,
			startIcon: <MdClose style={{ color: "#ef4444" }} />,
		},
		{
			type: "submit",
			label: "Continue",
			align: "right",
			color: "primary",
			disableElevation: true,
			endIcon: <MdChevronRight />,
		},
	],
};

export const contractsCreateStep7Definition = {
	form: {
		state: false,
		class: "form-vertical",
	},
	fields: {
		step_title: {
			type: "typography",
			text: "ABP Enrollment",
			variant: "h5",
			className:
				"text-center py-3 text-3xl text-cyan-800 font-light font-sans",
		},
	},
	layout: [
		{
			type: "div",
			class: "row",
			items: [
				{
					type: "div",
					class: "col-10 offset-lg-1",
					items: [
						{
							type: "div",
							class: "row mb-3",
							items: [
								{
									type: "typography",
									name: "step_title",
									class: "col-md-12",
								},
							],
						},
						{ type: "div", name: "conditionalElementPlaceholder1" },
						{ type: "div", name: "conditionalElementPlaceholder2" },
						{ type: "div", name: "conditionalElementPlaceholder3" },
						{ type: "div", name: "conditionalElementPlaceholder4" },
					],
				},
			],
		},
	],
	buttons: [
		{
			type: "button",
			label: "Back",
			align: "left",
			color: "default",
			action: "handleBack",
			disableElevation: true,
			startIcon: <MdChevronLeft />,
		},
		{
			type: "button",
			label: "Cancel & Delete",
			align: "center",
			color: "default",
			action: "handleDelete",
			disableElevation: true,
			startIcon: <MdClose style={{ color: "#ef4444" }} />,
		},
		{
			type: "submit",
			label: "Continue",
			align: "right",
			color: "primary",
			disableElevation: true,
			endIcon: <MdChevronRight />,
		},
	],
};

export const contractsCreateStep8Definition = {
	form: {
		state: false,
		class: "form-vertical",
	},
	fields: {
		step_title: {
			type: "typography",
			text: "Contract Completion",
			variant: "h5",
			className:
				"text-center py-3 text-3xl text-cyan-800 font-light font-sans",
		},
	},
	layout: [
		{
			type: "div",
			class: "row",
			items: [
				{
					type: "div",
					class: "col-10 offset-lg-1",
					items: [
						{
							type: "div",
							class: "row mb-3",
							items: [
								{
									type: "typography",
									name: "step_title",
									class: "col-md-12",
								},
							],
						},
						{ type: "div", name: "conditionalElementPlaceholder1" },
					],
				},
			],
		},
	],
	buttons: [
		{
			type: "button",
			label: "Back",
			align: "left",
			color: "default",
			action: "handleBack",
			disableElevation: true,
			startIcon: <MdChevronLeft />,
		},
		{
			type: "button",
			label: "Cancel & Delete",
			align: "center",
			color: "default",
			action: "handleDelete",
			disableElevation: true,
			startIcon: <MdClose style={{ color: "#ef4444" }} />,
		},
		{
			type: "submit",
			label: "Continue",
			align: "right",
			color: "primary",
			disableElevation: true,
			endIcon: <MdChevronRight />,
		},
	],
};

export const stateOptions = [
	{ id: "1", name: "Alabama" },
	{ id: "2", name: "Alaska" },
	{ id: "3", name: "Arizona" },
	{ id: "4", name: "Arkansas" },
	{ id: "5", name: "California" },
	{ id: "6", name: "Colorado" },
	{ id: "7", name: "Connecticut" },
	{ id: "8", name: "Delaware" },
	{ id: "51", name: "District of Columbia" },
	{ id: "9", name: "Florida" },
	{ id: "10", name: "Georgia" },
	{ id: "11", name: "Hawaii" },
	{ id: "12", name: "Idaho" },
	{ id: "13", name: "Illinois" },
	{ id: "14", name: "Indiana" },
	{ id: "15", name: "Iowa" },
	{ id: "16", name: "Kansas" },
	{ id: "17", name: "Kentucky" },
	{ id: "18", name: "Louisiana" },
	{ id: "19", name: "Maine" },
	{ id: "20", name: "Maryland" },
	{ id: "21", name: "Massachusetts" },
	{ id: "22", name: "Michigan" },
	{ id: "23", name: "Minnesota" },
	{ id: "24", name: "Mississippi" },
	{ id: "25", name: "Missouri" },
	{ id: "26", name: "Montana" },
	{ id: "27", name: "Nebraska" },
	{ id: "28", name: "Nevada" },
	{ id: "29", name: "New Hampshire" },
	{ id: "30", name: "New Jersey" },
	{ id: "31", name: "New Mexico" },
	{ id: "32", name: "New York" },
	{ id: "33", name: "North Carolina" },
	{ id: "34", name: "North Dakota" },
	{ id: "35", name: "Ohio" },
	{ id: "36", name: "Oklahoma" },
	{ id: "37", name: "Oregon" },
	{ id: "38", name: "Pennsylvania" },
	{ id: "39", name: "Rhode Island" },
	{ id: "40", name: "South Carolina" },
	{ id: "41", name: "South Dakota" },
	{ id: "42", name: "Tennessee" },
	{ id: "43", name: "Texas" },
	{ id: "44", name: "Utah" },
	{ id: "45", name: "Vermont" },
	{ id: "46", name: "Virginia" },
	{ id: "47", name: "Washington" },
	{ id: "48", name: "West Virginia" },
	{ id: "49", name: "Wisconsin" },
	{ id: "50", name: "Wyoming" },
];
