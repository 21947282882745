import React, {
	useEffect,
	useState,
	forwardRef,
	useImperativeHandle,
	useRef,
} from "react";
import CurrencyInput from "react-currency-input-field";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";

const StyledCurrencyInput = styled(CurrencyInput)({
	"& input": {
		textAlign: "right",
	},
});

const CustomInputNumberComponent = forwardRef(
	(
		{
			name,
			label,
			type,
			value,
			onChange,
			onBlur,
			className,
			error,
			helperText,
			...otherProps
		},
		ref
	) => {
		const [internalValue, setInternalValue] = useState(value);
		const [isTouched, setIsTouched] = useState(false);
		const isTouchedRef = useRef(isTouched);
		const [isValid, setIsValid] = useState(true);
		const [errorMessage, setErrorMessage] = useState(helperText);
		const [isMounted, setIsMounted] = useState(false);
		const inputRef = useRef(null);

		const updateValueFormat = (val) => {
			const fieldInfo = getFieldInfo();
			// Explicitly check for 0 and handle it
			if (val === 0) {
				return "0";
			}
			const formattedValue = String(val || "").replace(
				fieldInfo.prefix,
				""
			);
			return formattedValue;
		};

		useEffect(() => {
			if (value !== undefined) {
				setInternalValue(updateValueFormat(value));
			}
		}, [value]);

		useEffect(() => {
			if (isMounted) {
				if (internalValue) {
					setInternalValue(updateValueFormat(internalValue));
				}
			} else {
				setIsMounted(true);
			}
		}, [internalValue]);

		useEffect(() => {
			isTouchedRef.current = isTouched;
		}, [isTouched]);

		useImperativeHandle(ref, () => ({
			validate: (value) => validate(value),
			isTouched: () => isTouchedRef.current,
			setIsTouched: (value) => {
				setIsTouched(value);
				isTouchedRef.current = value;
			},
			focus: () => {
				if (inputRef.current) {
					inputRef.current.focus();
				}
			},
		}));

		const handleChange = (newValue) => {
			setInternalValue(newValue);
			validate(newValue); // Ensure validation is called with the new value
		};

		const handleInternalBlur = (event) => {
			setIsTouched(true);
			validate(internalValue);
			if (onBlur) {
				onBlur(event);
			}
		};

		const getFieldInfo = () => {
			return {
				decimalLimit: (type && type?.decimalLimit) || 0,
				maxValue: type && type?.maxValue,
				minValue: type && type?.minValue,
				placeholder: type && type?.placeholder,
				prefix: (type && type?.prefix) || "",
				readOnly: (type && type?.readOnly) || false,
				required: Boolean(type && type.required),
				suffix: (type && type?.suffix) || "",
				integerOnly: (type && type?.integerOnly) || false,
			};
		};

		const isValidDecimalNumber = (value) => {
			const fieldInfo = getFieldInfo();

			if (fieldInfo.decimalLimit === 0) {
				return isValidIntegerNumber(value);
			}

			let numberRegex = new RegExp(
				`^[0-9]+(\\.[0-9]{1,${fieldInfo.decimalLimit}})?$`
			);
			return numberRegex.test(value);
		};

		const isValidIntegerNumber = (value) => {
			let numberRegex = new RegExp(`^[0-9]+$`);
			return numberRegex.test(value);
		};

		const validate = (currentValue) => {
			const fieldInfo = getFieldInfo();

			let valueToValidate =
				currentValue !== undefined ? currentValue : internalValue;

			const parsedValue =
				valueToValidate === "" || valueToValidate === "$"
					? ""
					: parseFloat(valueToValidate);
			if (fieldInfo.readOnly) {
				setIsValid(true);
				setErrorMessage("");
				onChange(name, valueToValidate, true);
				return true;
			}

			if (fieldInfo.required) {
				if (valueToValidate === "" || valueToValidate === undefined) {
					setIsValid(false);
					setErrorMessage("This field is required.");
					setIsTouched(true);
					onChange(name, valueToValidate, false);
					return false;
				}

				if (
					!fieldInfo.integerOnly &&
					!isValidDecimalNumber(valueToValidate)
				) {
					setIsValid(false);
					setErrorMessage(
						`The value must be in a valid decimal format with ${fieldInfo.decimalLimit} decimal place.`
					);
					setIsTouched(true);
					onChange(name, valueToValidate, false);
					return false;
				}

				if (
					fieldInfo.integerOnly &&
					!isValidIntegerNumber(valueToValidate)
				) {
					setIsValid(false);
					setErrorMessage("Allowed integer numbers only.");
					setIsTouched(true);
					onChange(name, valueToValidate, false);
					return false;
				}
			}

			if (valueToValidate !== "") {
				let minValue = parseFloat(fieldInfo.minValue);
				let maxValue = parseFloat(fieldInfo.maxValue);

				// Check minValue
				if (typeof minValue === "number" && parsedValue < minValue) {
					setIsValid(false);
					setErrorMessage(`Minimum allowed number is ${minValue}.`);
					setIsTouched(true);
					onChange(name, valueToValidate, false);
					return false;
				}
				// Check maxValue
				if (typeof maxValue === "number" && parsedValue > maxValue) {
					setIsValid(false);
					setErrorMessage(`Maximum allowed number is ${maxValue}.`);
					setIsTouched(true);
					onChange(name, valueToValidate, false);
					return false;
				}
			}

			setIsValid(true);
			setErrorMessage("");
			onChange(name, valueToValidate, true);
			return true;
		};

		return (
			<div className={className}>
				<TextField
					id={name}
					label={label}
					variant="standard"
					size="small"
					placeholder={type?.placeholder || ""}
					fullWidth
					type="text" // Since we are using a custom input component
					value={internalValue}
					error={error ? error : isTouchedRef.current && !isValid}
					helperText={
						helperText
							? helperText
							: isTouchedRef.current && !isValid
							? errorMessage
							: ""
					}
					InputLabelProps={{
						shrink:
							internalValue !== "" &&
							internalValue !== undefined &&
							internalValue !== null,
					}}
					InputProps={{
						inputComponent: StyledCurrencyInput,
						inputProps: {
							id: name,
							name: name,
							value: internalValue,
							onValueChange: handleChange,
							onBlur: handleInternalBlur,
							decimalScale: type?.integerOnly
								? 0
								: type?.decimalLimit,
							decimalsLimit: type?.decimalLimit
								? type?.decimalLimit
								: type?.integerOnly
								? 0
								: 2,
							allowNegativeValue: false,
							disabled: type?.readOnly,
							prefix: type?.prefix,
							suffix: type?.suffix,
							...otherProps,
						},
						inputRef: inputRef,
					}}
					{...otherProps}
				/>
			</div>
		);
	}
);

export default CustomInputNumberComponent;
