import ApiService from "../ApiService";

/**
 * @see /docs/api/index.html#Reminder
 */

const LeadService = {
	/**
	 *
	 * @param {LeadView} LeadView
	 * @returns
	 */
	view: (LeadView) => {
		return ApiService.post("/lead/index", LeadView).catch((error) => {
			console.log(error);
			return error;
		});
	},

	/**
	 * Data filter by ID
	 * @param {int} id
	 * @see /docs/api/index.html#jump-Leads-LeadFetchById
	 * @returns JSON
	 */
	fetchById: (id) => {
		return ApiService.get("/lead/fetch-by-id", { id: id }).catch(
			(error) => {
				console.log(error);
			}
		);
	},

	/**
	 * @param LeadCreate
	 * @see /docs/api/index.html#jump-Reminder-apiNew_2Freminder_2Fcreate
	 * @returns JSON
	 */
	create: (LeadCreate) => {
		return ApiService.post("/lead/create", LeadCreate).catch((error) => {
			console.log(error);
		});
	},

	/**
	 * Update service
	 * @param json data {
	 * 		leadId : 1,
	 * 		company_name: 'xxxx',
	 * 		address_1: 'ADD1' ...
	 * }
	 * @see /docs/api/index.html#jump-Leads-LeadUpdate
	 * @returns
	 */
	update: (data) => {
		return ApiService.post("/lead/update", data).catch((error) => {
			console.log(error);
		});
	},

	/**
	 *
	 * @param {Integer} leadId
	 * @see /docs/api/index.html#jump-Leads-LeadDelete
	 * @returns
	 */
	delete: (leadId) => {
		return ApiService.post("/lead/delete", { leadId: leadId }).catch(
			(error) => {
				console.log(error);
			}
		);
	},

	/**
	 * @see /docs/api/index.html#jump-Leads-GetUsersBySysRtoIsoAdminRole
	 * @returns
	 */
	getUsersBySysRtoIsoAdminRole: () => {
		return ApiService.get(
			"/reminder/get-users-by-sys-rto-iso-admin-role"
		).catch((error) => {
			console.log(error);
		});
	},
};

export default LeadService;
