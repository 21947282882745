import React, { useEffect, useState } from "react";
import TopModal from "../../../common/TopModal";
import Grid from "@mui/material/Grid";
import { Box, Divider, TextField } from "@mui/material";
import ContractService from "../../../../service/contract/ContractService";
import { Button as MuiButton } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import CircularProgress from "@mui/material/CircularProgress";
import { toast } from "react-toastify";
import ReschedulePaymentStep from "./Reschedule/ReschedulePaymentStep";
import PaymentTermsForm from "../PaymentTermsForm";
import ContractSummary from "../ContractSummary";

const RescheduleModal = ({ show, onHide, contractId, contractIdShort }) => {
	const [modalLoading, setModalLoading] = useState(false);
	const [currentStep, setCurrentStep] = useState(0);
	const [currentResponse, setCurrentResponse] = useState({});
	const [selectedValue, setSelectedValue] = useState("");
	const [type, setType] = useState(0);
	const [month, setMonth] = useState(0);
	const [selectedDay, setSelectedDay] = useState(0);
	const [selectedDate, setSelectedDate] = useState("");
	const [isContinueDisabled, setIsContinueDisabled] = useState(false);
	const [nextData, setNextData] = useState({});
	const [summaryData, setSummaryData] = useState({});
	const [paymentResponse, setPaymentResponse] = useState({});
	const [selectedPaymentId, setSelectedPaymentId] = useState(null);
	// const navigate = useNavigate();
	const [values, setValues] = useState({
		amount: "0.00",
		fee: "0.00",
	});
	const [displayValue, setDisplayValue] = useState({
		fee: "0.00",
		amount: "0.00",
	});

	useEffect(() => {
		if (show) {
			rescheduleContract({
				contract_id: contractIdShort,
				action: "step_2",
				reschedule: {
					payment_towards_balance: values.amount,
					reschedule_fee: values.fee,
				},
			});
		}
	}, [show]);

	useEffect(() => {
		if (currentStep === 0 && show) {
			setIsContinueDisabled(false);
			rescheduleContract({
				contract_id: contractIdShort,
				action: "step_2",
				reschedule: {
					payment_towards_balance: values.amount,
					reschedule_fee: values.fee,
				},
			});
		} else if (currentStep === 1) {
			rescheduleContract({
				contract_id: contractIdShort,
				action: "step_2",
				reschedule: {
					payment_towards_balance: values.amount,
					reschedule_fee: values.fee,
					lease_type: currentResponse?.reschedule?.lease_type_id,
					lease_length: currentResponse?.reschedule?.lease_length,
					start_day: currentResponse?.reschedule?.start_day,
				},
			});
		} else if (currentStep === 2) {
			setIsContinueDisabled(false);
			rescheduleContract(nextData);
		} else if (currentStep === 3) {
			rescheduleContract(summaryData?.next?.data);
		}
	}, [currentStep]);

	useEffect(() => {
		if (currentStep === 3) {
			if (selectedPaymentId === null) {
				setIsContinueDisabled(true);
			} else {
				setIsContinueDisabled(false);
			}
		}
		console.log("outttttttter", selectedPaymentId);
		// console.log(displayValue);
	}, [selectedPaymentId]);

	const handleClose = (refresh) => {
		onHide(refresh);
		setCurrentStep(0);
		setValues({
			fee: "0.00",
			amount: "0.00",
		});
		setDisplayValue({
			fee: "0.00",
			amount: "0.00",
		});
		setIsContinueDisabled(false);
	};

	const formatAmount = (amount) => {
		if (amount) {
			return Number(amount)?.toFixed(2).toLocaleString();
		}
		return "0.00";
	};
	const changeInputs = (state, value) => {
		const newValue = value;
		if (state === "amount") {
			if (newValue === "" || newValue.match(/^\d*\.?\d{0,2}$/)) {
				setValues((prevInputs) => ({
					...prevInputs,
					amount: value,
				}));
				setDisplayValue((prevState) => ({
					...prevState,
					amount: value,
				}));
			}
		} else if (state === "fee") {
			if (newValue === "" || newValue.match(/^\d*\.?\d{0,2}$/)) {
				setValues((prevInputs) => ({
					...prevInputs,
					fee: value,
				}));
				setDisplayValue((prevState) => ({
					...prevState,
					fee: value,
				}));
			}
		}
	};

	const handleBlur = (state, value) => {
		const formattedValue = value ? parseFloat(value).toFixed(2) : "0.00";
		if (state === "fee") {
			// send step 2 again

			rescheduleContract({
				contract_id: contractIdShort,
				action: "step_2",
				reschedule: {
					payment_towards_balance: values.amount,
					reschedule_fee: formattedValue,
				},
			});

			setDisplayValue((prevState) => ({
				...prevState,
				fee: formattedValue,
			}));
		} else if (state === "amount") {
			rescheduleContract({
				contract_id: contractIdShort,
				action: "step_2",
				reschedule: {
					payment_towards_balance: formattedValue,
					reschedule_fee: values.fee,
				},
			});

			setDisplayValue((prevState) => ({
				...prevState,
				amount: formattedValue,
			}));
		}
	};

	function updateRunningBalance(runningBalance, transactions) {
		transactions.forEach((transaction) => {
			const net = parseFloat(transaction.net); // Convert net string to float
			runningBalance -= net; // Subtract net from running balance
			transaction.running_balance = runningBalance.toFixed(2); // Update running_balance in the transaction object
		});

		return transactions; // Return updated transactions array
	}

	const formatDate = (date) => {
		// Check for the specific "0000-00-00" case
		if (date === "0000-00-00") return "0000/00/00";

		if (!date) return ""; // return empty string if date is not valid
		const d = new Date(date);

		// Check if date is Invalid Date
		if (isNaN(d.getTime())) return ""; // return empty string if date is invalid

		const month = String(d.getMonth() + 1).padStart(2, "0"); // getMonth() is zero-based, pad with leading 0 if necessary
		const day = String(d.getDate()).padStart(2, "0"); // pad with leading 0 if necessary
		const year = d.getFullYear();
		return `${month}/${day}/${year}`;
	};

	const handleChange = (event, month, period) => {
		setSelectedValue(event.target.value);
		setMonth(month);
		setType(period);
		rescheduleContract({
			contract_id: contractIdShort,
			action: "step_2",
			reschedule: {
				payment_towards_balance: values.amount,
				reschedule_fee: values.fee,
				lease_type: period,
				lease_length: month,
			},
		});
	};

	const handleDayChange = (fieldName, value, pIsValid) => {
		setSelectedDay(value !== "" ? value : "");
		rescheduleContract({
			contract_id: contractIdShort,
			action: "step_2",
			reschedule: {
				payment_towards_balance: values.amount,
				reschedule_fee: values.fee,
				lease_type: currentResponse?.reschedule?.lease_type_id,
				lease_length: currentResponse?.reschedule?.lease_length,
				start_day: value,
			},
		});
	};

	const handleDateChange = (event) => {
		// console.log(event.target.value);
		setSelectedDate(event.target.value !== "" ? event.target.value : null);
		rescheduleContract({
			contract_id: contractIdShort,
			action: "step_2",
			reschedule: {
				payment_towards_balance: values.amount,
				reschedule_fee: values.fee,
				lease_type: currentResponse?.reschedule?.lease_type_id,
				lease_length: currentResponse?.reschedule?.lease_length,
				start_day: currentResponse?.reschedule?.start_day,
				start_date: event.target.value,
			},
		});
	};

	const rescheduleContract = async (data) => {
		// updateRunningBalance;
		try {
			setModalLoading(true);
			const response = await ContractService.rescheduleContract(data);
			if (currentStep === 3) {
				setPaymentResponse(response?.data);
			} else if (currentStep === 2) {
				// let updated_invoices = updateRunningBalance(
				// 		response?.data?.reschedule?.balance_after_payment,
				// 	response?.data?.reschedule?.invoices?.data?.invoices
				// );

				let updated_response = response?.data;

				updated_response.reschedule.invoices.data.invoices =
					updateRunningBalance(
						updated_response?.reschedule?.balance_after_payment,
						updated_response?.reschedule?.invoices?.data?.invoices
					);
				setSummaryData(updated_response);
			} else {
				setCurrentResponse(response?.data);

				setDisplayValue({
					fee: response?.data?.reschedule?.reschedule_fee,
					amount: response?.data?.reschedule?.payment_towards_balance,
				});

				setValues({
					amount: response?.data?.reschedule?.payment_towards_balance,
					fee: response?.data?.reschedule?.reschedule_fee,
				});

				if (Number(response?.data?.reschedule?.lease_type_id) === 1) {
					setSelectedValue(
						`Monthly-${response?.data?.reschedule?.lease_length}`
					);
				} else if (
					Number(response?.data?.reschedule?.lease_type_id) === 2
				) {
					setSelectedValue(
						`Weekly-${response?.data?.reschedule?.lease_length}`
					);
				} else if (
					Number(response?.data?.reschedule?.lease_type_id) === 3
				) {
					setSelectedValue(
						`Biweekly-${response?.data?.reschedule?.lease_length}`
					);
				}

				setMonth(response?.data?.reschedule?.lease_length);
				setType(response?.data?.reschedule?.lease_type_id);

				setSelectedDay(response?.data?.reschedule?.start_day);
				setSelectedDate(response?.data?.reschedule?.start_date);

				if (currentStep === 1) {
					//console.log(currentStep);
					if (response?.data?.reschedule?.check) {
						setIsContinueDisabled(false);
						setNextData(response?.data?.next?.data);
					} else {
						setIsContinueDisabled(true);
					}
					//setIsContinueDisabled(false);
					//setNextData(response?.data?.next?.data);
				}
			}

			setModalLoading(false);
		} catch (error) {
			setModalLoading(false);
			console.error("Error fetching Contract:", error);
		}
	};

	const confirmReschedule = async (data) => {
		try {
			setModalLoading(true);
			const response = await ContractService.rescheduleContract(data);
			if (response?.data?.isOk) {
				handleClose(true);
				toast.success(`Contract Rescheduled Successfully!`, {
					position: "top-center",
					autoClose: 1000,
				});
			} else {
				toast.error(
					`Error Rescheduling Contract: ${response?.data?.message}`,
					{
						position: "top-center",
						autoClose: 1000,
					}
				);
			}
			// navigate("/contracts/view-v3/82661923");
			handleClose(true);
			setModalLoading(false);
		} catch (error) {
			setModalLoading(false);
			console.error("Error fetching Contract:", error);
		}
	};

	const steps = [
		<>
			<Grid container spacing={2}>
				<Grid item xs={12} lg={6}>
					<Box
						sx={{
							display: "flex",
							justifyContent: "flex-start",
							color: "#21557a",
						}}
					>
						Prior Outstanding Balance:
					</Box>
				</Grid>
				<Grid item xs={12} lg={6}>
					<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
						$
						{formatAmount(
							currentResponse?.reschedule
								?.prior_outstanding_balance
						)}
					</Box>
				</Grid>
				<Grid
					item
					xs={12}
					lg={6}
					sx={{
						display: "flex",
						justifyContent: "flex-start",
						alignItems: "center",
						color: "#21557a",
					}}
				>
					<Box>Reschedule Fee: </Box>
				</Grid>
				<Grid item xs={12} lg={6}>
					<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
						<TextField
							inputProps={{
								style: {
									textAlign: "right",
								},
							}}
							type="number"
							label=""
							value={displayValue?.fee}
							onChange={(event) => {
								changeInputs("fee", event.target.value);
							}}
							onBlur={(event) => {
								handleBlur("fee", event.target.value);
							}}
						/>
					</Box>
				</Grid>
				<Grid item xs={12} lg={6}>
					<Box
						sx={{
							display: "flex",
							justifyContent: "flex-start",
							color: "#21557a",
						}}
					>
						New Contract Balance:
					</Box>
				</Grid>
				<Grid item xs={12} lg={6}>
					<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
						$
						{formatAmount(
							currentResponse?.reschedule?.new_contract_balance
						)}
					</Box>
				</Grid>
				<Grid
					item
					xs={12}
					lg={6}
					sx={{
						display: "flex",
						justifyContent: "flex-start",
						alignItems: "center",
						color: "#21557a",
					}}
				>
					<Box>Payment Amount Towards Balance:</Box>
				</Grid>
				<Grid item xs={12} lg={6}>
					<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
						<TextField
							inputProps={{
								style: {
									textAlign: "right",
								},
							}}
							style={{ padding: "0px !important" }}
							type="number"
							label=""
							value={displayValue?.amount}
							onChange={(event) => {
								changeInputs("amount", event.target.value);
							}}
							onBlur={(event) => {
								handleBlur("amount", event.target.value);
							}}
						/>
					</Box>
				</Grid>
			</Grid>
			<Box
				sx={{
					flexGrow: 1,
					background: "#F5F5F5",
					borderRadius: "10px",
					color: "#21557a",
					padding: "10px",
				}}
				marginTop={2}
			>
				<Grid container rowSpacing={2} columnSpacing={1}>
					<Grid
						item
						xs={6}
						sx={{
							textAlign: "left",
						}}
					>
						{`Tax [${
							currentResponse?.reschedule?.payment_tax_rate
								? currentResponse?.reschedule?.payment_tax_rate
								: "0.00"
						}%]:`}
					</Grid>
					<Grid
						item
						xs={6}
						sx={{
							textAlign: "right",
							color: "black",
						}}
					>
						$
						{formatAmount(
							currentResponse?.reschedule?.payment_tax
								? currentResponse?.reschedule?.payment_tax
								: "0.00"
						)}
					</Grid>
					<Grid
						item
						xs={6}
						sx={{
							textAlign: "left",
						}}
					>
						Payment Fee:
					</Grid>
					<Grid
						item
						xs={6}
						sx={{
							textAlign: "right",
							color: "black",
						}}
					>
						$
						{formatAmount(
							currentResponse?.reschedule?.payment_fee
								? currentResponse?.reschedule?.payment_fee
								: "0.00"
						)}
					</Grid>
					<Grid
						item
						xs={6}
						sx={{
							textAlign: "left",
						}}
					>
						Total Payment Amount:
					</Grid>
					<Grid
						item
						xs={6}
						sx={{
							textAlign: "right",
							color: "black",
						}}
					>
						$
						{formatAmount(
							currentResponse?.reschedule?.payment_total
								? currentResponse?.reschedule?.payment_total
								: "0.00"
						)}
					</Grid>
				</Grid>
			</Box>
			<Box sx={{ flexGrow: 1, color: "#21557a" }} marginTop={2}>
				<Grid container spacing={1}>
					<Grid
						item
						xs={6}
						sx={{
							textAlign: "left",
						}}
					>
						Balance After Payment:
					</Grid>
					<Grid
						item
						xs={6}
						sx={{
							textAlign: "right",
							color: "black",
						}}
					>
						$
						{formatAmount(
							currentResponse?.reschedule?.balance_after_payment
						)}
					</Grid>
				</Grid>
			</Box>
		</>,
		<>
			<Grid container spacing={2}>
				<Grid item xs={12} lg={6}>
					<Box
						sx={{
							display: "flex",
							justifyContent: "flex-start",
							color: "#21557a",
						}}
					>
						Balance After Payment:
					</Box>
				</Grid>
				<Grid item xs={12} lg={6}>
					<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
						$
						{formatAmount(
							currentResponse?.reschedule?.balance_after_payment
						)}
					</Box>
				</Grid>
			</Grid>
			<Divider
				sx={{
					borderColor: "#145d77",
					margin: "30px 0px",
					borderWidth: "1px 0px",
				}}
			/>

			<PaymentTermsForm
				availablePlans={currentResponse?.reschedule?.available_plans}
				planSelectDays={currentResponse?.reschedule?.select_day}
				planSelectDates={currentResponse?.reschedule?.select_date}
				leaseTypeId={currentResponse?.reschedule?.lease_type_id}
				planSelectedValue={selectedValue}
				planSelectedDay={selectedDay}
				planSelectedDate={selectedDate}
				PlanDayOnChange={handleDayChange}
				PlanDateOnChange={handleDateChange}
				paymentTermOnChange={handleChange}
			/>
		</>,
		<>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Box
						sx={{
							textAlign: "center",
						}}
					>
						You have chosen a{" "}
						<strong>
							{summaryData?.reschedule?.lease_length
								? summaryData?.reschedule?.lease_length
								: month}{" "}
							month
						</strong>{" "}
						contract with a{" "}
						<strong>
							{/* {currentResponse?.reschedule?.lease_type === "1"
								? "Monthly"
								: summaryData?.reschedule?.lease_type === "2"
								? "Weekly"
								: summaryData?.reschedule?.lease_type === "3"
								? "BiWeekly"
								: ""} */}
							{summaryData?.reschedule?.lease_type
								? summaryData?.reschedule?.lease_type === "1"
									? "Monthly"
									: summaryData?.reschedule?.lease_type ===
									  "2"
									? "Weekly"
									: summaryData?.reschedule?.lease_type ===
									  "3"
									? "BiWeekly"
									: ""
								: type === "1"
								? "Monthly"
								: type === "2"
								? "Weekly"
								: type === "3"
								? "BiWeekly"
								: ""}
						</strong>{" "}
						payment plan. You will have a total of{" "}
						<strong>
							{
								summaryData?.reschedule?.invoices?.data
									?.invoice_count
							}{" "}
							payments
						</strong>
						.
					</Box>
				</Grid>
				<Grid item xs={12}>
					{summaryData?.next?.data?.action === "step_4" ? (
						<Box sx={{ textAlign: "center" }}>
							Today's Payment of{" "}
							<strong>
								$
								{
									summaryData?.reschedule
										?.payment_towards_balance
								}
							</strong>
							, Sales Tax of{" "}
							<strong>
								${summaryData?.reschedule?.payment_tax_rate}
							</strong>{" "}
							and Payment Fee of{" "}
							<strong>
								${summaryData?.reschedule?.payment_fee}
							</strong>{" "}
							totaling{" "}
							<strong>
								${summaryData?.reschedule?.payment_total}
							</strong>{" "}
							is due today or the device will be locked{" "}
						</Box>
					) : (
						<Box sx={{ textAlign: "center" }}>
							<strong>NO PAYMENT</strong> is due today of{" "}
						</Box>
					)}
				</Grid>
				<Grid
					item
					xs={12}
					sx={{
						textAlign: "center",
					}}
				>
					{summaryData?.next?.data?.action === "step_4" && (
						<Box style={{ color: "red" }}>
							* Payment will be collected in the next step.{" "}
						</Box>
					)}
					<Box>
						{" "}
						<ContractSummary
							paymentPlans={summaryData?.reschedule}
						/>
					</Box>
				</Grid>
			</Grid>
		</>,
		<>
			<ReschedulePaymentStep
				setDisabledButton={setIsContinueDisabled}
				reschedulePaymentData={paymentResponse}
				setPaymentId={setSelectedPaymentId}
			></ReschedulePaymentStep>
		</>,
	];
	const modal_footer = (
		<div
			style={{
				display: "flex",
				justifyContent: "space-between",
				width: "100%",
			}}
		>
			<MuiButton
				// variant="danger"
				variant="outlined"
				color="error"
				disabled={modalLoading}
				onClick={() => {
					if (currentStep !== 0) {
						setCurrentStep(currentStep - 1);
					} else {
						handleClose(false);
					}
				}}
			>
				<ChevronLeftIcon></ChevronLeftIcon>
				{currentStep === 0 ? " Cancel" : " Back"}
			</MuiButton>

			<MuiButton
				variant="contained"
				color="primary"
				disabled={modalLoading || isContinueDisabled}
				onClick={() => {
					if (currentStep === 2) {
						if (summaryData?.next?.data?.action === "step_4") {
							setCurrentStep(currentStep + 1);
						} else {
							confirmReschedule(summaryData?.next?.data);
						}
					} else if (currentStep === 3) {
						let finalPaymentData = paymentResponse?.next?.data;
						finalPaymentData.payment.payment_method_id =
							selectedPaymentId;
						confirmReschedule(finalPaymentData);
					} else {
						setCurrentStep(currentStep + 1);
					}
				}}
			>
				{currentStep === 2 ? (
					summaryData?.next?.data?.action === "step_4" ? (
						<>
							Continue To Payment{" "}
							<ChevronRightIcon></ChevronRightIcon>
						</>
					) : (
						<>
							Confirm Reschedule{" "}
							<ChevronRightIcon></ChevronRightIcon>
						</>
					)
				) : currentStep === 3 ? (
					<>
						{paymentResponse?.next?.data?.button}{" "}
						<ChevronRightIcon></ChevronRightIcon>
					</>
				) : (
					<>
						Continue <ChevronRightIcon></ChevronRightIcon>
					</>
				)}
			</MuiButton>
		</div>
	);

	return (
		<>
			<TopModal
				isVisible={show}
				body={
					<>
						{steps[currentStep]}{" "}
						{modalLoading && (
							<div className="loading-backdrop">
								<CircularProgress
								// animation="border"
								// variant="primary"
								/>
							</div>
						)}
					</>
				}
				title={`Reschedule Contract - ${contractId}`}
				footer={modal_footer}
				onClose={() => {
					handleClose(false);
				}}
				loading={modalLoading}
			></TopModal>
		</>
	);
};

export default RescheduleModal;
