import React from "react";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
} from "@mui/material";
import { formatDate } from "../../../helpers/Utils";

/**
 * @param {*} paymentPlans array of payment plans
 *  {
        "balance_after_payment": "430.50",
        "invoices": {
            "data": {
                "invoice_count": "7",
                "invoices": [
                    {
                        "date": "2024-07-23",
                        "net": "61.50",
                        "tax_rate": "6.00",
                        "tax": "3.69",
                        "fee": "0.00",
                        "total": "65.19",
                        "running_balance": "61.50"
                    },
                    {
                        "date": "2024-08-23",
                        "net": "61.50",
                        "tax_rate": "6.00",
                        "tax": "3.69",
                        "fee": "0.00",
                        "total": "65.19",
                        "running_balance": "61.50"
                    },
                    {
                        "date": "2024-09-23",
                        "net": "61.50",
                        "tax_rate": "6.00",
                        "tax": "3.69",
                        "fee": "0.00",
                        "total": "65.19",
                        "running_balance": "61.50"
                    },
                    {
                        "date": "2024-10-23",
                        "net": "61.50",
                        "tax_rate": "6.00",
                        "tax": "3.69",
                        "fee": "0.00",
                        "total": "65.19",
                        "running_balance": "61.50"
                    },
                    {
                        "date": "2024-11-23",
                        "net": "61.50",
                        "tax_rate": "6.00",
                        "tax": "3.69",
                        "fee": "0.00",
                        "total": "65.19",
                        "running_balance": "61.50"
                    },
                    {
                        "date": "2024-12-23",
                        "net": "61.50",
                        "tax_rate": "6.00",
                        "tax": "3.69",
                        "fee": "0.00",
                        "total": "65.19",
                        "running_balance": "61.50"
                    },
                    {
                        "date": "2025-01-23",
                        "net": "61.50",
                        "tax_rate": "6.00",
                        "tax": "3.69",
                        "fee": "0.00",
                        "total": "65.19",
                        "running_balance": "123.00"
                    }
                ]
            }
        },
    }
 */
const ContractSummary = ({ paymentPlans = [] }) => {
	return (
		<div>
			<TableContainer component={Paper}>
				<Table sx={{ minWidth: 650 }} aria-label="customized table">
					<TableHead>
						<TableRow>
							<TableCell align="center" sx={{ padding: "5px" }}>
								Date
							</TableCell>
							<TableCell sx={{ padding: "5px" }} align="right">
								Rental Payment
							</TableCell>
							<TableCell sx={{ padding: "5px" }} align="right">
								Sales Tax
							</TableCell>
							<TableCell sx={{ padding: "5px" }} align="right">
								Payment Fee
							</TableCell>
							<TableCell sx={{ padding: "5px" }} align="right">
								Total Payments
							</TableCell>
							<TableCell sx={{ padding: "5px" }} align="center">
								Balance
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						<TableRow>
							<TableCell
								sx={{ padding: "5px" }}
								component="th"
								scope="row"
								align="center"
							>
								Starting Balance
							</TableCell>
							<TableCell
								sx={{ padding: "5px" }}
								align="right"
							></TableCell>
							<TableCell
								sx={{ padding: "5px" }}
								align="right"
							></TableCell>
							<TableCell
								sx={{ padding: "5px" }}
								align="right"
							></TableCell>
							<TableCell
								sx={{ padding: "5px" }}
								align="right"
							></TableCell>
							<TableCell sx={{ padding: "5px" }} align="center">
								${paymentPlans?.balance_after_payment}
							</TableCell>
						</TableRow>
						{paymentPlans?.invoices?.data?.invoices?.map((row) => (
							<TableRow key={row.date}>
								<TableCell component="th" scope="row">
									{formatDate(row.date)}
								</TableCell>
								<TableCell align="right">${row.net}</TableCell>
								<TableCell align="right">${row.tax}</TableCell>
								<TableCell align="right">${row.fee}</TableCell>
								<TableCell align="right">
									${row.total}
								</TableCell>
								<TableCell align="right">
									${row.running_balance}
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</div>
	);
};
export default ContractSummary;
